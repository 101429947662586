import { RouteObject } from 'react-router';
import AppLayout from '@pages/_layout/AppLayout';
import { TechnicalWorks } from '@pages/PageError';

export default [
  {
    path: '/wind',
    element: <AppLayout />,
    children: [{ index: true, element: <TechnicalWorks /> }],
  },
] as RouteObject[];
