import generateDevices from './generateDevices';

export enum SiteTypes {
  BMS = 'bms',
  TESLA = 'tesla',
  POWIN = 'powin',
}

const deviceFilter = (type: SiteTypes | null) => {
  const bmsTypes = ['bms', 'rack', 'meter', 'pcs'];
  const teslaTypes = ['tsc', 'mp'];
  const typesToFilter = type === SiteTypes.BMS ? bmsTypes : teslaTypes;

  if (!type) {
    return generateDevices;
  }

  return {
    siteControllerId: generateDevices.siteControllerId,
    unitControllerIds:
      type === SiteTypes.BMS && generateDevices.unitControllers?.engineeringIds
        ? generateDevices.unitControllers?.engineeringIds
        : [],
    sourceDevices: generateDevices.sourceDevices?.filter(
      (device) => typesToFilter.includes(device.type) || [],
    ),
  };
};

export { deviceFilter };
