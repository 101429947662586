import BMSDetail from './BMSDetail';
import RackContainer from './RackContainer';
import { Box, Stack } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { memo } from 'react';
import { PointConfig } from '@src/types/PointConfig';
import { bmsSx } from '@src/components/_shared/sxStyles/Battery/deivceTableList';

interface BMSRow {
  id: GridRowParams['id'];
  name: string;
  [key: string]: PointConfig | string | GridRowParams['id'];
}

interface BMSProps {
  row: BMSRow;
  unitControllerId: string;
  unitEngineeringId: string;
}

const BMSMasterDetail = memo(
  ({ row, unitControllerId, unitEngineeringId }: BMSProps) => (
    <Box sx={bmsSx.detailWrapper}>
      <Stack spacing={2}>
        <BMSDetail
          bmsId={row.id}
          bmsName={row.name}
          unitControllerId={unitControllerId}
          unitEngineeringId={unitEngineeringId}
        />
        <RackContainer
          bmsId={row.id}
          unitControllerId={unitControllerId}
          unitEngineeringId={unitEngineeringId}
        />
      </Stack>
    </Box>
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.row.id === nextProps.row.id &&
      prevProps.unitControllerId === nextProps.unitControllerId &&
      prevProps.unitEngineeringId === nextProps.unitEngineeringId
    );
  },
);

export default BMSMasterDetail;
