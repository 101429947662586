import { useState } from 'react';
import GaugeChart from '@components/_elements/GaugeChart/GaugeChart';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';
import { getSiteTemperatureUnit } from '@utils/getTemperatureString';

const GaugeContainer = ({ siteMeta }) => {
  const [siteRt, setSiteRtData] = useState({});

  useWsSubscribe({
    site: {
      fields: ['MinAmbT', 'MaxAmbT', 'AvgHumidity', 'MinCellT', 'MaxCellT'],
      cb: (data) => setSiteRtData((prev) => ({ ...prev, ...data })),
    },
  });

  const {
    Cell_temp_GB_high,
    Cell_temp_GB_low,
    Cell_temp_range_high,
    Cell_temp_range_low,
    Amb_Temp_GB_high,
    Amb_Temp_GB_low,
    Amb_Temp_Range_High,
    Amb_Temp_Range_Low,
  } = siteMeta;

  const isLoading = (field) => {
    return !Object.keys(siteRt).includes(field);
  };

  return (
    <div className='cell alarm-and-warning block-container'>
      <div className='col-container' style={{ flexDirection: 'column' }}>
        <div className='row-gauge-chart'>
          <GaugeChart
            width='100%'
            loading={isLoading('MinAmbT') || isLoading('MaxAmbT')}
            key={1}
            min={Amb_Temp_Range_Low || 0}
            max={Amb_Temp_Range_High || 50}
            minRangeNorm={Amb_Temp_GB_low || 18}
            maxRangeNorm={Amb_Temp_GB_high || 28}
            minValue={+(+siteRt.MinAmbT).toFixed(0)}
            maxValue={+(+siteRt.MaxAmbT).toFixed(0)}
            units={`°${getSiteTemperatureUnit()}`}
            title='Ambient Temperature'
            gradient='ambientGradient'
          />
          {siteMeta.ui.features?.Humidity_Gauge_Enabled ? (
            <GaugeChart
              width='100%'
              key={2}
              min={0}
              max={100}
              loading={isLoading('AvgHumidity')}
              value={+(+siteRt.AvgHumidity).toFixed(0)}
              units='%'
              title='Humidity'
              gradient='humidityGradient'
            />
          ) : null}
          <GaugeChart
            width='100%'
            key={3}
            minRangeNorm={Cell_temp_GB_low || 15}
            maxRangeNorm={Cell_temp_GB_high || 35}
            min={Cell_temp_range_low || 0}
            max={Cell_temp_range_high || 60}
            minValue={+(+siteRt.MinCellT + 0.01).toFixed(0)}
            maxValue={+(+siteRt.MaxCellT).toFixed(0)}
            loading={isLoading('MinCellT') || isLoading('MaxCellT')}
            units={`°${getSiteTemperatureUnit()}`}
            title='Cell Temperature'
            gradient='cellGradient'
          />
        </div>
      </div>
    </div>
  );
};

export default storeConnector(GaugeContainer, {
  config: ['siteMeta'],
});
