import MultiColPointDataTable from '@components/_shared/MultiColPointDataTable';
import UIBox from '@components/_shared/UIBox';
import { useCurrentTscDeviceContext } from '@hooks/useCurrentTscDevice';
import { Grid2 } from '@mui/material';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypePointList } from '@src/types/Widget';
import { useEffect, useMemo } from 'react';

function TeslaControllerDetails() {
  const { currentTscDevice } = useCurrentTscDeviceContext();
  const {
    data: widgetConfig,
    isLoading,
    refetch,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypePointList>(
    'tscDetails',
    {
      sourceDeviceId: currentTscDevice?.deviceId,
    },
    { enabled: !!currentTscDevice?.deviceId },
  );

  useEffect(() => {
    if (currentTscDevice?.deviceId) {
      refetch();
    }
  }, [refetch, currentTscDevice]);

  const memoizedDataPoints = useMemo(
    () => widgetConfig?.dataPoints || [],
    [widgetConfig?.dataPoints],
  );

  return (
    <UIBox
      header={widgetConfig?.header}
      subheader={currentTscDevice?.name}
      isLoading={isLoading || (!isLoading && !widgetConfig && !isError)}
      isError={isError || (!isLoading && !widgetConfig)}
      style={{ minHeight: 'min-content' }}
    >
      <Grid2 container justifyContent='space-evenly'>
        {memoizedDataPoints.length > 0 ? (
          <MultiColPointDataTable dataPointsMatrix={memoizedDataPoints} />
        ) : (
          'No data points set up for this widget.'
        )}
      </Grid2>
    </UIBox>
  );
}

export default TeslaControllerDetails;
