import { Breakpoint, Dialog, Paper, PaperProps } from '@mui/material';
import { ReactNode } from 'react';
import Draggable from 'react-draggable';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  children,
  onClose,
  maxWidth,
  fullWidth,
}: {
  children: ReactNode;
  onClose: () => void;
  maxWidth?: Breakpoint | false;
  fullWidth?: boolean;
}) {
  return (
    <Dialog
      open
      maxWidth={maxWidth ?? 'xs'}
      fullWidth={fullWidth ?? true}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby='draggable-dialog-title'
    >
      {children}
    </Dialog>
  );
}
