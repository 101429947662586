import SimplePointDataTableWithSubscribe from '@components/_shared/SimplePointDataTableWithSubscribe';
import { Grid2 } from '@mui/material';
import { MqttClient } from 'mqtt';
import { DataPoint } from '@src/types/DataPoint';

interface Props {
  dataPointsMatrix: DataPoint[][];
  useNewClient?: MqttClient;
}

function MultiColPointDataTable(props: Props) {
  return props.dataPointsMatrix.map((columnSectionDataPoints, i) => (
    <Grid2
      size={{ xs: Math.floor(12 / props.dataPointsMatrix.length) }}
      key={`datapoint-matrix-grid-item-${i + 1}`}
    >
      <SimplePointDataTableWithSubscribe
        data={columnSectionDataPoints}
        useNewClient={props.useNewClient}
      />
    </Grid2>
  ));
}

export default MultiColPointDataTable;
