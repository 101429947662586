import { Dialog, DialogTitle } from '@mui/material';
import useUserCreate, {
  AddUserFormValues,
} from '@src/hooks/api/mutations/useUserCreate';
import storeConnector from '@store/storeConnector';
import { Actions } from '@src/types/Actions';
import QueryKeys from '@src/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import AddUserForm from '../Forms/AddUserForm';

interface AddUserDialogProps {
  open: boolean;
  onClose: () => void;
  actions: Actions;
}

function AddUserDialog({ open, onClose, actions }: AddUserDialogProps) {
  const queryClient = useQueryClient();
  const { mutate: createUser, isPending } = useUserCreate();

  const handleSubmit = async ({
    passwordConfirmation: _,
    ...values
  }: AddUserFormValues) => {
    createUser(values, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.users] });
        actions.notify('Successfully created user.');
        onClose();
      },
      onError: (error: Error) => {
        const customError = error.cause as { error?: { message: string } };
        actions.notifyError(
          customError?.error?.message ?? 'Failed to create user.',
        );
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Add User</DialogTitle>
      <AddUserForm
        onCancel={onClose}
        onSubmit={handleSubmit}
        isLoading={isPending}
      />
    </Dialog>
  );
}

export default storeConnector(AddUserDialog, {});
