import storeConnector from '@store/storeConnector';
import { ConfigState } from '@src/store/reducers/configData';

function FractalModelIframe({ siteMeta }: ConfigState) {
  return (
    <div className='flex-row perspectiveContainer'>
      <iframe
        width='100%'
        height='100%'
        style={{
          overflow: 'hidden',
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        allow='fullscreen'
        src={
          siteMeta.ui.FractalModelURL ??
          'https://app.fractalmodel.com/corporate/login'
        }
      />
    </div>
  );
}

export default storeConnector(FractalModelIframe, { config: ['siteMeta'] });
