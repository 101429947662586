import { ReactElement } from 'react';

interface VerticalLineProps {
  translateXCoordinate: number;
  translateYCoordinate: number;
  length: number;
  color: string;
  width: number;
}

function VerticalLine(props: VerticalLineProps): ReactElement {
  const { translateXCoordinate, translateYCoordinate, length, color, width } =
    props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;
  const lineStyles = {
    style: {
      stroke: color,
      strokeWidth: width,
    },
  };

  return (
    <g transform={translate}>
      <line x1={0} y1={0} x2={0} y2={length} style={{ ...lineStyles.style }} />
    </g>
  );
}

export default VerticalLine;
