import { ReactElement } from 'react';

interface HorizontalLineProps {
  translateXCoordinate: number;
  translateYCoordinate: number;
  length: number;
  color: string;
  width: number;
}

function HorizontalLine(props: HorizontalLineProps): ReactElement {
  const { translateXCoordinate, translateYCoordinate, color, width, length } =
    props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;
  const lineStyles = {
    style: {
      stroke: color,
      strokeWidth: width,
    },
  };

  return (
    <g transform={translate}>
      <line x1={0} y1={0} x2={length} y2={0} style={{ ...lineStyles.style }} />
    </g>
  );
}

export default HorizontalLine;
