import { Button, Container, Stack } from '@mui/material';
import { SiteTypes } from '@src/utils/defaultDataStubs/stubFilters';
import SiteMetaEditor from '../_shared/SiteMetaEditor';
import wizardSx from './WizardSx';

interface SiteSettingsProps {
  type: string | null;
  updateSiteType: (siteType: SiteTypes) => void;
}

export default function SiteSettings({
  type,
  updateSiteType,
}: SiteSettingsProps) {
  const siteTypes = ['bms', 'tesla', 'powin'];

  return (
    <>
      <Stack gap={5} useFlexGap direction='row' sx={wizardSx.siteSettings}>
        {siteTypes.map((t) => (
          <Button
            onClick={() => updateSiteType(t as SiteTypes)}
            key={t}
            size='large'
            variant={t === type ? 'contained' : 'outlined'}
            sx={wizardSx.siteSettings.siteButton}
          >
            {t}
          </Button>
        ))}
      </Stack>

      {type && (
        <Container
          maxWidth='lg'
          sx={wizardSx.siteSettings.siteMetaEditorContainer}
        >
          <SiteMetaEditor inWizard />
        </Container>
      )}
    </>
  );
}
