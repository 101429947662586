import { DataPointWithValue } from '@src/types/DataPoint';
import { SiteMeta } from '@src/types/SiteMeta';

export type UnitConfig = {
  unitLabel: string;
  kw: string | undefined;
  kvar: string | undefined;
  soc: string | undefined;
  racksonline: string | undefined;
  invstatus: string | undefined;
  runmode: string | undefined;
};

export type DefaultSLDProps = {
  siteMeta: SiteMeta;
};

export type BreakerStackProps = {
  breakerStatus: DataPointWithValue | undefined;
  meterDataPoints: DataPointWithValue[] | undefined;
  translateXCoordinate: number;
  translateYCoordinate: number;
  meterDataPointsSectionXCoordinate: number;
  meterDataPointsSectionYCoordinate: number;
  feederALabel?: string;
  feederBLabel?: string;
  breakerLabel?: string;
  switchLabel?: string;
  meterLabel?: string;
  resistorLabel?: string;
};

export type TopSwitchResistorProps = {
  translateXCoordinate: number;
  translateYCoordinate: number;
  meterDataPoints: DataPointWithValue[] | undefined;
  breakerStatus: DataPointWithValue | undefined;
  switchStatus: DataPointWithValue | undefined;
  breakerLabel?: string;
  switchLabel?: string;
  meterLabel?: string;
  resistorLabel?: string;
};

export enum UnitDataPoints {
  KW = 'kW',
  KVAR = 'kVAR',
  SOC = 'SOC',
  RacksOnline = 'racks_online',
  InvStatus = 'InvStatus',
  RunMode = 'RunMode',
}
