import { RouteObject } from 'react-router';
import UserManagement from './pages/UserManagement/routes';
import AuditLogs from './pages/AuditLogs/routes';

export default [
  {
    path: '/admin',
    children: [...UserManagement, ...AuditLogs],
  },
] as RouteObject[];
