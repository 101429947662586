import { SiteMeta } from '@src/types/SiteMeta';
import { Interval, IntervalUnit, SavedSearch } from '@types';
import { ReactElement } from 'react';
import { currentTzString } from '@utils';
import Input from '@components/_elements/Input/Input';
import { AGREG_TYPES, checkShouldAggr } from './historianUtils';
import DateTimePicker from './DateTimePicker';

interface InputsProps {
  interval: Interval;
  setSearchField: <T>(field: string, value: T) => void;
}
interface InputProps {
  value: number;
  type: string;
  placeholder: string;
  className: string;
  onChange: (val: string) => void;
  options?: IntervalUnit[];
}

const s = {
  gridTimeStamp: {
    alignItems: 'center',
  },
  label: {
    lineHeight: '1',
    paddingTop: 20,
  },
} as const;
function Inputs(props: InputsProps): ReactElement {
  const { interval, setSearchField } = props;
  const options = [
    'millisecond',
    'second',
    'minute',
    'hour',
    'day',
    'month',
  ] as IntervalUnit[];
  const data = [
    {
      value: interval.value,
      type: 'number',
      placeholder: '',
      className: 'dark xs mr',
      onChange: (val: string): void => {
        setSearchField<Interval>('interval', {
          ...interval,
          value: Number(val),
        });
      },
      options: undefined,
    },
    {
      type: 'dropdown',
      placeholder: 'Interval',
      value: interval.unit ? { value: interval.unit } : false,
      className: 'width-s',
      onChange: (val: number): void => {
        setSearchField<Interval>('interval', {
          ...interval,
          unit: options[val],
        });
      },
      options,
    },
  ] as InputProps[];
  return (
    <div className='interval-row'>
      {data.map(
        (inputProps: InputProps): ReactElement => (
          <Input
            key={inputProps.type}
            value={inputProps.value}
            type={inputProps.type}
            placeholder={inputProps.placeholder}
            className={inputProps.className}
            onChange={inputProps.onChange}
            options={inputProps.options as never[]}
          />
        ),
      )}
    </div>
  );
}
interface AggregationInputProps {
  setSearchField: <T>(field: string, value: T) => void;
  activeSearch: SavedSearch;
}
function AggregationInput(props: AggregationInputProps): ReactElement {
  const { activeSearch, setSearchField } = props;
  return (
    <Input
      type='multiselect-dropdown'
      placeholder='Interval'
      value={activeSearch.aggregationFunctions}
      className='width-s'
      styleDD={{ width: '163px' }}
      onChange={(val: string[]): void =>
        setSearchField('aggregationFunctions', val)
      }
      options={AGREG_TYPES as never[]}
    />
  );
}
interface SettingsRowProps {
  siteMeta: SiteMeta;
  timeMode: string;
  activeSearch: SavedSearch;
  setSearchField: <T>(field: string, value: T) => void;
}
function SettingsRow(props: SettingsRowProps): ReactElement {
  const { siteMeta, timeMode, activeSearch, setSearchField } = props;
  const currentTz = currentTzString({
    timeMode,
    lat: siteMeta.GPSLat,
    long: siteMeta.GPSLong,
  });
  const aggrMessage =
    !checkShouldAggr(activeSearch) && 'Displayed aggregation types';
  return (
    <>
      <div className='label' style={s.label}>
        Timestamp
        <br />
        <br />
        <span>{currentTz}</span>
      </div>
      <div className='inputs-row no-pd-top with-buttons-search'>
        <div style={s.gridTimeStamp} className='grid-timestamp'>
          <div className='small-lable'>From</div>
          <div className='small-lable' />
          <div className='small-lable'>To</div>
          <div className='small-lable pd-l'>Interval</div>
          <div className='small-lable pd-l'>{aggrMessage}</div>
          <DateTimePicker
            field='startDateTime'
            activeSearch={activeSearch}
            setSearchField={setSearchField}
          />
          <div>&nbsp;-&nbsp;</div>
          <DateTimePicker
            field='stopDateTime'
            activeSearch={activeSearch}
            setSearchField={setSearchField}
          />
          <div className='pd-l dis'>
            <Inputs
              interval={activeSearch.interval}
              setSearchField={setSearchField}
            />
          </div>
          <div className='pd-l dis'>
            {!checkShouldAggr(activeSearch) && (
              <AggregationInput
                setSearchField={setSearchField}
                activeSearch={activeSearch}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingsRow;
