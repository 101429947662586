import Spinner from '@components/_elements/Spinner/Spinner';
import SimplePointDataTableWithTitle from '@components/_shared/MultiColPointDataTableWithTitle';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypeMultiSectionPointList } from '@src/types/Widget';
import { MqttClient } from 'mqtt';

interface Props {
  selectedBessId: string;
  useNewClient?: MqttClient;
}

function PowinBessDetails(props: Props) {
  const { selectedBessId } = props;

  const { data: widgetConfig, isLoading } =
    useGetInternalWidgetConfig<WidgetTypeMultiSectionPointList>(
      'powinBmsDetailsModal',
      {
        sourceDeviceId: selectedBessId,
      },
      { enabled: !!selectedBessId },
    );

  return isLoading ? (
    <Spinner type='content-small' />
  ) : (
    widgetConfig?.sections.map((sectionData, i) => (
      <SimplePointDataTableWithTitle
        key={`powinbms-details-${Math.random()}`}
        sectionIndex={i}
        data={sectionData}
        useNewClient={props.useNewClient}
      />
    ))
  );
}

export default PowinBessDetails;
