import { Button, Grid2, styled } from '@mui/material';
import { memo, useMemo, useState } from 'react';
import { isValueInRange } from '@utils/mathHelpers';
import storeConnector from '@store/storeConnector';
import CommandsNumberInput from './CommandsNumberInput';
import { useCommandsModalContext } from '../CommandsModalContext';

const StyledCommandLabel = styled(Grid2)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
}));

function PowerSection({
  currentPset,
  currentQset,
  minThreshold,
  maxThreshold,
}: {
  currentPset: string;
  currentQset: string;
  minThreshold: number;
  maxThreshold: number;
}) {
  const { sendCommand } = useCommandsModalContext();
  const [activePower, setActivePower] = useState<string>('');
  const [reactivePower, setReactivePower] = useState<string>('');

  const isPowerInputValid: boolean = useMemo(() => {
    if (activePower === '' || reactivePower === '') {
      return false;
    }
    return (
      isValueInRange(activePower, minThreshold, maxThreshold) &&
      isValueInRange(reactivePower, minThreshold, maxThreshold)
    );
  }, [minThreshold, maxThreshold, activePower, reactivePower]);

  const handleClickSend = (): void => {
    if (!isPowerInputValid) {
      return;
    }
    sendCommand('Power', {
      P: activePower,
      Q: reactivePower,
    });
  };

  return (
    <Grid2 container spacing={1}>
      <StyledCommandLabel size={{ xs: 4 }}>
        Real (P) Command:
      </StyledCommandLabel>
      <Grid2 size={{ xs: 8 }}>
        <CommandsNumberInput
          label='Active Power'
          unit='kW'
          minimum={minThreshold}
          maximum={maxThreshold}
          value={activePower}
          currentValue={currentPset}
          setValue={setActivePower}
        />
      </Grid2>
      <StyledCommandLabel size={{ xs: 4 }}>
        Reactive (Q) Command:
      </StyledCommandLabel>
      <Grid2 size={{ xs: 8 }}>
        <CommandsNumberInput
          label='Reactive Power'
          unit='kVAR'
          minimum={minThreshold}
          maximum={maxThreshold}
          value={reactivePower}
          currentValue={currentQset}
          setValue={setReactivePower}
        />
      </Grid2>
      <Grid2 size={{ xs: 4 }} />
      <Grid2 size={{ xs: 8 }} sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          disabled={!isPowerInputValid}
          onClick={handleClickSend}
        >
          Send
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default storeConnector(memo(PowerSection), {
  config: ['siteMeta'],
});
