import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import { Device } from '@src/types/Device';

export interface UseGetDevicesFilteredBody {
  filter: {
    type: {
      $in: string[];
    };
  };
}

export default (body: UseGetDevicesFilteredBody) =>
  useApiQuery<Device[]>({
    queryKey: [QueryKeys.devicesFiltered],
    apiPath: '/devices/search',
    method: 'POST',
    useV2API: true,
    body,
  });
