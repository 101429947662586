import { DataPoint } from '@src/types/DataPoint';
import { Device } from '@src/types/Device';
import { PointConfig } from '@src/types/PointConfig';

// Omit isn't distributive over unions and if you check implementation its Pick<T, Exclude<keyof T, K>
// keyof for union will return only properties that are available in all union members
// Below implementation helps with distributiveness over unions
export type SimpleUnionOmit<
  T,
  K extends string | number | symbol,
> = T extends unknown ? Omit<T, K> : never;

export enum DeviceListDeviceRefsAll {
  ALL = 'all',
}

export enum WidgetTypes {
  DEVICE_LIST = 'deviceList',
  POINT_LIST = 'pointList',
  MULTI_SECTION_POINT_LIST = 'multiSectionPointList',
  CHART = 'chart',
}

export type WidgetConfig =
  | WidgetTypeDeviceList
  | WidgetTypePointList
  | WidgetTypeMultiSectionPointList
  | WidgetTypeChart;

export type WidgetConfigCreateOrEdit =
  | Omit<
      WidgetTypeDeviceList,
      'id' | 'dataPoints' | 'devices' | 'createdAt' | 'updatedAt'
    >
  | Omit<WidgetTypePointList, 'id' | 'dataPoints' | 'createdAt' | 'updatedAt'>
  | Omit<
      WidgetTypeMultiSectionPointListCreateOrEdit,
      'id' | 'createdAt' | 'updatedAt'
    >
  | Omit<WidgetTypeChart, 'id' | 'dataPoints' | 'createdAt' | 'updatedAt'>;

export interface WidgetCreate {
  widgets: WidgetConfigCreateOrEdit[];
}

type WidgetTypeMultiSectionPointListCreateOrEdit = Omit<
  WidgetTypeMultiSectionPointList,
  'sections'
> & {
  sections: Omit<WidgetTypeMultiSectionPointListSection, 'dataPoints'>[];
};

export type ValueGenerationFunction = {
  function: string;
  argumentArray: string[];
};

export type PointConfigRef = Partial<
  Omit<PointConfig, 'isSiteControllerSourceDevicePointConfig'>
> & {
  pointAlias: PointConfig['pointAlias'];
  overrideSourceDeviceId?: string;
  valueGenerationFunction?: ValueGenerationFunction;
};

export interface WidgetTypeDeviceList extends WidgetConfigBase {
  type: WidgetTypes.DEVICE_LIST;
  deviceType: string;
  deviceRefs: string[] | DeviceListDeviceRefsAll.ALL;
  pointConfigRefs: PointConfigRef[];
  devices: Device[];
  dataPoints: DataPoint[];
}

export interface WidgetTypePointList extends WidgetConfigBase {
  type: WidgetTypes.POINT_LIST;
  pointConfigRefs: PointConfigRef[][];
  dataPoints: DataPoint[][];
}

export interface WidgetTypeMultiSectionPointListSection {
  title: string;
  pointConfigRefs: PointConfigRef[][];
  dataPoints: DataPoint[][];
}

export interface WidgetTypeMultiSectionPointList extends WidgetConfigBase {
  type: WidgetTypes.MULTI_SECTION_POINT_LIST;
  sections: WidgetTypeMultiSectionPointListSection[];
}

export interface WidgetTypeChart extends WidgetConfigBase {
  type: WidgetTypes.CHART;
  pointConfigRefs: PointConfigRef[];
  dataPoints: DataPoint[];
}

export interface WidgetConfigBase {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: string;
  internalId: string;
  name: string;
  isSystemWidget: boolean;
  header?: string;
  deleted?: boolean;
}

export type WidgetConfigExtended = WidgetConfig & {
  isNew?: boolean;
  remove?: boolean;
};
