import useGetInternalWidgetConfig from '@hooks/api/queries/useGetInternalWidgetConfig';
import { generateMqttClient } from '@pages/_loader/initMqttClient.loader';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import { MqttClient } from 'mqtt';
import React, { useEffect } from 'react';
import DeviceDataTable from '@src/components/_shared/DeviceDataTable';
import { GridRowId } from '@mui/x-data-grid-pro';
import { pcsSx } from '@src/components/_shared/sxStyles/Battery/deivceTableList';

function ConverterContainer({
  pcsId,
  unitControllerId,
  unitEngineeringId,
}: {
  pcsId: GridRowId;
  unitControllerId: string;
  unitEngineeringId: string;
}) {
  const [mqttClient, setMqttClient] = React.useState<MqttClient>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const startClient = async () => {
      const client = await generateMqttClient();
      setMqttClient(client);
    };
    startClient();
    return () => {
      mqttClient?.end();
    };
  }, []);

  const { data: widgetConfig } =
    useGetInternalWidgetConfig<WidgetTypeDeviceList>(
      'converterList',
      {
        sourceDeviceId: pcsId as string,
        unitControllerId,
        unitControllerEngineeringId: unitEngineeringId,
      },
      {
        queryKeys: [pcsId as string],
      },
    );

  return (
    <div>
      {widgetConfig && (
        <DeviceDataTable
          id='converter'
          hideFooter
          additionalClassnames='detail-data-table'
          devices={widgetConfig?.devices || []}
          dataPoints={widgetConfig?.dataPoints || []}
          pointConfigRefs={widgetConfig?.pointConfigRefs || []}
          mqttClient={mqttClient}
          sx={pcsSx.detailDataTable}
        />
      )}
    </div>
  );
}

export default ConverterContainer;
