import moment from 'moment';
import Spinner from '@components/_elements/Spinner/Spinner';
import { checkShouldAggr, AGREG_TYPES } from './historianUtils.js';

const DataPointStatistic = ({
  chart,
  reqParams,
  intevalDict,
  loadingStatus,
}) => {
  const expectedPointNum = () => {
    const diff =
      moment(reqParams.dateTo).unix() - moment(reqParams.dateFrom).unix();
    const pointsNum =
      diff / (+reqParams.interval.value * intevalDict[reqParams.interval.unit]);
    return Math.floor(pointsNum);
  };
  const actualPointNum = () => {
    return Math.round(
      chart.series.reduce((acc, cv) => acc + cv.data.length, 0),
    );
  };
  const loading = loadingStatus.current === 'loading';

  const checkShouldAggrReq = (req) => {
    return checkShouldAggr(req);
  };

  const isMultiline = !checkShouldAggrReq(reqParams);
  const aggr = isMultiline
    ? AGREG_TYPES.filter((el) =>
        (reqParams.aggregateFunctions || []).includes(el),
      )
    : [];
  return loading ? (
    <Spinner cover={'container'} />
  ) : (
    <div className='historian-stats'>
      <div className='title'>
        Expected count of points in each series for selected interval
      </div>
      <div className='value'>{expectedPointNum()}</div>
      <div className='title'>
        Actual count of points in all series for selected interval
      </div>
      <div className='value' style={{ height: '34px' }}>
        {actualPointNum()}
      </div>
      <div className='title'>Already loaded number of points</div>
      {reqParams.criteria.map((_el, index) => (
        <div key={`alreadyLoadedPoints-${index}`} className='value'>
          <>
            {!isMultiline ? (
              <span>{chart.series[index].data.length}</span>
            ) : (
              <span>
                {aggr.map((f, indx) => (
                  <span key={indx}>
                    <span
                      style={{
                        fontWeight: 'normal',
                        fontSize: '16px',
                        opacity: '0.6',
                      }}
                    >
                      {` ${f}: `}
                    </span>
                    <span>
                      {
                        chart.series[index * (aggr.length || 1) + indx].data
                          .length
                      }
                    </span>
                  </span>
                ))}
              </span>
            )}
          </>
        </div>
      ))}
    </div>
  );
};

export default DataPointStatistic;
