import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CommandsCategory } from '@src/types/Commands';
import { MqttClient } from 'mqtt';
import { memo } from 'react';
import { CommandsModalContextProvider } from '../CommandsModalContext';
import useSubscription from '@src/hooks/useSubscription';
import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import PowerSection from './PowerSection';

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  mqttClient: MqttClient;
  deviceName: string;
  open: boolean;
  powerPoints: DataPoint[];
  onClose: () => void;
  siteMeta: SiteMeta;
}

function PowinControllerCommandsModal({
  mqttClient,
  deviceName,
  powerPoints,
  onClose,
  siteMeta,
}: Props) {
  const onDialogClose = () => {
    onClose();
  };

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    powerPoints,
    mqttClient,
  );

  return (
    <CommandsModalContextProvider
      category={CommandsCategory.POWINEMS}
      deviceId={siteMeta.siteId}
    >
      <Dialog open onClose={onDialogClose} maxWidth='sm' fullWidth>
        <StyledDialogTitle>
          {deviceName} Commands
          <IconButton onClick={onClose} sx={{ mr: -2, mt: -1 }}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers>
          <PowerSection
            currentPset={dataPointsWithValue[0].value as string}
            currentQset={dataPointsWithValue[1].value as string}
            minPThreshold={Number(dataPointsWithValue[2].value)}
            maxPThreshold={Number(dataPointsWithValue[3].value)}
            minQThreshold={Number(dataPointsWithValue[4].value)}
            maxQThreshold={Number(dataPointsWithValue[5].value)}
          />
        </DialogContent>
      </Dialog>
    </CommandsModalContextProvider>
  );
}

export default storeConnector(memo(PowinControllerCommandsModal), {
  config: ['siteMeta'],
  user: ['role'],
});
