/* eslint-disable max-lines-per-function */
import { ReactElement } from 'react';
import './Spinner.scoped.scss';

interface SpinnerProps {
  cover?: 'container';
  type?: 'fullPage' | 'content' | 'content-small';
}

function Spinner({ cover, type }: SpinnerProps): ReactElement {
  return (
    <>
      {!cover && !type && (
        <div className='spinner-box'>
          <div className='configure-border-1' />
          <div className='configure-border-2' />
        </div>
      )}
      {type === 'content' && (
        <div className='content-spinner-container'>
          <div className='content-spinner'>
            <div className='row-c'>
              <div className='arrow-c up segment-1' />
              <div className='arrow-c down segment-2' />
              <div className='arrow-c up segment-3' />
            </div>
            <div className='row-c'>
              <div className='arrow-c down segment-6' />
              <div className='arrow-c up segment-5' />
              <div className='arrow-c down segment-4' />
            </div>
          </div>
        </div>
      )}
      {type === 'content-small' && (
        <div className='content-spinner-container small'>
          <div className='content-spinner'>
            <div className='row-c'>
              <div className='arrow-c up segment-1' />
              <div className='arrow-c down segment-2' />
              <div className='arrow-c up segment-3' />
            </div>
            <div className='row-c'>
              <div className='arrow-c down segment-6' />
              <div className='arrow-c up segment-5' />
              <div className='arrow-c down segment-4' />
            </div>
          </div>
        </div>
      )}
      {type === 'fullPage' ? (
        <div className='spinner-wrapper'>
          <div className='spinner-elements'>
            <div className='row'>
              <div className='arrow up outer-1' />
              <div className='arrow down outer-2' />
              <div className='arrow up outer-3' />
              <div className='arrow down outer-4' />
              <div className='arrow up outer-5' />
              <div className='arrow down outer-6' />
              <div className='arrow up outer-7' />
            </div>
            <div className='row'>
              <div className='arrow up outer-30' />
              <div className='arrow down outer-29' />
              <div className='arrow up inner-1' />
              <div className='arrow down f inner-2' />
              <div className='arrow up f inner-3' />
              <div className='arrow down inner-4' />
              <div className='arrow up inner-5' />
              <div className='arrow down outer-8' />
              <div className='arrow up outer-9' />
            </div>
            <div className='row'>
              <div className='arrow up outer-28' />
              <div className='arrow down outer-27' />
              <div className='arrow up f inner-18' />
              <div className='arrow down inner-17' />
              <div className='arrow up center-1' />
              <div className='arrow down f center-2' />
              <div className='arrow up f center-3' />
              <div className='arrow down inner-6' />
              <div className='arrow up f inner-7' />
              <div className='arrow down outer-10' />
              <div className='arrow up outer-11' />
            </div>
            <div className='row'>
              <div className='arrow down outer-26' />
              <div className='arrow up outer-25' />
              <div className='arrow down f inner-16' />
              <div className='arrow up f inner-15' />
              <div className='arrow down center' />
              <div className='arrow up center-5' />
              <div className='arrow down f center-4' />
              <div className='arrow up f inner-8' />
              <div className='arrow down f inner-9' />
              <div className='arrow up outer-12' />
              <div className='arrow down outer-13' />
            </div>
            <div className='row'>
              <div className='arrow down outer-24' />
              <div className='arrow up outer-23' />
              <div className='arrow down f inner-14' />
              <div className='arrow up f inner-13' />
              <div className='arrow down f inner-12' />
              <div className='arrow up f inner-11' />
              <div className='arrow down f inner-10' />
              <div className='arrow up outer-14' />
              <div className='arrow down outer-15' />
            </div>
            <div className='row'>
              <div className='arrow down outer-22' />
              <div className='arrow up outer-21' />
              <div className='arrow down outer-20' />
              <div className='arrow up outer-19' />
              <div className='arrow down outer-18' />
              <div className='arrow up outer-17' />
              <div className='arrow down outer-16' />
            </div>
          </div>
        </div>
      ) : null}
      {cover && ['container'].includes(cover) && (
        <div
          className={`spinner-holder ${
            cover === 'container' ? 'absolute' : ''
          }`}
        >
          <div className='lds-ripple'>
            <div />
            <div />
          </div>
        </div>
      )}
    </>
  );
}

export default Spinner;
