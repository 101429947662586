/* eslint-disable max-lines */
import { ReactElement } from 'react';
import { DynamicConnector } from '@src/sld/components/Shared';
import Breaker from '@src/sld/components/Breaker/Breaker';
import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import useSubscription from '@hooks/useSubscription';
import useDataPointsSearch from '@hooks/api/queries/useDataPointsSearch';
import {
  BrazosBendPointsWithValues,
  createBrazosBendDiagramPropertiesFromDataPoints,
  getBrazosBendPointsForSearchBody,
} from '@src/sld/brazos_bend/brazosBendUtils';
import { prepareSubscriptionDataPointArray } from '@src/sld/components/utils';

function BrazosBendDiagram(): ReactElement {
  const showOpenOrCloseSwitch = (
    pointValue: string | number | null | undefined,
    label: string,
  ) => {
    //  true = closed switch
    //  false = open switch
    //  null = open

    const openSwitch = <path d='m0 761.34 9.77 19.41V792' className='st7' />;
    const closedSwitch = <path d='M9.50 792v-0-.0v-20' className='st3' />;

    return (
      <>
        <text x={50} y={785} fill='currentColor' stroke='none'>
          {label}
        </text>
        {pointValue !== 0 ? closedSwitch : openSwitch}
      </>
    );
  };

  const adjustOpenOrCloseSwitchColor = (
    pointValue: string | number | null | undefined,
  ): string => {
    const openSwitchColor = 'green';
    const closedSwitchColor = 'red';

    return pointValue !== 0 ? closedSwitchColor : openSwitchColor;
  };

  const dataPoints = useDataPointsSearch(getBrazosBendPointsForSearchBody());

  const subscriptionDataPoints: DataPoint[] =
    prepareSubscriptionDataPointArray(dataPoints);

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    subscriptionDataPoints,
  );

  const diagramPointsWithValues: BrazosBendPointsWithValues =
    createBrazosBendDiagramPropertiesFromDataPoints(dataPointsWithValue);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      width='17in'
      height='11in'
      viewBox='0 0 1224 792'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor' }}
    >
      <g>
        <text x={589} y={300} fill='currentColor' stroke='none'>
          KT01
        </text>
        <text x={589} y={320} fill='currentColor' stroke='none'>
          XFMR
        </text>
        <title>Page-1</title>
        <g transform='rotate(90 523.03 799.78)'>
          <title>Transformer.1001</title>

          <path
            d='M33.36 758.64h-8.34a4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17h8.34'
            className='st1'
          />
          <path
            d='M0 758.64h8.34a4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17A4.17 4.17 0 0 1 8.34 792H0'
            className='st1'
          />
        </g>

        <g transform='translate(533.985 -584.927)'>
          <Breaker
            status={diagramPointsWithValues.k_010}
            enableBreakerCommands={false}
            label='K010'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(10, 638) rotate(-90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <text x={425} y={1338} fill='currentColor' stroke='none'>
            EAST LOOP #2
          </text>

          <text
            x={140}
            y={750}
            fill='currentColor'
            stroke='none'
          >{`Frequency: ${diagramPointsWithValues.k_010_frequency} Hz`}</text>
          <text
            x={140}
            y={770}
            fill='currentColor'
            stroke='none'
          >{`Voltage: ${diagramPointsWithValues.k_010_voltage} kV`}</text>
          <text
            x={140}
            y={790}
            fill='currentColor'
            stroke='none'
          >{`Current: ${diagramPointsWithValues.k_010_current} A`}</text>
          <text
            x={140}
            y={810}
            fill='currentColor'
            stroke='none'
          >{`Active Power: ${diagramPointsWithValues.k_010_activePower} MW`}</text>
          <text
            x={140}
            y={830}
            fill='currentColor'
            stroke='none'
          >{`Reactive Power: ${diagramPointsWithValues.k_010_reactivePower} MVAR`}</text>
        </g>

        <g transform='translate(218.214 -186.447)'>
          <Breaker
            status={diagramPointsWithValues.k_100}
            enableBreakerCommands={false}
            label='K100'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17.5, 907) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <text x={-60} y={940} fill='currentColor' stroke='none'>
            AUXILIARY #1 AND #2
          </text>
          <text x={-40} y={960} fill='currentColor' stroke='none'>
            POWER FEEDER
          </text>

          <text
            x={120}
            y={730}
            fill='currentColor'
            stroke='none'
          >{`Frequency: ${diagramPointsWithValues.k_100_frequency} Hz`}</text>
          <text
            x={120}
            y={750}
            fill='currentColor'
            stroke='none'
          >{`Voltage: ${diagramPointsWithValues.k_100_voltage} kV`}</text>
          <text
            x={120}
            y={770}
            fill='currentColor'
            stroke='none'
          >{`Current: ${diagramPointsWithValues.k_100_current} A`}</text>
          <text
            x={120}
            y={790}
            fill='currentColor'
            stroke='none'
          >{`Active Power: ${diagramPointsWithValues.k_100_activePower} kW`}</text>
          <text
            x={120}
            y={810}
            fill='currentColor'
            stroke='none'
          >{`Reactive Power: ${diagramPointsWithValues.k_100_reactivePower} kVAR`}</text>
        </g>

        <g transform='translate(533.952 -186.447)'>
          <Breaker
            status={diagramPointsWithValues.k_200}
            enableBreakerCommands={false}
            label='K200'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17.5, 907) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <text x={-50} y={940} fill='currentColor' stroke='none'>
            WEST LOOP #1
          </text>

          <text
            x={120}
            y={730}
            fill='currentColor'
            stroke='none'
          >{`Frequency: ${diagramPointsWithValues.k_200_frequency} Hz`}</text>
          <text
            x={120}
            y={750}
            fill='currentColor'
            stroke='none'
          >{`Voltage: ${diagramPointsWithValues.k_200_voltage} kV`}</text>
          <text
            x={120}
            y={770}
            fill='currentColor'
            stroke='none'
          >{`Current: ${diagramPointsWithValues.k_200_current} A`}</text>
          <text
            x={120}
            y={790}
            fill='currentColor'
            stroke='none'
          >{`Active Power: ${diagramPointsWithValues.k_200_activePower} kW`}</text>
          <text
            x={120}
            y={810}
            fill='currentColor'
            stroke='none'
          >{`Reactive Power: ${diagramPointsWithValues.k_200_reactivePower} KVAR`}</text>
        </g>

        <g transform='translate(849.691 -186.447)'>
          <Breaker
            status={diagramPointsWithValues.k_300}
            enableBreakerCommands={false}
            label='K300'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17.5, 907) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <text x={-50} y={940} fill='currentColor' stroke='none'>
            EAST LOOP #1
          </text>

          <text
            x={140}
            y={730}
            fill='currentColor'
            stroke='none'
          >{`Frequency: ${diagramPointsWithValues.k_300_frequency} Hz`}</text>
          <text
            x={140}
            y={750}
            fill='currentColor'
            stroke='none'
          >{`Voltage: ${diagramPointsWithValues.k_300_voltage} kV`}</text>
          <text
            x={140}
            y={770}
            fill='currentColor'
            stroke='none'
          >{`Current: ${diagramPointsWithValues.k_300_current} A`}</text>
          <text
            x={140}
            y={790}
            fill='currentColor'
            stroke='none'
          >{`Active Power: ${diagramPointsWithValues.k_300_activePower} kW`}</text>
          <text
            x={140}
            y={810}
            fill='currentColor'
            stroke='none'
          >{`Reactive Power: ${diagramPointsWithValues.k_300_reactivePower} kVAR`}</text>
        </g>

        <g transform='translate(231.75 -222.519)'>
          <title>Dynamic connector.1269</title>
          <path d='M0 801v-80.93h315.74V801' className='st3' />
        </g>

        <g transform='translate(863.227 -204.519)'>
          <title>Dynamic connector.1325</title>
          <path d='M0 783v-80.93h-315.74V783' className='st3' />
        </g>

        <g transform='rotate(90 209.098 814.652)'>
          <title>Sheet.1329</title>
          <path d='M0 792h115.38' className='st4' />
        </g>

        <g transform='rotate(90 366.968 972.522)'>
          <title>Sheet.1356</title>
          <path d='M0 792h115.38' className='st4' />
        </g>

        <g transform='rotate(90 524.838 1130.392)'>
          <title>Sheet.1362</title>
          <path d='M0 792h115.38' className='st4' />
        </g>

        <g transform='translate(538.505 -506.91)'>
          <title>Dynamic connector.1368</title>
          <path d='M8.98 792v-21.84h.04v-56.18' className='st3' />
        </g>

        {/* second switch */}
        <g transform='translate(528.75 -387)'>
          <title>Switch disconnector.1369</title>
          <g transform='translate(11.316 -4.733)'>
            <title>k-019</title>
          </g>
          <g transform='translate(8.937)'>
            <title>Sheet.1371</title>
            {/* switch red= closed / green=open */}
            <path
              d='M9.77 761.33a4.5 4.5 0 1 1-4.5 4.5c0-2.48 2.02-4.5 4.5-4.5Zm0 2.25c-1.24 0-2.25 1.01-2.25 2.25s1.01 2.25 2.25 2.25 2.25-1.01 2.25-2.25-1.01-2.25-2.25-2.25Z'
              className='st6'
              fill={adjustOpenOrCloseSwitchColor(diagramPointsWithValues.k_019)}
            />
            <path d='M9.77 754.54v6.79' className='st7' />

            {/* display open switch or closed switch */}
            {showOpenOrCloseSwitch(diagramPointsWithValues.k_019, 'K019')}

            <path d='M14.31 761.29h-9' className='st7' />
          </g>
        </g>

        <g transform='translate(556.473 -490.229)'>
          <title>Dynamic connector.1372</title>
          <path d='M-8.98 792v21.84h-.04v43.89' className='st3' />
        </g>

        <g transform='translate(556.501 -612)'>
          <title>Dynamic connector.1373</title>
          <path d='M-8.98 792v-13.5h-.04v-35.11' className='st3' />
        </g>

        {/* vertical line under switch */}
        <g transform='translate(538.473 -387)'>
          <title>Dynamic connector.1375</title>
          <path d='M8.98 792v13.5h.04v81' className='st3' />
        </g>

        <g transform='translate(547.489 -133.161)'>
          <title>K200 Second Arrow</title>
          <path d='M0 792h118.51v60.21' className='st4' />
          {/* arrow */}
          <polygon
            transform='translate(122, 853) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <text x={90} y={887} fill='currentColor' stroke='none'>
            WEST LOOP #2
          </text>
        </g>

        <g transform='translate(863.227 -129.786)'>
          <title>K300 Second Arrow</title>
          <path d='M0 792h118.51v60.21' className='st4' />
          {/* arrow */}
          <polygon
            transform='translate(122, 850) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
        </g>

        {/* first switch */}
        <g transform='translate(528.773 -660.609)'>
          <g transform='translate(8.937)'>
            <title>K-011</title>
            {/* switch */}
            <path
              d='M9.77 761.33a4.5 4.5 0 1 1-4.5 4.5c0-2.48 2.02-4.5 4.5-4.5Zm0 2.25c-1.24 0-2.25 1.01-2.25 2.25s1.01 2.25 2.25 2.25 2.25-1.01 2.25-2.25-1.01-2.25-2.25-2.25Z'
              className='st6'
              fill={adjustOpenOrCloseSwitchColor(diagramPointsWithValues.k_011)}
            />

            <path d='M9.77 754.54v6.79' className='st7' />

            {/* display open switch or closed switch   */}
            {showOpenOrCloseSwitch(diagramPointsWithValues.k_011, 'K011')}

            <path d='M14.31 761.29h-9' className='st7' />
          </g>
        </g>

        <g transform='translate(556.441 -698.107)'>
          <DynamicConnector
            label='138 kV FORT BEND SWITCH'
            pathCoordinates='m-8.96 792-.07-39.6" className="st8'
            className=''
            xCoordinate={-5}
            yCoordinate={735}
          />
        </g>
      </g>
    </svg>
  );
}

export default BrazosBendDiagram;
