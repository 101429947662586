import { Button, Grid2, styled } from '@mui/material';
import { memo, useMemo, useState } from 'react';
import { isValueInRange } from '@utils/mathHelpers';
import CommandsNumberInput from './CommandsNumberInput';
import { useCommandsModalContext } from '../CommandsModalContext';

const StyledCommandLabel = styled(Grid2)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
}));

interface Props {
  currentPset: string;
  currentQset: string;
  minPThreshold: number;
  maxPThreshold: number;
  minQThreshold: number;
  maxQThreshold: number;
}

function PowerSection({
  currentPset,
  currentQset,
  minPThreshold,
  maxPThreshold,
  minQThreshold,
  maxQThreshold,
}: Props) {
  const { sendCommand } = useCommandsModalContext();
  const [activePower, setActivePower] = useState<string>('');
  const [reactivePower, setReactivePower] = useState<string>('');

  const isActivePowerInputValid = useMemo(
    () => isValueInRange(activePower, minPThreshold, maxPThreshold),
    [activePower, minPThreshold, maxPThreshold],
  );
  const isReactivePowerInputValid = useMemo(
    () => isValueInRange(reactivePower, minQThreshold, maxQThreshold),
    [reactivePower, minQThreshold, maxQThreshold],
  );

  const handleClickSend = () => {
    if (!isActivePowerInputValid || !isReactivePowerInputValid) {
      return;
    }
    sendCommand('Power', {
      P: activePower,
      Q: reactivePower,
    });
  };

  return (
    <Grid2 container spacing={1}>
      <StyledCommandLabel size={{ xs: 4 }}>
        Real (P) Command:
      </StyledCommandLabel>
      <Grid2 size={{ xs: 8 }}>
        <CommandsNumberInput
          label='Active Power'
          unit='kW'
          minimum={minPThreshold}
          maximum={maxPThreshold}
          value={activePower}
          currentValue={currentPset}
          setValue={setActivePower}
        />
      </Grid2>
      <StyledCommandLabel size={{ xs: 4 }}>
        Reactive (Q) Command:
      </StyledCommandLabel>
      <Grid2 size={{ xs: 8 }}>
        <CommandsNumberInput
          label='Reative Power'
          unit='kVAR'
          minimum={minQThreshold}
          maximum={maxQThreshold}
          value={reactivePower}
          currentValue={currentQset}
          setValue={setReactivePower}
        />
      </Grid2>
      <Grid2 size={{ xs: 4 }} />
      <Grid2 size={{ xs: 8 }} sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          disabled={!isActivePowerInputValid || !isReactivePowerInputValid}
          onClick={handleClickSend}
        >
          Send
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default memo(PowerSection);
