import { useEffect } from 'react';
import './Modes.scoped.scss';
import {
  ExecutionsSettingsFormData,
  ModeConfig,
} from '@src/types/control-modes/Modes';
import storeConnector from '@store/storeConnector';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import { ServiceState } from '@src/store/reducers/serviceData';
import { SiteMeta } from '@src/types/SiteMeta';
import { Box, Tooltip } from '@mui/material';
import ModeConfigInput from './Inputs/ModeConfigInput';

interface ExecutionSettingsFormProps {
  config: ModeConfig;
  isControlsLocked: ServiceState['isControlsLocked'];
  executionSettingsFormData: ExecutionsSettingsFormData;
  inputErrorCount: number;
  setExecutionSettingsFormData: any;
  setInputErrorCount: any;
  siteMeta: SiteMeta;
}

function ExecutionSettingsForm({
  config,
  executionSettingsFormData,
  inputErrorCount,
  isControlsLocked,
  setExecutionSettingsFormData,
  setInputErrorCount,
  siteMeta,
}: ExecutionSettingsFormProps) {
  const commandAccessEnabled = isCommandsEnabled() && !isControlsLocked;
  const { controlsLockConfig } = siteMeta.ui;
  const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setExecutionSettingsFormData(
      (prevFormData: ExecutionsSettingsFormData) => ({
        ...prevFormData,
        additive: config.body['Execution Settings']?.Additive || '',
        // enabled: config.body['Execution Settings']?.Enabled || '',
        dataSource: config.body['Execution Settings']?.['Data Source'] || '',
        priority: config.body['Execution Settings']?.Priority || '',
      }),
    );
  }, [config]);

  const handleInputUpdate = (e: any) => {
    setExecutionSettingsFormData(
      (prevFormData: ExecutionsSettingsFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value,
      }),
    );
  };

  return (
    <div className='modes-row'>
      {/* <ModeConfigInput
        configInputLabel='Enabled'
        formType='full'
        handleInputUpdate={handleInputUpdate}
        inputErrorCount={inputErrorCount}
        name='enabled'
        setInputErrorCount={setInputErrorCount}
        value={executionSettingsFormData.enabled}
      /> */}

      <Tooltip
        title={isControlsLocked ? tooltipTitle : ''}
        placement='top'
        disableHoverListener={!isControlsLocked}
      >
        <Box>
          <ModeConfigInput
            configInputLabel='Additive'
            formType='full'
            handleInputUpdate={handleInputUpdate}
            inputErrorCount={inputErrorCount}
            name='additive'
            setInputErrorCount={setInputErrorCount}
            value={executionSettingsFormData.additive}
            disabled={!commandAccessEnabled}
          />
        </Box>
      </Tooltip>
      <Tooltip
        title={isControlsLocked ? tooltipTitle : ''}
        placement='top'
        disableHoverListener={!isControlsLocked}
      >
        <Box>
          <ModeConfigInput
            configInputLabel='Priority'
            formType='full'
            handleInputUpdate={handleInputUpdate}
            inputErrorCount={inputErrorCount}
            name='priority'
            setInputErrorCount={setInputErrorCount}
            range={config.body['Execution Settings']?.Priority__range || ''}
            value={executionSettingsFormData.priority}
            disabled={!commandAccessEnabled}
          />
        </Box>
      </Tooltip>

      <Tooltip
        title={isControlsLocked ? tooltipTitle : ''}
        placement='top'
        disableHoverListener={!isControlsLocked}
      >
        <Box>
          <ModeConfigInput
            configInputLabel='Data Source'
            formType='full'
            handleInputUpdate={handleInputUpdate}
            inputErrorCount={inputErrorCount}
            inputType='string'
            name='dataSource'
            setInputErrorCount={setInputErrorCount}
            value={executionSettingsFormData.dataSource}
            disabled={!commandAccessEnabled}
          />
        </Box>
      </Tooltip>

      <div className='modes-cell' style={{ flex: 4 }} />
    </div>
  );
}

export default storeConnector(ExecutionSettingsForm, {
  config: ['siteMeta'],
  service: ['isControlsLocked'],
});
