import Logo from '@assets/logo.png';
import { useTranslation } from 'react-i18next';

export default function VersionFooter() {
  const { t } = useTranslation();
  return (
    <div className='powered-by-and-version-info'>
      <div className='powered-by'>
        <div className='powered'>{t('POWERED_BY')}:</div>
        <img src={Logo} alt='' />
        <div className='company-name'>{t('FRACTAL_EMS_UPPERCASE')}</div>
      </div>
      <div className='version-label'>
        v{process.env.VITE_GITHUB_TAG_VERSION}
      </div>
    </div>
  );
}
