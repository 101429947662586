import React, { ReactElement } from 'react';
import { DataPointWithValue } from '@src/types/DataPoint';

type MeterDataPointsSectionProps = {
  translateXCoordinate: number;
  translateYCoordinate: number;
  meterDataPoints: DataPointWithValue[] | undefined;
  meterLabel: string;
};

const textProps = {
  fill: 'currentColor',
  stroke: '#cccccc',
  strokeWidth: '.5',
  textAnchor: 'right',
  fontSize: '8px',
};

function MeterDataPointsSection(
  props: MeterDataPointsSectionProps,
): ReactElement {
  const {
    translateXCoordinate,
    translateYCoordinate,
    meterDataPoints,
    meterLabel,
  } = props;

  const checkDataPoints = (dataPoint: DataPointWithValue) =>
    dataPoint?.value ? dataPoint.value : 'non-available';

  return (
    <g>
      <text
        x={translateXCoordinate}
        y={translateYCoordinate - 20}
        {...textProps}
      >
        <tspan x={translateXCoordinate} dy={0}>
          {meterLabel}
          <br />
        </tspan>
      </text>
      <rect
        x={translateXCoordinate - 5}
        y={translateYCoordinate - 15}
        width={translateXCoordinate + 150 - (translateXCoordinate - 5)}
        height={(meterDataPoints?.length || 0) * 15 + 10}
      />
      <text x={translateXCoordinate} y={translateYCoordinate} {...textProps}>
        {meterDataPoints?.map(
          (dataPoint: DataPointWithValue, index: number) => (
            <React.Fragment key={index}>
              <tspan x={translateXCoordinate} dy={index === 0 ? 0 : 15}>
                {`${dataPoint.displayName}:`}
              </tspan>
              <tspan x={translateXCoordinate + 50} textAnchor='start'>
                {checkDataPoints(dataPoint)}
              </tspan>
            </React.Fragment>
          ),
        )}
      </text>
    </g>
  );
}

export default MeterDataPointsSection;
