import { ReactElement } from 'react';
import { Arrow, Switch } from '@src/sld/components/Shared';

function Section4Devices(): ReactElement {
  return (
    <g>
      <g id='600as5' transform='translate(713.5 -495.619)'>
        <Switch label='AS-5' status={false} />
      </g>
      <g id='600as6' transform='rotate(180 371.3 495)'>
        <Switch reverse label='AS-6' status={false} />
      </g>
      <g id='600as7' transform='translate(775 -495.619)'>
        <Switch label='AS-7' status={false} />
      </g>
      <g id='600as8' transform='rotate(180 402 495)'>
        <Switch label='AS-8' reverse status={false} />
      </g>
      <g id='pmsg2' transform='translate(775 -411.244)'>
        <Switch label='PMSG-2' status={false} />
      </g>
    </g>
  );
}

function Section4Connectors(): ReactElement {
  return (
    <g>
      <g id='600as8-pmsg2' transform='rotate(-90 172.005 174.495)'>
        <path d='M0 792h51.25' />
      </g>
      <g id='600as5-600as7' transform='rotate(-90.105 99.402 163.37)'>
        <path d='M0 792h29.87' />
      </g>
      <g id='600as6-600as8' transform='rotate(-90.105 130.03 132.8)'>
        <path d='M0 792h29.87' />
      </g>
      <g id='psi-ii10-2' transform='translate(728.212 -540.253)'>
        <path d='M0 792h61.26' />
      </g>
      <g id='shunt-600as5' transform='rotate(-89.749 65.757 129.334)'>
        <path d='M0 792h22.5' />
      </g>
      <g id='to-nremc2' transform='translate(780.308 -594)'>
        <Arrow label='TO NREMC FEEDER' markerId='marker_0' />
      </g>
    </g>
  );
}
function Section4(): ReactElement {
  return (
    <g>
      <Section4Devices />
      <Section4Connectors />
    </g>
  );
}

export default Section4;
