const alertsx = {
  alertButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    minWidth: 'fit-content',
  },
  alertCenterAlignBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  historicAlertText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontFamily: 'inherit',
    fontWeight: 400,
  },
};

export default alertsx;
