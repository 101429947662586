import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Actions } from '@src/types/Actions';
import storeConnector from '@store/storeConnector';
import useGetDeviceNotesQuery from '@src/hooks/api/queries/useGetDeviceNotesQuery';
import notesx from './NotesSx';

interface NotesButtonProps {
  actions: Actions;
  className: string;
  deviceId: string;
  size: SizeProp | undefined;
}

function NotesButton({ actions, className, deviceId, size }: NotesButtonProps) {
  const { data } = useGetDeviceNotesQuery(deviceId);

  return (
    <FontAwesomeIcon
      style={notesx.notesButton(data)}
      className={className}
      icon={faStickyNote}
      size={size}
      onClick={() =>
        actions.showNotesModal({
          isNotesOpen: true,
          deviceId,
        })
      }
    />
  );
}

export default storeConnector(NotesButton, {});
