enum FunctionKey {
  POWER = 'POWER',
  AVERAGE = 'AVERAGE',
}

function isAllNumbers(arr: unknown[]): arr is number[] {
  return arr.every((item) => typeof item === 'number');
}

function safeAverage(args: unknown[]): number | undefined {
  if (!isAllNumbers(args)) {
    return;
  }
  return args.length
    ? args.reduce((acc, num) => acc + num, 0) / args.length
    : undefined;
}

function power(args: unknown[]): number | undefined {
  if (!isAllNumbers(args)) {
    return;
  }
  return (args[0] * args[1]) / 1000;
}

export function getValueGenerationFunction(
  functionKey: string | undefined,
): ((args: unknown[]) => number | undefined) | undefined {
  switch (functionKey) {
    case undefined:
      return;
    case FunctionKey.POWER:
      return power;
    case FunctionKey.AVERAGE:
      return safeAverage;
    default:
      console.error(`Unknown function key: ${functionKey}`);
      return;
  }
}
