import SimplePointDataTable from '@components/_shared/SimplePointDataTable';
import useSubscription from '@hooks/useSubscription';
import { DataPoint } from '@src/types/DataPoint';
import { MqttClient } from 'mqtt';

interface Props {
  data: DataPoint[];
  useNewClient?: MqttClient;
}

function SimplePointDataTableWithSubscribe(props: Props) {
  // TODO do not create a new client for each column section, lift the useSubscription to parent TeslaControllerDetails component
  const dataPointsWithValues = useSubscription(props.data, props.useNewClient);
  return <SimplePointDataTable data={dataPointsWithValues} />;
}

export default SimplePointDataTableWithSubscribe;
