import { prepColumns } from '@components/Home/Alerts/utils';
import { useMemo } from 'react';
import './index.scoped.scss';
import storeConnector from '@store/storeConnector';
import {
  AlertsState,
  SeverityTypes,
  AlertType,
  AlertColumn,
} from '@src/types/Alerts';
import { DataGridPro, GridRowClassNameParams } from '@mui/x-data-grid-pro';

import {
  initialDataGridState,
  slotProps,
  useGetRowSpacing,
} from '@components/Home/Alerts/config';
import { ConfigState } from '@store/reducers/configData';
import AlertsGridToolbar from '@src/components/Home/Alerts/Table/AlertsGridToolbar';
import { getAlertId } from '@src/utils/alerts';
import { useLocation } from 'react-router';
import { isSuperAdmin } from '@src/services/auth';
import { UserState } from '@store/reducers/userData';

interface ActiveAlertsProps {
  activeAlerts?: AlertsState['activeAlerts'];
  siteMeta: ConfigState['siteMeta'];
  role: UserState['role'];
}

interface LocationState {
  severity: number | undefined;
}

// eslint-disable-next-line max-lines-per-function
function ActiveAlerts({
  activeAlerts = [],
  siteMeta,
  role,
}: ActiveAlertsProps) {
  const getRowSpacing = useGetRowSpacing();
  const { state } = useLocation() as { state: LocationState };

  const initState = useMemo(() => {
    if (state?.severity !== undefined) {
      return {
        ...initialDataGridState,
        filter: {
          filterModel: {
            items: [
              {
                field: 'severity',
                operator: 'equals',
                value: SeverityTypes[state.severity],
              },
            ],
          },
        },
      };
    }
    return initialDataGridState;
  }, [state]);

  const {
    TIME_EVENT,
    SEVERITY,
    EVENT_TYPE,
    UNIT,
    ALERT_NAME,
    MESSAGE,
    TOPIC,
    HELP,
    ACKNOWLEDGE,
    CLEAR,
    IS_EQUIPMENT_FAULT,
  } = AlertColumn;

  const activeAlertsColumns = [
    TIME_EVENT,
    SEVERITY,
    EVENT_TYPE,
    UNIT,
    ALERT_NAME,
    MESSAGE,
    TOPIC,
    IS_EQUIPMENT_FAULT,
    ...(!process.env.VITE_READ_ONLY_MODE ? [ACKNOWLEDGE] : []),
    HELP,
    ...(isSuperAdmin(role) ? [CLEAR] : []),
  ];

  return (
    <div className='home-row frow'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <DataGridPro
            className='alerts-data-grid'
            getRowId={getAlertId}
            getRowSpacing={getRowSpacing}
            rows={activeAlerts}
            columns={prepColumns(
              activeAlertsColumns,
              AlertType.ACTIVE_ALERT,
              siteMeta,
            )}
            density='compact'
            initialState={initState}
            slots={{ toolbar: AlertsGridToolbar }}
            slotProps={slotProps}
            getRowClassName={({ row: { isAcked } }: GridRowClassNameParams) =>
              isAcked === true ? 'inactive-row' : ''
            }
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}

export default storeConnector(ActiveAlerts, {
  alerts: ['activeAlerts'],
  service: ['timeMode'],
  config: ['siteMeta'],
  user: ['role'],
});
