import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import SystemMeter from '@src/components/_shared/SystemMeter';
import UnitTable from '@src/components/_shared/UnitTable/UnitTable';
import { DataGridProProps, GridRowSpacingParams } from '@mui/x-data-grid-pro';
import PCSMasterDetail from '@src/components/Battery/PCSContainer/PCSMasterDetail';
import useGetDevicesFiltered from '@src/hooks/api/queries/collections/useGetDevicesFiltered';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import { useMemo, useCallback } from 'react';
import DeviceDataTable from '@src/components/_shared/DeviceDataTable';
import { pcsSx } from '@src/components/_shared/sxStyles/Battery/deivceTableList';
import UIBox from '@src/components/_shared/UIBox';

function PCS({
  currentUnitDeviceId,
  siteMeta,
}: {
  currentUnitDeviceId: string;
  siteMeta: SiteMeta;
}) {
  const { Units } = siteMeta;
  const {
    data: units,
    isLoading: unitsLoading,
    isError: unitsError,
  } = useGetDevicesFiltered({
    filter: {
      type: {
        $in: ['unitController'],
      },
    },
  });

  const unitControllerId: string = units?.find(
    (unit) => unit.engineeringId === currentUnitDeviceId,
  )?.deviceId as string;

  const {
    data: widgetConfig,
    isLoading,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypeDeviceList>(
    'pcsList',
    {
      unitControllerId: unitControllerId || 'unitController_1',
      unitControllerEngineeringId: currentUnitDeviceId || Units[0],
    },
    {
      enabled: !!unitControllerId,
      queryKeys: [currentUnitDeviceId],
    },
  );
  const memoizedUnitControllerId = useMemo(
    () => unitControllerId,
    [unitControllerId],
  );
  const memoizedCurrentUnitDeviceId = useMemo(
    () => currentUnitDeviceId,
    [currentUnitDeviceId],
  );

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row }) => (
      <PCSMasterDetail
        row={row}
        unitControllerId={memoizedUnitControllerId}
        unitEngineeringId={memoizedCurrentUnitDeviceId}
      />
    ),
    [memoizedUnitControllerId, memoizedCurrentUnitDeviceId],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  return (
    <div className='home-row bms-wrapper'>
      <div className='fcol unit-f1'>
        <SystemMeter />
        <UnitTable />
      </div>
      <div className='fcol unit-f2'>
        <UIBox
          header={`${siteMeta.UnitNames[siteMeta.Units.indexOf(currentUnitDeviceId)]} PCS List`}
          isError={isError || unitsError}
        >
          <DeviceDataTable
            id='pcs'
            sx={pcsSx.wrapper}
            hideFooter
            devices={widgetConfig?.devices}
            dataPoints={widgetConfig?.dataPoints || []}
            pointConfigRefs={widgetConfig?.pointConfigRefs || []}
            disableRowSelectionOnClick
            getHeaderHeight={() => 0}
            getRowClassName={() => 'MuiDataGrid-clickable pcs-row'}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            density={'standard'}
            loading={isLoading || unitsLoading}
            allowExpandedCount={2}
            additionalClassnames='pcs-data-table'
            getRowSpacing={(_params: GridRowSpacingParams) => ({
              top: 9,
              bottom: 4,
            })}
          />
        </UIBox>
      </div>
    </div>
  );
}

export default storeConnector(PCS, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
