import moment from 'moment';
import HighchartsReact from '@components/_elements/HighChart';
import Spinner from '@components/_elements/Spinner/Spinner';
import Button from '@components/_elements/Button/Button';
import { getLocale } from '@utils';
import storeConnector from '@store/storeConnector';
import { tsDueTimeMode } from '@utils/index_ts';

const HighChartRow = ({
  reqParams,
  timeMode,
  initTimeMode,
  siteMeta,
  chart,
  tooManyPoints,
  loadingStatus,
  tzOffset,
  setWrapperRef,
  chartType,
  wrapperRef,
}) => {
  const showExportActions = siteMeta.ui?.Home_Historian_Export_Enable;
  const containsStringType = chart.series?.some(
    (s) => s.data.length > 0 && s.dataType === 'string',
  );
  const dataForPlotting = {
    ...chart,
    series: chart.series?.filter((s) => s.dataType !== 'string'),
  };

  const openTable = () => {
    const link = document.createElement('a');
    const params = {
      req: reqParams,
      timeMode: timeMode,
      ...(timeMode !== 'UTC'
        ? {
            tzOffset: getLocale({
              timeMode: timeMode,
              initTZMode: initTimeMode,
              lat: siteMeta.GPSLat,
              long: siteMeta.GPSLong,
              returnOffset: true,
            }),
          }
        : {}),
    };
    link.href = `/historian-tableview/${btoa(JSON.stringify(params))}`;

    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadCSV = () => {
    const notUTC = timeMode !== 'UTC';
    let data = '';
    data +=
      [
        'Date UTC',
        ...(notUTC ? [`Date ${timeMode}`] : []),
        ...chart.series.map((s) => s.name.replace(/,/g, ' ')),
      ].join(',') + '\n';
    const dict = {};

    chart.series.forEach((s, i) => {
      s.data.forEach((d) => {
        if (!dict[d[0]]) {
          dict[d[0]] = new Array(chart.series.length).fill('');
        }
        dict[d[0]][i] = d[1];
      });
    });
    Object.keys(dict).forEach((r) => {
      const data = [];
      const dateUTC = moment
        .utc(moment(Number(r)))
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      data.push(dateUTC);
      if (notUTC) {
        const date = tsDueTimeMode({
          timeMode: timeMode,
          lat: siteMeta.GPSLat,
          long: siteMeta.GPSLong,
          returnMoment: true,
          ts: dateUTC,
        }).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        data.push(date);
      }
      dict[r] = [...data, ...dict[r]];
    });
    data += Object.keys(dict)
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
      .map((a) => dict[a].join(','))
      .join('\n');

    let blob = new Blob([data], { type: 'text/csv;base64' });
    let url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'historian-chart-data.csv';
    a.click();
    a.remove();
  };
  const noData =
    chart.series.some((s) => s.data.length === 0) && loadingStatus === 'loaded';
  return (
    <div className='chart-row fcol full-width'>
      <div className='high-chart-container block-container flex-1'>
        {containsStringType && (
          <div
            className={`${
              dataForPlotting.series.length > 0
                ? 'contains-text'
                : 'too-many-points center'
            }`}
          >
            Data results contains text
          </div>
        )}
        {tooManyPoints && (
          <div className='too-many-points'>
            <span>
              Number of points on chart exceded limit. Only first 50000 points
              for each data point will be displayed
            </span>
          </div>
        )}
        {noData && (
          <div
            data-test='historian-no-data-div'
            className='too-many-points center'
          >
            No data available to display
          </div>
        )}
        <div className='title-row'>
          <div className='chart-title'>{chartType}</div>
          <div className='loading'>
            STATUS:&nbsp;
            <span className={loadingStatus === 'loaded' ? 'green-text' : ''}>
              {loadingStatus}
            </span>
            {loadingStatus === 'loading' ? <Spinner /> : <></>}
          </div>
        </div>
        <div className='chart-container-row'>
          <HighchartsReact
            key={tzOffset}
            ref={setWrapperRef}
            options={dataForPlotting}
            tz={tzOffset}
          />
        </div>
      </div>
      <div className='chart-info-control-block cell block-container bg-transparent no-shadow'>
        {showExportActions && chartType === 'PLOT' && (
          <Button classType='active-bg' onClick={() => openTable()}>
            TABLE VIEW
          </Button>
        )}
        {showExportActions && (
          <Button classType='active-bg' onClick={() => downloadCSV()}>
            EXPORT TO CSV
          </Button>
        )}
        <Button classType='active-bg' onClick={() => wrapperRef.downloadPNG()}>
          SAVE PICTURE
        </Button>
      </div>
    </div>
  );
};

export default storeConnector(HighChartRow, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
