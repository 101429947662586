import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CommandsCategory } from '@src/types/Commands';
import PCS from '@components/Battery/Powin/Commands/PowinBess/PCS';
import DCContactor from '@components/Battery/Powin/Commands/PowinBess/DCContactor';
import FaultReset from '@components/Battery/Powin/Commands/PowinBess/FaultReset';
import { CommandsModalContextProvider } from '../CommandsModalContext';

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const btnContainer = {
  marginBottom: '15px',
};

interface Props {
  powinControllerName: string;
  open: boolean;
  bmsId: string;
  bmsName: string;
  onClose: () => void;
  siteMeta: SiteMeta;
}

function PowinBessCommandsModal({
  powinControllerName,
  open,
  bmsId,
  bmsName,
  onClose,
  siteMeta,
}: Props) {
  return (
    <CommandsModalContextProvider
      category={CommandsCategory.POWINBESS}
      deviceId={siteMeta.siteId}
      defaultParams={{
        POWINBESS: bmsId?.split('_').pop(),
      }}
    >
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
        <StyledDialogTitle>
          {powinControllerName} {bmsName} Controls
          <IconButton onClick={onClose} sx={{ mr: -2, mt: -1 }}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers>
          <Box sx={{ mt: 1 }}>
            <div style={btnContainer}>
              <PCS />
            </div>
            <div style={btnContainer}>
              <DCContactor />
            </div>
            <div style={btnContainer}>
              <FaultReset />
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </CommandsModalContextProvider>
  );
}

export default storeConnector(PowinBessCommandsModal, {
  config: ['siteMeta'],
});
