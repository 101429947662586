import { useState, useRef } from 'react';
import './HomeSystemMeter.scoped.scss';
import Input from '@components/_elements/Input/Input';
import TextLoader from '@components/_elements/TextLoader';
import CustomSitePoints from '@components/_shared/CustomSitePoints/CustomSitePoints';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { getTextColor, formatNum } from '@utils';
import storeConnector from '@store/storeConnector';
import ActiveModesList from '../Config/Modes/ActiveModesList';
import { getTopicForTooltip } from '@utils/index_ts';

const HomeSystemMeter = ({ siteMeta, topicDict }) => {
  const [selectedPOIMeterIndex, setSelectedPOIMeterIndex] = useState(0);
  const [dataRt, setDataRt] = useState({});
  const arrayFieldsData = useRef({});

  const useNewTopicStructure = siteMeta.ui.Use_New_Topic_Structure;

  const meterPrefix =
    selectedPOIMeterIndex === 0
      ? ''
      : `${siteMeta.MeterNames[selectedPOIMeterIndex - 1].Name}_`;
  const POIMeterAllVarNamesArr =
    selectedPOIMeterIndex === 0
      ? ['kW', 'kVAR', 'PF', 'Hz', 'Voltage', 'Current']
      : ['kW', 'kVAR', 'PF', 'Hz', 'Voltage', 'Current'].map(
          (f) => `${meterPrefix}${f}`,
        );

  useWsSubscribe(
    {
      site: {
        fields: [
          'UnitsOnline',
          'ChaSt',
          'LocRemCtl',
          ...(useNewTopicStructure ? [] : POIMeterAllVarNamesArr),
        ],
        cb: (data) => {
          Object.keys(data).forEach((f) => {
            if (typeof data[f] === 'object') {
              const arrayData = arrayFieldsData.current;
              arrayData[f] = { ...arrayData[f], ...data[f] };
              arrayFieldsData.current = arrayData;
            }
          });
          const newValue = Object.keys(data).reduce((acc, cv) => {
            const newAcc = { ...acc };
            if (Object.keys(arrayFieldsData.current).includes(cv)) {
              newAcc[cv] = Object.values(arrayFieldsData.current[cv]).reduce(
                (a, c) => a + c,
                0,
              );
            } else {
              newAcc[cv] = data[cv];
            }
            return newAcc;
          }, {});
          setDataRt((prev) => ({ ...prev, ...newValue }));
        },
      },
      ...(useNewTopicStructure
        ? {
            meter: {
              sourceDeviceId: 'meter_1',
              fields: POIMeterAllVarNamesArr,
              cb: (data) => setDataRt((prev) => ({ ...prev, ...data })),
            },
          }
        : {}),
    },
    [meterPrefix],
  );

  const getActive = (meterPrefix) => {
    return +siteMeta?.MW_Enable
      ? `${(Number(+dataRt[`${meterPrefix}kW`]) / 1000).toFixed(1)}`
      : (`${Number(dataRt[`${meterPrefix}kW`]).toFixed(1)}` ?? '-');
  };

  const getReactive = (meterPrefix) => {
    return +siteMeta?.MW_Enable
      ? `${(Number(+dataRt[`${meterPrefix}kVAR`]) / 1000).toFixed(1)}`
      : (`${Number(dataRt[`${meterPrefix}kVAR`]).toFixed(1)}` ?? '-');
  };

  const getPowerFactor = (meterPrefix) => {
    return (
      dataRt[`${meterPrefix}PF`] ||
      (
        dataRt[`${meterPrefix}kW`] /
          Math.abs(
            Math.sqrt(
              Math.pow(dataRt[`${meterPrefix}kW`], 2) +
                Math.pow(dataRt[`${meterPrefix}kVAR`], 2),
            ),
          ) || 0
      ).toFixed(2)
    );
  };

  const isLoadingPoverFactor = () => {
    return (
      [`${meterPrefix}kW`, `${meterPrefix}kVAR`]
        .map((f) => isLoading(f))
        .includes(true) ||
      ![`${meterPrefix}PF`, `${meterPrefix}kW`]
        .map((f) => isLoading(f))
        .includes(false)
    );
  };

  const isLoading = (field) => {
    return !Object.keys(dataRt).includes(field);
  };

  const unitsOnline = [dataRt.UnitsOnline, siteMeta?.Units.length].join(' / ');

  return (
    <div className='home-system-meter info-wrapper'>
      <table className='info'>
        <tbody>
          <tr>
            <td
              className='title-line small-pd-top small-pd-left'
              style={{ paddingBottom: '4px', paddingTop: '0' }}
            >
              System
            </td>
            <td />
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              <TextLoader
                toolTipContent={getTopicForTooltip('ChaSt', topicDict)}
                expectedSize={5}
                loading={isLoading('ChaSt')}
              >
                {dataRt.ChaSt}
              </TextLoader>
            </td>
          </tr>
          <tr>
            <td>Mode:</td>
            <td>
              <TextLoader
                toolTipContent={getTopicForTooltip('LocRemCtl', topicDict)}
                expectedSize={5}
                loading={isLoading('LocRemCtl')}
              >
                {dataRt.LocRemCtl}
              </TextLoader>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'baseline' }}>Application:</td>
            <td>
              <ActiveModesList />
            </td>
          </tr>
          <tr>
            <td>Units Online:</td>
            <td className={getTextColor(unitsOnline)}>
              <div className='row-cell'>
                <span>
                  <TextLoader
                    toolTipContent={getTopicForTooltip(
                      'UnitsOnline',
                      topicDict,
                    )}
                    // expectedSize={`${siteMeta?.Units.length}`.length}
                    loading={isLoading('UnitsOnline')}
                  >
                    {dataRt.UnitsOnline}
                  </TextLoader>
                </span>
                <span>&nbsp;/&nbsp;</span>
                <span>{siteMeta?.Units.length}</span>
              </div>
            </td>
          </tr>
          <CustomSitePoints siteMeta={siteMeta} />
          <tr>
            <td className='title-line'>Meter:</td>
            <td style={{ paddingTop: '10px' }}>
              {siteMeta?.MeterNames?.length ? (
                <Input
                  type='dropdown'
                  value={{ index: selectedPOIMeterIndex }}
                  onChange={(val) => setSelectedPOIMeterIndex(val)}
                  options={['POI Meter'].concat(
                    siteMeta.MeterNames.map(({ Display }) => Display),
                  )}
                />
              ) : (
                'POI Meter'
              )}
            </td>
          </tr>
          <tr>
            <td>Active Power:</td>
            <td>
              <div className='row-cell'>
                <span>
                  <TextLoader
                    toolTipContent={getTopicForTooltip(
                      `${meterPrefix}kW`,
                      topicDict,
                    )}
                    loading={isLoading(`${meterPrefix}kW`)}
                  >
                    {getActive(meterPrefix)}
                  </TextLoader>
                </span>
                <span>&nbsp;{+siteMeta?.MW_Enable ? 'MW' : 'kW'}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Reactive Power:</td>
            <td>
              <div className='row-cell'>
                <span>
                  <TextLoader
                    toolTipContent={getTopicForTooltip(
                      `${meterPrefix}kVAR`,
                      topicDict,
                    )}
                    loading={isLoading(`${meterPrefix}kVAR`)}
                  >
                    {getReactive(meterPrefix)}
                  </TextLoader>
                </span>
                <span>&nbsp;{+siteMeta?.MW_Enable ? 'MVAR' : 'kVAR'}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Frequency:</td>
            <td>
              <div className='row-cell'>
                <span>
                  <TextLoader
                    toolTipContent={getTopicForTooltip(
                      `${meterPrefix}Hz`,
                      topicDict,
                    )}
                    loading={isLoading(`${meterPrefix}Hz`)}
                  >
                    {dataRt[`${meterPrefix}Hz`]}
                  </TextLoader>
                </span>
                <span>&nbsp;Hz</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Voltage:</td>
            <td>
              <div className='row-cell'>
                <span>
                  <TextLoader
                    toolTipContent={getTopicForTooltip(
                      `${meterPrefix}Voltage`,
                      topicDict,
                    )}
                    loading={isLoading(`${meterPrefix}Voltage`)}
                  >
                    {formatNum({ v: dataRt[`${meterPrefix}Voltage`], a: 2 })}
                  </TextLoader>
                </span>
                <span>&nbsp;kV</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Current:</td>
            <td>
              <div className='row-cell'>
                <span>
                  <TextLoader
                    toolTipContent={getTopicForTooltip(
                      `${meterPrefix}Current`,
                      topicDict,
                    )}
                    loading={isLoading(`${meterPrefix}Current`)}
                  >
                    {dataRt[`${meterPrefix}Current`]}
                  </TextLoader>
                </span>
                <span>&nbsp;A</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Power Factor:</td>
            <td>
              <TextLoader
                toolTipContent={getTopicForTooltip(
                  `${meterPrefix}PF`,
                  topicDict,
                )}
                loading={isLoadingPoverFactor()}
              >
                {getPowerFactor(meterPrefix)}
              </TextLoader>
            </td>
          </tr>
          <tr />
        </tbody>
      </table>
    </div>
  );
};

export default storeConnector(HomeSystemMeter, {
  config: ['siteMeta'],
  mqtt: ['topicDict'],
});
