import SYS from '@pages/Sun/pages/SYS';
import PCS from '@pages/Sun/pages/PCS';
import TRACKERS from '@pages/Sun/pages/TRACKERS';
import MET from '@pages/Sun/pages/MET';
import SLD from '@pages/Sun/pages/SLD';
import { Navigate, RouteObject } from 'react-router';
import AppLayout from '@pages/_layout/AppLayout';
import TopBar from './TopBar';

export default [
  {
    path: '/sun',
    element: <AppLayout topBar={<TopBar />} />,
    children: [
      { index: true, element: <Navigate to='sys' replace /> },
      { path: 'sys', element: <SYS /> },
      { path: 'pcs', element: <PCS /> },
      { path: 'trackers', element: <TRACKERS /> },
      { path: 'met', element: <MET /> },
      { path: 'sld', element: <SLD /> },
    ],
  },
] as RouteObject[];
