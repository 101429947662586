import { NavLink } from 'react-router';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';

function TopBar({ siteMeta }: { siteMeta: SiteMeta }) {
  return (
    <div className='topbar'>
      <div className='frame'>
        {siteMeta.ui.Battery_SYS_Enable ? (
          <NavLink
            to='/battery/sys'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            SYS
          </NavLink>
        ) : null}
        {siteMeta.ui.Battery_PCS_Enable ? (
          <NavLink
            to='/battery/pcs'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            PCS
          </NavLink>
        ) : null}
        {siteMeta.ui.Battery_BATT_Enable ? (
          <NavLink
            to='/battery/bms'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            BMS
          </NavLink>
        ) : null}
        {siteMeta.ui.Battery_Tesla_Enable ? (
          <NavLink
            to='/battery/tesla'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            TESLA
          </NavLink>
        ) : null}
        {siteMeta.ui.Battery_Powin_Enable ? (
          <NavLink
            to='/battery/powin'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            POWIN
          </NavLink>
        ) : null}
        {siteMeta.ui.Battery_BOP_Enable ? (
          <NavLink
            to='/battery/bop'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            Enclosure
          </NavLink>
        ) : null}
        {siteMeta.ui.Battery_SLD_Enable ? (
          <NavLink
            to='/battery/sld'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            SLD
          </NavLink>
        ) : null}
      </div>
      <div />
    </div>
  );
}

export default storeConnector(TopBar, {
  config: ['siteMeta'],
});
