import { SeverityTypes } from '@src/types/Alerts';
import CautionIcon from '@assets/caution.svg?react';
import WarningIcon from '@assets/warning.svg?react';
import FireIcon from '@assets/fire.svg?react';
import { styled } from '@mui/material/styles';

const StyledSeverityFieldWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '.icon': {
    marginRight: '10px',
  },
});

export default function AlertSeverityField({
  severity,
}: {
  severity: SeverityTypes;
}) {
  function getIconTag() {
    switch (severity) {
      case 2:
        return <FireIcon className='icon s active' />;
      case 1:
        return <WarningIcon className='icon s active' />;
      case 0:
        return <CautionIcon className='icon s active' />;
      default:
        break;
    }
    return null;
  }

  return (
    <StyledSeverityFieldWrapper>
      {getIconTag()}
      <div>{SeverityTypes[severity]}</div>
    </StyledSeverityFieldWrapper>
  );
}
