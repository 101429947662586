import { TextField } from '@mui/material';
import { AddUserFormValues } from '@src/hooks/api/mutations/useUserCreate';
import { useFormContext } from 'react-hook-form';

function PasswordConfirmationField() {
  const {
    register,
    formState: { errors },
  } = useFormContext<AddUserFormValues>();

  return (
    <TextField
      fullWidth
      label='Confirmation'
      {...register('passwordConfirmation')}
      error={!!errors.passwordConfirmation}
      helperText={errors.passwordConfirmation?.message}
      type='password'
      size='small'
    />
  );
}

export default PasswordConfirmationField;
