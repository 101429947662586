import { ReactElement } from 'react';

const textProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'middle',
  transform: 'rotate(90) translate(-817,-785)',
  x: 45,
  y: 770,
};

export const GREEN = '#20c191';
export const RED = '#fc4349';
export const GRAY = '#BEBEBE';

interface ModuleProps {
  label: string;
  status?: 1 | 0;
}

export const getStatusFillForDCDisconnect = (status: number | undefined) => {
  if (status === undefined || status === null) {
    return GRAY;
  }
  if (status === 1) {
    return GREEN;
  }
  return RED;
};

export function Module(props: ModuleProps): ReactElement {
  const { label, status } = props;

  return (
    <g>
      <text {...textProps}>{label}</text>
      <rect
        y='765'
        width='27'
        height='27'
        fill={getStatusFillForDCDisconnect(status)}
      />
    </g>
  );
}

export interface DcBusData {
  DCkW?: number;
  DCV?: number;
  DCA?: number;
  PF?: number;
  Hz?: number;
  DCDisconnect?: 1 | 0;
}

interface DcBusProps {
  data: DcBusData;
}

export function DcBus(props: DcBusProps): ReactElement {
  const { data } = props;
  const svgAttrs = {
    y: 734,
    width: 145,
    height: 130,
  };

  return (
    <g>
      <rect y={svgAttrs.y} height={svgAttrs.height} width={svgAttrs.width} />
      <foreignObject
        y={svgAttrs.y}
        width={svgAttrs.height}
        height={svgAttrs.width}
        transform='rotate(-90) translate(-864,-734)'
      >
        <div style={{ padding: 10 }}>
          <b>DC Bus</b>
          <div>
            kW: <span>{data.DCkW}</span>
          </div>
          <div>
            Hz: <span>{data.Hz}</span>
          </div>
          <div>
            Pf: <span>{data.PF}</span>
          </div>
          <div>
            A: <span>{data.DCA}</span>
          </div>
          <div>
            V: <span>{data.DCV}</span>
          </div>
        </div>
      </foreignObject>
    </g>
  );
}

interface ArrowProps {
  markerId: string;
  label: string;
}

export function Arrow(props: ArrowProps): ReactElement {
  const { markerId, label } = props;
  return (
    <g>
      <path d='M8.92 792h.16v-53.71' markerEnd={`url(#${markerId})`} />
      <text {...textProps} x={170} y={797}>
        {label}
      </text>
    </g>
  );
}

export function Transformer(props: { label: string }): ReactElement {
  const { label } = props;

  return (
    <g>
      <text {...textProps} transform='rotate(-180) translate(-60,-1500)'>
        {label}
      </text>
      <path d='M32.89 759.11h-8.22a4.11 4.11-180 0 0-4.12 4.11 4.11 4.11-180 0 0 4.12 4.11 4.11 4.11-180 0 0-4.12 4.12 4.11 4.11-180 0 0 4.12 4.11 4.11 4.11-180 0 0-4.12 4.11 4.11 4.11-180 0 0 4.12 4.11 4.11 4.11-180 0 0-4.12 4.11 4.11 4.11-180 0 0 4.12 4.11h8.22' />
      <path d='M0 759.11h8.22a4.11 4.11 0 0 1 4.11 4.11 4.11 4.11 0 0 1-4.11 4.11 4.11 4.11 0 0 1 4.11 4.12 4.11 4.11 0 0 1-4.11 4.11 4.11 4.11 0 0 1 4.11 4.11 4.11 4.11 0 0 1-4.11 4.11 4.11 4.11 0 0 1 4.11 4.11A4.11 4.11 0 0 1 8.22 792H0' />
    </g>
  );
}

interface SwitchProps {
  status: boolean;
  label: string;
  reverse?: boolean;
  xCoordinate?: number;
  yCoordinate?: number;
}

//  true = closed
//  false = open
export function Switch({
  status,
  label,
  xCoordinate,
  yCoordinate,
}: SwitchProps): ReactElement {
  const connectorTextProps = {
    fill: 'currentColor',
    stroke: 'none',
    textAnchor: 'middle',
    transform: 'rotate(-179) translate(0,-1530)',
    x: xCoordinate,
    y: yCoordinate,
  };

  return (
    <>
      <path d='M14.5 757.78v8.95' />
      {status ? (
        <line x1={14.5} y1={765} x2={14.5} y2={792} />
      ) : (
        <path d='m3.95 768.1 10.55 14.96V792' />
      )}
      <text {...connectorTextProps} transform={connectorTextProps.transform}>
        {label}
      </text>
    </>
  );
}

interface DynamicConnectorProps {
  label: string;
  pathCoordinates: string;
  className: string;
  xCoordinate: number;
  yCoordinate: number;
}

export function DynamicConnector({
  label,
  pathCoordinates,
  xCoordinate,
  yCoordinate,
  className,
}: DynamicConnectorProps): ReactElement {
  const connectorTextProps = {
    fill: 'currentColor',
    stroke: 'none',
    textAnchor: 'middle',
    transform: '',
    x: xCoordinate,
    y: yCoordinate,
  };

  const { transform } = connectorTextProps;

  return (
    <>
      <path d={pathCoordinates} className={className} />

      <text {...connectorTextProps} transform={transform}>
        {label}
      </text>
    </>
  );
}

interface ResistorProps {
  label: string;
  path_1_coordinates: string;
  path_2_coordinates: string;
  xCoordinate: number;
  yCoordinate: number;
}

export function Resistor({
  label,
  path_1_coordinates,
  path_2_coordinates,
  xCoordinate,
  yCoordinate,
}: ResistorProps): ReactElement {
  const connectorTextProps = {
    fill: 'currentColor',
    stroke: 'none',
    textAnchor: 'middle',
    transform: 'rotate(-90) translate(-730,-720)',
    x: xCoordinate,
    y: yCoordinate,
  };

  return (
    <>
      <path d={path_1_coordinates} className='st1' />
      <path d={path_2_coordinates} className='st1' />

      <text {...connectorTextProps} transform={connectorTextProps.transform}>
        {label}
      </text>
    </>
  );
}

interface DatapointConnectorProps {
  voltage: string | number | null;
  current: string | number | null;
  activePower: string | number | null;
  reactivePower: string | number | null;
  pathCoordinates: string;
  className: string;
  xCoordinate: number;
  yCoordinate: number;
}

export function DatapointConnector({
  voltage,
  current,
  activePower,
  reactivePower,
  pathCoordinates,
  xCoordinate,
  yCoordinate,
  className,
}: DatapointConnectorProps): ReactElement {
  const connectorTextProps = {
    fill: 'currentColor',
    stroke: 'none',
    textAnchor: 'left',
    transform: '',
    x: xCoordinate,
  };

  const line2YCoordinate = yCoordinate + 40;
  const line3YCoordinate = line2YCoordinate + 40;
  const line4YCoordinate = line3YCoordinate + 40;
  const { transform } = connectorTextProps;

  return (
    <>
      <path d={pathCoordinates} className={className} />

      {/* 580 */}
      <text {...connectorTextProps} y={yCoordinate} transform={transform}>
        {voltage}
      </text>

      {/* 620 */}
      <text {...connectorTextProps} y={line2YCoordinate} transform={transform}>
        {current}
      </text>

      {/* 660 */}
      <text {...connectorTextProps} y={line3YCoordinate} transform={transform}>
        {activePower}
      </text>

      {/* 700 */}
      <text {...connectorTextProps} y={line4YCoordinate} transform={transform}>
        {reactivePower}
      </text>
    </>
  );
}
