import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '@mui/material';

import {
  deviceFilter,
  SiteTypes,
} from '@src/utils/defaultDataStubs/stubFilters';
import { CollectionPayload } from '@src/types/Collection';

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
}

function GenerateDevicesButton({
  setJsonPayload,
  setOpen,
}: Props): React.ReactElement {
  const json = deviceFilter(
    sessionStorage.getItem('siteType')
      ? (sessionStorage.getItem('siteType') as SiteTypes)
      : null,
  );
  const handleGenerateDevicesClick = () => {
    setJsonPayload(json);
    setOpen(true);
  };

  return (
    <Button
      component='label'
      variant='contained'
      onClick={handleGenerateDevicesClick}
    >
      Generate Devices
    </Button>
  );
}

export default GenerateDevicesButton;
