import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Typography } from '@mui/material';
import {
  CommandsCategory,
  CommandsOperation,
  CommandsParams,
} from '@src/types/Commands';
import useGetCommands from '@hooks/commands/useGetCommands';
import usePublishCommand from '@hooks/commands/usePublishCommand';
import storeConnector from '@store/storeConnector';
import { Actions } from '@src/types/Actions';
import CommandsConfirmationModal from './CommandsConfirmationModal';

type ContextState = {
  sendCommand: (operation: CommandsOperation, values?: CommandsParams) => void;
};

const CommandsModalContext = createContext<ContextState>({
  sendCommand: (_operation: CommandsOperation, _values?: CommandsParams) => {},
});

interface CommandsModalContextProviderProps {
  children: ReactNode;
  deviceId: string;
  unitName?: string;
  category: CommandsCategory;
  defaultParams?: Record<string, string>;
  actions: Actions;
}

export const CommandsModalContextProvider = storeConnector(
  ({
    children,
    deviceId,
    category,
    unitName,
    defaultParams = {},
    actions,
  }: CommandsModalContextProviderProps) => {
    const publishCommand = usePublishCommand({
      deviceId,
      unitName,
      sendCommand: actions.sendCommand,
    });

    const [commandString, setCommandString] = useState<string | null>(null);

    const sendCommand = useCallback(
      (operation: CommandsOperation, params: CommandsParams = {}) =>
        setCommandString(
          useGetCommands(category, operation, { ...defaultParams, ...params }),
        ),
      [defaultParams, category],
    );

    const handleSend = () => {
      if (!commandString) {
        return;
      }

      publishCommand(commandString);
      setCommandString(null);
    };

    return (
      <CommandsModalContext.Provider value={{ sendCommand }}>
        {children}
        {commandString && (
          <CommandsConfirmationModal
            open
            onClose={() => setCommandString(null)}
            onConfirm={handleSend}
          >
            <Typography variant='body1' textAlign='center'>
              Are you sure you want to Execute?
            </Typography>
            <Typography
              variant='body1'
              fontWeight={600}
              textAlign='center'
              sx={{ mt: 2 }}
            >
              {commandString}
            </Typography>
          </CommandsConfirmationModal>
        )}
      </CommandsModalContext.Provider>
    );
  },
  {},
);

export const useCommandsModalContext = () =>
  useContext<ContextState>(CommandsModalContext);
