import { SiteMeta } from '@src/types/SiteMeta';
import { Navigate } from 'react-router';
import {
  isActionRole,
  isSuperAdmin,
  isAdmin,
  engineerOrAdmin,
} from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { PageNotFound, TechnicalWorks } from '@pages/PageError';

export const findForwardRouteForBatteryRoot = (
  siteMeta: SiteMeta,
): string | undefined => {
  const { ui: siteMetaUi } = siteMeta;
  let forwardPath = '';
  if (siteMetaUi.Battery_SYS_Enable) {
    forwardPath = 'sys';
  } else if (siteMetaUi.Battery_PCS_Enable) {
    forwardPath = 'pcs';
  } else if (siteMetaUi.Battery_BATT_Enable) {
    forwardPath = 'batt';
  } else if (siteMetaUi.Battery_Tesla_Enable) {
    forwardPath = 'tesla';
  } else if (siteMetaUi.Battery_BOP_Enable) {
    forwardPath = 'bop';
  } else if (siteMetaUi.Battery_Powin_Enable) {
    forwardPath = 'powin';
  }
  return forwardPath || undefined;
};

export const findForwardRouteForIncidentCommandRoot = (
  siteMeta: SiteMeta,
): string | undefined => {
  const { ui: siteMetaUi } = siteMeta;
  let forwardPath = '';
  if (siteMetaUi.Incident_Command_Enable) {
    forwardPath = 'incident-command';
  }
  return forwardPath || undefined;
};

export const findForwardRouteForConfigRoot = (
  siteMeta: SiteMeta,
): string | undefined => {
  const { ui: siteMetaUi } = siteMeta;
  let forwardPath = '';
  if (siteMetaUi.Home_Config_Modes_Enable) {
    forwardPath = 'modes';
  } else if (siteMetaUi.Home_Config_Alerts_Enable) {
    forwardPath = 'alerts';
  } else if (siteMetaUi.Home_Calendar_Enable) {
    forwardPath = 'applications';
  }
  return forwardPath || undefined;
};

export default function runRouteGuards(
  siteMeta: SiteMeta | null,
  pathname: string,
  role: UserRole,
) {
  const navigate = (to: string) => <Navigate to={to} replace />;

  if (siteMeta === null && pathname !== '/wizard') {
    return <Navigate to='/wizard' replace />;
  }
  if (siteMeta === null && pathname === '/wizard' && isSuperAdmin(role)) {
    return false;
  }
  if (siteMeta === null) {
    return <TechnicalWorks />;
  }

  const checkPVPageConfig = (page: string) =>
    siteMeta.ui.TabsList?.PV?.some((item) => item.toLowerCase() === page);

  if (siteMeta.ui.Modes_Only_UI && pathname !== '/home/config/modes') {
    return navigate('/home/config/modes');
  }

  if (pathname === '/') {
    return navigate(`/home`);
  }

  // MAIN ROUTE GUARDS
  if (!isSuperAdmin(role)) {
    if (
      (pathname.startsWith('/battery') && !siteMeta.ui.Battery_Enable) ||
      (pathname.startsWith('/sun') && !siteMeta.ui.Sun_Enable) ||
      (pathname.startsWith('/mkt') && !siteMeta.ui.Mkt_Enable) ||
      (pathname.startsWith('/history') && !siteMeta.ui.History_Enable) ||
      (pathname.startsWith('/wind') && !siteMeta.ui.Wind_Enable)
    ) {
      return <PageNotFound />;
    }

    // USER ROUTE GUARDS
    if (pathname.match(/^\/admin\/users/) && !isAdmin(role)) {
      return <PageNotFound />;
    }

    // HOME ROOT ROUTE GUARDS
    if (
      (pathname.match(/^\/home\/dashboards/) &&
        !siteMeta.ui.Home_Dashboard_Enable) ||
      (pathname.match(/^\/home\/historian\/export/) &&
        !siteMeta.ui.Home_Historian_Export_Enable) ||
      (pathname.match(/^\/home\/calendar/) &&
        (!isActionRole(role) || !siteMeta.ui.Home_Calendar_Enable)) ||
      (pathname.match(/^\/home\/alerts\/configs/) &&
        (!engineerOrAdmin(role) ||
          !siteMeta.ui.Home_Alerts_Config_Editor_Enable)) ||
      (pathname.match(/^\/home\/alerts\/timeline/) &&
        !siteMeta.ui.Home_Alerts_Timeline_Enable) ||
      pathname.match(/^\/home\/collections\/.*/)
    ) {
      return <PageNotFound />;
    }

    // HOME CONFIG ROUTE GUARDS
    if (
      (pathname.match(/^\/home\/config\/applications/) &&
        !siteMeta.ui.Home_Calendar_Enable) ||
      (pathname.match(/^\/home\/config\/stacks/) &&
        !siteMeta.ui.Home_Calendar_Enable) ||
      (pathname.match(/^\/home\/config\/alerts/) &&
        !siteMeta.ui.Home_Config_Alerts_Enable) ||
      (pathname.match(/^\/home\/config\/modes/) &&
        !(
          siteMeta.ui.Home_Config_Modes_Enable &&
          siteMeta.ControlModes.length > 0
        ))
    ) {
      return <PageNotFound />;
    }

    // BATTERY ROUTE GUARDS
    if (
      (pathname.match(/^\/battery\/sys/) && !siteMeta.ui.Battery_SYS_Enable) ||
      (pathname.match(/^\/battery\/pcs/) && !siteMeta.ui.Battery_PCS_Enable) ||
      (pathname.match(/^\/battery\/bms/) && !siteMeta.ui.Battery_BATT_Enable) ||
      (pathname.match(/^\/battery\/tesla/) &&
        !siteMeta.ui.Battery_Tesla_Enable) ||
      (pathname.match(/^\/battery\/powin/) &&
        !siteMeta.ui.Battery_Powin_Enable) ||
      (pathname.match(/^\/battery\/bop/) && !siteMeta.ui.Battery_BOP_Enable) ||
      (pathname.match(/^\/battery\/sld/) && !siteMeta.ui.Battery_SLD_Enable)
    ) {
      return <PageNotFound />;
    }

    // SUN ROUTE GUARDS
    // "/sun/sys" "/sun/pcs"
    const currentSubPath = pathname.split('/')[2];
    if (
      pathname.startsWith(`/sun`) &&
      currentSubPath &&
      !checkPVPageConfig(currentSubPath)
    ) {
      return <PageNotFound />;
    }
  }

  return false;
}
