import { ApiSiteMetaUpdate } from '@src/types/SiteMeta';
import { DefaultTemp, PVSubPagesEnum } from '@src/types/SiteMetaUI';
/* eslint-disable max-lines */
import { ControlMode } from '@src/types/control-modes/ControlModes';

const siteMetaStub: ApiSiteMetaUpdate = {
  siteId: '0000000A',
  Topology_Override: {
    bms: [],
    rack: [],
    bmsRack: [],
  },
  ui: {
    TabsList: {
      PV: [
        PVSubPagesEnum.SYS,
        PVSubPagesEnum.PCS,
        PVSubPagesEnum.TRACKERS,
        PVSubPagesEnum.MET,
        PVSubPagesEnum.SLD,
      ],
    },
    features: {
      Humidity_Gauge_Enabled: true,
      SCADA_Controls_Disabled: false,
    },
    Mkt_Enable: true,
    Sun_Enable: true,
    dashboards: [
      {
        id: '12345',
        name: 'My Dashboard',
        cells: [
          {
            key: '1',
            data: {
              title: 'Topic box 1',
              pointList: [
                {
                  topic: 'ems/site/0000000A/root/BESSkW/float',
                  displayName: 'Site BESSkW',
                },
                {
                  topic: 'ems/site/0000000A/meter/meter_1/kW/float',
                  displayName: 'Meter 1 kW',
                },
                {
                  topic: 'ems/site/0000000A/unit/0000001F/root/kW/float',
                  displayName: 'Unit 1 kW',
                },
                {
                  topic: 'ems/site/0000000A/unit/0000002F/root/kW/float',
                  displayName: 'Unit 2 kW',
                },
              ],
            },
            variant: 'point-list',
          },
          {
            key: '2',
            data: {
              title: 'Topic box 2',
              pointList: [
                {
                  topic: 'ems/site/0000000A/unit/0000001F/root/kW/float',
                  displayName: 'Unit 1 kW',
                },
                {
                  topic: 'ems/site/0000000A/unit/0000001F/pcs/pcs_1/kW/float',
                  displayName: 'Unit 1 PCS 1 kW',
                },
              ],
            },
            variant: 'point-list',
          },
        ],
        layouts: {
          lg: [
            {
              h: 4,
              i: '1',
              w: 2,
              x: 0,
              y: 0,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '2',
              w: 2,
              x: 2,
              y: 0,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '3',
              w: 2,
              x: 4,
              y: 0,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '4',
              w: 2,
              x: 0,
              y: 4,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '5',
              w: 2,
              x: 2,
              y: 4,
              minH: 2,
              minW: 2,
            },
          ],
          md: [
            {
              h: 4,
              i: '1',
              w: 3,
              x: 0,
              y: 0,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '2',
              w: 3,
              x: 3,
              y: 0,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '3',
              w: 3,
              x: 0,
              y: 4,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '4',
              w: 3,
              x: 3,
              y: 4,
              minH: 2,
              minW: 2,
            },
            {
              h: 4,
              i: '5',
              w: 3,
              x: 0,
              y: 8,
              minH: 2,
              minW: 2,
            },
          ],
          xs: [
            {
              h: 4,
              i: '1',
              w: 6,
              x: 0,
              y: 0,
              minH: 2,
              minW: 6,
            },
            {
              h: 4,
              i: '2',
              w: 6,
              x: 0,
              y: 4,
              minH: 2,
              minW: 6,
            },
            {
              h: 4,
              i: '3',
              w: 6,
              x: 0,
              y: 8,
              minH: 2,
              minW: 6,
            },
            {
              h: 4,
              i: '4',
              w: 6,
              x: 0,
              y: 12,
              minH: 2,
              minW: 6,
            },
            {
              h: 4,
              i: '5',
              w: 6,
              x: 0,
              y: 16,
              minH: 2,
              minW: 6,
            },
          ],
        },
      },
    ],
    custom_links: [
      {
        url: 'https://minimum-battery.grafana.net/dashboards',
        display_name: 'Dashboard Reports',
      },
    ],
    controlsLockConfig: {
      topic: 'ems/site/0000000A/root/ControlLockThreshold/float',
      pointName: 'Lock Point',
      thresholdMax: 5,
    },
    Modes_Only_UI: false,
    Battery_Enable: true,
    Default_Temp_Unit: DefaultTemp.C,
    TSC_Config_Enable: true,
    Battery_BOP_Enable: true,
    Battery_PCS_Enable: true,
    Battery_SLD_Enable: true,
    Battery_SYS_Enable: true,
    Battery_BATT_Enable: true,
    Home_Overview_Weather_Widget_Enable: true,
    Home_Overview_SLD_Widget_Enable: false,
    rackPopupPointNames: [
      'ContactorStatus',
      'RackSOC',
      'RackSOH',
      'RackVoltage',
      'RackCurrent',
      'MinCellV',
      'MaxCellV',
      'DeltaCellV',
      'MinCellT',
      'MaxCellT',
      'DeltaCellT',
      'Alarm',
    ],
    Battery_Tesla_Enable: true,
    Home_Calendar_Enable: true,
    Command_Access_Enable: true,
    Notes_Enable: true,
    Disable_Controls_Lock: false,
    Home_Dashboard_Enable: true,
    dataRateTimeoutSeconds: 30,
    Incident_Command_Enable: true,
    Use_New_Topic_Structure: true,
    Home_Config_Modes_Enable: true,
    Home_Config_Alerts_Enable: false,
    Home_Alerts_Timeline_Enable: true,
    Home_Historian_Export_Enable: true,
    Battery_BATT_RackTable_Enable: true,
    Alerts_Timeline_Default_Period: '-15m',
    Home_Alerts_Config_Editor_Enable: true,
    WeatherForecast_enable: false,
    Auto_Command_Persist: 'prompt',
    TSC_Only_Allow_Admin_Enable_Disable_Commands: false,
  },
  kWPV: '0',
  NumPV: '0',
  PV_TZ: 'CST',
  UnitsPV: ['0000001F'],
  UnitNamesPV: ['PvUnit1'],
  Rack_Commands: {
    'Open DC Contactor': 'open dc contactor',
    'Close DC Contactor': 'close dc contactor',
  },
  Units: [
    '0000001F',
    '0000002F',
    '0000003F',
    '0000004F',
    '0000005F',
    '0000006F',
    '0000007F',
    '0000008F',
    '0000009F',
    '00000010F',
    '00000011F',
    '00000012F',
  ],
  GPSLat: '30.2672',
  GPSLong: '-97.7431',
  Meters: [],
  kWHRtg: '8000',
  kWMaxP: '4000',
  kWMinP: '-4000',
  kWWind: '0',
  NomFreq: '50',
  NumWind: '0',
  DataRate: '2',
  NumRacks: '3264',
  SiteRackCountLabel: '3200',
  PCS_type: 'PEDC',
  SiteName: 'Minimum Battery',
  kVARMaxQ: '4000',
  kVARMinQ: '-4000',
  kVArMaxQ: '4000',
  kVArMinQ: '-4000',
  Batt_type: 'default',
  MW_Enable: '1',
  RackNames: [
    'A01',
    'A02',
    'A03',
    'A04',
    'A05',
    'A06',
    'A07',
    'A08',
    'A09',
    'A10',
    'B01',
    'B02',
    'B03',
    'B04',
    'B05',
    'B06',
    'B07',
    'B08',
    'B09',
    'B10',
  ],
  UnitNames: [
    'U10000',
    'U2',
    'U3',
    'U4',
    'U5',
    'U6',
    'U7',
    'U8',
    'U9',
    'U10',
    'U11',
    'U12',
  ],
  SOCPlotMax: '100',
  SOCPlotMin: '0',
  Description: 'Site Meta',
  TempPlotMax: '50',
  TempPlotMin: '0',
  kWMaxChaRte: '999999',
  ControlModes: [
    'Frequency Response',
    'Voltage Response',
    'Voltage Response 2 Step',
    'P Correction',
    'AGC Response',
    'Dynamic P',
    'P Follow Ramped',
  ] as ControlMode[],
  FreqDeviation: '0.1',
  NumConverters: 0,
  NumTempSensor: '4',
  Site_Commands: [
    {
      Name: 'Unit Power Cmd',
      Device: 'Unit',
      Command: 'Power::',
    },
    {
      Name: 'Start Units',
      Device: 'Unit',
      Command: 'Start Units',
    },
    {
      Name: 'Stop Units',
      Device: 'Unit',
      Command: 'Stop Units',
    },
    {
      Name: 'Units to Auto',
      Device: 'Unit',
      Command: 'Units to Auto',
    },
    {
      Name: 'Units to Manual',
      Device: 'Unit',
      Command: 'Units to Manual',
    },
    {
      Name: 'Fault Reset',
      Device: 'Site',
      Command: 'Fault Reset',
    },
    {
      Name: 'HV Trip Suppress',
      Device: 'Site',
      Command: 'WriteToVariant::HVTRIP_Supress::1',
    },
    {
      Name: 'HV Trip Unsuppress',
      Device: 'Site',
      Command: 'WriteToVariant::HVTRIP_Supress::0',
    },
    {
      Name: 'Clear Fast Stop',
      Device: 'Site',
      Command: 'WriteToVariant::FastStopSiteUI::0',
    },
  ],
  Thresh_DeltaV: '0.05',
  Unit_Commands: [
    {
      Name: 'Start Unit',
      Command: 'Start Unit',
    },
    {
      Name: 'Stop Unit',
      Command: 'Stop Unit',
    },
    {
      Name: 'PCS Standby Enable',
      Command: 'WriteToVariant::PCS_Standby::1',
    },
    {
      Name: 'PCS Standby Disable',
      Command: 'WriteToVariant::PCS_Standby::0',
    },
    {
      Name: 'Open All DC Contactors',
      Command: 'Open DC Contactors (All)',
    },
    {
      Name: 'Close All DC Contactors',
      Command: 'Close DC Contactors (All)',
    },
    {
      Name: 'PCS Reset',
      Command: 'PCS Reset',
    },
    {
      Name: 'Fault Reset',
      Command: 'Fault Reset',
    },
    {
      Name: 'Clear Fast Stop',
      Command: 'WriteToVariant::FastStopSiteUI::0',
    },
  ],
  kVARMaxChaRte: '100',
  Thresh_AmbTMax: '28',
  Thresh_AmbTMin: '18',
  kWMaxDisChaRte: '999999999',
  Manual_Limit_MaxRateP: 4000,
  Manual_Limit_MaxRateQ: 4000,
  ChargeFromPVVar: 'PVkW',
  FastStop_Enable: '1',
  Thresh_CellTMax: '40',
  Thresh_CellTMin: '10',
  Thresh_UnitMaxP: '3146',
  Thresh_UnitMinP: '-3146',
  UnitPV_Commands: [
    {
      Name: 'Start Unit',
      Command: 'Start Unit',
    },
    {
      Name: 'Stop Unit',
      Command: 'Stop Unit',
    },
    {
      Name: 'Fault Reset',
      Command: 'Fault Reset',
    },
    {
      Name: 'PCS Reset',
      Command: 'PCS Reset',
    },
  ],
  ChargeFromPVOnly: '0',
  kVARMaxDisChaRte: '100',
  Thresh_DeltaCellT: '8',
  Custom_Site_Points: [
    {
      PointName: 'RTAC_P',
      DisplayName: 'RTAC P',
    },
    {
      PointName: 'RTAC_Q',
      DisplayName: 'RTAC Q',
    },
    {
      PointName: 'AllowedMaxQ',
      DisplayName: 'Max kVAR Limit',
    },
    {
      PointName: 'AllowedMinQ',
      DisplayName: 'Min kVAR Limit',
    },
    {
      PointName: 'ADSFlg',
      DisplayName: 'ADS Flag',
    },
  ],
  Thresh_HB_BMS_TimeOut: '30',
  Batt_perUnit_BMS_count: 4,
  Batt_perUnit_PCS_count: 1,
  ItemDeletionTimeSeconds: '14400',
  TeslaChargePowerPointName: 'holding_25005_dispatchable_charge_power',
  TeslaDischargePowerPointName: 'holding_25007_dispatchable_discharge_power',
  PowinAllowedMaxP_PointName: 'AllowedMaxP',
  PowinAllowedMinP_PointName: 'AllowedMinP',
  PowinAllowedMaxQ_PointName: 'AllowedMaxQ',
  PowinAllowedMinQ_PointName: 'AllowedMinQ',
  siteSelectorLinks: [
    {
      label: 'Dev.aws',
      url: 'https://dev.aws.fractalems.com',
    },
    {
      label: 'Funky Penguin',
      url: 'https://funkypenguin.dev.fractalems.com',
    },
  ],
};

export default siteMetaStub;
