import AuditLogs from '@pages/Admin/pages/AuditLogs/index';
import { RouteObject } from 'react-router';
import AppLayout from '@pages/_layout/AppLayout';

export default [
  {
    path: 'audit-logs',
    element: <AppLayout />,
    children: [{ index: true, element: <AuditLogs /> }],
  },
] as RouteObject[];
