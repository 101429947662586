import actionCreators from '@store/actionCreators';
import {
  AggregatedState,
  AppDefaultState,
  AppDefaultStateProps,
  StoreConnectorData,
} from '@store/reducers';
import { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(actionCreators, dispatch) };
}

function WithComp({ children }: { children: ReactElement }) {
  useEffect(() => () => {}, []);
  return children;
}

const withComp = (Component: FC) =>
  function storeHoc({ ...props }) {
    return (
      <WithComp>
        <Component {...props} />
      </WithComp>
    );
  };

type PartialRecord<K extends keyof unknown, T> = {
  [P in K]?: T;
};

export default (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comp: FC<any>,
  data: StoreConnectorData,
) => {
  const mapStateToProps = (
    state: AppDefaultState,
    ownProps?: Record<string, unknown>,
  ) => {
    const usedFields = (
      Object.keys(data) as (keyof AppDefaultStateProps)[]
    ).reduce((accumulator, topLevelStateKey) => {
      if (data[topLevelStateKey] === 'all') {
        return { ...accumulator, ...state[topLevelStateKey] };
      }
      const partialState = (
        data[topLevelStateKey] as unknown as (keyof PartialRecord<
          keyof AggregatedState,
          unknown
        >)[]
      ).reduce((acc, internalStateKey) => {
        acc[internalStateKey] = state[topLevelStateKey][internalStateKey];
        return acc;
      }, {});
      return { ...accumulator, ...partialState };
    }, {});
    return { ...usedFields, ...ownProps };
  };
  return connect(mapStateToProps, mapDispatchToProps)(withComp(comp));
};
