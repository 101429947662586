import { useState } from 'react';
import BatChart from '@components/Battery/BatChart/BatChart';
import HVAC from '@components/Battery/HVAC/HVAC';
import TemperaturesTable from '@components/Battery/TemperaturesTable/TemperaturesTable';
import SelectUnit from '@components/Battery/SelectUnit/SelectUnit';

function BOP() {
  const [selectedUnit, setSelectedUnit] = useState(-1);
  const [selectedTemperatureMode, setSelectedTemperatureMode] = useState(0);

  return (
    <div className='home-row'>
      <div
        style={{ position: 'absolute', top: '14px', left: '14px', zIndex: 101 }}
      >
        <SelectUnit
          unit={selectedUnit}
          noPadding
          onChange={(unitIndex: number) => setSelectedUnit(unitIndex)}
        />
      </div>
      <div className='fcol'>
        <div className='cell block-container'>
          <HVAC selectedUnit={selectedUnit} />
        </div>
        <div className='cell block-container' style={{ height: '50%' }}>
          <BatChart
            changeTempMode={(val: number) => {
              setSelectedTemperatureMode(val);
            }}
            selectedTemperatureMode={selectedTemperatureMode}
            selectedUnit={selectedUnit}
          />
        </div>
      </div>
      <div className='fcol'>
        <div className='cell block-container' style={{ minHeight: '20%' }}>
          <TemperaturesTable />
        </div>
      </div>
    </div>
  );
}
export default BOP;
