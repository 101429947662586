import './ChartLoader.scoped.scss';

const ChartLoader = () => {
  return (
    <div className='chart-loader-container'>
      <div className='chart-icon'>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default ChartLoader;
