import {
  PointConfigEdit,
  PointConfigEditable,
  PointConfigExtended,
} from '@src/types/PointConfig';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
import { CollectionPayload } from '@src/types/Collection';
import {
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import usePointConfigMutations from '@src/hooks/api/mutations/usePointConfigMutations';
import JSONEditor from '@src/components/_elements/JSONEditor/JSONEditor';
import pointConfigSx from '../configs/pointConfigSx';

interface DialogProps {
  jsonData: PointConfigExtended;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  handleCancel: (id?: GridRowId) => () => void;
  setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
}

function PointConfigDialog({
  jsonData,
  handleCancel,
  setOpen,
  setRowModesModel,
  setJsonPayload,
}: DialogProps) {
  const {
    id,
    remove,
    createdAt: _createdAt,
    updatedAt: _updatedAt,
    pointAlias,
    ...rest
  } = jsonData;
  const [newData, setNewData] = useState<PointConfigEdit | PointConfigEditable>(
    id ? rest : { ...rest, pointAlias },
  );

  const queryClient = useQueryClient();

  const getTitle = () => {
    if (remove) {
      return 'Are you sure you want to delete this config?';
    }

    if (id) {
      return 'Edit Point Config';
    }

    return 'Add Point Config';
  };

  const { createPointConfig, updatePointConfig, deletePointConfig } =
    usePointConfigMutations(jsonData.pointAlias);

  const handleSuccessCallback = () => {
    if (id !== undefined) {
      setRowModesModel((prevState) => ({
        ...prevState,
        [id]: { mode: GridRowModes.View },
      }));
    }

    queryClient.invalidateQueries({
      queryKey: [QueryKeys.pointConfigs],
    });
    setJsonPayload(null);
    setOpen(false);
  };

  const handlePCCreate = () =>
    createPointConfig.mutate(newData as PointConfigEdit, {
      onSuccess: handleSuccessCallback,
    });

  const handlePCUpdate = () =>
    updatePointConfig.mutate(newData as PointConfigEditable, {
      onSuccess: handleSuccessCallback,
    });

  const handlePCDelete = () =>
    deletePointConfig.mutate(
      {},
      {
        onSuccess: handleSuccessCallback,
      },
    );

  const handleConfirm = () => {
    if (remove !== undefined) {
      return handlePCDelete();
    }
    if (id) {
      return handlePCUpdate();
    }
    return handlePCCreate();
  };

  return (
    <Dialog
      open
      PaperProps={
        !remove
          ? {
              sx: pointConfigSx.pcDialogPaper,
            }
          : {}
      }
    >
      <DialogTitle>{getTitle()}</DialogTitle>
      {!remove && (
        <DialogContent>
          <JSONEditor
            json={newData}
            onChange={setNewData}
            options={{ mode: 'code' }}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel(id)}>Cancel</Button>
        <Button size='small' variant='contained' onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PointConfigDialog;
