import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';

export interface UseGetWidgetConfigBody {
  sourceDeviceId?: string;
  unitControllerId?: string;
  unitControllerEngineeringId?: string;
}

const useGetInternalWidgetConfig = <T>(
  internalId: string,
  body?: UseGetWidgetConfigBody,
  options?: { enabled?: boolean; queryKeys?: string[] },
) =>
  useApiQuery<T>({
    queryKey: [
      QueryKeys['widget-config-internal'],
      [internalId, ...(options?.queryKeys || [])].join('-'),
    ],
    apiPath: `/widgets/internal/${internalId}`,
    method: 'POST',
    useV2API: true,
    body: body || {},
    enabled: typeof options?.enabled === 'boolean' ? options.enabled : true,
  });

export default useGetInternalWidgetConfig;
