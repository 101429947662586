import { UserState } from '@store/reducers/userData';
import { useState } from 'react';
import storeConnector from '@store/storeConnector';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  gridRowSelectionStateSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Actions } from '@src/types/Actions';
import { LoadingButton } from '@mui/lab';
import { getAlertTopicFromId } from '@src/utils/alerts';
import { isActionRole } from '@src/services/auth';
import useAlertMutations from '@hooks/api/mutations/useAlertMutations';

const AcknowledgeAllDialog = storeConnector(
  // eslint-disable-next-line max-lines-per-function
  ({
    open,
    onClose,
    alertTopics,
    actions,
    username,
  }: {
    open: boolean;
    onClose: () => void;
    alertTopics: string[];
    actions: Actions;
    username: string;
  }) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [ackedNote, setAckedNote] = useState('');
    const { acknowledgeAlert } = useAlertMutations();

    const handleConfirm = async () => {
      if (!ackedNote) {
        return;
      }
      setIsUpdating(true);

      acknowledgeAlert.mutate(
        {
          alertTopics,
          ackedBy: username,
          ackedNote,
        },
        {
          onSuccess: async () => {
            onClose();
          },
          onError: async (e) => {
            actions.notifyError(`Failed to acknowledge alarm - ${e}`);
          },
          onSettled: async () => {
            setIsUpdating(false);
          },
        },
      );
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
        <DialogTitle>Acknowledge {alertTopics.length} Alarm(s)</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Leave a note...'
            type='text'
            fullWidth
            variant='standard'
            value={ackedNote}
            onChange={(e) => setAckedNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button onClick={onClose} variant='outlined' disabled={isUpdating}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleConfirm}
            variant='contained'
            loading={isUpdating}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  },
  {
    user: ['username'],
  },
);

function GridToolbarAcknowledgeButton({ role }: { role: UserState['role'] }) {
  const apiRef = useGridApiContext();
  const rowSelectionState = useGridSelector(
    apiRef,
    gridRowSelectionStateSelector,
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const selectedAlertTopics = rowSelectionState
    .map((rowId) => getAlertTopicFromId(rowId.toString()))
    .filter(Boolean);

  if (!isActionRole(role)) {
    return null;
  }

  return (
    <>
      <Button
        disabled={rowSelectionState.length === 0}
        onClick={() => setDialogOpen(true)}
      >
        <DoneAllIcon />
        Acknowledge
      </Button>
      {dialogOpen && (
        <AcknowledgeAllDialog
          open
          onClose={() => setDialogOpen(false)}
          alertTopics={selectedAlertTopics}
        />
      )}
    </>
  );
}

export default storeConnector(GridToolbarAcknowledgeButton, {
  user: ['role'],
});
