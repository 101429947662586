import { Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import './index.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePicker from 'rc-time-picker';
import Spinner from '@components/_elements/Spinner/Spinner';
import Button from '@components/_elements/Button/Button';
import Pagination from '@components/_elements/Pagination/Pagination';
import { getLocale, useIsFirstRender } from '@utils';
import { store } from '@store';
import storeConnector from '@store/storeConnector';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { tsDueTimeMode } from '@utils/index_ts';

const ALLOWED_MINUTES = [...Array(60).keys()];
const ALLOWED_HOURS = [...Array(24).keys()];
const MAX_MGS_NUM = 10000;
const ITEMS_NUM_ON_PAGE = 100;

function compare(a, b) {
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  return 0;
}
const dateFilterFields = ['dateFrom', 'timeFrom', 'dateTo', 'timeTo'];

const SystemMessagesSubpage = ({ actions, siteMeta, timeMode }) => {
  const TOPIC = 'MSG/+';
  const state = store.getState();
  const client = state.mqtt.clientMQTT;

  const isFirstRender = useIsFirstRender();
  const [filterDict, setFilterDict] = useState({
    dateFrom: undefined,
    timeFrom: undefined,
    dateTo: undefined,
    timeTo: undefined,
    filterStr: '',
    type: 'all',
  });
  const [pgnCurrPage, setPgnCurrPage] = useState(0);
  const [msgsPauseBtn, setMsgsPauseBtn] = useState('Hidden');
  const [msgs, setMsgs] = useState([]);
  const prevMsgsPauseBtn = useRef();
  const loading = useRef(true);
  const msgsCount = useRef(0);
  const msgsRtCount = useRef(0);
  const prevTimeMode = useRef(timeMode);
  const timer = useRef();
  const d = useRef();
  const noMoreMsgs =
    msgs.length && msgs.length >= msgsCount.current + msgsRtCount.current;

  const getUnitsIds = () => {
    const { Units, UnitNames } = siteMeta;
    return Units.map((deviceId, i) => ({ name: UnitNames[i], deviceId }));
  };
  const getActiveSns = () => {
    if (filterDict.type === 'all') {
      return [siteMeta.siteId, ...siteMeta.Units];
    }
    if (filterDict.type.startsWith('unit')) {
      return [
        getUnitsIds()?.find((i) => 'unit ' + i.name === filterDict.type)
          ?.deviceId,
      ];
    }
    return [siteMeta.siteId];
  };
  useEffect(() => {
    if (msgsPauseBtn === 'Pause' && !loading.current) {
      client.subscribe({ [TOPIC]: { qos: 0, rh: 2 } });
      client.on('message', (topic, message, packet) => {
        if (!packet.retain && topic.startsWith('MSG/')) {
          const data = JSON.parse(message.toString());
          setMsgs((prev) => {
            return [
              {
                createdAt: data.TS,
                deviceId: data.SN,
                message: data,
              },
              ...prev,
            ];
          });
        }
      });
    }
    if (msgsPauseBtn === 'Start' || msgsPauseBtn === 'Hidden') {
      client.unsubscribe(TOPIC);
    }
  }, [client, msgsPauseBtn, loading.current]);

  useEffect(() => {
    (async () => {
      loading.current = true;
      await callGetMessages();
      loading.current = false;
      setMsgsPauseBtn('Pause');
    })();
  }, []);

  useEffect(() => {
    const dateFrom = msgs[0]?.createdAt;
    let unloadedMsgs = [];
    let count = null;
    if (
      prevMsgsPauseBtn.current === 'Start' &&
      msgsPauseBtn === 'Pause' &&
      !!msgs.length &&
      !loading.current
    ) {
      const params = {
        filteredSNs: getActiveSns(),
        dateFrom: moment.utc(dateFrom).add(1, 'ms').toISOString(),
        keyword: filterDict.filterStr || undefined,
      };
      const loadData = async () => {
        if (count === null || count > unloadedMsgs.length) {
          const res = await actions.getMessagePaginated({
            ...params,
            offset: unloadedMsgs.length,
          });
          unloadedMsgs = [...unloadedMsgs, ...res.messages];
          count = res.count;
          loadData();
        } else {
          setMsgs([...msgs, ...unloadedMsgs].sort(compare));
          msgsRtCount.current += unloadedMsgs.length;
        }
      };
      loadData();
    }
    prevMsgsPauseBtn.current = msgsPauseBtn;
  }, [msgsPauseBtn]);

  useEffect(() => {
    if (!isFirstRender && timeMode) {
      const fields = {};
      dateFilterFields.forEach((f) => {
        fields[f] = filterDict[f]
          ? tsDueTimeMode({
              timeMode: timeMode,
              lat: siteMeta.GPSLat,
              long: siteMeta.GPSLong,
              ts: filterDict[f],
              prevTimeMode: prevTimeMode.current,
              returnMoment: true,
            })
          : undefined;
      });
      setFilterDict((prev) => ({ ...prev, ...fields }));
      prevTimeMode.current = timeMode;
    }
  }, [timeMode]);

  const getCalendarContainer = () => {
    return d.current || document.getElementById('calendar-container');
  };

  const disabledDateFrom = (current) => {
    const { dateTo, timeTo, timeFrom } = filterDict;
    return current && dateTo
      ? (timeTo &&
          timeFrom &&
          timeFrom.valueOf() > timeTo.valueOf() &&
          current.valueOf() >= dateTo.valueOf()) ||
          current.valueOf() > dateTo.valueOf()
      : null;
  };

  const disabledDateTo = () => {
    const { dateFrom, timeTo, timeFrom } = filterDict;
    return timeTo && dateFrom
      ? (timeTo &&
          timeFrom &&
          timeFrom.valueOf() > timeTo.valueOf() &&
          timeTo.valueOf() <= dateFrom.valueOf()) ||
          timeTo.valueOf() < dateFrom.valueOf()
      : null;
  };

  const disabledMinutesFrom = (hour) => {
    const { dateFrom, dateTo, timeFrom, timeTo } = filterDict;
    const max = timeTo && +timeTo.format('mm');
    if (
      timeTo &&
      timeFrom &&
      dateFrom &&
      dateTo &&
      dateFrom.isSame(dateTo, 'date') &&
      hour === +timeTo.format('HH')
    ) {
      return ALLOWED_MINUTES.filter((m) => m > max);
    }
    return null;
  };

  const disabledHoursFrom = () => {
    const { dateFrom, dateTo, timeTo } = filterDict;
    const max = timeTo && +timeTo.format('HH');
    if (timeTo && dateFrom && dateTo && dateFrom.isSame(dateTo, 'date')) {
      return ALLOWED_HOURS.filter((h) => h > max);
    }
    return null;
  };

  const disabledMinutesTo = (hour) => {
    const { dateFrom, dateTo, timeFrom, timeTo } = filterDict;
    const min = timeFrom && +timeFrom.format('mm');
    if (
      timeTo &&
      timeFrom &&
      dateFrom &&
      dateTo &&
      dateFrom.isSame(dateTo, 'date') &&
      hour === +timeTo.format('HH')
    ) {
      return ALLOWED_MINUTES.filter((m) => m < min);
    }
    return null;
  };

  const disabledHoursTo = () => {
    const { dateFrom, dateTo, timeFrom } = filterDict;
    const min = timeFrom && +timeFrom.format('HH');
    if (timeFrom && dateFrom && dateTo && dateFrom.isSame(dateTo, 'date')) {
      return ALLOWED_HOURS.filter((h) => h < min);
    }
    return null;
  };

  const checkType = (m) => {
    if (siteMeta.Units.includes(m.deviceId)) {
      const index = siteMeta.Units.findIndex((i) => i === m.deviceId);
      if (index !== -1) {
        return 'Unit ' + siteMeta.UnitNames[index];
      }
    }
    return 'SYSTEM';
  };

  const saveAs = (uri, filename) => {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      document.body.appendChild(link);
      link.download = filename;
      link.href = uri;
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };

  const exportCsv = () => {
    const csvContent =
      'data:text/csv;charset=utf-8,dateFrom,timeFrom,type,message\n' +
      msgs.reduce((str, m) => {
        return (
          str +
          `${formatTS(m.createdAt)[0]},${formatTS(m.createdAt)[1]},${checkType(
            m,
          )},${m.message.MSG}\n`
        );
      }, '');
    const encodedUri = encodeURI(csvContent);
    saveAs(encodedUri, 'exported-messages.csv');
  };

  const callGetMessages = async (loadMore) => {
    loading.current = true;
    const { dateFrom, dateTo, timeFrom, timeTo, type } = filterDict;
    const filterDictParams = {};
    if (dateFrom && timeFrom) {
      const from = dateFrom;
      from.set({
        hour: +timeFrom.format('HH'),
        minute: +timeFrom.format('mm'),
        second: 0,
      });
      if (timeMode === 'UTC') {
        filterDictParams.from = moment
          .utc(from.format('YYYY-MM-DD HH:mm:ss'))
          .format();
      } else if (timeMode === 'User') {
        filterDictParams.from = moment.utc(from).format();
      } else {
        const locale = getLocale({
          timeMode,
          lat: siteMeta.GPSLat,
          long: siteMeta.GPSLong,
        });
        filterDictParams.from = moment
          .utc(moment.tz(from.format('YYYY-MM-DD HH:mm:ss'), locale))
          .format();
      }
    }
    if (dateTo && timeTo) {
      const to = dateTo;
      to.set({
        hour: +timeTo.format('HH'),
        minute: +timeTo.format('mm'),
        second: 0,
      });
      filterDictParams.to = to.utc().format();
      if (timeMode === 'UTC') {
        filterDictParams.to = moment
          .utc(to.format('YYYY-MM-DD HH:mm:ss'))
          .format();
      } else if (timeMode === 'User') {
        filterDictParams.to = moment.utc(to).format();
      } else {
        const locale = getLocale({
          timeMode,
          lat: siteMeta.GPSLat,
          long: siteMeta.GPSLong,
        });
        filterDictParams.to = moment
          .utc(moment.tz(to.format('YYYY-MM-DD HH:mm:ss'), locale))
          .format();
      }
      setMsgsPauseBtn('Hidden');
    }
    if (type !== 'all') {
      const unitsList = getUnitsIds();
      filterDictParams.deviceId = type.startsWith('unit')
        ? unitsList.find((i) => 'unit ' + i.name === type).deviceId
        : siteMeta.siteId;
    }
    if (!loadMore) {
      setPgnCurrPage(0);
    }
    const { messages, count } = await actions.getMessagePaginated({
      filteredSNs: filterDictParams.deviceId
        ? [filterDictParams.deviceId]
        : undefined,
      dateTo: filterDictParams.to || undefined,
      dateFrom: filterDictParams.from || undefined,
      keyword: filterDict.filterStr || undefined,
      offset: loadMore ? msgs.length - msgsRtCount.current : 0,
    });
    loading.current = false;
    if (messages && count) {
      msgsCount.current = count;
    }
    if (loadMore) {
      const newMsgs = [...msgs, ...messages];
      setMsgs(newMsgs.sort(compare));
      setPgnCurrPage(Math.ceil(newMsgs.length / ITEMS_NUM_ON_PAGE - 1));
      const container = document.getElementById('msg-scroll-container');
      if (container) {
        container.scrollTop = 0;
      }
    } else {
      setMsgs(messages);
    }
    setMsgsPauseBtn(filterDictParams.to ? 'Hidden' : 'Pause');
  };

  const updateFilterField = (fieldName, value) => {
    const newDict = !dateFilterFields.includes(fieldName)
      ? { [fieldName]: value }
      : {
          [fieldName]: value
            ? tsDueTimeMode({
                timeMode: timeMode,
                lat: siteMeta.GPSLat,
                long: siteMeta.GPSLong,
                ts: value.format('YYYY-MM-DD HH:mm:ss'),
                returnMoment: true,
              })
            : value,
        };
    setFilterDict((prev) => ({ ...prev, ...newDict }));
  };

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!isFirstRender) {
      timer.current = setTimeout(() => {
        const dict = {
          dateFrom: 'timeFrom',
          timeFrom: 'dateFrom',
          dateTo: 'timeTo',
          timeTo: 'dateTo',
        };
        if (
          !dateFilterFields
            .map((f) => (filterDict[f] ? !!filterDict[dict[f]] : true))
            .includes(false)
        ) {
          callGetMessages();
        }
      }, 1500);
    }
  }, [filterDict]);

  const formatTS = (ts) => {
    if (ts && ts !== '=') {
      return tsDueTimeMode({
        timeMode: timeMode,
        lat: siteMeta.GPSLat,
        long: siteMeta.GPSLong,
        splited: true,
        ts,
        format: 'YYYY-MM-DD HH:mm:ss.SSS',
      });
    }
    return '-';
  };

  const getCurrPageMessages = (msgsArr) => {
    const paginationPagesLength = Math.ceil(msgsArr.length / ITEMS_NUM_ON_PAGE);
    const paginationCurrPage =
      pgnCurrPage > paginationPagesLength ? paginationPagesLength : pgnCurrPage;
    const index = ITEMS_NUM_ON_PAGE * paginationCurrPage;
    return msgsArr.slice(index, index + ITEMS_NUM_ON_PAGE);
  };

  const handleMsgPauseBtn = async () => {
    setMsgsPauseBtn((prev) => (prev === 'Pause' ? 'Start' : 'Pause'));
  };

  const allMsgs = msgs;
  const paginatedMessages = getCurrPageMessages(allMsgs);
  const paginationPagesLength = Math.ceil(allMsgs.length / ITEMS_NUM_ON_PAGE);
  const paginationCurrPage =
    pgnCurrPage > paginationPagesLength ? paginationPagesLength : pgnCurrPage;
  return (
    <div className='home-row frow'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-hidden'>
          <div className='system-messages'>
            <div className='message-options'>
              <div className='column-options'>
                <div className='filter-option'>
                  <input
                    value={filterDict.filterStr}
                    onChange={(e) =>
                      updateFilterField('filterStr', e.target.value)
                    }
                    type='text'
                    className='filter-input'
                    placeholder='Filter by keyword'
                  />
                </div>
                <div className='filter-option'>
                  <select
                    className='type-dropdown'
                    onChange={(e) => updateFilterField('type', e.target.value)}
                  >
                    {[
                      'all',
                      ...getUnitsIds().map((u) => 'unit ' + u.name),
                      'system',
                    ].map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='column-options'>
                <div id='calendar-container' ref={d} />
                <div className='datetime-option'>
                  <span className='label'>From:</span>
                  <div className='date-input'>
                    <div>
                      <DatePicker
                        key={timeMode}
                        value={filterDict.dateFrom}
                        onChange={(val) => updateFilterField('dateFrom', val)}
                        getCalendarContainer={getCalendarContainer}
                        calendar={
                          <Calendar
                            disabledDate={disabledDateFrom}
                            format='YYYY-MM-DD'
                          />
                        }
                      >
                        {({ value }) => {
                          return (
                            <span>
                              <input
                                style={{ width: 250 }}
                                readOnly
                                placeholder='Set date from'
                                value={value ? value.format('YYYY-MM-DD') : ''}
                              />
                            </span>
                          );
                        }}
                      </DatePicker>
                    </div>
                  </div>
                  <div className='time-input'>
                    <TimePicker
                      className='timepicker'
                      value={filterDict.timeFrom}
                      defaultOpenValue={tsDueTimeMode({
                        timeMode: timeMode,
                        lat: siteMeta.GPSLat,
                        long: siteMeta.GPSLong,
                        returnMoment: true,
                      })}
                      key={timeMode}
                      popupClassName={`popup-timepicker ${
                        filterDict.timeFrom ? '' : 'novalue'
                      }`}
                      placeholder='Set time from'
                      clearIcon=''
                      showSecond={false}
                      onChange={(val) => updateFilterField('timeFrom', val)}
                      disabledMinutes={disabledMinutesFrom}
                      disabledHours={disabledHoursFrom}
                    />
                  </div>
                </div>
                <div className='datetime-option'>
                  <span className='label'>To:</span>
                  <div className='date-input'>
                    <div>
                      <DatePicker
                        key={timeMode}
                        value={filterDict.dateTo}
                        onChange={(val) => updateFilterField('dateTo', val)}
                        getCalendarContainer={getCalendarContainer}
                        calendar={
                          <Calendar
                            format='YYYY-MM-DD'
                            disabledDate={disabledDateTo}
                            dateInputPlaceholder='Set date to'
                          />
                        }
                      >
                        {({ value }) => {
                          return (
                            <span>
                              <input
                                style={{ width: 250 }}
                                readOnly
                                placeholder='Set date to'
                                value={value ? value.format('YYYY-MM-DD') : ''}
                              />
                            </span>
                          );
                        }}
                      </DatePicker>
                    </div>
                  </div>
                  <div className='time-input'>
                    <TimePicker
                      key={timeMode}
                      className='timepicker'
                      value={filterDict.timeTo}
                      popupClassName={`popup-timepicker ${
                        filterDict.timeTo ? '' : 'novalue'
                      }`}
                      placeholder='Set time to'
                      clearIcon=''
                      showSecond={false}
                      onChange={(val) => updateFilterField('timeTo', val)}
                      disabledMinutes={disabledMinutesTo}
                      disabledHours={disabledHoursTo}
                    />
                  </div>
                </div>
              </div>
              <div className='column-options'>
                <Button
                  className='csv-export-button'
                  disabled={!allMsgs.length}
                  onClick={() => exportCsv()}
                  style={{ margin: '3px 0px 5px' }}
                >
                  Export loaded messages to .csv
                </Button>
                {!!allMsgs.length && (
                  <>
                    <span
                      className={`sys-msg-counter ${
                        !loading.current && noMoreMsgs && 'big'
                      }`}
                    >
                      {`${!loading.current && noMoreMsgs ? 'All ' : 'First '}`}{' '}
                      {allMsgs.length}
                      &ensp;messages are already loaded
                    </span>
                    {!noMoreMsgs && !!msgsCount.current && (
                      <span className={'sys-msg-counter'}>
                        {msgsCount.current + msgsRtCount.current} messages
                        satisfy filter
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='system-messages-list'>
              {msgsPauseBtn !== 'Hidden' &&
                !paginatedMessages.length &&
                !loading.current && (
                  <div
                    className='pause-btn-container'
                    style={{ alignSelf: 'center', margin: '8px' }}
                  >
                    <span
                      style={msgsPauseBtn !== 'Pause' ? { opacity: 0.6 } : {}}
                    >
                      Realtime updating:&ensp;
                    </span>
                    <Button
                      classType='trans'
                      className='pause-btn'
                      onClick={() => handleMsgPauseBtn()}
                    >
                      <div className='btn-row'>
                        <span>{msgsPauseBtn}</span>
                        <FontAwesomeIcon
                          icon={msgsPauseBtn === 'Pause' ? faPause : faPlay}
                        />
                      </div>
                    </Button>
                  </div>
                )}
              {loading.current ? (
                <Spinner cover='container' />
              ) : (
                <div
                  className='mess-container with-title gen'
                  id='msg-scroll-container'
                >
                  {!allMsgs.length && !loading.current ? (
                    <div
                      className='placeholder-no-data'
                      style={{ gridColumn: 'span 4' }}
                    >
                      No messages filtered with pointed params
                    </div>
                  ) : (
                    <></>
                  )}
                  {
                    <>
                      {paginatedMessages.length ? (
                        <>
                          <div className='title-cell'>Date</div>
                          <div className='title-cell'>Time</div>
                          <div className='title-cell'>Device</div>
                          <div
                            className='title-cell container'
                            style={{ minWidth: '100%' }}
                          >
                            <span>Message</span>
                            {msgsPauseBtn !== 'Hidden' && (
                              <div className='pause-btn-container'>
                                <span
                                  style={
                                    msgsPauseBtn !== 'Pause'
                                      ? { opacity: 0.6 }
                                      : {}
                                  }
                                >
                                  Realtime updating:&ensp;
                                </span>
                                <Button
                                  classType='trans'
                                  className='pause-btn'
                                  onClick={() => handleMsgPauseBtn()}
                                >
                                  <div className='btn-row'>
                                    <span>{msgsPauseBtn}</span>
                                    <FontAwesomeIcon
                                      icon={
                                        msgsPauseBtn === 'Pause'
                                          ? faPause
                                          : faPlay
                                      }
                                    />
                                  </div>
                                </Button>
                              </div>
                            )}
                            {allMsgs.length && paginationPagesLength > 1 ? (
                              <Pagination
                                numOfPages={paginationPagesLength}
                                currentPage={paginationCurrPage}
                                changeCurrPage={(val) => setPgnCurrPage(val)}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {paginatedMessages.map((f, row) => {
                        return (
                          <Fragment key={row}>
                            <div className='mess-date'>
                              {formatTS(f.createdAt)[0]}
                            </div>
                            <div className='mess-time'>
                              {formatTS(f.createdAt)[1]}
                            </div>
                            {siteMeta.Units.includes(f.deviceId) ? (
                              <div className='mess-time'>
                                {`[Unit ${
                                  siteMeta.UnitNames[
                                    siteMeta.Units.findIndex(
                                      (u) => u === f.deviceId,
                                    )
                                  ]
                                }]`}
                              </div>
                            ) : (
                              <div className='mess-time'>[SYSTEM]</div>
                            )}
                            <div className='ms-content'>{`${f.message.MSG}`}</div>
                          </Fragment>
                        );
                      })}

                      {!loading.current &&
                        paginationPagesLength === paginationCurrPage + 1 &&
                        MAX_MGS_NUM < allMsgs.length && (
                          <div
                            className='flex-row'
                            style={{
                              gridColumn: '1 / 5',
                              justifyContent: 'center',
                              paddingTop: '10px',
                            }}
                          >
                            Too many messages. Please change filter&apos;s{' '}
                            <b>from</b> or <b>to</b>
                            fields
                          </div>
                        )}
                      {loading.current && (
                        <div
                          className='flex-row'
                          style={{
                            gridColumn: '1 / 5',
                            justifyContent: 'center',
                          }}
                        >
                          <Spinner />
                          <Spinner />
                          <Spinner />
                        </div>
                      )}
                    </>
                  }
                </div>
              )}
              {!loading.current &&
                !noMoreMsgs &&
                paginationPagesLength === paginationCurrPage + 1 && (
                  <div
                    className='flex-row'
                    style={{
                      padding: '4px',
                    }}
                  >
                    <Button
                      label='Load more messages'
                      classType='blue'
                      size='s'
                      disabled={MAX_MGS_NUM < allMsgs.length}
                      onClick={() => callGetMessages(true)}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(SystemMessagesSubpage, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
