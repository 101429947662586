import { Dispatch, SetStateAction, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import JSONEditor from '@src/components/_elements/JSONEditor/JSONEditor';
import { GenerateDevicePayload } from '@src/types/Device';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import useDeviceMutations from '@src/hooks/api/mutations/useDeviceMutations';
import storeConnector from '@src/store/storeConnector';
import deviceSx from '../configs/DeviceSx';

interface DialogProps {
  jsonData: GenerateDevicePayload;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setConfirmOpen: Dispatch<SetStateAction<boolean>>;
}

function GenerateDeviceDialog({
  jsonData,
  setOpen,
  setConfirmOpen,
}: DialogProps) {
  const [newPayload, setNewPayload] = useState<GenerateDevicePayload>({
    ...jsonData,
  });
  const queryClient = useQueryClient();
  const { generateDevices } = useDeviceMutations();

  const handleGenDeviceSave = () =>
    generateDevices.mutate(newPayload, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.devices],
        });
        setConfirmOpen(true);
        setOpen(false);
      },
    });

  return (
    <Dialog
      open
      PaperProps={{
        sx: deviceSx.genDialogPaper,
      }}
    >
      <DialogContent>
        <JSONEditor
          json={newPayload}
          onChange={setNewPayload}
          options={{ mode: 'code' }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button size='small' variant='contained' onClick={handleGenDeviceSave}>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default storeConnector(GenerateDeviceDialog, {
  user: ['role'],
});
