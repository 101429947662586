import { ReactElement } from 'react';
import { UnitConfig } from '@src/sld/types';

interface UnitProps {
  unitConfig: UnitConfig;
  rectangleXCoordinate: string;
  rectangleYCoordinate: string;
  textStartingXCoordinate: number;
  textStartingYCoordinate: number;
  lineTransformXCoordinate?: number;
  lineTransformYCoordinate?: number;
  skipHorizontalLine?: boolean;
}

type TextCoordinates = {
  yCoordinate: number;
};

function Unit(props: UnitProps): ReactElement {
  const {
    unitConfig,
    rectangleXCoordinate,
    rectangleYCoordinate,
    textStartingXCoordinate,
    textStartingYCoordinate,
  } = props;

  const getTextCoordinates = (rowIndex: number): TextCoordinates => {
    const verticalSpace = 15;
    const verticalOffset = verticalSpace * rowIndex;

    return {
      yCoordinate: textStartingYCoordinate + verticalOffset,
    };
  };

  const textProps = {
    fill: 'black',
    stroke: 'none',
    fontSize: '5px',
  };

  return (
    <>
      <rect
        x={rectangleXCoordinate}
        y={rectangleYCoordinate}
        width='75'
        height='115'
        fill='white'
      />

      <text
        fontWeight='bold'
        x={textStartingXCoordinate}
        y={getTextCoordinates(1).yCoordinate}
        {...textProps}
      >
        {unitConfig.unitLabel}
      </text>

      <text
        x={textStartingXCoordinate}
        y={getTextCoordinates(2).yCoordinate}
        {...textProps}
      >
        kW: {unitConfig.kw}
      </text>
      <text
        x={textStartingXCoordinate}
        y={getTextCoordinates(3).yCoordinate}
        {...textProps}
      >
        kVAR: {unitConfig.kvar}
      </text>
      <text
        x={textStartingXCoordinate}
        y={getTextCoordinates(4).yCoordinate}
        {...textProps}
      >
        SOC: {unitConfig.soc}
      </text>
      <text
        x={textStartingXCoordinate}
        y={getTextCoordinates(5).yCoordinate}
        {...textProps}
      >
        Racks: {unitConfig.racksonline}
      </text>
      <text
        x={textStartingXCoordinate}
        y={getTextCoordinates(6).yCoordinate}
        {...textProps}
      >
        Status: {unitConfig.invstatus}
      </text>
      <text
        x={textStartingXCoordinate}
        y={getTextCoordinates(7).yCoordinate}
        {...textProps}
      >
        Mode: {unitConfig.runmode}
      </text>
    </>
  );
}

export default Unit;
