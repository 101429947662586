import { IMqttMessage } from '@src/types/Mqtt';
import { PointConfig } from '@src/types/PointConfig';

export interface DataPoint extends PointConfig {
  siteId: string;
  controllerId: string;
  sourceDeviceId?: null | string;
  sourceDeviceType?: null | string;
  fullTopic: string;
}

export function isDataPointWithValue(
  value: unknown,
): value is DataPointWithValue {
  return (
    typeof value === 'object' &&
    value !== null &&
    'fullTopic' in value &&
    'pointAlias' in value
  );
}

export interface DataPointWithValue extends DataPoint {
  value?: IMqttMessage['value'];
  ts?: IMqttMessage['ts'];
}

export interface QueryDataPointsSearchBodyElement {
  pointAlias?: DataPoint['pointAlias'];
  deviceType: DataPoint['deviceType'];
  controllerId?: DataPoint['controllerId'];
  pointName: DataPoint['pointName'];
  sourceDeviceId?: DataPoint['sourceDeviceId'];
}
