import Spinner from '@components/_elements/Spinner/Spinner';
import SimplePointDataTableWithTitle from '@components/_shared/MultiColPointDataTableWithTitle';
import { Typography } from '@mui/material';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypeMultiSectionPointList } from '@src/types/Widget';
import { MqttClient } from 'mqtt';

interface Props {
  selectedMpId: string;
  useNewClient?: MqttClient;
}

function MegapackDetails(props: Props) {
  const { selectedMpId } = props;

  const {
    data: widgetConfig,
    isLoading,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypeMultiSectionPointList>(
    'mpDetailsModal',
    {
      sourceDeviceId: selectedMpId,
    },
  );

  if (isLoading) {
    return <Spinner type='content-small' />;
  }
  if (isError) {
    return (
      <Typography textAlign='center' marginTop='50px'>
        Error fetching widget data.
      </Typography>
    );
  }

  return widgetConfig?.sections.map((sectionData, i) => (
    <SimplePointDataTableWithTitle
      key={`megapack-details-${Math.random()}`}
      sectionIndex={i}
      data={sectionData}
      useNewClient={props.useNewClient}
    />
  ));
}

export default MegapackDetails;
