import JSONEditor from '@components/_elements/JSONEditor/JSONEditor';
import useSaveSiteMeta from '@hooks/api/mutations/useSaveSiteMeta';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { ApiSiteMetaUpdate, SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import defaultSiteMetaStub from '@utils/defaultDataStubs/siteMeta';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

interface SiteMetaEditorProps {
  siteMeta: SiteMeta;
  inWizard?: boolean;
}

const prepareNewSiteMeta = ({
  id: _id,
  siteId,
  createdBy: _updatedBy,
  createdAt: _updatedAt,
  ...rest
}: SiteMeta): ApiSiteMetaUpdate => ({
  siteId,
  ...rest,
});

function SiteMetaEditor({ siteMeta, inWizard }: SiteMetaEditorProps) {
  const navigate = useNavigate();
  const isSiteMetaValid = useMemo(
    () => Object.keys(siteMeta).length > 0,
    [siteMeta],
  );
  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync } = useSaveSiteMeta();
  const [workingCopy, setWorkingCopy] = useState<ApiSiteMetaUpdate>(
    isSiteMetaValid ? prepareNewSiteMeta(siteMeta) : defaultSiteMetaStub,
  );
  const lastUpdateDate = useMemo(() => {
    const date = siteMeta?.createdAt
      ? new Date(siteMeta.createdAt)
      : new Date();
    return date.toLocaleString();
  }, [siteMeta]);

  const saveSiteMeta = async () => {
    setIsSaving(true);
    try {
      await mutateAsync(workingCopy);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    if (!inWizard) {
      navigate(0);
    }
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          maxWidth: '1200px',
          maxHeight: '722px',
          display: 'block',
        }}
      >
        <JSONEditor
          json={workingCopy}
          onChange={setWorkingCopy}
          options={{ mode: 'code' }}
        />
      </div>
      <div className='btn-row'>
        <Box>
          <small>
            Last updated by{' '}
            <strong>{siteMeta?.createdBy || 'initial-system-generated'}</strong>{' '}
            on <em>{lastUpdateDate}</em>
          </small>
        </Box>
        <LoadingButton
          variant='contained'
          disabled={isSaving}
          loading={isSaving}
          onClick={saveSiteMeta}
          sx={{ minWidth: '180px' }}
        >
          {`Save${!inWizard ? 'and reload app' : ''}`}
        </LoadingButton>
      </div>
    </>
  );
}

export default storeConnector(SiteMetaEditor, {
  config: ['siteMeta'],
});
