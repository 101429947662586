const generateSx = (prefix: string) => ({
  wrapper: {
    '& .MuiDataGrid-scrollbar': {
      display: 'none',
    },
    border: 'none',
    bgcolor: 'transparent !important',
    '& .MuiDataGrid-detailPanel': {
      backgroundColor: 'transparent',
    },
    [`& .MuiDataGrid-container--top:not(.detail-data-table .MuiDataGrid-container--top)`]:
      {
        display: 'none',
      },
    [`& .${prefix}-row.MuiDataGrid-row`]: {
      backgroundColor: 'rgba(215, 215, 215, 0.05)',
      border: 'solid 1px rgba(215, 215, 215, 0.1)',
    },
    [`& .${prefix}-row.MuiDataGrid-row--detailPanelExpanded`]: {
      mb: '0 !important',
      borderBottom: 0,
    },
    [`& .${prefix}-row .MuiDataGrid-cell`]: {
      border: 'none',
    },
    '& .detail-panel': {
      transition: 'height 0.3s ease',
    },
  },
  detailWrapper: {
    padding: 2.5,
    mb: 1,
    boxShadow: 'none !important',
    border: 'solid 1px rgba(215, 215, 215, 0.1)',
    borderTop: 0,
    backgroundColor: 'rgba(215, 215, 215, 0.05) !important',
  },
  detailDataTable: {
    toolbar: {
      pt: 1,
      pb: 0.5,
      px: '20px',
    },
    bgcolor: 'transparent !important',
    borderRadius: 0,
    maxHeight: '350px',
    '& .MuiDataGrid-row': {
      backgroundColor: 'rgba(215, 215, 215, 0.05) !important',
      borderTop: 'solid 1px rgba(215, 215, 215, 0.1) !important',
    },
  },
});

const pcsSx = generateSx('pcs');
const bmsSx = generateSx('bms');

export { pcsSx, bmsSx };
