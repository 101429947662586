import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditUserDialog from '../Dialogs/EditUserDialog';

function EditUserButton({ userId }: { userId: string }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClick = () => setDialogOpen(true);

  return (
    <>
      <Tooltip title='Edit User'>
        <IconButton color='primary' onClick={handleClick}>
          <ModeEditIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <EditUserDialog
          open
          onClose={() => setDialogOpen(false)}
          userId={userId}
        />
      )}
    </>
  );
}

export default EditUserButton;
