import Spinner from '@components/_elements/Spinner/Spinner';
import { Card, SxProps, Theme, Typography } from '@mui/material';
import { CSSProperties, ReactElement, ReactNode } from 'react';

interface UIBoxProps {
  header?: string;
  subheader?: string;
  children?: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  sx?: SxProps<Theme>;
  style?: CSSProperties;
}

const PADDING = 2;
const MARGIN = 2;

const sx = (theme: Theme) => ({
  borderColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  p: PADDING,
  m: MARGIN,
  minHeight: 150,
  height: 'max-content',
});

function UIBox(p: UIBoxProps): ReactElement {
  const renderContent = () => {
    if (p.isLoading) {
      return <Spinner type='content-small' />;
    }
    if (p.isError) {
      return (
        <Typography textAlign='center' marginTop='50px'>
          Error fetching widget data.
        </Typography>
      );
    }
    return p.children;
  };

  return (
    <Card className='cell' sx={sx} style={p.style || {}}>
      {p.header ? (
        <Typography
          variant='h6'
          sx={{ display: 'inline', marginBottom: '15px' }}
        >
          {p.header}
          {p.subheader ? (
            <span
              style={{
                marginLeft: '15px',
                fontSize: '14px',
                opacity: 0.5,
              }}
            >
              {p.subheader}
            </span>
          ) : null}
        </Typography>
      ) : null}
      {renderContent()}
    </Card>
  );
}

export default UIBox;
