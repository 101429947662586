import { engineerOrAdmin, isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import React, { useState, useEffect } from 'react';
import {
  ModeConfig,
  ModeMetadataContent,
  ModeStatus,
  ConfigField,
  ConfigFormData,
  ExecutionsSettingsFormData,
  ModeConfigFormType,
  ModeSubmitForm,
  ModeConfigInputType,
} from '@src/types/control-modes/Modes';
import { ControlMode } from '@src/types/control-modes/ControlModes';
import Switch from 'react-switch';
import Spinner from '@components/_elements/Spinner/Spinner';
import './Modes.scoped.scss';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import Draggable from 'react-draggable';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Box,
  Tooltip,
} from '@mui/material';
import { Actions } from '@src/types/Actions';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import { ServiceState } from '@src/store/reducers/serviceData';
import modesMetadata from './ModesMetadata';
import {
  buttonStyles,
  getInputValue,
  getUpdatedConfigData,
  parseFormDataFromConfig,
  parseFormDataFromInput,
} from './modes-utils';
import QuickSavePopupButton from './ModesPopupButtons/QuickSavePopupButton';
import ExecutionSettingsForm from './ExecutionSettingsForm';
import QuickConfigInputLabel from './Inputs/QuickConfigInputLabel';
import ModeConfigInput from './Inputs/ModeConfigInput';
import DroopCurves from '../DroopCurves/DroopCurves';

interface ModeConfigFormProps {
  config: ModeConfig;
  close?: { (): Promise<void> } | null;
  formType: ModeConfigFormType;
  mode: ControlMode;
  status?: ModeStatus | null;
  SCADAMode?: boolean;
  submitForm: ModeSubmitForm;
  siteMeta: SiteMeta;
  isControlsLocked: ServiceState['isControlsLocked'];
  role: UserRole;
  actions: Actions;
}
function ModeConfigForm({
  config,
  close = null,
  formType,
  mode,
  status = null,
  SCADAMode = false,
  submitForm,
  siteMeta,
  isControlsLocked,
  role,
  actions,
}: ModeConfigFormProps) {
  const modeMetadata: ModeMetadataContent = modesMetadata[mode];
  const isQuickConfig = formType === 'quick';
  const [isModeSwitchDirty, setIsModeSwitchDirty] = useState(false);
  const [isModeEnabled, setIsModeEnabled] = useState(status === 0);
  const [inputErrorCount, setInputErrorCount] = useState(0);
  const [droopConfig, setDroopConfig] = useState(config);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const { controlsLockConfig } = siteMeta.ui;
  const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;

  const [formData, setFormData] = useState({
    row1Input1: '',
    row1Input2: '',
    row1Input3: '',
    row1Input4: '',
    row1Input5: '',
    row1Input6: '',
    row1Input7: '',
    row1Input8: '',
    row1Input9: '',
    row2Input1: '',
    row2Input2: '',
    row2Input3: '',
    row2Input4: '',
    row2Input5: '',
    row2Input6: '',
    row2Input7: '',
    row2Input8: '',
    row2Input9: '',
    row3Input1: '',
    row3Input2: '',
    row3Input3: '',
    row3Input4: '',
    row3Input5: '',
    row3Input6: '',
    row3Input7: '',
    row3Input8: '',
    row3Input9: '',
    row4Input1: '',
    row4Input2: '',
    row4Input3: '',
    row4Input4: '',
    row4Input5: '',
    row4Input6: '',
    row4Input7: '',
    row4Input8: '',
    row4Input9: '',
  } as ConfigFormData);

  const [executionSettingsFormData, setExecutionSettingsFormData] = useState({
    additive: '',
    dataSource: '',
    enabled: '',
    priority: '',
  } as ExecutionsSettingsFormData);

  const commandAccessEnabled = isCommandsEnabled();

  //  handles the Enable / Disable slider change
  //  event. Set the value in state and opens
  //  the confirmation modal
  const handleToggleUpdate = (enableSelectedMode: boolean) => {
    //  set true / false for the isModeEnabledState
    setIsModeEnabled(enableSelectedMode);

    //  set confirmation message
    setConfirmationMessage(
      `${enableSelectedMode ? 'Enable' : 'Disable'} ${mode}?`,
    );

    //  pop the confirmation modal
    setShowConfirm(true);
  };

  //  modeChangeSelectionConfirmedAsTrue of true means the user is confirming the
  //  enable / disable change. If modeChangeSelectionConfirmedAsTrue = false,
  //  that means the user is cancelling or "backing out" of their
  //  changes - so ignore and reset the isModeEnabled to its
  //  previous state. If the modeChangeSelectionConfirmedAsTrue value = true
  //  then keep the isModeEnabled state
  const handleConfirmOnClick = async (
    modeChangeSelectionConfirmedAsTrue: boolean,
  ): Promise<void> => {
    //  close the modal window
    setShowConfirm(false);

    //  set dirty state (true / false)
    setIsModeSwitchDirty(modeChangeSelectionConfirmedAsTrue);
    setConfirmationMessage('');

    //  send command only if the user confirmed their selection
    const commands: string[] = [];

    //  send command only if the user confirmed their selection
    if (modeChangeSelectionConfirmedAsTrue) {
      //  push initial command
      commands.push(
        `WriteToVariant::Disable_${mode}_Fractal::${isModeEnabled ? 0 : 1}`,
      );

      // this business logic will ensure that both Power Factor and Voltage Response cannot be
      // both enabled at the same time.
      if (
        ['Power Factor', 'Voltage Response'].includes(mode) &&
        isModeEnabled
      ) {
        //  push second disable command to the commands array
        commands.push(
          `WriteToVariant::Disable_${
            mode === 'Power Factor' ? 'Voltage Response' : 'Power Factor'
          }_Fractal::1`,
        );
      }

      //  send the commands
      const sendEnableDisableCommand = actions.sendCommand(
        commands,
        siteMeta.siteId,
      );

      await sendEnableDisableCommand();
    } else {
      //  user didn't confirm changes so revert
      //  to previous state and do not send command
      setIsModeEnabled(!isModeEnabled);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    const parsedFormData = parseFormDataFromConfig(config, modeMetadata);

    setFormData((prevData: ConfigFormData) => ({
      ...prevData,
      ...parsedFormData,
    }));
  }, [config]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (!isModeSwitchDirty) {
      setIsModeEnabled(status === 0);
    }
  }, [status]);

  const handleInputUpdate = (
    e: React.FormEvent<HTMLInputElement>,
    isInvalid = false,
  ) => {
    const parsedInputName = JSON.parse(e.currentTarget.name);
    const { value } = e.currentTarget;

    const parsedFormData = parseFormDataFromInput(parsedInputName, value);
    setFormData((prevData: ConfigFormData) => {
      const updatedFormData = {
        ...prevData,
        ...parsedFormData,
      };

      if (!isInvalid) {
        const parsedDroopConfig = getUpdatedConfigData(
          config,
          updatedFormData,
          modeMetadata.configFields,
          executionSettingsFormData,
        );
        setDroopConfig(parsedDroopConfig);
      }

      return updatedFormData;
    });
  };

  const preventDefault = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleFullConfigUpdate = async () => {
    setSubmitLoading(true);
    const updatedConfigData = getUpdatedConfigData(
      config,
      formData,
      modeMetadata.configFields,
      executionSettingsFormData,
    );
    await submitForm(mode, updatedConfigData, true).then(() => {
      setSubmitLoading(false);
      if (close) {
        close();
      }
    });
  };

  const displayChart = !isQuickConfig && ['SOC Rebalance'].includes(mode);

  const dialogActionsStyles = {
    display: 'flex',
    justifyContent: 'center',
    pb: 2,
  };

  return (
    <div className='form-outer'>
      <div className='modes-column'>
        <form onSubmit={preventDefault} style={{ width: '100%' }}>
          <div className='modes-container'>
            <div className='modes-row'>
              {isQuickConfig ? (
                <div
                  className='modes-cell'
                  style={{ flex: 1, justifyContent: 'center' }}
                >
                  {commandAccessEnabled ? (
                    <Tooltip
                      title={isControlsLocked ? tooltipTitle : ''}
                      placement='top'
                      disableHoverListener={!isControlsLocked}
                    >
                      <Box>
                        <Switch
                          data-testid='enable-mode-switch'
                          disabled={
                            SCADAMode || isControlsLocked || !isActionRole(role)
                          }
                          name='isModeEnabled'
                          height={20}
                          width={40}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor='#fa9632'
                          checked={isModeEnabled}
                          onChange={handleToggleUpdate}
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
              <div
                className='modes-cell'
                style={{ flex: 8, justifyContent: 'center' }}
              >
                <div className='modes-container'>
                  {modeMetadata.configFields.map(
                    (configField: ConfigField, idx: number) => (
                      <div key={configField.configParentProp}>
                        {!isQuickConfig ? (
                          <div className='modes-row'>
                            <h5 style={{ marginTop: '15px' }}>
                              {configField.configParentProp}:{' '}
                            </h5>
                          </div>
                        ) : (
                          ''
                        )}

                        <div className='modes-row'>
                          {!isQuickConfig ||
                          configField.configInput1?.quickConfig ? (
                            <QuickConfigInputLabel
                              configInputLabel={
                                configField.configInput1?.label || ''
                              }
                              formType={formType}
                            />
                          ) : (
                            ''
                          )}

                          {!isQuickConfig ||
                          configField.configInput1?.quickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput1?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput1?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 1}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput1?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 1)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}

                          {!isQuickConfig ||
                          configField.configInput2?.quickConfig ? (
                            <QuickConfigInputLabel
                              configInputLabel={
                                configField.configInput2?.label || ''
                              }
                              formType={formType}
                            />
                          ) : (
                            ''
                          )}

                          {!isQuickConfig ||
                          configField.configInput2?.quickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput2?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput2?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 2}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput2?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 2)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}

                          {isQuickConfig &&
                          !configField.configInput2?.quickConfig ? (
                            <div className='modes-cell' />
                          ) : (
                            ''
                          )}

                          {isQuickConfig &&
                          !configField.configInput2?.quickConfig ? (
                            <div className='modes-cell' />
                          ) : (
                            ''
                          )}

                          {!isQuickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput3?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput3?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 3}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput3?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 3)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}

                          {!isQuickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput4?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput4?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 4}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput4?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 4)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}

                          {!isQuickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput5?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput5?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 5}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput5?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 5)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          className='modes-row'
                          style={{ marginTop: '10px' }}
                        >
                          {!isQuickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput6?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput6?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 6}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput6?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 6)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}
                          {!isQuickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput7?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput7?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 7}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput7?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 7)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}
                          {!isQuickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput8?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput8?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 8}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput8?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 8)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}
                          {!isQuickConfig ? (
                            <ModeConfigInput
                              configInputLabel={
                                configField.configInput9?.label || ''
                              }
                              formType={formType}
                              handleInputUpdate={handleInputUpdate}
                              inputErrorCount={inputErrorCount}
                              inputType={
                                config && configField.configParentProp
                                  ? (config.body[
                                      configField.configParentProp
                                    ]?.[
                                      `${configField.configInput9?.label}__type`
                                    ] as ModeConfigInputType)
                                  : ('number' as ModeConfigInputType)
                              }
                              name={`{"idx": ${idx}, "input": 9}`}
                              range={
                                (config &&
                                  configField.configParentProp &&
                                  config.body[configField.configParentProp]?.[
                                    `${configField.configInput9?.label}__range`
                                  ]) ||
                                ''
                              }
                              setInputErrorCount={setInputErrorCount}
                              value={getInputValue(formData, idx, 9)}
                              disabled={
                                !commandAccessEnabled || !engineerOrAdmin(role)
                              }
                            />
                          ) : (
                            ''
                          )}
                          <div className='modes-cell' style={{ flex: 2 }} />
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>

              {isQuickConfig &&
              commandAccessEnabled &&
              engineerOrAdmin(role) ? (
                <div
                  className='modes-cell'
                  style={{
                    flex: 1,
                    justifyContent: 'right',
                    marginRight: '10px',
                  }}
                >
                  <QuickSavePopupButton
                    clearModeSwitchState={() => setIsModeSwitchDirty(false)}
                    config={config}
                    disabled={inputErrorCount !== 0}
                    formData={formData}
                    isModeEnabled={isModeEnabled}
                    mode={mode}
                    modeMetadata={modeMetadata}
                    submitForm={submitForm}
                  />
                </div>
              ) : (
                ''
              )}
            </div>

            {!isQuickConfig ? (
              <div style={{ display: 'run-in' }}>
                <div className='modes-row'>
                  <div className='modes-cell' style={{ flex: 3 }}>
                    <h5 style={{ marginTop: '15px', whiteSpace: 'nowrap' }}>
                      Execution Settings:{' '}
                    </h5>
                  </div>
                  <div className='modes-cell' style={{ flex: 8 }} />
                </div>
                <ExecutionSettingsForm
                  config={config}
                  executionSettingsFormData={executionSettingsFormData}
                  inputErrorCount={inputErrorCount}
                  setExecutionSettingsFormData={setExecutionSettingsFormData}
                  setInputErrorCount={setInputErrorCount}
                />
                {commandAccessEnabled && engineerOrAdmin(role) ? (
                  <div className='modes-row' style={{ paddingTop: '30px' }}>
                    <div className='modes-cell'>
                      <Button
                        disabled={inputErrorCount > 0 || submitLoading}
                        onClick={handleFullConfigUpdate}
                        style={buttonStyles}
                      >
                        Save
                      </Button>
                      {submitLoading ? <Spinner type='content' /> : ''}
                    </div>
                    <div className='modes-cell' style={{ flex: 9 }} />
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

            {showConfirm && (
              <div className='cover-container cover-container-confirm'>
                <Draggable bounds='body'>
                  <Dialog open={showConfirm}>
                    <DialogContent>
                      <DialogContentText>
                        {confirmationMessage}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={dialogActionsStyles}>
                      <Button
                        size='small'
                        onClick={() => handleConfirmOnClick(true)}
                        variant='contained'
                      >
                        Yes
                      </Button>
                      <Button
                        size='small'
                        onClick={() => handleConfirmOnClick(false)}
                        autoFocus
                        variant='outlined'
                      >
                        No
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Draggable>
              </div>
            )}
          </div>
        </form>
      </div>

      {displayChart ? (
        <div className='modes-column'>
          <div
            className='modes-cell system-right'
            style={{ flex: 11, marginTop: '15px' }}
          >
            <DroopCurves config={droopConfig} />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default storeConnector(ModeConfigForm, {
  config: ['siteMeta'],
  user: ['role'],
  service: ['isControlsLocked'],
});
