import queryClient from '@utils/queryClient';
import { useEffect, useMemo } from 'react';
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigation,
} from 'react-router';
import { ThemeProvider } from '@emotion/react';
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { ServiceState } from '@store/reducers/serviceData';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import runRouteGuards from '@pages/_loader/runRouteGuards';
import prepHtmlDocumentLoader from '@pages/_loader/prepHtmlDocument';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClientProvider } from '@tanstack/react-query';
import { UserRole } from './services/auth/roles';

if (process.env.VITE_INIT_DATADOG === 'true') {
  datadogRum.init({
    applicationId: process.env.VITE_DATADOG_RUM_APPLICATION_ID || '',
    clientToken: process.env.VITE_DATADOG_RUM_CLIENT_TOKEN || '',
    site: process.env.VITE_DATADOG_RUM_SITE,
    service: process.env.VITE_DATADOG_RUM_SERVICE,
    env: process.env.VITE_DATADOG_RUM_ENV,
    version: process.env.VITE_DATADOG_RUM_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

function App({
  role,
  darkTheme,
}: {
  role: UserRole;
  darkTheme: ServiceState['darkTheme'];
}) {
  const siteMeta = useLoaderData() as SiteMeta;
  const { pathname } = useLocation();
  const { state } = useNavigation();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkTheme ? 'dark' : 'light',
          primary: {
            main: '#d68b55',
          },
          secondary: {
            main: '#19857b',
          },
          error: {
            main: red.A400,
          },
        },
        mixins: {
          MuiDataGrid: {
            pinnedBackground: '#44484e',
          },
        },
      }),
    [darkTheme],
  );

  useEffect(() => {
    prepHtmlDocumentLoader().then();
  }, []);

  const navigateToError = runRouteGuards(siteMeta, pathname, role);
  if (navigateToError !== false) {
    return navigateToError;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {state === 'loading' ? <Spinner type='fullPage' /> : <Outlet />}
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default storeConnector(App, {
  user: ['role'],
  service: ['darkTheme'],
});
