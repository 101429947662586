import { Button, Grid2 } from '@mui/material';
import { POWIN_COMMANDS } from '@src/types/Commands';
import { useCommandsModalContext } from '../CommandsModalContext';

function FaultReset() {
  const { sendCommand } = useCommandsModalContext();

  const handleClickFaultReset = (): void => {
    sendCommand(POWIN_COMMANDS.UNIT_FAULT_RESET);
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 4 }}
        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
      >
        Unit Fault Reset:
      </Grid2>
      <Grid2 size={{ xs: 8 }}>
        <Button variant='contained' onClick={handleClickFaultReset}>
          Reset
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default FaultReset;
