import { ReactElement } from 'react';

interface CircleProps {
  translateXCoordinate: number;
  translateYCoordinate: number;
  diameter: number;
  stroke: string;
  strokeWidth: number;
  label: string;
  fillColor?: string;
  innerText?: string;
  overrideTextXCoordinate?: number;
}

type CircleStyles = {
  style: {
    stroke: string;
    strokeWidth: number;
  };
  dimensions: {
    circleDimension: string;
  };
};

const circleTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

function Circle(props: CircleProps): ReactElement {
  const {
    translateXCoordinate,
    translateYCoordinate,
    stroke,
    strokeWidth,
    diameter,
    label,
    fillColor,
    innerText,
    overrideTextXCoordinate,
  } = props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;
  const fill = fillColor || '';
  const textXCoordinate =
    overrideTextXCoordinate !== undefined ? overrideTextXCoordinate : 15;

  const circleStyles: CircleStyles = {
    style: {
      stroke,
      strokeWidth,
    },
    dimensions: {
      circleDimension: `M 0 0 a ${diameter} ${diameter} 0 1 0 0.1 0`,
    },
  };

  return (
    <g transform={translate}>
      <path
        d={circleStyles.dimensions.circleDimension}
        style={{ ...circleStyles.style }}
        fill={fill}
      />
      <text x={textXCoordinate} y={13} {...circleTextProps}>
        {label}
      </text>

      <text x={-5} y={14} {...circleTextProps} fontSize='12px'>
        {innerText}
      </text>
    </g>
  );
}

export default Circle;
