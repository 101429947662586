import { DeviceExtended } from '@src/types/Device';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import storeConnector from '@store/storeConnector';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColType,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import useGetDevices from '@hooks/api/queries/collections/useGetDevices';
import CollectionToolbar from '@src/components/Home/Collections/CollectionToolbar';
import GenerateDevicesButton from '@src/components/Home/Collections/Devices/GenerateDevicesButton';
import getCollectionsInitialState from '@src/components/Home/Collections/utils/getCollectionsInitialState';
import { CollectionPayload, CollectionTypes } from '@src/types/Collection';
import { UserRole } from '@src/services/auth/roles';
import deviceBaseColumns from '@src/components/Home/Collections/configs/deviceBaseColumns';
import { GridBaseColDef } from '@mui/x-data-grid/internals';
import { isActionRole } from '@src/services/auth';
import useDeviceMutations from '@src/hooks/api/mutations/useDeviceMutations';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import GenerateDeviceDialog from '@src/components/Home/Collections/Devices/GenerateDeviceDialog';
import { Alert } from '@mui/material';
import DeleteDeviceDialog from '@src/components/Home/Collections/Devices/DeleteDeviceDialog';
import deviceSx from '@src/components/Home/Collections/configs/DeviceSx';
import CollectionWrapper from '../CollectionWrapper';

declare module '@mui/x-data-grid-pro' {
  interface NoRowsOverlayPropsOverrides {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
  }
  interface ToolbarPropsOverrides {
    collectionType?: CollectionTypes;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
  }
}

function DeviceCollection({ role }: { role: UserRole }) {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [openGen, setOpenGen] = useState(false);
  const [rows, setRows] = useState<DeviceExtended[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const apiRef = useGridApiRef();

  const [jsonPayload, setJsonPayload] = useState<CollectionPayload | null>(
    null,
  );
  const { data, isFetching, isPending, isSuccess, error, isRefetching } =
    useGetDevices();

  const { updateDevice } = useDeviceMutations(Object.keys(rowModesModel)[0]);

  const queryClient = useQueryClient();

  const handleClose = (
    _: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setConfirmOpen(false);
  };

  const handleSaveClick = (id: GridRowId) => () =>
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleDeleteClick = (row: GridRowModel) => () => {
    if (apiRef.current !== undefined) {
      setJsonPayload({
        ...(row as CollectionPayload),
        remove: true,
      });
    }
    setOpen(true);
  };

  const handleCancelClick = (id?: GridRowId) => () => {
    if (id !== undefined && id !== null) {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    }
    setJsonPayload(null);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false } as DeviceExtended;
    const { name, engineeringId, type } = updatedRow;
    updateDevice.mutate(
      {
        name,
        ...(type === 'unitController' && { engineeringId }),
      },
      {
        onSuccess: () => {
          setConfirmOpen(true);
          return queryClient.invalidateQueries({
            queryKey: [QueryKeys.devices],
          });
        },
      },
    );
    return updatedRow;
  };

  const columns = [
    ...deviceBaseColumns,
    ...(isActionRole(role) && !process.env.VITE_READ_ONLY_MODE
      ? [
          {
            field: 'actions',
            type: 'actions' as GridColType,
            headerName: 'Actions',
            description: 'Actions',
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id, row }: GridRowParams<{ id: string }>) => {
              const isInEditMode =
                rowModesModel[id] !== undefined
                  ? rowModesModel[id].mode === GridRowModes.Edit
                  : false;

              if (isInEditMode) {
                return [
                  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label='Save'
                    sx={{
                      color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                  />,
                  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label='Cancel'
                    className='textPrimary'
                    onClick={handleCancelClick(id)}
                    color='inherit'
                  />,
                ];
              }

              return [
                // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label='Edit'
                  className='textPrimary'
                  onClick={() => handleEditClick(id)}
                  color='inherit'
                />,
                // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label='Delete'
                  onClick={handleDeleteClick(row)}
                  color='inherit'
                />,
              ];
            },
          } as GridBaseColDef,
        ]
      : []),
  ];

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    if (isSuccess && data !== null) {
      setRows(
        data.map((device) => ({ ...device, isNew: false, id: device.id })),
      );
    }
  }, [isSuccess, data, error]);

  return (
    <CollectionWrapper>
      <div className='cell block-container flex-1 overflow-y-auto'>
        <Snackbar
          open={confirmOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          sx={deviceSx.confirm}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            variant='filled'
            sx={deviceSx.confirm.alert}
          >
            Device changes saved
          </Alert>
        </Snackbar>
        <DataGridPro
          rows={rows}
          apiRef={apiRef}
          getRowId={({ id }) => id}
          checkboxSelection
          disableRowSelectionOnClick
          onCellDoubleClick={({ id }) => handleEditClick(id)}
          onRowDoubleClick={({ id }) => handleEditClick(id)}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          initialState={getCollectionsInitialState()}
          columns={columns as GridBaseColDef[]}
          loading={!isRefetching && (isFetching || isPending)}
          slots={{
            toolbar: CollectionToolbar,
            noRowsOverlay: GenerateDevicesButton,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
            toolbar: {
              collectionType: CollectionTypes.DEVICES,
              setOpen: setOpenGen,
              setJsonPayload,
            },
            noRowsOverlay: {
              setJsonPayload,
              setOpen: setOpenGen,
            },
          }}
          sx={deviceSx.collectionGrid}
        />

        {open && jsonPayload && (
          <DeleteDeviceDialog
            jsonData={jsonPayload}
            setOpen={setOpen}
            handleCancel={handleCancelClick}
          />
        )}
        {openGen && (
          <GenerateDeviceDialog
            jsonData={jsonPayload}
            setOpen={setOpenGen}
            setConfirmOpen={setConfirmOpen}
          />
        )}
      </div>
    </CollectionWrapper>
  );
}

export default storeConnector(DeviceCollection, {
  user: ['role'],
});
