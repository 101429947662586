import RatioValueLoader from '@components/_shared/RatioValueLoader';
import { Device } from '@src/types/Device';
import { PointConfigRef, ValueGenerationFunction } from '@src/types/Widget';
import { getValueGenerationFunction } from '@utils/evaluateValueGenerationFunction';
import {
  isValidElement,
  MutableRefObject,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridValidRowModel,
  useGridApiRef,
  GridApiPro,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import { Box, IconButton, Tooltip } from '@mui/material';
import ValueLoader from '@components/_shared/ValueLoader';
import { Build } from '@mui/icons-material';
import { store } from '@src/store';
import useGridSubscription from '@src/hooks/useGridSubscription';
import { MqttClient } from 'mqtt/*';

interface CellProps {
  dataPoint: DataPointWithValue;
}

export function Cell(p: CellProps): ReactElement {
  return p.dataPoint.displayDenominator ? (
    <RatioValueLoader dataPoint={p.dataPoint} />
  ) : (
    <ValueLoader dataPoint={p.dataPoint} />
  );
}

function getColumns(
  pointConfigRefs: PointConfigRef[],
  enableCmdBtnColumn?: boolean,
  disableBtn?: boolean,
  openCmdModal?: (deviceId: string) => void,
): GridColDef[] {
  const staticCols: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Device ID',
      flex: 1,
      type: 'string',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'Device',
      flex: 1,
      type: 'string',
      align: 'center',
      headerAlign: 'center',
    },
  ];

  if (enableCmdBtnColumn && openCmdModal !== undefined) {
    staticCols.push({
      field: 'cmdBtn',
      headerName: 'CMD',
      flex: 0.6,
      sortable: false,
      filterable: false,
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { controlsLockConfig } = store.getState().config.siteMeta.ui;
        const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;

        return (
          <Tooltip
            title={disableBtn ? tooltipTitle : ''}
            placement='top'
            disableHoverListener={!disableBtn}
          >
            <Box p={0}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  openCmdModal(params.row.id);
                }}
                disabled={disableBtn}
              >
                <Build />
              </IconButton>
            </Box>
          </Tooltip>
        );
      },
    });
  }

  const liveDataCols = pointConfigRefs.map((pc): GridColDef => {
    const func = getValueGenerationFunction(
      pc.valueGenerationFunction?.function,
    );
    return {
      field: pc.pointAlias as string,
      headerName: pc.displayName || pc.pointName,
      flex: 1,
      type: 'string',
      align: 'center',
      cellClassName: 'fractal-datapoint-value-cell',
      headerAlign: 'center',
      ...(func
        ? {
            valueGetter: (
              value: DataPointWithValue,
              row: GridValidRowModel,
            ): DataPointWithValue =>
              ({
                ...value,
                value: func(
                  (
                    pc.valueGenerationFunction as ValueGenerationFunction
                  ).argumentArray.map((pointAlias) => row[pointAlias]?.value),
                ),
              }) as DataPointWithValue,
          }
        : {}),
      renderCell: (
        params: GridCellParams<GridValidRowModel, DataPointWithValue>,
      ) => (params.value ? <Cell dataPoint={params.value} /> : '-'),
    };
  });

  return [
    ...staticCols,
    ...liveDataCols,
    ...[
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params: GridCellParams) => (
          <CustomDetailPanelToggle id={params.id} value={params.value} />
        ),
      },
    ],
  ];
}

function createRow(
  data: DataPoint[],
  pointConfigRefs: PointConfigRef[],
  id: string,
  name?: string,
): DeviceDataTableRow {
  const row = { id, name } as DeviceDataTableRow;
  pointConfigRefs.forEach((point) => {
    const dataPoint = data.find((d) => {
      const deviceId = d.sourceDeviceId || d.controllerId;
      return deviceId === id && d.pointAlias === point.pointAlias;
    });
    if (dataPoint) {
      row[point.pointAlias as string] = dataPoint;
    }
  });

  return row;
}

export type DeviceDataTableRow = {
  id: string;
  name: string;
  fullTopic: string;
  [key: string]: DataPoint | string;
};

export function getRows(
  data: DataPoint[],
  pointAliases: PointConfigRef[],
  devices?: Device[],
): DeviceDataTableRow[] {
  return (
    devices?.map((device) =>
      createRow(data, pointAliases, device.engineeringId, device.name),
    ) || []
  );
}

interface DeviceDataTableProps extends GridValidRowModel {
  dataPoints: DataPoint[];
  pointConfigRefs: PointConfigRef[];
  enableCmdBtnColumn?: boolean;
  additionalClassnames?: string;
  openCmdModal?: (deviceId: string) => void;
  devices: Device[] | undefined;
  rowSelectionModel?: GridRowSelectionModel;
  commandAccessEnable?: boolean;
  disableMultipleRowSelection?: boolean;
  boxStyle?: Record<string, string | number>;
  checkboxSelection?: boolean;
  defaultUnitSelection?: string;
  onRowSelectionChange?: (selectedRows: GridRowSelectionModel) => void;
  mqttClient?: MqttClient;
  allowExpandedCount?: number;
}

function CustomDetailPanelToggle(
  props: Pick<GridRenderCellParams, 'id' | 'value'>,
) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector,
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton
      size='small'
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMoreIcon
        sx={(theme) => ({
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        })}
        fontSize='inherit'
      />
    </IconButton>
  );
}

function DeviceDataTable(p: DeviceDataTableProps): ReactElement {
  const {
    checkboxSelection,
    disableMultipleRowSelection = false,
    onRowSelectionChange,
    unitSelectionModel,
    dataPoints,
    pointConfigRefs,
    enableCmdBtnColumn,
    openCmdModal,
    devices = [],
    mqttClient,
    allowExpandedCount,
  } = p;
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > (allowExpandedCount ?? 1)
          ? [newIds[newIds.length - 1]]
          : newIds,
      );
    },
    [allowExpandedCount],
  );

  const { isControlsLocked } = store.getState().service;

  const apiRef: MutableRefObject<GridApiPro> = useGridApiRef();

  const columns = useMemo(
    () =>
      getColumns(
        pointConfigRefs,
        enableCmdBtnColumn,
        isControlsLocked,
        openCmdModal,
      ),
    [pointConfigRefs, enableCmdBtnColumn, isControlsLocked, openCmdModal],
  );

  const rows = useMemo(
    () => getRows(dataPoints, pointConfigRefs, devices),
    [dataPoints, pointConfigRefs, devices],
  );

  const initialColumnVisibility = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          id: false,
        },
      },
    }),
    [],
  );

  const handleRowSelectionChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel): void => {
      if (onRowSelectionChange) {
        onRowSelectionChange(rowSelectionModel);
      }
    },
    [onRowSelectionChange],
  );

  useGridSubscription(rows, apiRef, mqttClient);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'name', sort: 'asc' },
  ]);

  return (
    <Box sx={{ height: 'calc(100% - 47px)', ...p.boxStyle }}>
      <DataGridPro
        rowSelectionModel={
          unitSelectionModel !== undefined ? unitSelectionModel : undefined
        }
        throttleRowsMs={1000}
        onRowSelectionModelChange={handleRowSelectionChange}
        checkboxSelection={checkboxSelection}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        {...p}
        initialState={initialColumnVisibility}
        className={`MuiDataGrid-DeviceDataTable ${p.additionalClassnames}`}
        disableMultipleRowSelection={disableMultipleRowSelection || false}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
      />
    </Box>
  );
}

export default DeviceDataTable;
