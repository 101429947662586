import { Dayjs } from 'dayjs';

export type TimeMode = 'UTC' | 'User' | 'Site';
export function isTimeMode(timeMode: unknown): timeMode is TimeMode {
  return ['UTC', 'Site', 'User'].includes(timeMode as TimeMode);
}

export type IntervalUnit =
  | 'millisecond'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month';

export type AggregationFunction = 'min' | 'max' | 'mean' | 'sum';
export interface PointField {
  pointName: string;
  dataType: string;
  displayName: string | null;
}
export interface HistorianDataPoint {
  axis: string;
  category: string;
  siteId: string;
  unitId: string;
  sourceDeviceId: string;
  dataPoint: string | PointField;
  topic?: string;
}

export interface Criterion {
  category: string;
  siteId: string;
  field: string;
  dataType?: string;
}
export interface HistorianQuery {
  criteria: Criterion[];
  dateFrom: Date;
  dateTo: Date;
  interval: Interval;
  aggregateFunctions: AggregationFunction[];
}

export interface Interval {
  value: number;
  unit: string;
}

export interface SavedSearch {
  [key: string]: unknown;
  id?: string;
  name: string;
  siteId: string;
  aggregationFunctions: AggregationFunction[];
  dataPoints: HistorianDataPoint[];
  interval: Interval;
  startDateTime: Dayjs;
  stopDateTime: Dayjs;
}

export interface Meter {
  readonly displayName: string;
  readonly id: string;
}

export interface RackCommands {
  readonly 'Open DC Contactor': string;
  readonly 'Close DC Contactor': string;
}

export interface SiteCommand {
  readonly Name: string;
  readonly Device: string;
  readonly Command: string;
}

export interface UnitCommand {
  readonly Name: string;
  readonly Command: string;
}

export interface UnitPvCommand {
  readonly Name: string;
  readonly Command: string;
}

export interface CustomSitePoint {
  readonly PointName: string;
  readonly DisplayName: string;
}

export interface Topology_Override {
  readonly bms?: UnitBMSConfig[];
  readonly rack?: UnitRackConfig[];
  readonly bmsRack?: UnitBMSRackConfig[];
}

export interface UnitBMSConfig {
  readonly unitSN: string;
  readonly bmsExcludeList: string[];
  readonly rackCount?: number;
}
export interface UnitRackConfig {
  readonly unitSN: string;
  readonly rackCount: number;
}
export interface UnitBMSRackConfig {
  readonly unitSN: string;
  readonly bmsId: string;
  readonly rackCount: number;
}

export type BooleanInteger = 0 | 1;
