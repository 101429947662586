import { ReactElement, useMemo, useState } from 'react';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import Spinner from '@components/_elements/Spinner/Spinner';
import { SiteMeta } from '@src/types/SiteMeta';
import UIBox from '@components/_shared/UIBox';
import storeConnector from '@store/storeConnector';
import DeviceDataTable from '@components/_shared/DeviceDataTable';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import IncidentCmdChart from '@components/IncidentCommand/IncidentCmdChart';
import useMakePageScrollable from '@hooks/useMakePageScrollable';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';

interface IncidentCommandProps {
  siteMeta: SiteMeta;
}

function IncidentCommand(p: IncidentCommandProps): ReactElement {
  useMakePageScrollable();

  const { siteMeta } = p;
  const [selectedUnits, setSelectedUnits] = useState<GridRowSelectionModel>([
    siteMeta.Units[0],
  ]);

  const rowSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ): void => {
    if (rowSelectionModel.length > 0) {
      //  save new selectedUnits to state
      setSelectedUnits(rowSelectionModel);
    }
  };

  const deviceNameDictionary = useMemo(
    () =>
      siteMeta.Units.map((unitId, i) => ({
        id: unitId,
        name: siteMeta.UnitNames[i],
      })),
    [siteMeta],
  );

  const {
    data: widgetConfig,
    isLoading,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypeDeviceList>(
    'incidentCommandList',
    {},
  );

  const memoizedDevices = useMemo(
    () => widgetConfig?.devices || [],
    [widgetConfig?.devices],
  );

  const memoizedDataPoints = useMemo(
    () => widgetConfig?.dataPoints || [],
    [widgetConfig?.dataPoints],
  );

  const memoizedPointConfigRefs = useMemo(
    () => widgetConfig?.pointConfigRefs || [],
    [widgetConfig?.pointConfigRefs],
  );

  return isLoading ? (
    <Spinner type='fullPage' />
  ) : (
    <div className='home-row full-width'>
      <div className='fcol full-width'>
        <div className='frow full-width'>
          <div className='fcol full-width'>
            <UIBox
              header='Incident Command List'
              isLoading={isLoading}
              isError={isError}
            >
              <DeviceDataTable
                enableCmdBtnColumn={false}
                hideFooter
                devices={memoizedDevices}
                dataPoints={memoizedDataPoints}
                pointConfigRefs={memoizedPointConfigRefs}
                rowSelectionModel={selectedUnits}
                checkboxSelection
                onRowSelectionChange={rowSelectionModelChange}
                deviceNameDictionary={deviceNameDictionary}
                getRowClassName={() => 'MuiDataGrid-clickable'}
                boxStyle={{ height: '400px' }}
              />
            </UIBox>
          </div>
        </div>
        <div className='frow full-width'>
          <div className='fcol full-width'>
            <UIBox header='Max Ambient Temperature'>
              <IncidentCmdChart selectedUnits={selectedUnits} />
            </UIBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default storeConnector(IncidentCommand, {
  config: ['siteMeta'],
});
