import QueryDropdowns from '@components/Home/Historian/QueryDropdowns';
import storeConnector from '@store/storeConnector';
import { HistorianDataPoint, SavedSearch, TimeMode } from '@types';
import { SiteMeta } from '@src/types/SiteMeta';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import { getDateTimeFromTimeMode } from '@utils/index_ts';
import AddDataPointButton from './AddDataPointButton';
import { DATA_POINT_LENGTH_LIMIT, dataPointDictInit } from './historianUtils';
import SavedSearchRow from './SavedSearchRow/SavedSearchRow';
import SettingsRow from './SettingsRow';
import PlotButtons from './PlotButtons';

const MemoQueryDropdowns = memo(QueryDropdowns);

interface FilterBlockProps extends FilterBlockContainerProps {
  activeSearch: SavedSearch;
  setActiveSearch: React.Dispatch<SavedSearch>;
  removeDataPoint: (i: number) => void;
  cloneDataPoint: (i: number, dp: HistorianDataPoint) => void;
  updateDataPoint: (i: number, dp: HistorianDataPoint) => void;
  searchUnderEdit: SavedSearch | undefined;
  setSearchUnderEdit: React.Dispatch<SavedSearch | undefined>;
  setSearchField: (field: string, value: unknown) => void;
  initialSearchState: SavedSearch;
}
function FilterBlock(p: FilterBlockProps): ReactElement {
  return (
    <div className='filter-grid'>
      <div className='label no-pd-top'>Saved Search</div>
      <SavedSearchRow
        activeSearch={p.activeSearch}
        setSearchField={p.setSearchField}
        setActiveSearch={p.setActiveSearch}
        searchUnderEdit={p.searchUnderEdit}
        setSearchUnderEdit={p.setSearchUnderEdit}
        newSearch={p.initialSearchState}
      />
      <SettingsRow
        siteMeta={p.siteMeta}
        timeMode={p.timeMode}
        activeSearch={p.activeSearch}
        setSearchField={p.setSearchField}
      />
      {p.activeSearch.dataPoints.map((dp, i) => (
        <MemoQueryDropdowns
          // eslint-disable-next-line react/no-array-index-key
          key={`dataPoint_${dp.category}_${i}`}
          disabled={DATA_POINT_LENGTH_LIMIT <= p.activeSearch.dataPoints.length}
          cloneDataPoint={p.cloneDataPoint}
          removeDataPoint={p.removeDataPoint}
          updateDataPoint={p.updateDataPoint}
          dp={dp}
          i={i}
          categories={p.categories}
        />
      ))}
      <AddDataPointButton
        activeSearch={p.activeSearch}
        searchUnderEdit={p.searchUnderEdit}
        setActiveSearch={p.setActiveSearch}
      />
      <PlotButtons
        searchUnderEdit={p.searchUnderEdit}
        activeSearch={p.activeSearch}
        runPlot={p.runPlot}
        runAutoPlot={p.runAutoPlot}
      />
    </div>
  );
}

export function getInitialState(
  siteMeta: SiteMeta,
  timeMode: TimeMode,
): SavedSearch {
  const startDateTime = getDateTimeFromTimeMode(timeMode, siteMeta).subtract(
    1,
    'hour',
  );
  const stopDateTime = getDateTimeFromTimeMode(timeMode, siteMeta);

  return {
    name: '',
    siteId: siteMeta.siteId,
    startDateTime,
    stopDateTime,
    interval: {
      value: siteMeta.DataRate ? Number(siteMeta.DataRate) : 1,
      unit: 'second',
    },
    aggregationFunctions: ['mean'],
    dataPoints: [{ ...dataPointDictInit, siteId: siteMeta.siteId }],
  };
}
interface FilterBlockContainerProps {
  siteMeta: SiteMeta;
  timeMode: TimeMode;
  runPlot: () => Promise<void>;
  runAutoPlot: (val: string) => void;
  categories: string[];
}

export function FilterBlockContainer(
  p: FilterBlockContainerProps,
): ReactElement {
  const initialSearchState = getInitialState(p.siteMeta, p.timeMode);
  const [activeSearch, setActiveSearch] =
    useState<SavedSearch>(initialSearchState);
  const [searchUnderEdit, setSearchUnderEdit] = useState<SavedSearch>();

  const cloneDataPoint = (i: number, dp: HistorianDataPoint) => {
    setActiveSearch((prev) => ({
      ...prev,
      dataPoints: [
        ...prev.dataPoints.slice(0, i + 1),
        { ...dp },
        ...prev.dataPoints.slice(i + 1),
      ],
    }));
  };

  const removeDataPoint = (i: number) =>
    setActiveSearch((prev) => ({
      ...prev,
      dataPoints: prev.dataPoints.toSpliced(i, 1),
    }));

  const updateDataPoint = (i: number, dp: HistorianDataPoint) =>
    setActiveSearch((prev) => ({
      ...prev,
      dataPoints: [
        ...prev.dataPoints.slice(0, i),
        {
          ...prev.dataPoints[i],
          ...dp,
        },
        ...prev.dataPoints.slice(i + 1),
      ],
    }));

  function setSearchField<T>(field: string, value: T): void {
    setActiveSearch((prev) => ({ ...prev, [field]: value }));
  }
  // update startDateTime and stopDateTime when timeMode changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    setSearchField(
      'startDateTime',
      getDateTimeFromTimeMode(p.timeMode, p.siteMeta).subtract(1, 'hour'),
    );
    setSearchField(
      'stopDateTime',
      getDateTimeFromTimeMode(p.timeMode, p.siteMeta),
    );
  }, [p.timeMode]);

  return (
    <FilterBlock
      {...p}
      activeSearch={activeSearch}
      setActiveSearch={setActiveSearch}
      cloneDataPoint={cloneDataPoint}
      removeDataPoint={removeDataPoint}
      updateDataPoint={updateDataPoint}
      searchUnderEdit={searchUnderEdit}
      setSearchUnderEdit={setSearchUnderEdit}
      setSearchField={setSearchField}
      initialSearchState={initialSearchState}
    />
  );
}

export default storeConnector(FilterBlockContainer, {
  config: ['siteMeta'],
  service: ['timeMode'],
});
