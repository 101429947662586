import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const Application = ({ searchStr, name, description, id, onDeleteClick }) => {
  const nameParts = searchStr
    ? name.split(new RegExp(`(${searchStr})`, 'gi'))
    : [name];
  return (
    <div className='stack-style'>
      <div className='stack-name-wrap'>
        <Link
          className='stack-name'
          style={{ cursor: 'pointer' }}
          to={`/home/config/applications/edit/${id}`}
        >
          {nameParts.map((p, i) => (
            <span
              key={i}
              className={
                p.toUpperCase() === searchStr.toUpperCase() ? 'highlight' : ''
              }
            >
              {p}
            </span>
          ))}
        </Link>
        <div className='app-description'>{description}</div>
      </div>
      <div className='stack-icons'>
        <div className='clickable-icon'>
          <Link to={`/home/config/applications/edit/${id}`}>
            <FontAwesomeIcon className='stack-icons-icon' icon={faPencilAlt} />
          </Link>
        </div>
        <div className='clickable-icon'>
          <FontAwesomeIcon
            className='stack-icons-icon'
            icon={faTrashAlt}
            onClick={() => {
              onDeleteClick(id);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Application;
