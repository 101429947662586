import type { DataPointWithValue } from '@src/types/DataPoint';
import type { TransformedTeslaConfigItem } from '@src/types/TeslaConfig';
import { Box, Switch, TableCell, Tooltip } from '@mui/material';
import { useCommandsModalContext } from '@src/components/Battery/Tesla/Commands/CommandsModalContext';
import storeConnector from '@src/store/storeConnector';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import { ServiceState } from '@src/store/reducers/serviceData';
import { SiteMeta } from '@src/types/SiteMeta';
import TeslaStatusIcon from './TeslaStatusIcon';

interface StatusProps {
  datapoint: DataPointWithValue;
  item: TransformedTeslaConfigItem;
  siteMeta: SiteMeta;
  isControlsLocked: ServiceState['isControlsLocked'];
}

function TSCStatus({
  datapoint,
  item,
  isControlsLocked,
  siteMeta,
}: StatusProps) {
  const { sendCommand } = useCommandsModalContext();
  const { controlsLockConfig } = siteMeta.ui;
  const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;
  const handleCommand = (value: boolean) => {
    const newValue = value ? '1' : '0';

    sendCommand('WriteToVariant', {
      P: item.cmdPayload.replace('[input value]', newValue),
    });
  };

  return (
    <>
      <TableCell>
        <TeslaStatusIcon status={datapoint.value} />
      </TableCell>

      <TableCell>
        {isCommandsEnabled() && (
          <Tooltip
            title={isControlsLocked ? tooltipTitle : ''}
            placement='top'
            disableHoverListener={!isControlsLocked}
          >
            <Box>
              <Switch
                checked={datapoint.value === 1}
                onChange={(e) => handleCommand(e.target.checked)}
                disabled={isControlsLocked}
              />
            </Box>
          </Tooltip>
        )}
      </TableCell>
    </>
  );
}

export default storeConnector(TSCStatus, {
  config: ['siteMeta'],
  service: ['isControlsLocked'],
});
