import { ReactElement } from 'react';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';
import { getBreakerStatusColor } from '@src/sld/utils';
import { BreakerStackProps } from '@src/sld/types';
import DataPointsSection from './MeterDataPointsSection';

const rectTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

function BreakerStack(props: BreakerStackProps): ReactElement {
  const {
    breakerStatus,
    meterDataPoints,
    translateXCoordinate,
    translateYCoordinate,
    feederALabel,
    feederBLabel,
    breakerLabel,
    meterLabel,
    meterDataPointsSectionXCoordinate,
    meterDataPointsSectionYCoordinate,
  } = props;

  return (
    <g transform={`translate(${translateXCoordinate} ${translateYCoordinate})`}>
      <VerticalLine
        translateXCoordinate={-112}
        translateYCoordinate={691}
        length={31}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <rect
        x={-123}
        y='723'
        width='20'
        height='20'
        fill={getBreakerStatusColor(breakerStatus)}
        strokeWidth={0.5}
      />

      <text x={-90} y={735} {...rectTextProps}>
        {breakerLabel}
      </text>

      <VerticalLine
        translateXCoordinate={-113.5}
        translateYCoordinate={744}
        length={100}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <HorizontalLine
        translateXCoordinate={-113.5}
        translateYCoordinate={800}
        length={60}
        color='white'
        width={0.5}
      />

      <VerticalLine
        translateXCoordinate={-54}
        translateYCoordinate={800}
        length={44}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      {/* left down arrow */}
      <polygon
        transform='translate(-111, 845) rotate(-270 0 0) scale(1)'
        points='0,5 0,0 5,2.5'
        stroke='white'
        fill='white'
      />

      <text x={-120} y={870} fill='currentColor' stroke='none' fontSize='8px'>
        {feederALabel}
      </text>

      {/* right down arrow */}
      <polygon
        transform='translate(-52, 845) rotate(-270 0 0) scale(1)'
        points='0,5 0,0 5,2.5'
        stroke='white'
        fill='white'
      />

      <text x={-60} y={870} fill='currentColor' stroke='none' fontSize='8px'>
        {feederBLabel}
      </text>

      <DataPointsSection
        translateXCoordinate={meterDataPointsSectionXCoordinate}
        translateYCoordinate={meterDataPointsSectionYCoordinate}
        meterDataPoints={meterDataPoints}
        meterLabel={meterLabel || ''}
      />
    </g>
  );
}

export default BreakerStack;
