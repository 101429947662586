import { ReactElement } from 'react';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import Switch from '@src/sld/components/Switch/Switch';
import { Resistor } from '@src/sld/components/Resistor/Resistor';
import DataPointsSection from './MeterDataPointsSection';
import { getBreakerStatusColor } from '@src/sld/utils';
import { TopSwitchResistorProps } from '@src/sld/types';

const rectTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

function TopSwitchResistorSection(props: TopSwitchResistorProps): ReactElement {
  const {
    translateXCoordinate,
    translateYCoordinate,
    meterDataPoints,
    breakerStatus,
    switchStatus,
    breakerLabel,
    switchLabel,
    meterLabel,
    resistorLabel,
  } = props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;

  return (
    <g transform={translate}>
      {/* up arrow */}
      <polygon
        transform='translate(705.5, 16) rotate(270 0 0) scale(1)'
        points='0,5 0,0 5,2.5'
        stroke='white'
        fill='white'
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={17}
        length={8}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <Switch
        label={switchLabel || ''}
        isClosed={switchStatus?.value === 1}
        translateXCoordinate={708}
        translateYCoordinate={25}
        stroke={switchStatus?.value === 1 ? 'red' : 'green'}
        overrideLabelXCoordinate={28}
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={35}
        length={14}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <rect
        x={698}
        y='50'
        width='20'
        height='20'
        fill={getBreakerStatusColor(breakerStatus)}
        strokeWidth={0.5}
      />

      <text x={737} y={65} {...rectTextProps}>
        {breakerLabel}
      </text>

      <DataPointsSection
        meterLabel={meterLabel || ''}
        translateXCoordinate={790}
        translateYCoordinate={55}
        meterDataPoints={meterDataPoints}
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={70}
        length={22}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <g transform='rotate(90 700 783)'>
        <Resistor label={resistorLabel || ''} />
      </g>

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={105}
        length={20}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={125}
        length={45}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <VerticalLine
        translateXCoordinate={235}
        translateYCoordinate={590}
        length={10}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <VerticalLine
        translateXCoordinate={235}
        translateYCoordinate={590}
        length={10}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />
    </g>
  );
}

export default TopSwitchResistorSection;
