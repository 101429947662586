import React, { ReactElement, useState } from 'react';
import storeConnector from '@store/storeConnector';
import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  Container,
  IconButton,
  StepConnector,
  stepConnectorClasses,
  Button,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DatasetIcon from '@mui/icons-material/Dataset';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import WidgetsIcon from '@mui/icons-material/Widgets';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { StepIconProps } from '@mui/material/StepIcon';
import DeviceCollection from '@src/components/Home/Collections/Devices/DeviceCollection';
import PointConfigCollection from '@src/components/Home/Collections/PointConfigs/PointConfigCollection';
import WidgetConfigCollection from '@src/components/Home/Collections/WidgetConfigs/WidgetConfigCollection';
import usePointConfigMutations from '@src/hooks/api/mutations/usePointConfigMutations';
import useWidgetConfigMutations from '@src/hooks/api/mutations/useWidgetConfigMutations';
import { SiteTypes } from '@src/utils/defaultDataStubs/stubFilters';
import { useNavigate } from 'react-router';
import { useQueryClient, useIsFetching } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import { Device } from '@src/types/Device';
import { PointConfig } from '@src/types/PointConfig';
import pointConfigs from '@src/utils/defaultDataStubs/pointConfigs';
import widgets from '@src/utils/defaultDataStubs/widgetConfigs';
import { SiteMeta } from '@src/types/SiteMeta';
import SiteSettings from './SiteSettings';
import wizardSx from './WizardSx';
import './Wizard.scss';

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient(136deg, rgb(250 181 50) 0%, rgb(250 150 50) 50%, rgb(254 96 0) 100%)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient(136deg, rgb(139 210 239) 0%, rgb(109 188 219) 50%, rgb(109 135 219) 100%)',
        boxShadow: '0 5px 10px 0 rgba(0,0,0,.25)',
      },
    },
  ],
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const steps = [
  'Add site details',
  'Add devices',
  'Add point configs',
  'Add Widget Configs',
];

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement<unknown> } = {
    1: <DisplaySettingsIcon />,
    2: <DeviceHubIcon />,
    3: <DatasetIcon />,
    4: <WidgetsIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function SetupWizard({ siteMeta }: { siteMeta: SiteMeta }): ReactElement {
  const queryClient = useQueryClient();
  const [type, setType] = useState<string | null>(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const isFetchingDevices =
    useIsFetching({ queryKey: [QueryKeys.devices] }) > 0;

  const navigate = useNavigate();

  const updateSiteType = (siteType: SiteTypes) => {
    setType(siteType);
    sessionStorage.setItem('siteType', siteType);
  };

  const { createPointConfig } = usePointConfigMutations();
  const { createWidgetConfig } = useWidgetConfigMutations();

  const totalSteps = steps.length;

  const completedSteps = Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps - 1;

  const allStepsCompleted = () => completedSteps === totalSteps;

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((_, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    if (!completed[step]) {
      return;
    }
    setActiveStep(step);
  };

  const handleComplete = async () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });

    if (activeStep === 1) {
      try {
        await Promise.all([
          createPointConfig.mutateAsync({
            pointConfigs,
          }),
          createWidgetConfig.mutateAsync(widgets),
        ]);
      } catch (error) {
        console.error('One or more mutations failed', error);
      }
    }

    handleNext();
  };

  const disableNextStep = () => {
    if (activeStep === 0) {
      return !type || siteMeta === undefined;
    }
    if (activeStep === 1) {
      const queryDevices = queryClient.getQueryData<Device[]>([
        QueryKeys.devices,
      ]);

      return (
        isFetchingDevices || (queryDevices ? queryDevices.length < 1 : true)
      );
    }
    if (activeStep === 2) {
      const queryPointConfigs = queryClient.getQueryData<PointConfig[]>([
        QueryKeys.pointConfigs,
      ]);
      return (
        isFetchingDevices ||
        (queryPointConfigs ? queryPointConfigs.length < 1 : true)
      );
    }

    return false;
  };

  return (
    <main id='wizard-main'>
      <Stepper
        alternativeLabel
        sx={wizardSx.stepper}
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              slots={{
                stepIcon: ColorlibStepIcon,
              }}
              slotProps={{
                stepIcon: {
                  completed: completed[index],
                },
              }}
              onClick={handleStep(index)}
              sx={wizardSx.stepper.label(completed[index])}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container
        maxWidth='lg'
        className='wizard-steps-container'
        sx={wizardSx.container}
      >
        {activeStep === 0 && (
          <SiteSettings type={type} updateSiteType={updateSiteType} />
        )}
        {activeStep === 1 && <DeviceCollection />}
        {activeStep === 2 && <PointConfigCollection />}
        {activeStep === 3 && <WidgetConfigCollection />}
      </Container>
      <Stack direction='row' sx={wizardSx.buttonWrapper}>
        <IconButton
          aria-label='delete'
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          <NavigateBeforeIcon sx={wizardSx.buttonWrapper.navbutton} />
        </IconButton>
        {completedSteps !== totalSteps - 1 ? (
          <IconButton onClick={handleComplete} disabled={disableNextStep()}>
            <NavigateNextIcon sx={wizardSx.buttonWrapper.navbutton} />
          </IconButton>
        ) : (
          <Box sx={wizardSx.buttonWrapper.finishButtonWrapper}>
            <Button onClick={() => navigate('/home')} variant='contained'>
              Finish
            </Button>
          </Box>
        )}
      </Stack>
    </main>
  );
}

export default storeConnector(SetupWizard, {
  config: ['siteMeta'],
});
