import './ApplicationItem.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretUp,
  faMinus,
  faPencilAlt,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

const ApplicationItem = ({
  activeItem,
  symbol,
  id,
  name,
  description,
  movable,
  onUpClick,
  editOnClick,
  minusOnClick,
  closeOnClick,
  addOnClick,
  isUsing,
}) => {
  return (
    <div
      className={
        'application-item ' + (activeItem === true ? 'editing-app-item' : '')
      }
    >
      <div>
        {symbol === 'minus' && (
          <div
            onClick={() => {
              editOnClick(id);
            }}
            style={{ cursor: 'pointer' }}
          >
            {name}
          </div>
        )}
        {symbol === 'plus' && <div>{name}</div>}
        <div className='app-description'>{description}</div>
      </div>
      <div className='clickable-icon-wrap'>
        {movable === true && symbol === 'minus' && (
          <div className='clickable-icon'>
            <FontAwesomeIcon
              className='application-icons caret-up'
              icon={faCaretUp}
              onClick={() => onUpClick(id)}
            />
          </div>
        )}
        {symbol === 'minus' && (
          <>
            <div
              className='clickable-icon'
              onClick={() => {
                editOnClick(id);
              }}
            >
              <FontAwesomeIcon
                className='application-icons'
                icon={faPencilAlt}
              />
            </div>
            <div
              className='clickable-icon'
              onClick={() => {
                minusOnClick(id);
                closeOnClick();
              }}
            >
              <FontAwesomeIcon className='application-icons' icon={faMinus} />
            </div>
          </>
        )}
        {symbol === 'plus' && (
          <div
            className='none'
            onClick={() => {
              if (!isUsing) {
                addOnClick(id);
              }
            }}
          >
            <FontAwesomeIcon
              title={`${isUsing ? `${description} is already in Stack` : ''}`}
              className={`application-icons ${
                isUsing ? 'disabled-icon' : 'clickable-icon'
              }`}
              icon={faPlus}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationItem;
