import { useEffect, useMemo, useState } from 'react';
import { store } from '@store';
import storeConnector from '@store/storeConnector';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { getTsDueTimeMode } from '@utils/index_ts';
import { isNumberString, isNumeric } from '@utils/mathHelpers';
import { Chip, CircularProgress } from '@mui/material';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { TimeMode } from '@types';
import { GridRowModelUpdate } from '@mui/x-data-grid/models/gridRows';
import { OnMessageCallback } from 'mqtt';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
import { SiteMeta } from '@src/types/SiteMeta';
import { IPointsListData, IPointsListEntry } from '@src/types/SiteMetaUI';
import { IMqttMessage, isIMqttMessage } from '@src/types/Mqtt';

interface PointListCellProps {
  data: IPointsListData;
  siteMeta: SiteMeta;
  timeMode: TimeMode;
}

interface ITopicData {
  displayName: string;
  topic: string;
  value: string | boolean;
  id: string;
}

const decoder = new TextDecoder('utf-8');

interface ILiveIcon {
  isRetainedMsg?: boolean;
  isLive: boolean;
}

function LiveIcon({ isRetainedMsg, isLive }: ILiveIcon) {
  let color = 'red';
  if (isLive) {
    color = 'green';
  }
  if (isRetainedMsg) {
    color = 'gold';
  }
  return (
    <svg height='16' width='8'>
      <circle cx='4' cy='8' r='4' fill={color} />
    </svg>
  );
}

const runValueMap = (
  pointValue: number,
  pointListData: IPointsListData,
): number | string | null => {
  if (!pointListData.pointList || pointListData.pointList.length === 0) {
    return pointValue;
  }

  const pointWithMap = pointListData.pointList.find(
    (point) =>
      point.valueMap &&
      point.valueMap.length > 0 &&
      point.valueMap.some((item) => Object.hasOwn(item, pointValue.toString())),
  );

  if (pointWithMap && pointWithMap.valueMap) {
    const foundValueMapItem = pointWithMap.valueMap.find((valueMapItem) =>
      Object.hasOwn(valueMapItem, pointValue.toString()),
    );

    if (foundValueMapItem) {
      const mappedValue = foundValueMapItem[pointValue];

      return isNumeric(mappedValue)
        ? parseFloat(mappedValue).toFixed(2)
        : mappedValue;
    }
  }

  return pointValue;
};

const prepColumns = (
  siteMeta: SiteMeta,
  timeMode: TimeMode,
  pointsListData: IPointsListData,
): GridColDef[] => [
  {
    field: 'displayName',
    headerName: 'Display Name',
    flex: 1,
  },
  {
    field: 'topic',
    headerName: 'Topic',
    flex: 1,
  },
  {
    field: 'lastUpdatedAt',
    headerName: 'Last Update',
    minWidth: 130,
    type: 'dateTime',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (value: string) => new Date(value),
    renderCell: ({ value }: { value?: Date }) => {
      if (!value || value.toString() === 'Invalid Date') {
        return '';
      }
      const date = getTsDueTimeMode(siteMeta, timeMode, value.toISOString());
      return (
        <Chip
          className='value-chip'
          label={`${date.format('HH:mm:ss')} ${date.format('z')}`}
          title={date.format()}
        />
      );
    },
  },
  {
    field: 'value',
    headerName: 'Value',
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    flex: 0.6,
    minWidth: 120,
    maxWidth: 150,
    valueGetter: (value: IMqttMessage['value']) => {
      try {
        if (isNumberString(value)) {
          const stringAsNumber = Number(parseFloat(value as string).toFixed(3));
          return runValueMap(stringAsNumber, pointsListData);
        }

        return runValueMap(value as number, pointsListData);
      } catch {
        return 'ERR';
      }
    },
    renderCell: ({ value, row }) => {
      let label = value;
      let icon = (
        <LiveIcon isRetainedMsg={row.isRetainedMsg} isLive={value !== 'ERR'} />
      );
      if (value === undefined) {
        icon = (
          <CircularProgress
            sx={{
              position: 'absolute',
              marginLeft: '0 !important',
              marginRight: '0 !important',
            }}
            thickness={5}
            color='primary'
            size={20}
          />
        );
      }
      if (typeof value === 'boolean' || value === null) {
        label = '';
      }
      if (typeof value === 'number') {
        label = Number(value.toFixed(3));
      }
      return <Chip className='value-chip' label={label} icon={icon} />;
    },
  },
];

const initialDataGridState: GridInitialStatePro = {
  columns: {
    columnVisibilityModel: {
      topic: false,
      lastUpdatedAt: false,
    },
  },
};

const slotProps: GridProSlotProps = {
  toolbar: {
    showQuickFilter: true,
    // csvOptions: { disableToolbarButton: true },
    printOptions: { disableToolbarButton: true },
  },
};

function PointListCell({ data, siteMeta, timeMode }: PointListCellProps) {
  const clientMQTT = useMemo(() => store.getState().mqtt.clientMQTT, []);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [topicData, setTopicData] = useState<IPointsListEntry[]>([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    setTopicData(
      data.pointList.map<IPointsListEntry>(({ topic, displayName }, i) => ({
        topic,
        displayName,
        id: i.toString(),
      })),
    );
  }, [data.pointList]);

  useEffect(() => {
    setColumns(prepColumns(siteMeta, timeMode, data));
  }, [timeMode, siteMeta, data]);

  useEffect(() => {
    if (!clientMQTT) {
      return () => {};
    }

    const getDataGridData = () =>
      Array.from(
        apiRef.current.getRowModels(),
        ([, value]) => value,
      ) as ITopicData[];

    const topicList = getDataGridData().map((tData) => tData.topic);
    const onMessage: OnMessageCallback = (topic, msg, packet) => {
      const currentTopicData = [...topicData];
      const matchingTopicObj = currentTopicData.find((o) => o.topic === topic);

      if (matchingTopicObj) {
        try {
          const decodedMsg: IMqttMessage = JSON.parse(decoder.decode(msg));
          const validatedMsg = {
            value: isIMqttMessage(decodedMsg) ? decodedMsg.value : decodedMsg,
            lastUpdatedAt: isIMqttMessage(decodedMsg)
              ? decodedMsg.ts
              : new Date().toISOString(),
          };

          apiRef.current.updateRows([
            {
              ...matchingTopicObj,
              ...validatedMsg,
              isRetainedMsg: packet.retain,
            },
          ] as GridRowModelUpdate[]);
        } catch (_err) {
          apiRef.current.updateRows([
            { ...matchingTopicObj, value: 'ERR' },
          ] as GridRowModelUpdate[]);
        }
      }
    };

    clientMQTT.on('message', onMessage);
    clientMQTT.subscribe(topicList);

    return () => {
      clientMQTT.removeListener('message', onMessage);
      clientMQTT.unsubscribe(topicList);
    };
  }, [apiRef, topicData, clientMQTT]);

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={topicData}
      columns={columns}
      disableColumnResize
      disableColumnReorder
      isRowSelectable={() => false}
      density='compact'
      disableDensitySelector
      initialState={initialDataGridState}
      slots={{ toolbar: GridToolbar }}
      slotProps={slotProps}
    />
  );
}

export default storeConnector(PointListCell, {
  config: ['siteMeta'],
  service: ['timeMode'],
});
