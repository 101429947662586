import { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

function CommandsConfirmationModal({
  children,
  open,
  onClose,
  onConfirm,
}: {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ p: 4, mt: 2 }}>{children}</DialogContent>
      <DialogActions
        sx={{ display: 'flex', p: 2, justifyContent: 'space-between' }}
      >
        <Button onClick={onConfirm} autoFocus variant='contained'>
          Yes
        </Button>
        <Button onClick={onClose} variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CommandsConfirmationModal;
