import MultiColPointDataTable from '@components/_shared/MultiColPointDataTable';
import UIBox from '@components/_shared/UIBox';
import { useCurrentPowinEmsDeviceContext } from '@hooks/useCurrentPowinEmsDevice';
import { Grid2 } from '@mui/material';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypePointList } from '@src/types/Widget';
import { useEffect } from 'react';

function PowinControllerDetails() {
  const { currentPowinEmsDevice } = useCurrentPowinEmsDeviceContext();
  const {
    data: widgetConfig,
    isLoading,
    isError,
    refetch,
  } = useGetInternalWidgetConfig<WidgetTypePointList>(
    'powinControllerDetails',
    {
      sourceDeviceId: currentPowinEmsDevice?.deviceId,
    },
    { enabled: !!currentPowinEmsDevice?.deviceId },
  );

  useEffect(() => {
    if (currentPowinEmsDevice?.deviceId) {
      refetch();
    }
  }, [refetch, currentPowinEmsDevice?.deviceId]);

  return (
    <UIBox
      header={widgetConfig?.header}
      subheader={currentPowinEmsDevice?.name}
      isLoading={isLoading || (!isLoading && !widgetConfig && !isError)}
      isError={isError || (!isLoading && !widgetConfig)}
    >
      <Grid2 container justifyContent='space-evenly'>
        <MultiColPointDataTable
          dataPointsMatrix={widgetConfig?.dataPoints || []}
        />
      </Grid2>
    </UIBox>
  );
}

export default PowinControllerDetails;
