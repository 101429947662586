import { SeverityTypes } from '@src/types/Alerts';
import {
  GridActionsCellItem,
  GridColDef,
  GridColType,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridBaseColDef } from '@mui/x-data-grid/internals';
import { engineerOrAdmin, isAdmin } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import SeverityCell from '../Table/SeverityCell';
import EventTimeCell from '../Table/EventTimeCell';

interface ConfigColsParams {
  role: UserRole;
  rowSelectionModel: GridRowSelectionModel;
  handleEditClick: (id: GridRowId) => () => void;
  handleDeleteClick: (id: GridRowId) => () => void;
}

const prepConfigColumns = ({
  role,
  rowSelectionModel,
  handleEditClick,
  handleDeleteClick,
}: ConfigColsParams): GridColDef[] => [
  {
    field: 'alertName',
    headerName: 'Alert name',
    description: 'Alert name',
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    editable: true,
  },
  {
    field: 'topic',
    headerName: 'Topic',
    description: 'Topic',
    type: 'string',
    width: 300,
    editable: true,
  },
  {
    field: 'severity',
    headerName: 'Severity',
    description: 'Severity',
    width: 80,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'singleSelect',
    valueOptions: [0, 1, 2],
    renderCell: ({ value }) => (
      <SeverityCell severity={SeverityTypes[value]} isAcked={false} />
    ),
  },
  {
    field: 'thresholdMin',
    headerName: 'Min threshold',
    description: 'Min threshold',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'thresholdMax',
    headerName: 'Max threshold',
    description: 'Max threshold',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'messageAboveMax',
    headerName: 'Message above max',
    description: 'Message above max',
    type: 'string',
    width: 250,
    editable: true,
  },
  {
    field: 'messageBelowMin',
    headerName: 'Message below min',
    description: 'Message below min',
    type: 'string',
    width: 250,
    editable: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Edit',
    description: 'Last Edit',
    type: 'string',
    width: 200,
    editable: true,
    renderCell: ({ value }) => <EventTimeCell time={new Date(value)} />,
  },
  {
    field: 'isEquipmentFault',
    headerName: 'Is Equipment Fault',
    description: 'Is Equipment Fault',
    type: 'string',
    width: 150,
    editable: true,
    align: 'center',
    renderCell: ({ value }) =>
      value === true ? <FontAwesomeIcon icon={faCheck} size='lg' /> : null,
  },
  {
    field: 'timeoutActivateMs',
    headerName: 'Timeout to active (ms)',
    description: 'Timeout to active (ms)',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    width: 120,
    editable: true,
  },
  {
    field: 'timeoutRemoveMs',
    headerName: 'Timeout to remove (ms)',
    description: 'Timeout to remove (ms)',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    width: 120,
    editable: true,
  },
  ...(engineerOrAdmin(role) && !process.env.VITE_READ_ONLY_MODE
    ? [
        {
          field: 'actions',
          type: 'actions' as GridColType,
          headerName: 'Actions',
          description: 'Actions',
          width: 120,
          cellClassName: 'actions',
          getActions: ({ id }: GridRowParams<{ id: string }>) => [
            // biome-ignore lint/correctness/useJsxKeyInIterable: safe
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              className='textPrimary'
              onClick={handleEditClick(id)}
              color='inherit'
              disabled={rowSelectionModel.length > 1}
            />,
            // biome-ignore lint/correctness/useJsxKeyInIterable: safe
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Delete'
              onClick={handleDeleteClick(id)}
              color='inherit'
              disabled={!(rowSelectionModel.length < 2 && isAdmin(role))}
            />,
          ],
        } as GridBaseColDef,
      ]
    : []),
];

export default prepConfigColumns;
