import { NavLink } from 'react-router';
import useLayoutPermission from '@src/hooks/useLayoutPermission';
import { ConfigFeature } from '@src/types/LayoutPermission';

const configNavItems: Array<{
  title: string;
  feature: ConfigFeature;
  link: string;
}> = [
  {
    title: 'Application',
    feature: 'applications',
    link: 'applications',
  },
  {
    title: 'Stack',
    feature: 'stack',
    link: 'stacks',
  },
  {
    title: 'Modes',
    feature: 'modes',
    link: 'modes',
  },
  {
    title: 'Tesla Config',
    feature: 'tsc-config',
    link: 'tsc-config',
  },
  {
    title: 'Alerts',
    feature: 'alerts',
    link: 'alerts',
  },
];

function TopBar() {
  const { configFeatures } = useLayoutPermission();

  return (
    <div className='topbar'>
      <div className='frame'>
        {configNavItems.map(({ feature, title, link }) =>
          configFeatures.includes(feature) ? (
            <NavLink
              key={feature}
              title={title}
              to={`/home/config/${link}`}
              className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
            >
              {title}
            </NavLink>
          ) : null,
        )}
      </div>
    </div>
  );
}

export default TopBar;
