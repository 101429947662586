import { useEffect, useState } from 'react';
import { Link } from 'react-router';
import './index.scoped.scss';
import ApplicationItem from '@components/Home/Config/ApplicationItem/ApplicationItem';
import ApplicationSettings from '@components/Home/Config/ApplicationSettings/ApplicationSettings';
import Spinner from '@components/_elements/Spinner/Spinner';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';
import { engineerOrAdmin } from '@src/services/auth';

const CreateStackPage = ({ actions, siteMeta, match, history, role }) => {
  const [stackApplications, setStackApplications] = useState([]);
  const [allApplications, setAllApplications] = useState({});
  const [showAllAppsList, setShowAllAppsList] = useState(true);
  const [editingAppId, setEditingAppId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stackName, setStackName] = useState(null);
  const [stack, setStack] = useState({});
  const [usingModes, setUsingModes] = useState([]);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    (async () => {
      const allApplications = await actions.loadApplications(siteMeta.siteId);
      if (match.params.stackID !== 'create') {
        let stackName = '';
        let res = await actions.getStack(siteMeta.siteId, match.params.stackID);
        let stackApplications = [];
        let usingModes = [];
        if (res && !res.error) {
          stackApplications = res.applications;
          stackName = res.name;
          usingModes = stackApplications.map((el) => el.description);
        } else {
          res = {};
        }
        setStackName(stackName || '');
        setEdited(false);
        setStackApplications(stackApplications);
        setStack(res);
        setUsingModes(usingModes);
      }
      setAllApplications(allApplications);
      setLoading(false);
    })();
  }, []);

  const editClicked = (id) => {
    setEditingAppId(id);
    setShowAllAppsList(false);
  };
  const closeClicked = () => {
    setEditingAppId(null);
    setShowAllAppsList(true);
  };

  const updateStackName = (value) => {
    setEdited(true);
    setStackName(JSON.parse(JSON.stringify(value)));
  };

  const upClicked = (id) => {
    const updatedAppList = [...stackApplications];
    for (let i = 0; i < updatedAppList.length; i += 1) {
      if (updatedAppList[i].id === id) {
        const toDown = updatedAppList[i - 1];
        updatedAppList[i - 1] = updatedAppList[i];
        updatedAppList[i] = toDown;
      }
    }
    setEdited(true);
    setStackApplications(updatedAppList);
  };
  const addClicked = (id) => {
    const allApplicationsCopy = { ...allApplications };
    const app = { ...allApplications[id], id };
    const newAppOfStack = [...stackApplications];
    const usingModesCopy = [...usingModes];

    newAppOfStack.unshift(app);
    usingModesCopy.push(app.description);

    delete allApplicationsCopy[id];
    setEdited(true);
    setStackApplications(newAppOfStack);
    setAllApplications(allApplicationsCopy);
    setUsingModes(usingModesCopy);
  };
  const minusCLicked = (id) => {
    const usingModesCopy = [...usingModes];
    const allApplicationsCopy = { ...allApplications };
    const stackApplicationsCopy = stackApplications.filter(
      (app) => app.id !== id,
    );
    allApplicationsCopy[id] = stackApplications.find((app) => app.id === id);
    usingModesCopy.splice(
      usingModesCopy.indexOf(allApplicationsCopy[id].description),
      1,
    );

    delete allApplicationsCopy[id].id;
    setEdited(true);
    setStackApplications(stackApplicationsCopy);
    setAllApplications(allApplicationsCopy);
    setUsingModes(usingModesCopy);
  };
  const saveStack = async () => {
    setLoading(true);
    const stacks = await actions.loadStacks(siteMeta.siteId);
    const stackNames = Object.values(stacks)
      .map((el) => el.name)
      .filter(() => {
        if (stack) {
          return stack.name !== stackName;
        }
        return true;
      });
    if (stackNames.includes(stackName)) {
      setLoading(false);
      actions.notifyError(`name ${stackName} is already in use`);
    } else {
      const stackToSave = { ...stack };
      stackToSave.applications = Object.values(stackApplications).map(
        (app) => app.id,
      );
      stackToSave.name = stackName;
      actions
        .saveStack(
          siteMeta.siteId,
          match.params.stackID !== 'create' ? match.params.stackID : null,
          stackToSave,
        )
        .then(() => {
          setLoading(false);
          history.push('/home/config/stacks/');
        });
    }
  };

  return (
    <>
      {loading && <Spinner cover='container' />}
      {!loading && (
        <div className='create-stack'>
          <div className='title-stack'>
            <div className='name'>Stack name:</div>
            <input
              className={'text' + (stackName === '' ? ' invalid-input' : '')}
              type='text'
              id='input'
              disabled={editingAppId}
              placeholder='Enter Stack name'
              defaultValue={stackName}
              onChange={(e) => updateStackName(e.target.value)}
            />
            <div
              className={
                engineerOrAdmin(role) && !editingAppId ? 'button-row' : ''
              }
            >
              {engineerOrAdmin(role) && !editingAppId && (
                <>
                  <Button
                    disabled={!stackName || !edited}
                    onClick={() => saveStack()}
                  >
                    Save
                  </Button>
                  <Link to='/home/config/stacks/'>
                    <Button>Back</Button>
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className='stack-container'>
            <div className='stack-list'>
              <div className='stack-list-name'>Applications of Stack:</div>
              <div className='applications'>
                {stackApplications &&
                  stackApplications.map((app, index) => {
                    return (
                      <ApplicationItem
                        activeItem={app.id === editingAppId}
                        name={app.name}
                        description={app.description}
                        key={`app-${app.id}`}
                        id={app.id}
                        symbol={'minus'}
                        movable={!!index}
                        onUpClick={(id) => upClicked(id)}
                        minusOnClick={(id) => {
                          minusCLicked(id);
                        }}
                        editOnClick={(id) => {
                          editClicked(id);
                        }}
                        closeOnClick={() => closeClicked()}
                      />
                    );
                  })}
              </div>
            </div>
            {showAllAppsList && (
              <div className='stack-list'>
                <div className='stack-list-name'>Application List:</div>
                <div className='applications'>
                  {Object.keys(allApplications).map((id) => {
                    return (
                      <ApplicationItem
                        isUsing={usingModes.includes(
                          allApplications[id].description,
                        )}
                        name={allApplications[id].name}
                        id={id}
                        key={`app-${id}`}
                        symbol={'plus'}
                        description={allApplications[id].description}
                        addOnClick={(id) => addClicked(id)}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {editingAppId && !showAllAppsList && (
              <div
                className='app-settings-stack-wrap'
                style={{ position: 'relative', width: '' }}
              >
                <ApplicationSettings
                  paramsId={editingAppId}
                  closeOnClick={() => closeClicked()}
                  key={editingAppId}
                  appInStackEdit={true}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default storeConnector(CreateStackPage, {
  service: ['history'],
  user: ['role'],
  config: ['siteMeta'],
});
