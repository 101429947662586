import { MqttClient } from 'mqtt';
import { useEffect, useRef, useState } from 'react';
import { SiteMeta } from '@src/types/SiteMeta';

function usePingTopic(siteMeta: SiteMeta, unitId?: string) {
  const [topic, setTopic] = useState<string | null>(null);
  const { siteId } = siteMeta;

  useEffect(() => {
    setTopic(
      unitId
        ? `ems/site/${siteId}/unit/${unitId}/root/kW/float`
        : `ems/site/${siteId}/root/LocRemCtl/string`,
    );
  }, [siteId, unitId]);

  return topic;
}

function useIsNoComms(
  siteMeta: SiteMeta,
  clientMQTT: MqttClient | undefined,
  unitId?: string,
) {
  const topic = usePingTopic(siteMeta, unitId);
  const [dataLoss, setDataLoss] = useState<boolean>(false);
  const lastMessageTimestamp = useRef<number | null>(null);
  const { dataRateTimeoutSeconds } = siteMeta.ui;

  useEffect(() => {
    if (!clientMQTT) {
      return () => {};
    }
    const onMessage = (brokerTopic: string) => {
      if (brokerTopic === topic) {
        lastMessageTimestamp.current = new Date().getTime();
      }
    };

    if (topic) {
      clientMQTT.on('message', onMessage);
      clientMQTT.subscribe([topic]);

      return () => {
        clientMQTT.removeListener('message', onMessage);
        clientMQTT.unsubscribe([topic]);
      };
    }

    return undefined;
  }, [topic, clientMQTT]);

  useEffect(() => {
    const checkDataLossInterval = setInterval(() => {
      if (lastMessageTimestamp.current === null) {
        lastMessageTimestamp.current = new Date().getTime();
      } else {
        setDataLoss(
          dataRateTimeoutSeconds !== undefined &&
            dataRateTimeoutSeconds > 0 &&
            new Date().getTime() - lastMessageTimestamp.current >
              dataRateTimeoutSeconds * 1000,
        );
      }
    }, 1000);

    return () => clearInterval(checkDataLossInterval);
  }, [dataRateTimeoutSeconds]);

  return dataLoss;
}

export default useIsNoComms;
