import SiteMetaEditor from '@components/_shared/SiteMetaEditor';

function SiteMetaEditorPage() {
  return (
    <div className='home-row frow' id='alerts-config-wrapper'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <SiteMetaEditor />
        </div>
      </div>
    </div>
  );
}

export default SiteMetaEditorPage;
