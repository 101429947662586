import useApiMutation from '@src/hooks/useApiMutation';

export default (id?: string) => {
  const widgetPath = `/widgets${id ? `/${id}` : ''}`;
  const createWidgetConfig = useApiMutation({
    apiPath: widgetPath,
    method: 'POST',
    useV2API: true,
  });

  const updateWidgetConfig = useApiMutation({
    apiPath: widgetPath,
    method: 'PATCH',
    useV2API: true,
  });

  const deleteWidgetConfig = useApiMutation({
    apiPath: widgetPath,
    method: 'DELETE',
    useV2API: true,
  });

  return {
    createWidgetConfig,
    updateWidgetConfig,
    deleteWidgetConfig,
  };
};
