import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { CollectionPayload, CollectionTypes } from '@src/types/Collection';
import { PointConfigEdit } from '@src/types/PointConfig';
import {
  DeviceListDeviceRefsAll,
  WidgetCreate,
  WidgetTypes,
} from '@src/types/Widget';
import React, { Dispatch, SetStateAction } from 'react';
import collectionSx from './configs/CollectionSx';
import GenerateDevicesButton from './Devices/GenerateDevicesButton';

interface ToolbarProps {
  collectionType?: CollectionTypes;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
}

const baseAddPointConfig: PointConfigEdit = {
  pointConfigs: [
    {
      pointAlias: '',
      deviceType: '',
      displayName: '',
      pointName: '',
      dataType: '',
      isSiteControllerSourceDevicePointConfig: false,
      decimalPrecision: 0,
      scalingFactor: 1,
      invertDisplay: false,
      unitOfMeasurement: '',
      displayDenominator: '',
      valueMappings: {},
    },
  ],
};

const baseAddWidgetConfig: WidgetCreate = {
  widgets: [
    {
      type: WidgetTypes.DEVICE_LIST,
      internalId: '',
      name: '',
      isSystemWidget: true,
      header: '',
      pointConfigRefs: [{ pointAlias: 'somePoint' }],
      deviceType: 'unit', // legacy name: 'category'
      deviceRefs: DeviceListDeviceRefsAll.ALL,
    },
  ],
};

function CollectionToolbar({
  collectionType,
  setOpen,
  setJsonPayload,
}: ToolbarProps): React.ReactElement {
  const handleOpenAdd = () => {
    const baseConfig =
      collectionType === CollectionTypes.POINT_CONFIGS
        ? baseAddPointConfig
        : baseAddWidgetConfig;
    setOpen(true);
    setJsonPayload(baseConfig);
  };

  return (
    <GridToolbarContainer sx={collectionSx.collectionToolbar}>
      <div>
        {collectionType === CollectionTypes.DEVICES && (
          <GenerateDevicesButton
            setJsonPayload={setJsonPayload}
            setOpen={setOpen}
          />
        )}
        {(collectionType === CollectionTypes.POINT_CONFIGS ||
          collectionType === CollectionTypes.WIDGETS) && (
          <Button startIcon={<AddIcon />} onClick={handleOpenAdd}>
            Add config
          </Button>
        )}
      </div>

      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarQuickFilter
          sx={collectionSx.collectionToolbar.quickFilter}
        />
      </div>
    </GridToolbarContainer>
  );
}

export default CollectionToolbar;
