import { UnitConfig } from '@src/sld/types';
import { DataPointWithValue } from '@src/types/DataPoint';

const noData = 'missing data';

const unitConfigWithNoData: UnitConfig = {
  unitLabel: noData,
  kw: noData,
  kvar: noData,
  soc: noData,
  racksonline: noData,
  invstatus: noData,
  runmode: noData,
};

const getUnitConfig = (unitConfigItem: UnitConfig): UnitConfig =>
  unitConfigItem !== undefined ? unitConfigItem : unitConfigWithNoData;

const checkIfDataPointsDefined = (
  dataPoints: DataPointWithValue[] | undefined,
): boolean => (dataPoints && dataPoints.length > 0 ? true : false);

const getBreakerStatusColor = (
  breakerStatus: DataPointWithValue | undefined,
): string =>
  breakerStatus ? (breakerStatus.value === 1 ? 'red' : 'green') : 'gray';

const getDataPointByPointName = (
  dataPoints: DataPointWithValue[] | undefined,
  pointName: string,
): DataPointWithValue | undefined =>
  dataPoints?.find(
    (dataPoint) =>
      dataPoint.pointName.toLowerCase() === pointName.toLowerCase(),
  );

export {
  getUnitConfig,
  checkIfDataPointsDefined,
  getBreakerStatusColor,
  getDataPointByPointName,
};
