import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import { MuiLicenseKey } from '@utils/index_ts';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'rc-time-picker/assets/index.css';
import 'rc-calendar/assets/index.css';
import '@assets/App.scss';

import AppRouter from './AppRouter';

LicenseInfo.setLicenseKey(MuiLicenseKey);

createRoot(document.getElementById('root')!).render(<AppRouter />);
