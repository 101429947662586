import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { sleep } from '@utils/index_ts';
import { useState, useEffect, useMemo } from 'react';
import { Actions } from '@src/types/Actions';
import { ControlMode } from '@src/types/control-modes/ControlModes';
import {
  EnabledModesState,
  ModeConfig,
  ModeConfigState,
  ModeStatus,
} from '@src/types/control-modes/Modes';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import Switch from 'react-switch';
import './Modes.scoped.scss';
import ModesMetadata from '@src/components/Home/Config/Modes/ModesMetadata';
import {
  convertControlModesToTopicFields,
  convertTopicFieldToControlMode,
  getSCADAStatus,
  parseConfigPayload,
} from '@src/components/Home/Config/Modes/modes-utils';
import ModeStatusIcon from '@src/components/Home/Config/Modes/ModeStatusIcon';
import ConfirmScadaPopupButton from '@src/components/Home/Config/Modes/ModesPopupButtons/ConfirmScadaPopupButton';
import ModeRow from '@src/components/Home/Config/Modes/ModeRow';
import useSendControllersConfig from '@hooks/api/mutations/useSendControllersConfig';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import { ServiceState } from '@src/store/reducers/serviceData';
import { Tooltip, Box } from '@mui/material';

interface ModesProps {
  actions: Actions;
  siteMeta: SiteMeta;
  role: UserRole;
  isControlsLocked: ServiceState['isControlsLocked'];
}

function Modes({ actions, siteMeta, role, isControlsLocked }: ModesProps) {
  const modesMetadata = ModesMetadata;
  const { controlsLockConfig } = siteMeta.ui;
  const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;

  const controlModes: ControlMode[] = useMemo(
    () => siteMeta.ControlModes.filter((mode) => modesMetadata[mode]),
    [siteMeta, modesMetadata],
  );

  const initEnabledModes = controlModes.reduce<EnabledModesState>(
    (acc, mode) => {
      acc[mode] = ModeStatus.Pending;
      acc[`${mode}_Fractal`] = ModeStatus.Pending;
      acc[`${mode}_SCADA`] = ModeStatus.Pending;
      return acc;
    },
    {} as EnabledModesState,
  );

  const [enabledModes, setEnabledModes] = useState(initEnabledModes);
  const [modeConfigs, setModeConfigs] = useState({} as ModeConfigState);
  const [SCADAMode, setSCADAMode] = useState(ModeStatus.Pending);
  const [SCADAModeToggle, setSCADAModeToggle] = useState(ModeStatus.Pending);
  const [isSCADASwitchDirty, setIsSCADASwitchDirty] = useState(false);

  const commandAccessEnabled = isCommandsEnabled();

  const { mutateAsync: sendControllerConfig } = useSendControllersConfig();

  const parseConfigResponseFromAPI = (
    configResponse: ModeConfig[],
  ): ModeConfigState =>
    configResponse.reduce((acc: ModeConfigState, config: ModeConfig) => {
      if (config.description) {
        acc[config.description] = config;
      }
      return acc;
    }, {} as ModeConfigState);

  const fields = useMemo(
    () => [
      'ControlMode_Authority',
      ...convertControlModesToTopicFields(controlModes, true),
    ],
    [controlModes],
  );

  useWsSubscribe({
    site: {
      fields,
      cb: (data: Record<string, number>) => {
        const key = Object.keys(data)[0];
        if (key === 'ControlMode_Authority') {
          const status = getSCADAStatus(data[key]);
          setSCADAMode(status);
        } else {
          setEnabledModes((prevModes: EnabledModesState) => ({
            ...prevModes,
            [convertTopicFieldToControlMode(key)]: data[key],
          }));
        }
      },
    },
  });

  const fetchAndSetConfigs = async () => {
    const configResponse: ModeConfig[] = await actions.getSystemConfig(
      siteMeta.siteId,
      Object.keys(modesMetadata),
    );
    const modeConfigState = parseConfigResponseFromAPI(configResponse);
    setModeConfigs(modeConfigState);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    (async () => {
      await fetchAndSetConfigs();
    })();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (!isSCADASwitchDirty) {
      setSCADAModeToggle(SCADAMode);
    }
  }, [SCADAMode]);

  const confirmConfigUpdate = async (
    mode: ControlMode,
    config: ModeConfig,
  ): Promise<void> => {
    if (modesMetadata[mode].configFields[0]?.configParentProp) {
      const actualConfigPayload = {
        configBody: config.body,
        modeName: mode,
        siteId: siteMeta.siteId,
      };

      const siteMetaConfigPayload = {
        configBody: siteMeta,
        modeName: mode,
        siteId: siteMeta.siteId,
      };

      await actions.updateSystemConfig(
        siteMeta.siteId,
        mode,
        parseConfigPayload(config),
      );

      await sendControllerConfig(actualConfigPayload, {
        onError: (error: Error) => {
          const customError = error.cause as { error?: { message: string } };
          actions.notifyError(
            customError?.error?.message ??
              'Failed to send mode config to controller.',
          );
        },
      });

      // Necessary 1s wait for controllers to receive and process previous config
      await sleep(1000);

      await sendControllerConfig(siteMetaConfigPayload, {
        onError: (error: Error) => {
          const customError = error.cause as { error?: { message: string } };
          actions.notifyError(
            customError?.error?.message ??
              'Failed to send site-meta to controller.',
          );
        },
      });

      actions.notify('Update controller config command sent.');
      await fetchAndSetConfigs();
    }
  };

  const handleSCADAToggle = (value: boolean) => {
    setIsSCADASwitchDirty(true);
    setSCADAModeToggle(value ? ModeStatus.Enabled : ModeStatus.Disabled);
  };

  return (
    <div className='home-row frow'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <div className='modes-container'>
            <div className='modes-row' style={{ margin: '8px 0' }}>
              <div className='modes-cell' style={{ justifyContent: 'right' }}>
                <span style={{ marginRight: '20px', fontSize: '20px' }}>
                  <b>SCADA Mode:</b>
                </span>
              </div>
              <div
                className='modes-cell'
                style={{ flex: 1, justifyContent: 'center' }}
              >
                <ModeStatusIcon status={SCADAMode} />
              </div>
              <div
                className='modes-cell'
                style={{ flex: 1, justifyContent: 'center' }}
              >
                {siteMeta.ui.features?.SCADA_Controls_Disabled ? (
                  ''
                ) : (
                  <Tooltip
                    title={isControlsLocked ? tooltipTitle : ''}
                    placement='top'
                    disableHoverListener={!isControlsLocked}
                  >
                    <Box>
                      <Switch
                        name='SCADAMode'
                        height={20}
                        width={40}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor='#fa9632'
                        checked={SCADAModeToggle === ModeStatus.Enabled}
                        onChange={handleSCADAToggle}
                        disabled={
                          !commandAccessEnabled ||
                          isControlsLocked ||
                          !isActionRole(role)
                        }
                      />
                    </Box>
                  </Tooltip>
                )}
              </div>
              <div
                className='modes-cell'
                style={{ flex: 1, justifyContent: 'center' }}
              >
                {siteMeta.ui.features?.SCADA_Controls_Disabled ||
                !commandAccessEnabled ||
                !isActionRole(role) ? (
                  ''
                ) : (
                  <Tooltip
                    title={isControlsLocked ? tooltipTitle : ''}
                    placement='top'
                    disableHoverListener={!isControlsLocked}
                  >
                    <Box>
                      <ConfirmScadaPopupButton
                        clearSCADASwitchState={() =>
                          setIsSCADASwitchDirty(false)
                        }
                        SCADAModeToggle={SCADAModeToggle}
                        disabled={isControlsLocked}
                      />
                    </Box>
                  </Tooltip>
                )}
              </div>
              <div className='modes-cell' style={{ flex: 7 }} />
            </div>
            <hr />
            <div className='modes-row' style={{ margin: '5px 0' }}>
              <div className='modes-cell' />
              <div
                className='modes-cell'
                style={{ flex: 1, justifyContent: 'center' }}
              >
                <b>
                  Active
                  <br />
                  Status
                </b>
              </div>
              <div
                className='modes-cell'
                style={{ flex: 1, justifyContent: 'center' }}
              >
                <b>
                  HMI
                  <br />
                  Status
                </b>
              </div>
              <div
                className='modes-cell'
                style={{ flex: 1, justifyContent: 'center' }}
              >
                <b>
                  SCADA
                  <br />
                  Status
                </b>
              </div>
              <div
                className='modes-cell'
                style={{ flex: 1, justifyContent: 'center' }}
              >
                {commandAccessEnabled ? (
                  <b>
                    Enable / <br />
                    Disable
                  </b>
                ) : (
                  ''
                )}
              </div>

              <div
                className='modes-cell'
                style={{ flex: 8, justifyContent: 'center' }}
              >
                <b>Quick Config</b>
              </div>
              <div className='modes-cell' />
            </div>
            {controlModes.map((mode) => (
              <ModeRow
                config={modeConfigs[mode]}
                status={enabledModes[mode]}
                fractalStatus={enabledModes[`${mode}_Fractal`]}
                SCADAStatus={enabledModes[`${mode}_SCADA`]}
                key={mode}
                mode={mode}
                SCADAMode={SCADAMode === ModeStatus.Enabled}
                submitForm={confirmConfigUpdate}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default storeConnector(Modes, {
  user: ['username', 'role'],
  config: ['siteMeta'],
  service: ['isControlsLocked'],
});
