import {
  ModeConfig,
  ModeConfigState,
  ConfigField,
  ConfigFormData,
  ExecutionsSettingsFormData,
  ModeMetadataContent,
  ParsedInputName,
} from '@src/types/control-modes/Modes';
import { ControlMode } from '@src/types/control-modes/ControlModes';

export const buttonStyles = {
  margin: '2px',
  fontWeight: 700,
  textDecoration: 'none',
  borderRadius: '0',
  paddingLeft: '5px',
  paddingRight: '5px',
  background: '#d7d7d7',
};

export const convertControlModesToTopicFields = (
  controlModes: ControlMode[],
  subToAll = false,
) =>
  controlModes.reduce((acc: string[], controlMode) => {
    acc.push(`Disable_${controlMode}`);

    if (subToAll) {
      acc.push(`Disable_${controlMode}_Fractal`);
      acc.push(`Disable_${controlMode}_SCADA`);
    }

    return acc;
  }, []);

export const convertTopicFieldToControlMode = (topicField: string) =>
  topicField.replace('Disable_', '') as ControlMode;

export const getInputValue = (
  formData: ConfigFormData,
  idx: number,
  input: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (formData as any)[`row${idx + 1}Input${input}`];

type StringRecord = Record<string, string>;

const updateExecutionSettingData = (
  configData: ModeConfig['body'],
  executionSettingsFormData: ExecutionsSettingsFormData,
) => {
  const configDataCopy = { ...configData };
  if (executionSettingsFormData.additive.length !== 0) {
    (configDataCopy['Execution Settings'] as Record<string, string>).Additive =
      executionSettingsFormData.additive;
  }
  if (executionSettingsFormData.dataSource.length !== 0) {
    (configDataCopy['Execution Settings'] as Record<string, string>)[
      'Data Source'
    ] = executionSettingsFormData.dataSource;
  }
  // if (executionSettingsFormData.enabled.length !== 0)
  //   (configDataCopy['Execution Settings'] as Record<string, string>).Enabled =
  //     executionSettingsFormData.enabled;
  if (executionSettingsFormData.priority.length !== 0) {
    (configDataCopy['Execution Settings'] as Record<string, string>).Priority =
      executionSettingsFormData.priority;
  }
  return configDataCopy;
};

export const getUpdatedConfigData = (
  // eslint-disable-next-line max-params
  config: ModeConfig,
  formData: ConfigFormData,
  modeQuickConfig: ConfigField[],
  executionSettingsFormData?: ExecutionsSettingsFormData,
) => {
  const configData: ModeConfig = { ...config };

  if (
    executionSettingsFormData &&
    Object.keys(executionSettingsFormData).length > 0
  ) {
    configData.body = updateExecutionSettingData(
      configData.body,
      executionSettingsFormData,
    );
  }

  modeQuickConfig.forEach((configField: ConfigField, idx: number) => {
    if (
      configField.configParentProp &&
      typeof configData.body[configField.configParentProp] === 'object'
    ) {
      if (configField.configInput1?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput1.label
        ] = getInputValue(formData, idx, 1);
      }
      if (configField.configInput2?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput2.label
        ] = getInputValue(formData, idx, 2);
      }
      if (configField.configInput3?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput3.label
        ] = getInputValue(formData, idx, 3);
      }
      if (configField.configInput4?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput4.label
        ] = getInputValue(formData, idx, 4);
      }
      if (configField.configInput5?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput5.label
        ] = getInputValue(formData, idx, 5);
      }
      if (configField.configInput6?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput6.label
        ] = getInputValue(formData, idx, 6);
      }
      if (configField.configInput7?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput7.label
        ] = getInputValue(formData, idx, 7);
      }
      if (configField.configInput8?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput8.label
        ] = getInputValue(formData, idx, 8);
      }
      if (configField.configInput9?.label) {
        (configData.body[configField.configParentProp] as StringRecord)[
          configField.configInput9.label
        ] = getInputValue(formData, idx, 9);
      }
    }
  });
  return configData;
};

export const parseConfigPayload = (
  config: ModeConfig | undefined | null,
): ModeConfigState => {
  if (!config || Object.keys(config).length === 0) {
    return {} as ModeConfigState;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const configCopy: any = config;
  return Object.keys(configCopy)
    .filter(
      (key) =>
        ![
          'id',
          'deviceId',
          'description',
          'updatedAt',
          'updatedBy',
          'version',
        ].includes(key),
    )
    .reduce((obj: ModeConfigState, key: string) => {
      // eslint-disable-next-line no-param-reassign
      obj[key as ControlMode] = configCopy[key];
      return obj;
    }, {} as ModeConfigState);
};

export const parseFormDataFromConfig = (
  config: ModeConfig | undefined,
  modeMetadata: ModeMetadataContent,
): ConfigFormData => {
  const formData: ConfigFormData = {} as ConfigFormData;
  modeMetadata.configFields.forEach((configField: ConfigField, idx: number) => {
    if (config && configField.configParentProp) {
      if (configField.configInput1?.label) {
        formData[`row${idx + 1}Input1` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput1.label
          ] || 'Config Input 1';
      }
      if (configField.configInput2?.label) {
        formData[`row${idx + 1}Input2` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput2.label
          ] || 'Config Input 2';
      }
      if (configField.configInput3?.label) {
        formData[`row${idx + 1}Input3` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput3.label
          ] || 'Config Input 3';
      }
      if (configField.configInput4?.label) {
        formData[`row${idx + 1}Input4` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput4.label
          ] || 'Config Input 4';
      }
      if (configField.configInput5?.label) {
        formData[`row${idx + 1}Input5` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput5.label
          ] || 'Config Input 5';
      }
      if (configField.configInput6?.label) {
        formData[`row${idx + 1}Input6` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput6.label
          ] || 'Config Input 6';
      }
      if (configField.configInput7?.label) {
        formData[`row${idx + 1}Input7` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput7.label
          ] || 'Config Input 7';
      }
      if (configField.configInput8?.label) {
        formData[`row${idx + 1}Input8` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput8.label
          ] || 'Config Input 8';
      }
      if (configField.configInput9?.label) {
        formData[`row${idx + 1}Input9` as keyof ConfigFormData] =
          config.body[configField.configParentProp]?.[
            configField.configInput9.label
          ] || 'Config Input 9';
      }
    }
  });
  return formData;
};

export const parseFormDataFromInput = (
  parsedInputName: ParsedInputName,
  value: string,
): ConfigFormData => {
  const formData = {
    [`row${parsedInputName.idx + 1}Input${parsedInputName.input}`]: value,
  } as unknown;
  return formData as ConfigFormData;
};

export const validateInputNumber = (
  inputToValidate: string,
  rangeString: string,
) => {
  const valueNum = Number(inputToValidate);
  let isRangeValid;
  const parsedRange: number[] = rangeString
    .split(',')
    .sort()
    .map(Number)
    .filter((e) => !Number.isNaN(e));

  if (parsedRange[0] !== undefined && parsedRange[1] !== undefined) {
    isRangeValid = valueNum >= parsedRange[0] && valueNum <= parsedRange[1];
  }

  if (Number.isNaN(valueNum)) {
    return 'Input must be number';
  }

  if (rangeString && !isRangeValid) {
    return `Input must be between ${parsedRange[0]} and ${parsedRange[1]}`;
  }

  return '';
};

export const getSCADAStatus = (value: number | null) => {
  switch (value) {
    case 0:
      // 0 = fractal control = SCADA is disabled (1 for disabled indicator)
      return 1;
    case 1:
      // 1 = SCADA control = SCADA is enabled (0 for enabled indicator)
      return 0;
    default:
      // set to pending status (2 for pending indicator) with null or err values
      return 2;
  }
};
