import { useRouteError } from 'react-router';
import './index.scoped.scss';
import Windmill from '@components/_elements/Windmill/Windmill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

interface PageErrorProps {
  message?: string;
}

export default function PageError({
  message = '500 - Something went wrong',
}: PageErrorProps) {
  const error = useRouteError();
  if (error !== undefined) {
    console.debug(error);
  }
  return (
    <div className='page-not-found'>
      <Windmill opacity='0.7' bottom='30px' left='20%' scale='1.2' />
      <Windmill opacity='0.5' bottom='-100px' left='15%' scale='0.6' />
      <div className='info'>
        <div className='first-line'>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </div>
        <div className='second-line'>{message}</div>
      </div>
    </div>
  );
}

export function TechnicalWorks() {
  return <PageError message='Technical works...' />;
}
export function PageNotFound() {
  return <PageError message='404 - Page not found' />;
}
