import { Layouts } from 'react-grid-layout';

export enum DefaultTemp {
  C = 'C',
  F = 'F',
}

export interface SiteMetaUI {
  readonly Meter_Point_Dictionary?: Record<string, string>;
  readonly Use_New_Topic_Structure?: boolean;

  // Rack table points
  readonly rackPointNames?: string[];

  // Rack Modal points
  readonly rackPopupPointNames?: string[];

  // Allow access to send commands
  readonly Command_Access_Enable: boolean;

  // Enable Notes Feature
  readonly Notes_Enable: boolean;

  // Controls Lock Config
  readonly controlsLockConfig: {
    pointName: string;
    topic: string;
    thresholdMax: number;
  };

  //  IncidentCommand Command Custom Page
  readonly Incident_Command_Enable?: boolean;

  readonly Auto_Command_Persist: string;

  readonly Battery_Enable?: boolean;
  readonly Sun_Enable?: boolean;
  readonly Mkt_Enable?: boolean;
  readonly History_Enable?: boolean;
  readonly Wind_Enable?: boolean;
  readonly TSC_Config_Enable?: boolean;
  readonly Battery_SYS_Enable?: boolean;
  readonly Battery_PCS_Enable?: boolean;
  readonly Battery_BATT_Enable?: boolean;
  readonly Battery_BOP_Enable?: boolean;
  readonly Battery_SLD_Enable?: boolean;
  readonly Battery_Tesla_Enable?: boolean;
  readonly Battery_Powin_Enable?: boolean;
  readonly TSC_Only_Allow_Admin_Enable_Disable_Commands?: boolean;
  readonly POWIN_Only_Allow_Admin_Enable_Disable_Commands?: boolean;

  readonly Battery_BATT_RackTable_Enable?: boolean;

  readonly Home_Calendar_Enable?: boolean;
  readonly Home_Dashboard_Enable?: boolean;
  readonly Home_Historian_Export_Enable?: boolean;
  readonly Home_Config_Alerts_Enable?: boolean;
  readonly Home_Config_Modes_Enable?: boolean;
  readonly Home_Alerts_Timeline_Enable?: boolean;
  readonly Home_Alerts_Config_Editor_Enable?: boolean;

  readonly TabsList?: TabsList;
  readonly TrackerList?: TrackerList;
  readonly custom_links?: CustomLink[];
  readonly dashboards?: IDashboard[];
  readonly features?: UIFeatures;

  readonly Default_Temp_Unit?: DefaultTemp;
  readonly Home_Overview_Weather_Widget_Enable?: boolean;
  readonly Home_Overview_SLD_Widget_Enable?: boolean;
  readonly WeatherForecast_enable: boolean;

  readonly Alerts_Timeline_Default_Period?: string;

  // useIsNoComms.ts
  readonly dataRateTimeoutSeconds?: number;
  readonly Disable_Controls_Lock?: boolean;
  readonly Modes_Only_UI?: boolean;

  readonly FractalModelURL?: string;
}

export interface UIFeatures {
  readonly Humidity_Gauge_Enabled: boolean;
  readonly SCADA_Controls_Disabled?: boolean;
}

export interface IDashboard {
  readonly id: string;
  readonly name: string;
  readonly layouts: Layouts;
  readonly cells: IDashboardCell[];
}

export interface IDashboardCell {
  readonly variant: string;
  readonly key: string;
  readonly data: IPointsListData;
}

interface ICoreCellData {
  readonly title: string;
}

export interface IPointsListData extends ICoreCellData {
  readonly pointList: IPointsListEntry[];
}

export interface IPointsListEntry {
  readonly topic: string;
  readonly displayName: string;
  readonly valueMap?: Record<string, string>[];
}

export interface CustomLink {
  readonly url: string;
  readonly display_name: string;
}

export enum PVSubPagesEnum {
  SYS = 'sys',
  PCS = 'pcs',
  TRACKERS = 'trackers',
  MET = 'met',
  SLD = 'sld',
}

export interface TrackerList {
  readonly PV?: {
    readonly masterTracker?: string[];
    readonly unitTracker?: string[];
  };
}

export interface TabsList {
  readonly PV: PVSubPagesEnum[];
}
