import { ReactElement } from 'react';
import { AggregationFunction, HistorianQuery, SavedSearch } from '@types';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import Button from '@components/_elements/Button/Button';
import { checkShouldAggr } from '@components/Home/Historian/historianUtils';

interface PlotButtonsProps {
  timeMode: string;
  siteMeta: SiteMeta;
  searchUnderEdit: SavedSearch | undefined;
  activeSearch: SavedSearch;
  runPlot: (q: HistorianQuery) => Promise<void>;
  runAutoPlot: (val: HistorianQuery) => void;
}

function getQuery(p: PlotButtonsProps): HistorianQuery {
  const aggregateFunctions = !checkShouldAggr(p.activeSearch)
    ? p.activeSearch.aggregationFunctions
    : (['mean'] as AggregationFunction[]);
  const criteria = p.activeSearch.dataPoints.map((dp) => ({
    category: dp.category,
    siteId: dp.siteId,
    field:
      typeof dp.dataPoint === 'string' ? dp.dataPoint : dp.dataPoint.pointName,
    axis: dp.axis,
    unitId: dp.unitId,
    sourceDeviceId: dp.sourceDeviceId,
    dataType: typeof dp.dataPoint === 'string' ? '' : dp.dataPoint.dataType,
  }));
  return {
    dateFrom: p.activeSearch.startDateTime.toDate(),
    dateTo: p.activeSearch.stopDateTime.toDate(),
    interval: p.activeSearch.interval,
    aggregateFunctions,
    criteria,
  };
}

function PlotButtons(p: PlotButtonsProps): ReactElement {
  const plotBtnDisabled = p.activeSearch.dataPoints.some((dp) => !dp.category);
  return (
    <div className='btns-row-for-search'>
      <Button
        key='plot-btn'
        data-test='historian-plot-button'
        classType='container-bg'
        size='m'
        disabled={plotBtnDisabled}
        onClick={() => p.runPlot(getQuery(p))}
      >
        PLOT
      </Button>
      <Button
        key='auto_plot_btn'
        classType='container-bg'
        size='m'
        disabled={plotBtnDisabled}
        onClick={() => p.runAutoPlot(getQuery(p))}
      >
        AUTO PLOT
      </Button>
    </div>
  );
}

export default storeConnector(PlotButtons, {
  config: ['siteMeta'],
  service: ['timeMode'],
});
