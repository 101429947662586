import { GenerateDevicePayload } from '@src/types/Device';

const generateDevices: GenerateDevicePayload = {
  siteControllerId: '000000F',
  unitControllers: {
    engineeringIds: ['0000001F', '0000002F'],
    namePrefix: 'Unit ',
    nameSuffix: '',
  },
  sourceDevices: [
    {
      type: 'tsc',
      parentType: 'siteController',
      count: 4,
    },
    {
      type: 'mp',
      parentType: 'tsc',
      count: 4,
      namePrefix: 'Megapack',
    },
    {
      type: 'bms',
      parentType: 'unitController',
      count: 4,
    },
    {
      type: 'rack',
      parentType: 'bms',
      count: 2,
    },
    {
      type: 'meter',
      parentType: 'siteController',
      count: 2,
    },
    {
      type: 'pcs',
      parentType: 'unitController',
      count: 2,
    },
  ],
};

export default generateDevices;
