import useApiMutation from '@src/hooks/useApiMutation';
import {
  PointConfig,
  PointConfigEdit,
  PointConfigEditable,
} from '@src/types/PointConfig';

export default (pointAlias?: string) => {
  const pcPath = `/point-configs${pointAlias ? `/${pointAlias}` : ''}`;
  const createPointConfig = useApiMutation<PointConfigEdit, PointConfig>({
    apiPath: pcPath,
    method: 'POST',
    useV2API: true,
  });

  const updatePointConfig = useApiMutation<PointConfigEditable, PointConfig>({
    apiPath: pcPath,
    method: 'PATCH',
    useV2API: true,
  });

  const deletePointConfig = useApiMutation({
    apiPath: pcPath,
    method: 'DELETE',
    useV2API: true,
  });

  return {
    createPointConfig,
    updatePointConfig,
    deletePointConfig,
  };
};
