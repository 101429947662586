import { dispatcher, store } from '@store';

export default function loadCheckCommandsEnablement() {
  const state = store.getState();
  const client = state.mqtt.clientMQTT;
  if (!client || state.config.siteMeta?.ui?.controlsLockConfig === undefined) {
    return;
  }
  const thresholdMax =
    state.config.siteMeta.ui.controlsLockConfig?.thresholdMax;
  const lockTopic = state.config.siteMeta.ui.controlsLockConfig?.topic ?? '';

  client.subscribe(lockTopic);
  client.on('message', (topic, message) => {
    if (
      topic === lockTopic &&
      thresholdMax !== undefined &&
      !Number.isNaN(Number(thresholdMax))
    ) {
      const lockValue = JSON.parse(message.toString())?.value;
      store.dispatch(
        dispatcher('UPDATE_SERVICE_MODULE', {
          isControlsLocked: lockValue >= thresholdMax,
        }),
      );
    }
  });
}
