import { parsePayload } from '@hooks/useSubscription';
import { store } from '@src/store';
import { extractEngineeringIdFromTopic } from '@utils/generateTopic';
import { MqttClient } from 'mqtt';
import { MutableRefObject, useEffect } from 'react';
import {
  DataPoint,
  DataPointWithValue,
  isDataPointWithValue,
} from '@src/types/DataPoint';
import { DeviceDataTableRow } from '@src/components/_shared/DeviceDataTable';
import { GridApiPro } from '@mui/x-data-grid-pro';

function updateDataPointsWithValues(
  topic: DataPoint['fullTopic'],
  apiRef: MutableRefObject<GridApiPro>,
  value: unknown,
): void {
  const engineeringId = extractEngineeringIdFromTopic(topic);
  if (!engineeringId) {
    return;
  }
  const row = apiRef.current.getRow(engineeringId);
  const prevColumnValueObj = Object.values(row).find<DataPointWithValue>(
    (value: unknown): value is DataPointWithValue =>
      isDataPointWithValue(value) && value.fullTopic === topic,
  );

  if (!prevColumnValueObj) {
    return;
  }

  return apiRef.current?.updateRows([
    {
      id: engineeringId,
      [prevColumnValueObj.pointAlias as string]: {
        ...prevColumnValueObj,
        value,
      },
    },
  ]);
}

function messageHandler(
  topic: string,
  message: Buffer,
  apiRef: MutableRefObject<GridApiPro>,
) {
  const { value } = parsePayload(message, topic);
  updateDataPointsWithValues(topic, apiRef, value);
}

function useGridSubscription(
  rows: DeviceDataTableRow[],
  apiRef: MutableRefObject<GridApiPro>,
  useNewClient?: MqttClient,
) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (rows.length === 0) {
      return () => {};
    }

    const state = store.getState();
    const mqttClient =
      useNewClient !== undefined ? useNewClient : state.mqtt.clientMQTT;

    const topics = rows.reduce<string[]>((acc, curr) => {
      const rowTopics: string[] = [];
      Object.values(curr).forEach((v) =>
        typeof v === 'object' && v !== null && 'fullTopic' in v
          ? rowTopics.push(v.fullTopic)
          : null,
      );

      return acc.concat(rowTopics);
    }, []);

    const onMessageCb = (topic: string, buffer: Buffer) => {
      if (topics.includes(topic)) {
        messageHandler(topic, buffer, apiRef);
      }
    };
    if (mqttClient) {
      mqttClient.on('message', onMessageCb);
      mqttClient.subscribe(topics);
    }
    return () => {
      if (mqttClient) {
        mqttClient.unsubscribe(topics);
        mqttClient.off('message', onMessageCb);
      }
    };
  }, [rows, useNewClient]);
}

export default useGridSubscription;
