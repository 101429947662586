import { useState } from 'react';
import './Popup.scoped.scss';
import cn from 'classnames';
import Popup from './Popup';
import { Button } from 'reactstrap';

const PopupButton = ({
  children,
  disabled = false,
  title,
  content,
  link,
  buttonStyles,
  bodyClass = '',
}) => {
  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);

  return (
    <>
      <Button
        color={link && 'link'}
        disabled={disabled}
        onClick={() => setOpen(!open)}
        className={cn({
          link,
        })}
        style={buttonStyles}
      >
        {children}
      </Button>
      <Popup isOpen={open} title={title} close={close} bodyClass={bodyClass}>
        {typeof content === 'function' ? content(close) : content}
      </Popup>
    </>
  );
};

export default PopupButton;
