import './index.scoped.scss';
import ApplicationSettings from '@components/Home/Config/ApplicationSettings/ApplicationSettings';
import storeConnector from '@store/storeConnector';

const ApplicationAddEdit = ({ match }) => {
  return (
    <div className='add-edit-container'>
      <div className='app-settings'>
        <ApplicationSettings
          showtitle={true}
          paramsId={match.params.name || null}
        />
      </div>
    </div>
  );
};

export default storeConnector(ApplicationAddEdit, {});
