import { NavLink } from 'react-router';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';

function TopBar({ siteMeta }: { siteMeta: SiteMeta }) {
  return (
    <div className='topbar'>
      <div className='frame'>
        <NavLink
          to='/home/historian/data'
          className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
        >
          Historian
        </NavLink>
        {siteMeta.ui.Home_Historian_Export_Enable ? (
          <NavLink
            data-test='historian-export'
            to='/home/historian/export'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            Data Export
          </NavLink>
        ) : (
          ''
        )}
      </div>
      <div />
    </div>
  );
}

export default storeConnector(TopBar, {
  config: ['siteMeta'],
});
