import { PointConfigExtended } from '@src/types/PointConfig';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import storeConnector from '@store/storeConnector';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColType,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import useGetPointConfigs from '@src/hooks/api/queries/collections/useGetPointConfigs';
import { CollectionPayload, CollectionTypes } from '@src/types/Collection';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { GridBaseColDef } from '@mui/x-data-grid/internals';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import deviceSx from '@src/components/Home/Collections/configs/DeviceSx';
import getCollectionsInitialState from '@src/components/Home/Collections/utils/getCollectionsInitialState';
import CollectionToolbar from '@src/components/Home/Collections/CollectionToolbar';
import PointConfigDialog from '@src/components/Home/Collections/PointConfigs/PointConfigDialog';
import pointConfigBaseColumns from '@src/components/Home/Collections/configs/pointConfigBaseColumns';
import CollectionWrapper from '../CollectionWrapper';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    collectionType?: CollectionTypes;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
  }
}

function PointConfigCollection({ role }: { role: UserRole }) {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [rows, setRows] = useState<PointConfigExtended[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const apiRef = useGridApiRef();

  const [jsonPayload, setJsonPayload] = useState<CollectionPayload | null>(
    null,
  );
  const { data, isFetching, isPending, isSuccess, error, isRefetching } =
    useGetPointConfigs();

  const handleClose = (
    _: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setConfirmOpen(false);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    if (apiRef.current !== undefined) {
      setJsonPayload(apiRef.current.getRow(id) as CollectionPayload);
    }
    setOpen(true);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (apiRef.current !== undefined) {
      setJsonPayload({
        ...(apiRef.current.getRow(id) as CollectionPayload),
        remove: true,
      });
    }
    setOpen(true);
  };

  const handleCancelClick = (id?: GridRowId) => () => {
    if (id !== undefined) {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    }
    setOpen(false);
    setJsonPayload(null);
  };

  const columns = [
    ...pointConfigBaseColumns,
    ...(isActionRole(role) && !process.env.VITE_READ_ONLY_MODE
      ? [
          {
            field: 'actions',
            type: 'actions' as GridColType,
            headerName: 'Actions',
            description: 'Actions',
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }: GridRowParams<{ id: string }>) => [
              // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
              <GridActionsCellItem
                icon={<EditIcon />}
                label='Edit'
                className='textPrimary'
                onClick={handleEditClick(id)}
                color='inherit'
              />,
              // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
              />,
            ],
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    if (isSuccess && data !== null) {
      setRows(data.map((device) => ({ ...device, id: device.id })));
    }
  }, [isSuccess, data, error]);

  return (
    <CollectionWrapper>
      <div className='cell block-container flex-1 overflow-y-auto'>
        <Snackbar
          open={confirmOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          sx={deviceSx.confirm}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            variant='filled'
            sx={deviceSx.confirm.alert}
          >
            Point config changes saved
          </Alert>
        </Snackbar>
        <DataGridPro
          rows={rows}
          apiRef={apiRef}
          checkboxSelection
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          initialState={getCollectionsInitialState()}
          columns={columns as GridBaseColDef[]}
          getRowId={({ pointAlias }) => pointAlias}
          loading={!isRefetching && (isFetching || isPending)}
          slots={{
            toolbar: CollectionToolbar,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
            toolbar: {
              collectionType: CollectionTypes.POINT_CONFIGS,
              setOpen,
              setJsonPayload,
            },
          }}
        />

        {open && (
          <PointConfigDialog
            jsonData={jsonPayload as PointConfigExtended}
            setOpen={setOpen}
            handleCancel={handleCancelClick}
            setRowModesModel={setRowModesModel}
            setJsonPayload={setJsonPayload}
          />
        )}
      </div>
    </CollectionWrapper>
  );
}

export default storeConnector(PointConfigCollection, {
  user: ['role'],
});
