import { TextField } from '@mui/material';
import { AddUserFormValues } from '@src/hooks/api/mutations/useUserCreate';
import { EditUserFormValues } from '@src/hooks/api/mutations/useUserUpdate';
import { useFormContext } from 'react-hook-form';

function EmailField() {
  const {
    register,
    formState: { errors },
  } = useFormContext<EditUserFormValues | AddUserFormValues>();

  return (
    <TextField
      fullWidth
      label='Email'
      {...register('email')}
      error={!!errors.email}
      helperText={errors.email?.message}
      size='small'
    />
  );
}

export default EmailField;
