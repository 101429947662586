import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import './index.scoped.scss';
import FilterBlock from '@components/Home/Historian/FilterBlock.tsx';
import HighChartRow from '@components/Home/Historian/HighChartRow';
import DataPointStatistic from '@components/Home/Historian/DataPointStatistic';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';

import {
  AGREG_TYPES,
  checkShouldAggr,
} from '@components/Home/Historian/historianUtils.js';
import { getLocale, currentTzString, useIsFirstRender } from '@utils';
import storeConnector from '@store/storeConnector';
import Spinner from '@components/_elements/Spinner/Spinner';
import useMakePageScrollable from '@hooks/useMakePageScrollable';

const POINTS_LIMIT_AUTOPLOT = 5000;
const POINTS_LIMIT = 50000;
const intevalDict = {
  millisecond: 0.001,
  second: 1,
  minute: 60,
  hour: 60 * 60,
  day: 24 * 60 * 60,
  month: 30 * 24 * 60 * 60,
};

const colors = [
  '255, 91, 215',
  '138, 43, 226',
  '252, 67, 73',
  '0, 123, 255',
  '225, 206, 49',
  '49, 225, 3',
  '191, 77, 216',
  '77, 216, 217',
  '250, 150, 50',
  '233, 195, 164',
  '0, 191, 255',
  '255, 127, 80',
  '154, 205, 50',
  '255, 99, 71',
  '75, 0, 130',
  '255, 105, 180',
  '173, 216, 230',
  '46, 139, 87',
  '255, 140, 0',
  '106, 90, 205',
  '238, 130, 238',
  '165, 42, 42',
  '34, 139, 34',
  '127, 255, 0',
  '210, 105, 30',
];

const colorsMultiLine = [
  ['255, 91, 215', '255, 173, 235', '173, 0, 130', '84, 0, 80'],
  ['138, 43, 226', '196, 148, 240', '69, 16, 118', '41, 0, 84'],
  ['252, 67, 73', '253, 161, 163', '156, 2, 7', '84, 0, 18'],
  ['0, 123, 255', '127, 189, 255', '15, 0, 112', '8, 0, 59'],
  ['225, 206, 49', '255, 230, 152', '119, 108, 17', '59, 51, 0'],
  ['49, 225, 3', '152, 255, 129', '24, 112, 1', '13, 59, 0'],
  ['191, 77, 216', '223, 165, 235', '103, 26, 120', '44, 0, 59'],
  ['77, 216, 217', '165, 235, 236', '25, 120, 121', '0, 56, 59'],
  ['250, 150, 50', '252, 202, 152', '146, 75, 3', '59, 32, 0'],
  ['233, 195, 164', '244, 244, 209', '159, 93, 38', '92, 58, 17'],
  ['0, 191, 255', '135, 206, 235', '70, 130, 180', '25, 25, 112'],
  ['255, 127, 80', '255, 160, 122', '205, 92, 92', '139, 69, 19'],
  ['154, 205, 50', '127, 255, 0', '34, 139, 34', '0, 100, 0'],
  ['255, 99, 71', '255, 160, 122', '205, 92, 92', '178, 34, 34'],
  ['75, 0, 130', '138, 43, 226', '106, 90, 205', '72, 61, 139'],
  ['255, 105, 180', '255, 182, 193', '219, 112, 147', '139, 0, 139'],
  ['173, 216, 230', '135, 206, 235', '70, 130, 180', '25, 25, 112'],
  ['46, 139, 87', '60, 179, 113', '34, 139, 34', '0, 128, 0'],
  ['255, 140, 0', '255, 165, 0', '255, 69, 0', '255, 99, 71'],
  ['106, 90, 205', '123, 104, 238', '147, 112, 219', '72, 61, 139'],
  ['238, 130, 238', '221, 160, 221', '186, 85, 211', '148, 0, 211'],
  ['165, 42, 42', '205, 92, 92', '178, 34, 34', '139, 0, 0'],
  ['34, 139, 34', '50, 205, 50', '0, 128, 0', '0, 100, 0'],
  ['127, 255, 0', '124, 252, 0', '0, 255, 0', '34, 139, 34'],
  ['210, 105, 30', '244, 164, 96', '205, 133, 63', '139, 69, 19'],
];

const HistorianData = (props) => {
  useMakePageScrollable();
  const useNewTopicStructure = props.siteMeta.ui?.Use_New_Topic_Structure;
  const isFirstRender = useIsFirstRender();
  const wrapperRef = useRef();
  const propsRef = useRef(props);
  const initTimeMode = useRef(props.timeMode);
  const tzOffset = useRef(
    getLocale({
      timeMode: props.timeMode,
      lat: props.siteMeta.GPSLat,
      long: props.siteMeta.GPSLong,
      returnOffset: true,
    }),
  );

  const reqParams = useRef(null);
  const chart = useRef(null);
  const loadingStatus = useRef(null);
  const [tooManyPoints, setTooManyPoints] = useState(false);
  const [chartType, setChartType] = useState('');
  const [statRenderKey, setStatRenderKey] = useState('');
  const [rtMode, setRtMode] = useState('historical');
  const [tzRenderKey, setTzRenderKey] = useState(0);
  const requestId = useRef(null);

  const [categories, setCategories] = useState(null);
  const [devices, setDevices] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      const categoriesAndUnits =
        await props.actions.getHistorianCategoriesAndUnits();
      if (!categoriesAndUnits || categoriesAndUnits.error) {
        setCategories([]);
      } else {
        setCategories(categoriesAndUnits.categories);
        setDevices(categoriesAndUnits.unitIds);
      }
      setLoading(false);
    };
    loadData();
  }, []);

  const checkShouldAggrReq = (req) => {
    return checkShouldAggr(req);
  };

  const getAggregationArr = (req) => {
    return checkShouldAggrReq(req)
      ? ['mean']
      : AGREG_TYPES.filter((el) => (req.aggregateFunctions || []).includes(el));
  };

  const getTopicsDict = () => {
    return reqParams.current?.criteria?.reduce((acc, cv) => {
      const newAcc = { ...acc };
      if (!newAcc[cv.category]) {
        newAcc[cv.category] = {};
        newAcc[cv.category].sns = [cv.unitId || cv.siteId];
        newAcc[cv.category].fields = [cv.field];
        if (cv.sourceDeviceId) {
          newAcc[cv.category].sourceDeviceId = cv.sourceDeviceId;
        }
        const currentCategory = cv.category;
        newAcc[cv.category].cb = (data, deviceId) => {
          const currentTimeStamp = +moment.utc(
            moment().format('YYYY-MM-DDTHH:mm:ss'),
          );
          setAutoPlotData(
            currentCategory,
            deviceId,
            Object.keys(data)[0],
            currentTimeStamp,
            data[Object.keys(data)[0]],
          );
        };
      } else {
        newAcc[cv.category].sns.push(cv.unitId || cv.siteId);
        newAcc[cv.category].fields.push(cv.field);
      }
      newAcc[cv.category].sns = [...new Set(newAcc[cv.category].sns)];
      newAcc[cv.category].fields = [...new Set(newAcc[cv.category].fields)];
      return newAcc;
    }, {});
  };

  useWsSubscribe(
    rtMode === 'live'
      ? {
          ...getTopicsDict(),
        }
      : {},
    [rtMode],
  );

  const initChart = (req, type) => {
    const genOpts = {
      exporting: {
        filename: 'historian-chart-data',
        csv: { dateFormat: '%Y-%m-%d %H:%M:%S' },
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      chart: { zoomType: 'x', height: '600px' },
      xAxis: {
        type: 'datetime',
        title: {
          text: `<span id="high-chart-title">\
            Date <span id="high-chart-tz">${getCurrentTzString()}</span>\
          </span>`,
        },
      },
      title: { text: ' ' },
      time: { useUTC: true, timezoneOffset: tzOffset.current },
    };
    const axisArr = req.criteria.map((r) => r.axis);
    const axisMode = axisArr.includes('Y1')
      ? axisArr.includes('Y2')
        ? 'both'
        : 'left'
      : 'right';
    genOpts.yAxis = [
      {
        title: { text: axisMode === 'right' ? 'Y2' : 'Y1' },
        opposite: axisMode === 'right',
      },
      ...(axisMode === 'both'
        ? [{ title: { text: 'Y2' }, opposite: true }]
        : []),
    ];
    genOpts.series = [];
    const generateSeriaName = (dp) => {
      const { unitId, sourceDeviceId } = dp;
      let deviceName = props.siteMeta.SiteName;
      if (unitId) {
        const unitIndex = props.siteMeta.Units.indexOf(unitId);
        const unitName = props.siteMeta.UnitNames[unitIndex];
        if (unitName) {
          deviceName += `, Unit ${unitName}`;
          if (sourceDeviceId && useNewTopicStructure) {
            deviceName += `, ${sourceDeviceId}`;
          }
        }
      }
      return deviceName;
    };
    const aggregationArr = type === 'PLOT' ? getAggregationArr(req) : [];
    req.criteria.forEach((dp, index) => {
      [...(aggregationArr.length ? aggregationArr : [null])].forEach(
        (aggr, indx) => {
          genOpts.series.push({
            id: index * (aggregationArr.length || 1) + indx,
            name: `${dp.category}-${generateSeriaName(dp)}-${dp.field}${
              aggr ? ' (' + aggr + ')' : ''
            }`,
            data: [],
            sourceDeviceId: dp.sourceDeviceId,
            deviceId: dp.unitId || dp.siteId,
            category: dp.category,
            field: dp.field,
            ...(aggr ? { aggr } : {}),
            color: `rgba(${
              aggr ? colorsMultiLine[index][indx] : colors[index]
            },1)`,
            ...(dp.dataType ? { dataType: dp.dataType } : ''),
            ...(axisMode === 'both' ? { yAxis: dp.axis === 'Y1' ? 0 : 1 } : {}),
          });
        },
      );
    });
    chart.current = genOpts;
  };

  const setReqInitData = (req, type, id) => {
    requestId.current = id;
    reqParams.current = { ...req };
    initChart(req, type);
    setTooManyPoints(false);
    setChartType(type);
  };

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  useEffect(() => {
    if (props.timeMode && !isFirstRender) {
      const tz = getLocale({
        timeMode: props.timeMode,
        initTZMode: initTimeMode.current,
        lat: props.siteMeta.GPSLat,
        long: props.siteMeta.GPSLong,
        returnOffset: true,
      });
      chart.current = chart.current
        ? {
            ...chart.current,
            xAxis: {
              type: 'datetime',
              title: {
                text: `<span id="high-chart-title">\
              Date <span id="high-chart-tz">${getCurrentTzString(
                props.timeMode,
              )}</span>\
            </span>`,
              },
            },
            time: {
              useUTC: true,
              timezoneOffset: tz,
            },
          }
        : null;
      tzOffset.current = tz;
      setTzRenderKey(Math.random());
    }
  }, [props.timeMode]);

  const getCurrentTzString = (tm) => {
    return currentTzString({
      timeMode: tm || propsRef.current.timeMode,
      lat: propsRef.current.siteMeta.GPSLat,
      long: propsRef.current.siteMeta.GPSLong,
    });
  };

  const getTimeStamp = (dataStr) => {
    return moment.utc(dataStr).valueOf();
  };

  const setAutoPlotData = (category, deviceId, field, ts, data) => {
    const i = chart.current.series.findIndex((s) => {
      return (
        s.category === category &&
        (s.deviceId === deviceId || s.sourceDeviceId === deviceId) &&
        s.field === field
      );
    });
    const dataOfSeria = [
      ...(chart.current.series?.[i]?.data || []),
      [ts, data],
    ];
    const slicedData = dataOfSeria.slice(
      Math.max(dataOfSeria.length - POINTS_LIMIT_AUTOPLOT, 0),
    );
    chart.current.series[i].data = slicedData;
    wrapperRef.current.updateChart({ series: [...chart.current.series] });
  };

  const setDataToChart = (req, res, forAutoPlot) => {
    const aggregationArr = getAggregationArr(req);
    const dataInit = req.criteria.reduce((acc, c) => {
      if (aggregationArr.length) {
        const aggrParts = {};
        // prettier-ignore
        aggregationArr.forEach((aggr) => {
          let key;
          if (c.category === 'site') {
            key = `site_${c.field}_${aggr}`;
          } else if (!c.unitId) {
            key = `${c.category.toLowerCase()}_${c.sourceDeviceId}_${c.field}_${aggr}`;
          } else if (c.category === 'unit') {
            key = `unit_${c.unitId}_${c.field}_${aggr}`;
          } else {
            key = `${c.category === 'Battery' ? 'bms' : c.category.toLowerCase()}_${c.unitId}_${c.sourceDeviceId}_${c.field}_${aggr}`;
          }
          aggrParts[key] = [];
        });
        return { ...acc, ...aggrParts };
      }
      return {
        ...acc,
        [`${c.category === 'Battery' ? 'bms' : c.category.toLowerCase()}_${
          c.deviceId
        }_${c.field}`]: [],
      };
    }, {});
    const fields = Object.keys(dataInit);
    if (res && !res.error && res.length) {
      const data = res.reduce((acc, cv) => {
        const accNew = { ...acc };
        fields.forEach((f) => {
          if (cv[f] || cv[f] === 0) {
            accNew[f] = [...accNew[f], [getTimeStamp(cv._time), cv[f]]];
          }
        });
        return accNew;
      }, dataInit);
      fields.forEach((f, i) => {
        const dataOfSeria = [...chart.current.series[i].data, ...data[f]];
        if (forAutoPlot) {
          const slicedData = dataOfSeria.slice(
            Math.max(dataOfSeria.length - POINTS_LIMIT_AUTOPLOT, 0),
          );
          chart.current.series[i].data = slicedData;
        } else {
          chart.current.series[i].data = dataOfSeria;
        }
      });
      wrapperRef.current.updateChart({ series: [...chart.current.series] });
    }
  };

  const runPlot = async (req) => {
    loadingStatus.current = 'loading';
    setStatRenderKey(Math.random());
    setRtMode('historical');
    const reqId = Math.random();
    setReqInitData(req, 'PLOT', reqId);
    const reqToPass = { ...req };
    reqToPass.criteria = reqToPass.criteria.reduce((a, c) => {
      const q = { ...c };
      q.deviceId = c.sourceDeviceId || c.unitId || c.siteId;
      q.unitId = c.unitId;
      return [...(a || []), q];
    }, []);
    const res = await props.actions.getHistorianData(reqToPass);
    if (reqId === requestId.current) {
      if (res && !res.error && res.length >= POINTS_LIMIT) {
        setTooManyPoints(true);
      }
      setDataToChart(req, res);
      loadingStatus.current = 'loaded';
      setStatRenderKey(Math.random());
    }
  };

  const runAutoPlot = (req) => {
    loadingStatus.current = 'realtime mode';
    const reqId = Math.random();
    setReqInitData(req, 'AUTO PLOT', reqId);
    setRtMode('live');
  };

  return (
    <div className='home-row historian-data'>
      {!loading ? (
        <div className='fcol full-width'>
          <div className='frow'>
            <div className='filter-block cell block-container'>
              <FilterBlock
                runPlot={(val) => runPlot(val)}
                runAutoPlot={(val) => runAutoPlot(val)}
                categories={categories}
                deviceIds={devices}
              />
            </div>
            {chart.current && chartType === 'PLOT' && reqParams.current && (
              <div className='filter-block cell block-container centered-content w-25'>
                <DataPointStatistic
                  chart={chart.current}
                  reqParams={reqParams.current}
                  intevalDict={intevalDict}
                  loadingStatus={loadingStatus}
                  key={statRenderKey}
                />
              </div>
            )}
          </div>
          <div className='frow'>
            {chart.current && (
              <HighChartRow
                chart={chart.current}
                chartType={chartType}
                tooManyPoints={tooManyPoints}
                loadingStatus={loadingStatus.current}
                reqParams={reqParams.current}
                initTimeMode={initTimeMode.current}
                tzOffset={tzOffset.current}
                setWrapperRef={wrapperRef}
                wrapperRef={wrapperRef.current}
                key={tzRenderKey}
              />
            )}
          </div>
        </div>
      ) : (
        <Spinner type='fullPage' />
      )}
    </div>
  );
};

export default storeConnector(HistorianData, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
