import PowinBessCommandsModal from '@components/Battery/Powin/Commands/PowinBess/PowinBessCommandsModal';
import PowinBessDetailsModal from '@components/Battery/Powin/PowinBessDetailsModal';
import DeviceDataTable, {
  DeviceDataTableRow,
} from '@components/_shared/DeviceDataTable';
import UIBox from '@components/_shared/UIBox';
import { useCurrentPowinEmsDeviceContext } from '@hooks/useCurrentPowinEmsDevice';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import storeConnector from '@store/storeConnector';
import isCommandsEnabled from '@utils/isCommandsEnabled';
import { useEffect, useMemo, useState } from 'react';

function PowinBessList({ role }: { role: UserRole }) {
  const { currentPowinEmsDevice } = useCurrentPowinEmsDeviceContext();
  const [cmdModal, setCmdModal] = useState(false);
  const [bessDetailsModal, setBessDetailsModal] = useState(false);

  const {
    data: widgetConfig,
    isError,
    isLoading,
    refetch,
  } = useGetInternalWidgetConfig<WidgetTypeDeviceList>(
    'powinBessList',
    {
      sourceDeviceId: currentPowinEmsDevice?.deviceId,
    },
    { enabled: !!currentPowinEmsDevice?.deviceId },
  );

  const memoizedDevices = useMemo(
    () => widgetConfig?.devices || [],
    [widgetConfig?.devices],
  );

  const memoizedDataPoints = useMemo(
    () => widgetConfig?.dataPoints || [],
    [widgetConfig?.dataPoints],
  );

  const memoizedPointConfigRefs = useMemo(
    () => widgetConfig?.pointConfigRefs || [],
    [widgetConfig?.pointConfigRefs],
  );

  useEffect(() => {
    if (currentPowinEmsDevice?.deviceId) {
      refetch();
    }
  }, [refetch, currentPowinEmsDevice?.deviceId]);

  const [deviceIdForCmdModal, setDeviceIdForCmdModal] = useState<string>('');
  const deviceNameForCmdModal = useMemo(
    () =>
      widgetConfig?.devices?.find(
        (device) => device.deviceId === deviceIdForCmdModal,
      )?.name,
    [widgetConfig?.devices, deviceIdForCmdModal],
  );

  return (
    <UIBox
      header={widgetConfig?.header}
      subheader={currentPowinEmsDevice?.name}
      isLoading={isLoading || (!isLoading && !widgetConfig && !isError)}
      isError={isError || (!isLoading && !widgetConfig)}
    >
      <DeviceDataTable
        enableCmdBtnColumn={isCommandsEnabled() && isActionRole(role)}
        hideFooter
        devices={memoizedDevices}
        dataPoints={memoizedDataPoints}
        pointConfigRefs={memoizedPointConfigRefs}
        disableRowSelectionOnClick
        onRowClick={({ id }: DeviceDataTableRow) => {
          setDeviceIdForCmdModal(id);
          setBessDetailsModal(true);
        }}
        openCmdModal={(deviceId) => {
          setDeviceIdForCmdModal(deviceId);
          setCmdModal(true);
        }}
        getRowClassName={() => 'MuiDataGrid-clickable'}
      />
      <PowinBessCommandsModal
        powinControllerName={currentPowinEmsDevice?.name}
        bessId={deviceIdForCmdModal}
        onClose={() => setCmdModal(false)}
        open={cmdModal}
      />
      <PowinBessDetailsModal
        selectedBessId={deviceIdForCmdModal}
        bessName={deviceNameForCmdModal}
        powinControllerName={currentPowinEmsDevice?.name || ''}
        open={bessDetailsModal}
        onClose={() => setBessDetailsModal(false)}
      />
    </UIBox>
  );
}

export default storeConnector(PowinBessList, {
  user: ['role'],
});
