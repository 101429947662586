const wizardSx = {
  stepper: {
    width: 600,
    label: (completed: boolean) => ({
      cursor: completed ? 'pointer' : '',
    }),
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    my: 2,
  },
  buttonWrapper: {
    justifyContent: 'space-between',
    navbutton: {
      fontSize: '60px',
    },
    finishButtonWrapper: {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  siteSettings: {
    justifyContent: 'space-around',
    maxWidth: '600px',
    width: '100%',
    alignSelf: 'center',
    siteButton: {
      borderRadius: 0,
    },
    siteMetaEditorContainer: {
      mt: 3,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  },
};

export default wizardSx;
