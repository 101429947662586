import { useTranslation } from 'react-i18next';
import Popup from '@components/_elements/Popup/Popup';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';

const FastStopPopup = ({ actions, isOpen, close, siteMeta }) => {
  const { t } = useTranslation();
  const sendFastStop = async () => {
    await actions.sendCommand(
      ['WriteToVariant::FastStopSiteUI::1'],
      siteMeta.siteId,
    );
    actions.notify('Command was sent');
    close();
  };

  return (
    <Popup
      isOpen={isOpen}
      title={t('CONFIRM_EXECUTION_QUESTION')}
      close={() => close()}
      bodyClass='content-popup'
    >
      <div style={{ margin: '20px' }}>{t('FAST_STOP')}</div>
      <div className='btn-row'>
        <Button onClick={() => sendFastStop()} size='m'>
          {t('OK_UPPERCASE')}
        </Button>
        <Button onClick={() => close()} size='m'>
          {t('CANCEL_UPPERCASE')}
        </Button>
      </div>
    </Popup>
  );
};

export default storeConnector(FastStopPopup, {
  config: ['siteMeta'],
});
