import { ReactElement } from 'react';
import useDataPointsSearch from '@hooks/api/queries/useDataPointsSearch';
import useSubscription from '@hooks/useSubscription';
import {
  DataPointWithValue,
  QueryDataPointsSearchBodyElement,
} from '@src/types/DataPoint';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import { UnitDataPoints, UnitConfig } from '@src/sld/types';
import TopSwitchResistorSection from '@src/sld/beaumont/components/TopSwitchResistorSection';
import Switch2Units from '@src/sld/beaumont/components/Switch2Units';
import Switch1Units from '@src/sld/beaumont/components/Switch1Units';
import Switch3Units from '@src/sld/beaumont/components/Switch3Units';
import MultiSwitchConnector from '@src/sld/components/MultiSwitchConnector/MultiSwitchConnector';
import BreakerStack1 from '@src/sld/beaumont/components/BreakerStack1';
import BreakerStack2 from '@src/sld/beaumont/components/BreakerStack2';
import Switch4Units from '@src/sld/beaumont/components/Switch4Units';

type BeaumontSLDProps = {
  siteMeta: SiteMeta;
};

const unitDataPoints: UnitDataPoints[] = [
  UnitDataPoints.KW,
  UnitDataPoints.KVAR,
  UnitDataPoints.SOC,
  UnitDataPoints.RacksOnline,
  UnitDataPoints.InvStatus,
  UnitDataPoints.RunMode,
];

function BeaumontDiagram(props: BeaumontSLDProps): ReactElement {
  const { siteMeta } = props;

  const queryItems: QueryDataPointsSearchBodyElement[] = [];
  const units: UnitConfig[] = [];

  siteMeta.Units.forEach((controllerId: string): void => {
    unitDataPoints.forEach((pointName: UnitDataPoints): void => {
      queryItems.push({ deviceType: 'unit', pointName, controllerId });
    });
  });

  const dataPoints = useDataPointsSearch(queryItems);

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(dataPoints);

  const getUnitDataPoint = (
    pointName: string,
    unitPoints: DataPointWithValue[],
  ) =>
    unitPoints.find(
      (x: DataPointWithValue): boolean => x.pointName === pointName,
    );

  siteMeta.Units.forEach((controllerId: string, index: number): void => {
    const unitPoints: DataPointWithValue[] = dataPointsWithValue.filter(
      (dataPointObj: DataPointWithValue): boolean =>
        dataPointObj.controllerId === controllerId,
    );

    const kw = getUnitDataPoint(UnitDataPoints.KW, unitPoints)?.value;
    const kvar = getUnitDataPoint(UnitDataPoints.KVAR, unitPoints)?.value;
    const racksonline = getUnitDataPoint(
      UnitDataPoints.RacksOnline,
      unitPoints,
    )?.value;
    const invstatus = getUnitDataPoint(
      UnitDataPoints.InvStatus,
      unitPoints,
    )?.value;
    const soc = getUnitDataPoint(UnitDataPoints.SOC, unitPoints)?.value;
    const runmode = getUnitDataPoint(UnitDataPoints.RunMode, unitPoints)?.value;

    units.push({
      unitLabel: siteMeta.UnitNames[index],
      kw,
      kvar,
      racksonline,
      invstatus,
      soc,
      runmode,
    } as UnitConfig);
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      viewBox='125 25 1300 645'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor', overflowX: 'auto' }}
    >
      <title>Beaumont</title>

      <TopSwitchResistorSection
        translateXCoordinate={110}
        translateYCoordinate={19}
      />
      <MultiSwitchConnector
        translateXCoordinate={343}
        translateYCoordinate={-354}
        verticalLength={20}
        horizontalLength={125}
        startingXCoordinate={410}
      />

      <MultiSwitchConnector
        translateXCoordinate={315}
        translateYCoordinate={-250}
        verticalLength={20}
        horizontalLength={55}
        startingXCoordinate={410}
      />
      <BreakerStack1 />

      <MultiSwitchConnector
        translateXCoordinate={430}
        translateYCoordinate={-250}
        verticalLength={20}
        horizontalLength={70}
        startingXCoordinate={410}
      />
      <BreakerStack2 />

      <Switch1Units unitConfigs={units} />
      <Switch2Units unitConfigs={units} />
      <Switch3Units unitConfigs={units} />
      <Switch4Units unitConfigs={units} />
    </svg>
  );
}

export default storeConnector(BeaumontDiagram, {
  config: ['siteMeta'],
});
