import MultiColPointDataTable from '@components/_shared/MultiColPointDataTable';
import { MqttClient } from 'mqtt';
import { Box, DialogTitle, Grid2, styled } from '@mui/material';
import { WidgetTypeMultiSectionPointListSection } from '@src/types/Widget';

const StyledSectionTitle = styled(DialogTitle)(() => ({
  textAlign: 'center',
  fontWeight: 'bold',
}));

interface Props {
  sectionIndex: number;
  data: WidgetTypeMultiSectionPointListSection;
  useNewClient?: MqttClient;
}

function MultiColPointDataTableWithTitle(props: Props) {
  return (
    <Box sx={{ mt: 1 }} id={`mp_content_box_${props.sectionIndex}`}>
      <StyledSectionTitle>{props.data.title}</StyledSectionTitle>
      <Grid2 container justifyContent='space-evenly'>
        <MultiColPointDataTable
          dataPointsMatrix={props.data.dataPoints}
          useNewClient={props.useNewClient}
        />
      </Grid2>
    </Box>
  );
}

export default MultiColPointDataTableWithTitle;
