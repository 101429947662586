import DraggableDialog from '@components/_shared/DraggableDialog';
import { ReactElement, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import BreakerCommandsConfirmationDialog from '@src/sld/components/Breaker/BreakerCommandsConfirmationDialog';

const dialogActionsStyles = {
  display: 'flex',
  justifyContent: 'center',
  pb: 2,
};

interface BreakerCommandDialogProps {
  breakerName: string;
  setShowBreakerCmdDialog: (v: boolean) => void;
}

export default function BreakerCommandsDialog({
  breakerName,
  setShowBreakerCmdDialog,
}: BreakerCommandDialogProps): ReactElement {
  const [showConfirm, setShowConfirm] = useState(false);
  const [operation, setOperation] = useState('');

  const handleCommandOnClick = async (
    newBreakerStatus: string,
  ): Promise<void> => {
    setOperation(newBreakerStatus);
    setShowConfirm(true);
  };

  return (
    <div>
      <div className='cover-container cover-container-confirm'>
        <DraggableDialog onClose={() => setShowBreakerCmdDialog(false)}>
          <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
            Breaker {breakerName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Send breaker command</DialogContentText>
          </DialogContent>
          <DialogActions sx={dialogActionsStyles}>
            <div>
              <Button
                size='small'
                onClick={() => handleCommandOnClick('open')}
                variant='contained'
              >
                Open Breaker
              </Button>
            </div>
            <div>
              <Button
                size='small'
                onClick={() => handleCommandOnClick('close')}
                autoFocus
                variant='contained'
              >
                Close Breaker
              </Button>
            </div>
            <div>
              <Button
                size='small'
                onClick={() => setShowBreakerCmdDialog(false)}
                autoFocus
                variant='outlined'
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </DraggableDialog>
      </div>

      {showConfirm && (
        <BreakerCommandsConfirmationDialog
          operation={operation}
          breakerName={breakerName}
          setShowConfirm={setShowConfirm}
          setShowBreakerCmdDialog={setShowBreakerCmdDialog}
        />
      )}
    </div>
  );
}
