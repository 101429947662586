import GaugeContainer from '@components/Battery/GaugeContainer';
import Messages from '@components/Battery/Messages';

function SYS() {
  return (
    <>
      <GaugeContainer />
      <Messages withTitle />
    </>
  );
}

export default SYS;
