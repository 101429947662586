import { DataPoint } from '@src/types/DataPoint';
import { useMemo } from 'react';
import { isActionRole } from '@src/services/auth';
import useSubscription from '@src/hooks/useSubscription';
import { useCommandsModalContext } from '@src/components/Battery/Tesla/Commands/CommandsModalContext';
import { Box, Switch, Tooltip } from '@mui/material';
import storeConnector from '@src/store/storeConnector';
import { UserRole } from '@src/services/auth/roles';
import { SiteMeta } from '@src/types/SiteMeta';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import { ServiceState } from '@src/store/reducers/serviceData';
import TeslaStatusIcon from './TeslaStatusIcon';
import './TSCConfig.scoped.scss';

interface StatusProps {
  siteMeta: SiteMeta;
  role: UserRole;
  isControlsLocked: ServiceState['isControlsLocked'];
}

function TSCStatusDisplay({ siteMeta, role, isControlsLocked }: StatusProps) {
  const { siteId, ui } = siteMeta;
  const { controlsLockConfig } = ui;
  const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;

  const statusPointConfig = useMemo<DataPoint[]>(
    () => [
      {
        id: '000-000-000-000-000',
        createdAt: 'someDate',
        updatedAt: 'someDate',
        deviceType: 'site',
        controllerId: siteId,
        pointName: '',
        dataType: 'string',
        displayName: 'InvStatus',
        fullTopic: `ems/site/${siteId}/tsc/tsc_1/InvStatus/string`,
        sourceDeviceId: null,
        sourceDeviceType: null,
        siteId,
      },
    ],
    [siteId],
  );
  const dataPoints = useSubscription(statusPointConfig);
  const { value } = dataPoints[0];

  const isEnabled = value === 'RunPQ';
  const enabledStatus = value === 'RunPQ' ? 1 : 0;

  const { sendCommand } = useCommandsModalContext();
  const handleCommand = (val: boolean) => {
    const newValue = val ? 'Enable' : 'Disable';

    sendCommand(newValue);
  };

  return (
    <div className='modes-row'>
      <div className='modes-cell status__title-cell '>
        <strong className='tsc__title'>TSC Enabled:</strong>
      </div>
      <div className='modes-cell'>
        <div className='status__status-cell'>
          <TeslaStatusIcon status={enabledStatus} />
        </div>
        {isCommandsEnabled() && (
          <Tooltip
            title={isControlsLocked ? tooltipTitle : ''}
            placement='top'
            disableHoverListener={!isControlsLocked}
          >
            <Box>
              <Switch
                checked={isEnabled}
                onChange={(e) => handleCommand(e.target.checked)}
                disabled={
                  !ui.TSC_Config_Enable ||
                  !isActionRole(role) ||
                  isControlsLocked
                }
              />
            </Box>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default storeConnector(TSCStatusDisplay, {
  user: ['role'],
  config: ['siteMeta'],
  service: ['isControlsLocked'],
});
