import TextLoader from '@components/_elements/TextLoader';
import {
  ConverterDetailsBessFields,
  ConverterDetailsPvFields,
  ConverterDetailsStatusFields,
} from './constants.js';

const ConverterDetailsCell = ({ label, value, loading }) => {
  return (
    <>
      <div className='title'>{label}</div>
      <div>
        <TextLoader expectedSize={2} loading={loading}>
          {value}
        </TextLoader>
      </div>
    </>
  );
};

export const ConverterDetails = ({ converter, converterIndex }) => {
  return (
    <div className='converter-details-wrapper'>
      <div className='converter-title'>
        Converter {converterIndex + 1} Details
      </div>
      <div className='converter-details-grid'>
        <div className='converter-details-column'>
          {ConverterDetailsStatusFields.map((field, i) => (
            <ConverterDetailsCell
              key={`converter-details-cell-${field.field}-${i}`}
              label={field.label}
              value={converter[field.field].value}
              loading={converter[field.field].loading}
            />
          ))}
          <div />
          <div />
        </div>

        <div className='converter-details-column'>
          {ConverterDetailsBessFields.map((field, i) => (
            <ConverterDetailsCell
              key={`converter-details-cell-${field.field}-${i}`}
              label={field.label}
              value={converter[field.field].value}
              loading={converter[field.field].loading}
            />
          ))}
        </div>

        <div className='converter-details-column'>
          {ConverterDetailsPvFields.map((field, i) => (
            <ConverterDetailsCell
              key={`converter-details-cell-${field.field}-${i}`}
              label={field.label}
              value={converter[field.field].value}
              loading={converter[field.field].loading}
            />
          ))}
        </div>

        <div className='converter-details-column' />
      </div>
    </div>
  );
};
