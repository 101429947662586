import { Checkbox, FormControlLabel } from '@mui/material';

interface ResetPasswordFieldProps {
  onResetCheckboxChanged: (resetPasswordChecked: boolean) => void;
}

function ResetPasswordField({
  onResetCheckboxChanged,
}: ResetPasswordFieldProps) {
  const handleOnChange = (isChecked: boolean): void => {
    onResetCheckboxChanged(isChecked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e) => handleOnChange(e.target.checked)}
          size='small'
        />
      }
      label='Reset Password'
    />
  );
}

export default ResetPasswordField;
