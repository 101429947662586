import { useState } from 'react';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { formatNum } from '@utils';
import { store } from '@store';
import storeConnector from '@store/storeConnector';
import DefaultUnits from '../../types/Unit';

const WeatherStation = () => {
  const [rtData, setRtData] = useState({});
  const isLoading = (field) => {
    return !Object.keys(rtData).includes(field);
  };
  useWsSubscribe({
    site: {
      fields: [
        'PV_Irradiance_GHI',
        'PV_Amb_T',
        'PV_Amb_RH',
        'PV_Amb_BarPress',
        'PV_BackP_T',
        'PV_Wind_Speed',
        'PV_Wind_Direction',
        'PV_Precipitation',
      ],
      cb: (data) => setRtData((prev) => ({ ...prev, ...data })),
    },
  });

  return (
    <div className='weather-col' style={{ flex: 3 }}>
      <div className='weather-title'>Weather Station</div>
      <div className='weather-block'>
        <table className='info in' style={{ margin: '5px' }}>
          <tbody>
            <tr>
              <td>Irradiance:</td>
              <td className='green-text'>
                <div className='flex-row'>
                  <TextLoader
                    expectedSize={5}
                    loading={isLoading('PV_Irradiance_GHI')}
                  >
                    {formatNum({ v: rtData.PV_Irradiance_GHI, a: 2 })}
                  </TextLoader>
                  <span>
                    &nbsp;Wm<sup>2</sup>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Ambient Temperature:</td>
              <td className='green-text'>
                <div className='flex-row'>
                  <TextLoader expectedSize={5} loading={isLoading('PV_Amb_T')}>
                    {formatNum({ v: rtData.PV_Amb_T, a: 2 })}
                  </TextLoader>
                  <span>
                    {store.getState().config.siteMeta.ui.Default_Temp_Unit ||
                      `&deg;${DefaultUnits.Ambient_Temperature}`}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Relative Humidity:</td>
              <td className='green-text'>
                <div className='flex-row'>
                  <TextLoader expectedSize={5} loading={isLoading('PV_Amb_RH')}>
                    {formatNum({ v: rtData.PV_Amb_RH, a: 2 })}
                  </TextLoader>
                  <span>&nbsp;%</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Barometric Pressure:</td>
              <td className='green-text'>
                <div className='flex-row'>
                  <TextLoader
                    expectedSize={5}
                    loading={isLoading('PV_Amb_BarPress')}
                  >
                    {formatNum({ v: rtData.PV_Amb_BarPress, a: 2 })}
                  </TextLoader>
                  <span>&nbsp;mbar</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Wind Speed:</td>
              <td className='green-text'>
                <div className='flex-row'>
                  <TextLoader
                    expectedSize={5}
                    loading={isLoading('PV_Wind_Speed')}
                  >
                    {formatNum({ v: rtData.PV_Wind_Speed, a: 2 })}
                  </TextLoader>
                  {store.getState().config.siteMeta.ui.Default_Speed_Unit ||
                    DefaultUnits.Wind_Speed}
                </div>
              </td>
            </tr>
            <tr>
              <td>Wind Direction:</td>
              <td className='green-text'>
                <div className='flex-row'>
                  <TextLoader
                    expectedSize={5}
                    loading={isLoading('PV_Wind_Direction')}
                  >
                    {formatNum({ v: rtData.PV_Wind_Direction, a: 2 })}
                  </TextLoader>
                  <span>&nbsp;&deg;</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Precipitation:</td>
              <td className='green-text'>
                <div className='flex-row'>
                  <TextLoader
                    expectedSize={5}
                    loading={isLoading('PV_Precipitation')}
                  >
                    {formatNum({ v: rtData.PV_Precipitation, a: 2 })}
                  </TextLoader>
                  <span>&nbsp;in</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default storeConnector(WeatherStation, {});
