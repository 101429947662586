import PowinControllerCommandsModal from '@components/Battery/Powin/Commands/PowinController/PowinControllerCommandsModal';
import DeviceDataTable from '@components/_shared/DeviceDataTable';
import UIBox from '@components/_shared/UIBox';
import { useCurrentPowinEmsDeviceContext } from '@hooks/useCurrentPowinEmsDevice';
import { generateMqttClient } from '@pages/_loader/initMqttClient.loader';
import useDataPointsSearch from '@src/hooks/api/queries/useDataPointsSearch';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { SiteMeta } from '@src/types/SiteMeta';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import storeConnector from '@store/storeConnector';
import { MqttClient } from 'mqtt';
import { useEffect, useMemo, useState } from 'react';

function PowinControllerList({
  role,
  siteMeta,
}: { role: UserRole; siteMeta: SiteMeta }) {
  const { currentPowinEmsDevice, setCurrentPowinEmsDevice } =
    useCurrentPowinEmsDeviceContext();
  const { deviceId: currentPowinEmsId } = currentPowinEmsDevice || {
    deviceId: 'powinems_1',
  };

  const MIN_P_POINT_NAME = siteMeta.PowinAllowedMinP_PointName;
  const MAX_P_POINT_NAME = siteMeta.PowinAllowedMaxP_PointName;
  const MIN_Q_POINT_NAME = siteMeta.PowinAllowedMinQ_PointName;
  const MAX_Q_POINT_NAME = siteMeta.PowinAllowedMaxQ_PointName;
  const QSET = 'Qset';
  const PSET = 'Pset';

  const powerPoints = useDataPointsSearch([
    { deviceType: 'site', pointName: PSET },
    { deviceType: 'site', pointName: QSET },
    { deviceType: 'site', pointName: MIN_P_POINT_NAME },
    { deviceType: 'site', pointName: MAX_P_POINT_NAME },
    { deviceType: 'site', pointName: MIN_Q_POINT_NAME },
    { deviceType: 'site', pointName: MAX_Q_POINT_NAME },
  ]);

  const {
    data: widgetConfig,
    isLoading,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypeDeviceList>('powinControllerList');

  const memoizedDevices = useMemo(
    () => widgetConfig?.devices || [],
    [widgetConfig?.devices],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (currentPowinEmsDevice === undefined) {
      setCurrentPowinEmsDevice(memoizedDevices[0]);
    }
  }, [memoizedDevices]);

  const memoizedDataPoints = useMemo(
    () => widgetConfig?.dataPoints || [],
    [widgetConfig?.dataPoints],
  );

  const memoizedPointConfigRefs = useMemo(
    () => widgetConfig?.pointConfigRefs || [],
    [widgetConfig?.pointConfigRefs],
  );

  const [cmdModal, setCmdModal] = useState(false);
  const [deviceIdForCmdModal, setDeviceIdForCmdModal] = useState<string>('');
  const deviceNameForCmdModal = useMemo(
    () =>
      memoizedDevices.find((device) => device.deviceId === deviceIdForCmdModal)
        ?.name,
    [memoizedDevices, deviceIdForCmdModal],
  );

  const handleRowSelection = (newDeviceId: string) => {
    if (newDeviceId) {
      setCurrentPowinEmsDevice(
        memoizedDevices.find((device) => device.deviceId === newDeviceId),
      );
    }
  };

  const closeCmdModal = () => {
    setCmdModal(false);
  };

  const [mqttClient, setMqttClient] = useState<MqttClient | undefined>(
    undefined,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (!cmdModal) {
      mqttClient?.end();
      setMqttClient(undefined);
      return () => {};
    }
    const startClient = async () => {
      const client = await generateMqttClient();
      setMqttClient(client);
    };

    startClient();
    return () => {
      mqttClient?.end();
    };
  }, [cmdModal]);

  return (
    <UIBox
      header={widgetConfig?.header}
      isLoading={isLoading}
      isError={isError || (isError && !widgetConfig)}
    >
      <DeviceDataTable
        enableCmdBtnColumn={isCommandsEnabled() && isActionRole(role)}
        hideFooter
        devices={memoizedDevices}
        dataPoints={memoizedDataPoints}
        pointConfigRefs={memoizedPointConfigRefs}
        openCmdModal={(deviceId: string) => {
          setDeviceIdForCmdModal(deviceId);
          setCmdModal(true);
        }}
        rowSelectionModel={[currentPowinEmsId]}
        onRowSelectionModelChange={([newPowinEmsId]: string) =>
          handleRowSelection(newPowinEmsId)
        }
        getRowClassName={() => 'MuiDataGrid-clickable'}
      />
      {isCommandsEnabled() && isActionRole(role) && cmdModal && mqttClient ? (
        <PowinControllerCommandsModal
          mqttClient={mqttClient}
          deviceName={deviceNameForCmdModal}
          onClose={() => closeCmdModal()}
          powerPoints={powerPoints}
        />
      ) : null}
    </UIBox>
  );
}

export default storeConnector(PowinControllerList, {
  config: ['siteMeta'],
  user: ['role'],
});
