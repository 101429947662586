import { Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import { ConfirmType } from '@src/types/Notes';
import notesx from './NotesSx';

interface NotesConfirmDialogProps {
  error: boolean;
  confirmOpen: boolean;
  handleClose: () => void;
  confirmType: ConfirmType | string;
  deviceId: string;
  setError: Dispatch<SetStateAction<boolean>>;
  setConfirmOpen: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
}

export default function NotesConfirmDialog({
  error,
  confirmOpen,
  handleClose,
  confirmType,
  deviceId,
  setError,
  setConfirmOpen,
  handleConfirm,
}: NotesConfirmDialogProps) {
  let confirmText;
  if (confirmType === ConfirmType.CREATE) {
    confirmText = `Create note for ${deviceId}?`;
  } else if (confirmType === ConfirmType.CLEAR) {
    confirmText = 'Are you sure want to remove all notes?';
  } else {
    confirmText = 'Are you sure want to remove this note?';
  }

  return (
    <Dialog open={confirmOpen} onClose={handleClose}>
      <DialogContent>
        {error && (
          <Alert sx={notesx.alert} variant='outlined' severity='error'>
            Note unable to be posted.
          </Alert>
        )}
        <DialogContentText>{confirmText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={notesx.dialogActions}>
        <Button
          size='small'
          onClick={() => {
            setError(false);
            setConfirmOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size='small'
          onClick={handleConfirm}
          autoFocus
          variant='contained'
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
