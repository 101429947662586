import MPDetailsModal from '@components/Battery/Tesla/MPDetailsModal';
import DeviceDataTable, {
  DeviceDataTableRow,
} from '@components/_shared/DeviceDataTable';
import UIBox from '@components/_shared/UIBox';
import { useCurrentTscDeviceContext } from '@hooks/useCurrentTscDevice';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import storeConnector from '@store/storeConnector';
import { useEffect, useMemo, useState } from 'react';
import MPCommandsModal from './Commands/MP/MPCommandsModal';

function MegapackList({ role }: { role: UserRole }) {
  const { currentTscDevice } = useCurrentTscDeviceContext();
  const [cmdModal, setCmdModal] = useState(false);
  const [mpDetailsModal, setMpDetailsModal] = useState(false);

  const {
    data: widgetConfig,
    isLoading,
    refetch,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypeDeviceList>(
    'mpList',
    {
      sourceDeviceId: currentTscDevice?.deviceId,
    },
    { enabled: currentTscDevice?.deviceId !== undefined },
  );

  useEffect(() => {
    if (currentTscDevice?.deviceId) {
      refetch();
    }
  }, [currentTscDevice, refetch]);

  const memoizedDevices = useMemo(
    () => widgetConfig?.devices || [],
    [widgetConfig?.devices],
  );

  const memoizedDataPoints = useMemo(
    () => widgetConfig?.dataPoints || [],
    [widgetConfig?.dataPoints],
  );

  const memoizedPointConfigRefs = useMemo(
    () => widgetConfig?.pointConfigRefs || [],
    [widgetConfig?.pointConfigRefs],
  );

  const [deviceIdForCmdModal, setDeviceIdForCmdModal] = useState<string>('');
  const deviceNameForCmdModal = useMemo(
    () =>
      widgetConfig?.devices?.find(
        (device) => device.deviceId === deviceIdForCmdModal,
      )?.name,
    [widgetConfig?.devices, deviceIdForCmdModal],
  );

  return (
    <UIBox
      header={widgetConfig?.header}
      subheader={currentTscDevice?.name}
      isLoading={isLoading || (!isLoading && !widgetConfig && !isError)}
      isError={isError || (!isLoading && !widgetConfig)}
    >
      <DeviceDataTable
        id={'megapack-list'}
        enableCmdBtnColumn={isCommandsEnabled() && isActionRole(role)}
        hideFooter
        devices={memoizedDevices}
        dataPoints={memoizedDataPoints}
        pointConfigRefs={memoizedPointConfigRefs}
        disableRowSelectionOnClick
        onRowClick={({ id }: DeviceDataTableRow) => {
          setDeviceIdForCmdModal(id);
          setMpDetailsModal(true);
        }}
        openCmdModal={(deviceId) => {
          setDeviceIdForCmdModal(deviceId);
          setCmdModal(true);
        }}
        getRowClassName={() => 'MuiDataGrid-clickable'}
      />
      <MPCommandsModal
        tscName={currentTscDevice?.name || ''}
        mpId={deviceIdForCmdModal}
        mpName={deviceNameForCmdModal || ''}
        onClose={() => setCmdModal(false)}
        open={cmdModal}
      />
      <MPDetailsModal
        mpId={deviceIdForCmdModal}
        mpName={deviceNameForCmdModal || ''}
        tscName={currentTscDevice?.name || ''}
        open={mpDetailsModal}
        onClose={() => setMpDetailsModal(false)}
      />
    </UIBox>
  );
}

export default storeConnector(MegapackList, {
  user: ['role'],
});
