import { publishMQTT, topicTimeoutIds } from '@store/actionCreators/mqtt';
import { dispatcher, store } from '@store';
import mqtt, { IClientOptions, IPublishPacket } from 'mqtt';
import { MQTT_WSS_URL } from '@utils';
import { IMqttMessage } from '@src/types/Mqtt';

const topicTimeoutIdsStore = topicTimeoutIds as Record<string, number>;
export const TOPIC_TIMEOUT_MS = 5 * 1000;

const handleBadParse = (topic: string, msg: unknown) => {
  // Proper error. However, very common during dev and therefore noisy. Downgrading to debug.
  console.debug(`BAD MQTT MESSAGE PAYLOAD, ERROR PARSING`, {
    topic,
    msg,
  });
  return { value: 'ERR', ts: new Date().toISOString() };
};

function processMessage(t: string, messageBuffer: Buffer) {
  let parsedMsg: IMqttMessage;
  try {
    const bufferAsString = messageBuffer.toString();
    parsedMsg = JSON.parse(messageBuffer.toString());
    if (
      parsedMsg.value === null ||
      parsedMsg.ts === null ||
      parsedMsg.value === undefined ||
      parsedMsg.ts === undefined
    ) {
      parsedMsg = handleBadParse(t, bufferAsString);
    }
  } catch (e) {
    parsedMsg = handleBadParse(t, e);
  }
  if (topicTimeoutIdsStore[t] !== 0) {
    window.clearTimeout(topicTimeoutIdsStore[t]);
  }
  topicTimeoutIdsStore[t] = window.setTimeout(
    () =>
      publishMQTT(
        `ERROR/topic_msg_timeout_errors`,
        JSON.stringify({
          msg: `Warn: ${TOPIC_TIMEOUT_MS}ms since last msg to ${t}`,
          topic: t,
          lastSent: parsedMsg.ts,
          lastValue: parsedMsg.value,
          ts: new Date().toISOString(),
        }),
      ),
    TOPIC_TIMEOUT_MS, // 5 seconds
  );

  const { topicDict, subsMqttTopicCbDict } = store.getState().mqtt;
  const cbTopic = topicDict?.[t];
  // If not a topic from dictionary, exit without attempting to process;
  if (!cbTopic) {
    return;
  }
  const deviceId = cbTopic.split('/')[1];
  const field = cbTopic.split('/')[2];
  const sourceDeviceId = cbTopic.split('/')[3];

  if (
    subsMqttTopicCbDict?.[cbTopic] !== undefined &&
    subsMqttTopicCbDict?.[cbTopic] !== null &&
    subsMqttTopicCbDict?.[cbTopic].cbs !== undefined &&
    subsMqttTopicCbDict?.[cbTopic].cbs !== null
  ) {
    subsMqttTopicCbDict[cbTopic].cbs.forEach((cb) => {
      cb(
        {
          [field]: parsedMsg.value,
        },
        deviceId,
        parsedMsg.ts,
        { topicName: t, sourceDeviceId },
      );
    });
  }
}

export async function generateMqttClient(): Promise<mqtt.MqttClient> {
  const state = store.getState();
  const { username } = state.user;
  const password = state.user.keycloak?.token;
  const options: IClientOptions = {
    clientId: `EMS-WEB-UI-${state.user.username}-${Math.random()
      .toString(16)
      .slice(2)}`,
    keepalive: 60, // sec (1min)
    reschedulePings: true,
    protocolId: 'MQTT',
    protocolVersion: 5,
    clean: true,
    reconnectPeriod: 1000, // ms (1s)
    connectTimeout: 60 * 1000, // ms (1min)
    rejectUnauthorized: true,
    resubscribe: true,
    // Below is required on top of passing username/password options to client due to background token refresh
    transformWsUrl: (url, _options, client) => {
      const currentState = store.getState();
      // eslint-disable-next-line no-param-reassign
      client.options.username = currentState.user.username;
      // eslint-disable-next-line no-param-reassign
      client.options.password = currentState.user.keycloak?.token;
      return url;
    },
  };

  return mqtt.connectAsync(MQTT_WSS_URL, {
    ...options,
    username,
    password,
  });
}

export default async function initMqttClient(): Promise<void> {
  const state = store.getState();
  if (state.mqtt.clientMQTT) {
    state.mqtt.clientMQTT.end();
  }
  const MQTT_CLIENT = await generateMqttClient();

  // TODO remove after refactoring existing UnitTable using DeviceDataTable
  // Setting to 130, we support max 100 units,
  // current UnitTableItem is scoped and attaches x amount of of listeners
  // to MQTT_CLIENT
  MQTT_CLIENT.setMaxListeners(130);

  MQTT_CLIENT.on('error', (err: unknown) => {
    console.error(err);
  });

  MQTT_CLIENT.on('connect', () => {
    console.info('Connection established. Subscribing to topics');
  });

  MQTT_CLIENT.on(
    'message',
    (topic: string, message: Buffer, _packet: IPublishPacket) => {
      processMessage(topic, message);
    },
  );

  MQTT_CLIENT.on('close', () => {
    console.info('Connection closed');
  });

  store.dispatch(
    dispatcher('UPDATE_MQTT_MODULE', {
      clientMQTT: MQTT_CLIENT,
    }),
  );
}
