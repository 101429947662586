import { LOOKBACK_MS, TIMELINE_REFRESH } from '@components/Home/Alerts/config';
import { ConfigState } from '@store/reducers/configData';
import { ServiceState } from '@store/reducers/serviceData';
import { getTsDueTimeMode } from '@utils/index_ts';
import { Dayjs } from 'dayjs';

const getCrossHairStyle = () => ({
  stroke: {
    color: '#000',
    width: 1,
  },
  show: true,
  position: 'front',
  fill: {
    color: '#000',
  },
});

const getCustomTooltip =
  (siteMeta: ConfigState['siteMeta'], timeMode: ServiceState['timeMode']) =>
  ({
    seriesIndex,
    dataPointIndex,
    w,
  }: {
    seriesIndex: number;
    dataPointIndex: number;
    w: {
      globals: {
        initialSeries: {
          name: string;
          data: { alertName: string; y: number[] }[];
        }[];
      };
    };
  }) => {
    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
    const date1 = getTsDueTimeMode(
      siteMeta,
      timeMode,
      new Date(data.y[0]).toISOString(),
    );
    const date2 = getTsDueTimeMode(
      siteMeta,
      timeMode,
      new Date(data.y[1]).toISOString(),
    );
    return (
      `<div style='arrow-box'>` +
      `<div><b>Alert name</b>: '${data.alertName}'</div>` +
      `<div><b>Start</b>: ${date1.format('HH:mm:ss')} ${date1.format(
        'z',
      )}</div>` +
      `<div><b>End</b>: ${date2.format('HH:mm:ss')} ${date2.format(
        'z',
      )}</div>` +
      `<div><b>Alert type</b>: ${w.globals.initialSeries[seriesIndex].name}</div>` +
      `</div>`
    );
  };

const xAxisLabelFormatter =
  (siteMeta: ConfigState['siteMeta'], timeMode: ServiceState['timeMode']) =>
  (epochDate: string) =>
    getTsDueTimeMode(
      siteMeta,
      timeMode,
      new Date(epochDate).toISOString(),
    ).format('HH:mm');

const prepTimelineChartOptions = (
  historicalAlertsCount: number,
  activeAlertsCount: number,
  lookBackPeriod: string,
  freezeEvents: Record<string, () => void>,
  siteMeta: ConfigState['siteMeta'],
  timeMode: ServiceState['timeMode'],
  start?: Dayjs,
  stop?: Dayjs,
  // eslint-disable-next-line max-params
): ApexCharts.ApexOptions => {
  const isCustom = lookBackPeriod === 'custom';
  return {
    chart: {
      id: 'alerts-timeline',
      width: '100%',
      height: 80,
      type: 'rangeBar',
      events: freezeEvents,
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: !isCustom,
          speed: !isCustom ? TIMELINE_REFRESH[lookBackPeriod] : undefined,
        },
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    yaxis: {
      show: false,
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 24,
      tickPlacement: 'between',
      max: !isCustom || !stop ? Date.now() : stop.valueOf(),
      min:
        !isCustom || !start
          ? Date.now() - LOOKBACK_MS[lookBackPeriod]
          : start.valueOf(),
      labels: {
        show: true,
        formatter: xAxisLabelFormatter(siteMeta, timeMode),
      },
      axisBorder: { show: true },
      crosshairs: getCrossHairStyle(),
      tooltip: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%',
        rangeBarGroupRows: true,
      },
    },
    grid: {
      show: false,
      row: {
        colors:
          activeAlertsCount > 0 && historicalAlertsCount === 0
            ? ['#e63449']
            : ['#40c363'],
        opacity: 1,
      },
    },
    fill: {
      type: 'solid',
    },
    tooltip: {
      enabled: true,
      custom: getCustomTooltip(siteMeta, timeMode),
      theme: 'dark',
    },
  };
};

export default prepTimelineChartOptions;
