import { generateMqttClient } from '@pages/_loader/initMqttClient.loader';
import { MqttClient } from 'mqtt';
import { ReactElement, useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import Notifications from '@components/_elements/Notifications/Notifications';
import SiteUnitCommandsRTWrapper from '@components/_shared/SiteUnitCommandsRTWrapper';
import UserPanel from '@components/_shared/UserPanel/UserPanel';
import ToggleRDGPopup from '@components/_elements/ToggleRDGPopup';
import SiteMetaEditorPopup from '@components/_elements/SiteMetaEditorPopup';
import SettingsPopup from '@components/_elements/SettingsPopup';
import SupportPopup from '@components/_elements/SupportPopup';
import FastStopPopup from '@components/_elements/FastStopPopup';
import ClearAlertsPopup from '@components/_elements/ClearAlertsPopup';
import AudioNotifications from '@src/components/AudioNotifications';
import useLayoutPermission from '@src/hooks/useLayoutPermission';
import NotesPopup from '@components/_shared/Notes/NotesModal';
import AppHeader from './AppHeader';
import LeftBar from './LeftBar';

export interface AppLayoutProps {
  topBar?: ReactElement;
  isNested?: boolean;
}

function AppLayout({ topBar, isNested }: AppLayoutProps): ReactElement {
  const { leftbar } = useLayoutPermission();
  const [showUserPanel, setShowUserPanel] = useState(false);
  const [supportPopup, setSupportPopup] = useState(false);
  const [fastStopPopup, setFastStopPopup] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [clearAlertsPopup, setClearAlertsPopup] = useState(false);
  const [toggleRDGPopup, setToggleRDGPopup] = useState(false);
  const [siteMetaEditorPopup, setSiteMetaEditorPopup] = useState(false);
  const [useIsNoCommsMqttClient, setUseIsNoCommsMqttClient] =
    useState<MqttClient>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    const startClient = async () => {
      const client = await generateMqttClient();
      setUseIsNoCommsMqttClient(client);
    };
    startClient();
    return () => {
      useIsNoCommsMqttClient?.end();
    };
  }, []);

  const renderContent = (topMenuBar?: ReactElement) =>
    topMenuBar ? (
      <div className='topbar-section' style={{ position: 'relative' }}>
        {topMenuBar}
        <Outlet />
      </div>
    ) : (
      <Outlet />
    );

  return isNested ? (
    renderContent(topBar)
  ) : (
    <div className='page'>
      <AppHeader
        useIsNoCommsMqttClient={useIsNoCommsMqttClient}
        setShowUserPanel={setShowUserPanel}
      />
      <div className='leftbar-section'>
        {leftbar && (
          <div className='leftbar'>
            <LeftBar />
          </div>
        )}

        <div id='app-layout-content' className='content'>
          {renderContent(topBar)}
        </div>
      </div>
      <Notifications />
      <AudioNotifications />
      <SiteUnitCommandsRTWrapper />
      <NotesPopup />
      <UserPanel
        showUserPanel={showUserPanel}
        close={() => setShowUserPanel(false)}
        triggerClearAlertsPopup={(v: boolean) => setClearAlertsPopup(v)}
        triggerFastStopPopup={(v: boolean) => setFastStopPopup(v)}
        triggerSettingsPopup={(v: boolean) => setSettingsPopup(v)}
        triggerSupportPopup={(v: boolean) => setSupportPopup(v)}
        triggerToggleRDG={(v: boolean) => setToggleRDGPopup(v)}
        triggerSiteMetaEditor={(v: boolean) => setSiteMetaEditorPopup(v)}
      />
      <SettingsPopup
        isOpen={settingsPopup}
        close={() => setSettingsPopup(false)}
      />
      <SupportPopup
        isOpen={supportPopup}
        close={() => setSupportPopup(false)}
      />
      <FastStopPopup
        isOpen={fastStopPopup}
        close={() => setFastStopPopup(false)}
      />
      <ClearAlertsPopup
        isOpen={clearAlertsPopup}
        close={() => setClearAlertsPopup(false)}
      />
      <ToggleRDGPopup
        isOpen={toggleRDGPopup}
        close={() => setToggleRDGPopup(false)}
      />
      <SiteMetaEditorPopup
        isOpen={siteMetaEditorPopup}
        close={() => setSiteMetaEditorPopup(false)}
      />
    </div>
  );
}

export default AppLayout;
