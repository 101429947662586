import { SiteMeta } from '@src/types/SiteMeta';
import { useQueryClient } from '@tanstack/react-query';
import useApiMutation from '@src/hooks/useApiMutation';
import QueryKeys from '@src/constants/queryKeys';

export default () => {
  const queryClient = useQueryClient();

  return useApiMutation<Omit<SiteMeta, 'id' | 'createdBy' | 'createdAt'>, void>(
    {
      apiPath: '/site-meta',
      method: 'POST',
      useV2API: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.siteMeta] });
      },
    },
  );
};
