/* eslint-disable max-lines */
import { PointConfig } from '@src/types/PointConfig';

const pointConfigs: Omit<PointConfig, 'id' | 'createdAt' | 'updatedAt'>[] = [
  {
    deviceType: 'bms',
    dataType: 'float',
    pointName: 'AvgCellV',
    pointAlias: 'ic_bms1_avg_cell_v',
    displayName: 'BMS 1 Average Cell V',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'bms',
    dataType: 'float',
    pointName: 'AvgCellT',
    pointAlias: 'ic_bms2_avg_cell_t',
    displayName: 'BMS 2 Average Cell T',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Meter_Real_Power',
    pointAlias: 'meter_real_power',
    displayName: 'Meter Real Power (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Meter Reactive Power',
    pointAlias: 'meter_reactive_power',
    displayName: 'Meter Reactive Power (kVAR)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Remaining Battery Energy',
    pointAlias: 'remaining_battery_energy',
    displayName: 'Remaining Battery Energy (Wh)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'integer',
    pointName: 'Number of Available Megapacks',
    pointAlias: 'number_of_available_megapacks',
    displayName: 'Number of Available Megapacks',
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Dispatchable Charge Power',
    pointAlias: 'dispatchable_charge_power',
    displayName: 'Dispatchable Charge Power (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Dispatchable Discharge Power',
    pointAlias: 'dispatchable_discharge_power',
    displayName: 'Dispatchable Discharge Power (kVAR)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Battery Direct Real Power Command',
    pointAlias: 'battery_direct_real_power_command',
    displayName: 'Battery Direct Real Power Command (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Battery Direct Reactive Power Command',
    pointAlias: 'battery_direct_reactive_power_command',
    displayName: 'Battery Direct Reactive Power Command (kVAR)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'string',
    pointName: 'State Request',
    pointAlias: 'state_request',
    displayName: 'State Request',
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Meter Voltage',
    pointAlias: 'meter_voltage',
    displayName: 'Meter Voltage (kV)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'tsc',
    dataType: 'float',
    pointName: 'Meter Frequency',
    pointAlias: 'meter_frequency',
    displayName: 'Meter Frequency (Hz)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'boolean_integer',
    pointName: 'BI_401_Loss_of_Megapack_Communication',
    pointAlias: 'loss_of_megapack_communication',
    displayName: 'Megapack Comms',
    invertDisplay: true,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'boolean_integer',
    pointName: 'BI_407_AC_Breaker_Status',
    pointAlias: 'ac_breaker_status',
    displayName: 'AC Breaker Status',
    invertDisplay: true,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'boolean_integer',
    pointName: 'BI_417_Megapack_Disabled',
    pointAlias: 'megapack_disabled',
    displayName: 'Megapack Enabled Test',
    invertDisplay: true,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_State_of_Energy',
    pointAlias: 'megapack_state_of_energy',
    displayName: 'Megapack State of Energy (%)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    unitOfMeasurement: 'kW',
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Real_Power',
    pointAlias: 'megapack_real_power',
    displayName: 'Megapack Real Power (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Reactive_Power',
    pointAlias: 'megapack_reactive_power',
    displayName: 'Megapack Reactive Power (kVAR)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Real_Power_Target',
    pointAlias: 'megapack_real_power_target',
    displayName: 'Megapack Real Power Target (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Reactive_Power_Target',
    pointAlias: 'megapack_reactive_power_target',
    displayName: 'Megapack Reactive Power Target (kVAR)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Frequency',
    pointAlias: 'megapack_frequency',
    displayName: 'Megapack Frequency (Hz)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_AC_Voltage',
    pointAlias: 'megapack_ac_voltage',
    displayName: 'Megapack AC Voltage (V)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Phase_A_Voltage',
    pointAlias: 'megapack_phase_a_voltage',
    displayName: 'Megapack Phase A Voltage (V)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Phase_B_Voltage',
    pointAlias: 'megapack_phase_b_voltage',
    displayName: 'Megapack Phase B Voltage (V)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Phase_C_Voltage',
    pointAlias: 'megapack_phase_c_voltage',
    displayName: 'Megapack Phase C Voltage (V)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Phase_A_Current',
    pointAlias: 'megapack_phase_a_current',
    displayName: 'Megapack Phase A Current (A)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Phase_B_Current',
    pointAlias: 'megapack_phase_b_current',
    displayName: 'Megapack Phase B Current (A)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Phase_C_Current',
    pointAlias: 'megapack_phase_c_current',
    displayName: 'Megapack Phase C Current (A)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Maximum_Battery_Temperature',
    pointAlias: 'maximum_battery_temperature',
    displayName: 'Maximum Battery Temperature (C)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Ambient_Temperature',
    pointAlias: 'ambient_temperature',
    displayName: 'Ambient Temperature (C)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Available_Charge_Power',
    pointAlias: 'megapack_available_charge_power',
    displayName: 'Megapack Available Charge Power (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'mp',
    dataType: 'float',
    pointName: 'Megapack_Available_Discharge_Power',
    pointAlias: 'megapack_available_discharge_power',
    displayName: 'Megapack Available Discharge Power (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'connection_alive',
    pointAlias: 'connection_alive',
    displayName: 'Connection Alive',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'AllowedMaxP',
    pointAlias: 'allowed_max_p',
    displayName: 'Allowed Max P (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'AllowedMaxQ',
    pointAlias: 'allowed_max_q',
    displayName: 'Allowed Max Q (kVAR)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'AllowedMinP',
    pointAlias: 'allowed_min_p',
    displayName: 'Allowed Min P (kW)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'AllowedMinQ',
    pointAlias: 'allowed_min_q',
    displayName: 'Allowed Min Q (kVAR',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'MaxCellT',
    pointAlias: 'max_cell_t',
    displayName: 'Max Cell T (C)',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'MinCellT',
    pointAlias: 'min_cell_t',
    displayName: 'Min Cell T',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'AvgCellT',
    pointAlias: 'avg_cell_t',
    displayName: 'Avg Cell T',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'string',
    pointName: 'InvStatus',
    pointAlias: 'inv_status',
    displayName: 'Inv Status',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'PCS_P',
    pointAlias: 'pcs_p',
    displayName: 'PCS P',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'PCS_Q',
    pointAlias: 'pcs_q',
    displayName: 'PCS Q',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'Pset',
    pointAlias: 'pset',
    displayName: 'PSET',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'Qset',
    pointAlias: 'qset',
    displayName: 'QSET',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'string',
    pointName: 'RunMode',
    pointAlias: 'run_mode',
    displayName: 'Run Mode',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'SOC',
    pointAlias: 'soc',
    displayName: 'SOC',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'integer',
    pointName: 'racks_online',
    pointAlias: 'racks_online',
    displayName: 'Racks Online',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'kW',
    pointAlias: 'kw',
    displayName: 'kW',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'kVAR',
    pointAlias: 'kvar',
    displayName: 'kVAR',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'MinAmbT',
    pointAlias: 'min_amb_t',
    displayName: 'Minimum Ambient Temperature',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'AvgAmbT',
    pointAlias: 'avg_amb_t',
    displayName: 'Average Ambient Temperature',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'MaxAmbT',
    pointAlias: 'max_amb_t',
    displayName: 'Maximum Ambient Temperature',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'float',
    pointName: 'MaxCellT',
    pointAlias: 'max_cell_t',
    displayName: 'Max Cell Temperature',
    decimalPrecision: 2,
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'ACBreaker',
    pointAlias: 'ac_breaker',
    displayName: 'AC Breaker',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'DCDisconnect',
    pointAlias: 'dc_disconnect',
    displayName: 'DC Disconnect',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'integer',
    pointName: 'HVAC_STATUS_UnitState',
    pointAlias: 'hvac_status_unit_state',
    displayName: 'HVAC Status Unit State',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'FanStatus',
    pointAlias: 'fan_status',
    displayName: 'Fan Status',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'LO_CO_ALARM',
    pointAlias: 'lo_co_alarm',
    displayName: 'Lo Co Alarm',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'LO_HYDROGEN_ALARM',
    pointAlias: 'lo_hydrogen_alarm',
    displayName: 'LO Hydrogen Alarm',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'HI_HYDROGEN_ALARM',
    pointAlias: 'hi_hydrogen_alarm',
    displayName: 'HI Hydrogen Alarm',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'VESDA_FIRE_ALARM',
    pointAlias: 'vesda_fire_alarm',
    displayName: 'Vesda Fire Alarm',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'integer',
    pointName: 'VESDA_ACTION',
    pointAlias: 'vesda_action',
    displayName: 'Vesda Action',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'unitController',
    dataType: 'boolean_integer',
    pointName: 'FA_DISCHARGE_ALARM',
    pointAlias: 'fa_discharge_alarm',
    displayName: 'FA Discharge Alarm',
    isSiteControllerSourceDevicePointConfig: false,
  },
  {
    deviceType: 'rtac',
    dataType: 'float',
    pointName: '891_status',
    pointAlias: '891_status',
    displayName: '891 Status',
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'rtac',
    dataType: 'float',
    pointName: '52h1_status',
    pointAlias: '52h1_status',
    displayName: '52h1 Status',
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'rtac',
    dataType: 'float',
    pointName: '52f1_status',
    pointAlias: '52f1_status',
    displayName: '52f1 Status',
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'rtac',
    dataType: 'float',
    pointName: '52f2_status',
    pointAlias: '52f2_status',
    displayName: '52f2 Status',
    isSiteControllerSourceDevicePointConfig: true,
  },
  {
    deviceType: 'bms',
    dataType: 'float',
    pointName: 'AvgCellV',
    pointAlias: 'avg_cell_v',
    displayName: 'Average Cell V',
    isSiteControllerSourceDevicePointConfig: false,
  },
];

export default pointConfigs;
