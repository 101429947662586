import { SiteMeta } from '@src/types/SiteMeta';
import { TimeMode, isTimeMode } from '@types';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import tzlookup from 'tz-lookup';

export const MuiLicenseKey =
  '8280983b471b81069d21cc506d2802a0Tz05OTM2NyxFPTE3NTk2MzY2MjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==';

export interface TsDueTimeModeParams {
  ts?: string | unknown;
  timeMode?: TimeMode | unknown;
  lat?: string | unknown;
  long?: string | unknown;
  format?: string | unknown;
  splited?: boolean | unknown;
  returnMoment?: boolean | unknown;
  prevTimeMode?: string | unknown;
}

export const sleep = (ms = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const purgeEmptyStrings = (topicFn: () => string) =>
  topicFn().split('/').filter(Boolean).join('/');

export const capitalized = (w: string) =>
  w.charAt(0).toUpperCase() + w.slice(1);

export function tsDueTimeMode(
  data: TsDueTimeModeParams,
): string | string[] | Moment {
  const {
    ts,
    timeMode,
    lat,
    long,
    format,
    splited,
    returnMoment,
    prevTimeMode,
  }: TsDueTimeModeParams = data;
  const modesTz = {
    UTC: 'UTC',
    Site: tzlookup(lat as number, long as number),
    User: moment.tz.guess(),
  };
  const tz = modesTz[timeMode as never];
  if (typeof prevTimeMode === 'string' && isTimeMode(prevTimeMode)) {
    const momentDate = moment
      .tz(typeof ts === 'string' ? ts : moment(), modesTz[prevTimeMode])
      .tz(tz);
    if (returnMoment === true) {
      return momentDate;
    }
    const date = momentDate.format(
      typeof format === 'string' ? format : 'YYYY-MM-DD HH:mm:ss',
    );
    return splited === true ? [date.split(' ')[0], date.split(' ')[1]] : date;
  }
  const momentDate = moment.tz(typeof ts === 'string' ? ts : moment(), tz);
  if (returnMoment === true) {
    return momentDate;
  }
  const date = momentDate.format(
    typeof format === 'string' ? format : 'YYYY-MM-DD HH:mm:ss',
  );
  return splited === true ? [date.split(' ')[0], date.split(' ')[1]] : date;
}

export interface DestructuredTopicV1 {
  siteId?: string;
  deviceName?: string;
  deviceId?: string;
  field?: string;
  type?: string;
  v: 'V1';
}

export function getTsDueTimeMode(
  siteMeta: SiteMeta,
  timeMode: TimeMode,
  ts?: string,
) {
  return tsDueTimeMode({
    timeMode,
    lat: siteMeta.GPSLat,
    long: siteMeta.GPSLong,
    returnMoment: true,
    ts: ts || moment(),
  }) as Moment;
}

const destructureTopicV1 = (topic: string): DestructuredTopicV1 => {
  let siteId;
  let deviceName;
  let deviceId;
  let field;
  let type;
  const topicSplit = topic.split('/');
  if (topicSplit.length === 4) {
    // prettier-ignore
    [deviceName, deviceId, field, type] = topicSplit;
  } else if (topicSplit.length === 5) {
    // prettier-ignore
    [siteId, deviceName, deviceId, field, type] = topicSplit;
  }
  return { siteId, deviceName, deviceId, field, type, v: 'V1' };
};

export interface DestructuredTopicV2 {
  siteControllerId: string;
  unitControllerId?: string;
  sourceDeviceType?: string;
  sourceDeviceId?: string;
  pointName: string;
  valueType: string;
  v: 'V2';
}

/// //////
// For explanation on t1, t2, t3, t4
// https://github.com/FEMSDevelopers/org/wiki/Topic-and-Payload-Guidelines-(Subject-to-Change)#structure
/// /////
const destructureTopicV2 = (topic: string): DestructuredTopicV2 => {
  const topicParts = topic.split('/');
  const [
    _EMS, // ems/
    // /site/
    siteControllerId,
    siteRootOrUnitOrSiteSrcDeviceType,
    ,
    possibleSourceDeviceType,
  ] = topicParts;
  let unitControllerId;
  let sourceDeviceType;
  let sourceDeviceId;
  let pointName;
  let valueType;

  if (siteRootOrUnitOrSiteSrcDeviceType === 'root') {
    // t1
    // ems/site/:siteSN/root/:pointName/:valueType
    // prettier-ignore
    [, , , , pointName, valueType] = topicParts;
  } else if (siteRootOrUnitOrSiteSrcDeviceType === 'unit') {
    if (possibleSourceDeviceType === 'root') {
      // t3
      // ems/site/:siteSN/unit/:unitSN/root/:pointName/:valueType
      // prettier-ignore
      [, , , , unitControllerId, /* root */ , pointName, valueType] =
        topicParts;
    } else {
      // t4
      // ems/site/:siteSN/unit/:unitSN/pcs/pcs_1/:pointName/:valueType
      // prettier-ignore
      [
        ,
        ,
        ,
        ,
        unitControllerId,
        sourceDeviceType,
        sourceDeviceId,
        pointName,
        valueType,
      ] = topicParts;
    }
  } else {
    // t2
    // ems/site/:siteSN/meter/meter_1/:pointName/:valueType
    // prettier-ignore
    [, , , sourceDeviceType, sourceDeviceId, pointName, valueType] = topicParts;
  }

  return {
    siteControllerId,
    unitControllerId,
    sourceDeviceType,
    sourceDeviceId,
    pointName,
    valueType,
    v: 'V2',
  };
};

export const destructureTopic = (
  useNewTopicStructure: boolean | undefined,
  topic: string,
): DestructuredTopicV1 | DestructuredTopicV2 => {
  if (useNewTopicStructure) {
    return destructureTopicV2(topic);
  }
  return destructureTopicV1(topic);
};

export const getFromLS = <T>(storageRootKey: string, key: string): T | null => {
  const rootStorage = localStorage.getItem(storageRootKey);
  if (rootStorage) {
    try {
      const ls = JSON.parse(rootStorage);
      return ls[key];
    } catch (_e) {
      return null;
    }
  }
  return null;
};

export const saveToLS = (
  rootStorageKey: string,
  key: string,
  value: unknown,
) => {
  localStorage.setItem(
    rootStorageKey,
    JSON.stringify({
      [key]: value,
    }),
  );
};
export function isStringArray(array: unknown[]): array is string[] {
  return array.every((item) => typeof item === 'string');
}

export function getTopicForTooltip(
  _pointName: string,
  _topicDict: Record<string, string>,
): string {
  return _pointName;
  // return (
  //   Object.keys(topicDict).find((item) => item.includes(`/${pointName}/`)) ||
  //   `No matching topic fetched for point name: ${pointName}`
  // );
}

export const parseOrReturn = (value: string): object | string => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export function isNumberTypeGuard(value: unknown): value is number {
  return typeof value === 'number';
}

export function isValidNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function formatFloat(
  value: number,
  scalingFactor: number | null | undefined,
  decimalPrecision: number | null | undefined,
  defaultPrecision: number,
): string {
  const targetPrecision = isValidNumber(decimalPrecision)
    ? decimalPrecision
    : defaultPrecision;
  const targetScalingFactor = isValidNumber(scalingFactor) ? scalingFactor : 1;
  return (value * targetScalingFactor).toFixed(targetPrecision);
}

function getSiteTimeZone(siteMeta: SiteMeta): string | undefined {
  if (!siteMeta.GPSLat || !siteMeta.GPSLong) {
    return undefined;
  }
  const lat = Number(siteMeta.GPSLat);
  const lon = Number(siteMeta.GPSLong);
  if (!isValidNumber(lat) || !isValidNumber(lon)) {
    throw new Error('Invalid GPS coordinates');
  }
  return tzlookup(lat, lon);
}

export function getDateTimeFromTimeMode(
  timeMode: TimeMode,
  siteMeta: SiteMeta,
): dayjs.Dayjs {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const lat = Number(siteMeta.GPSLat);
  const lon = Number(siteMeta.GPSLong);
  if (!isValidNumber(lat) || !isValidNumber(lon)) {
    throw new Error('Invalid GPS coordinates');
  }

  const siteTimeZone = getSiteTimeZone(siteMeta);

  switch (timeMode) {
    case 'UTC':
      return dayjs().utc();
    case 'Site':
      return dayjs().tz(siteTimeZone);
    default: // this default accounts for the User TimeMode
      return dayjs();
  }
}

export const isDayjsOrNull = (val: unknown): val is Dayjs | null | undefined =>
  val === null || val === undefined || dayjs.isDayjs(val);
