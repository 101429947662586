import getTemperatureString from '@utils/getTemperatureString';
import './WeatherForecastWidget.scoped.scss';
import BlowingDust from '@assets/weather/BlowingDust.svg?react';
import Clear from '@assets/weather/Clear.svg?react';
import Cloudy from '@assets/weather/Cloudy.svg?react';
import Foggy from '@assets/weather/Foggy.svg?react';
import Haze from '@assets/weather/Haze.svg?react';
import MostlyClear from '@assets/weather/MostlyClear.svg?react';
import MostlyCloudy from '@assets/weather/MostlyCloudy.svg?react';
import PartlyCloudy from '@assets/weather/PartlyCloudy.svg?react';
import Smoky from '@assets/weather/Smoky.svg?react';
import Breezy from '@assets/weather/Breezy.svg?react';
import Windy from '@assets/weather/Windy.svg?react';
import Drizzle from '@assets/weather/Drizzle.svg?react';
import HeavyRain from '@assets/weather/HeavyRain.svg?react';
import IsolatedThunderstorms from '@assets/weather/IsolatedThunderstorms.svg?react';
import Rain from '@assets/weather/Rain.svg?react';
import SunShowers from '@assets/weather/SunShowers.svg?react';
import ScatteredThunderstorms from '@assets/weather/ScatteredThunderstorms.svg?react';
import StrongStorms from '@assets/weather/StrongStorms.svg?react';
import Thunderstorms from '@assets/weather/Thunderstorms.svg?react';
import Frigid from '@assets/weather/Frigid.svg?react';
import Hail from '@assets/weather/Hail.svg?react';
import Hot from '@assets/weather/Hot.svg?react';
import Flurries from '@assets/weather/Flurries.svg?react';
import Sleet from '@assets/weather/Sleet.svg?react';
import Snow from '@assets/weather/Snow.svg?react';
import SunFlurries from '@assets/weather/SunFlurries.svg?react';
import WintryMix from '@assets/weather/WintryMix.svg?react';
import Blizzard from '@assets/weather/Blizzard.svg?react';
import BlowingSnow from '@assets/weather/BlowingSnow.svg?react';
import FreezingDrizzle from '@assets/weather/FreezingDrizzle.svg?react';
import FreezingRain from '@assets/weather/FreezingRain.svg?react';
import HeavySnow from '@assets/weather/HeavySnow.svg?react';
import Hurricane from '@assets/weather/Hurricane.svg?react';
import TropicalStorm from '@assets/weather/TropicalStorm.svg?react';

import moment from 'moment';
import storeConnector from '@store/storeConnector';
import Spinner from '@components/_elements/Spinner/Spinner';
import PageError from '@pages/PageError';

const WEATHER_ICON = {
  BlowingDust,
  Clear,
  Cloudy,
  Foggy,
  Haze,
  MostlyClear,
  MostlyCloudy,
  PartlyCloudy,
  Smoky,
  Breezy,
  Windy,
  Drizzle,
  HeavyRain,
  IsolatedThunderstorms,
  Rain,
  SunShowers,
  ScatteredThunderstorms,
  StrongStorms,
  Thunderstorms,
  Frigid,
  Hail,
  Hot,
  Flurries,
  Sleet,
  Snow,
  SunFlurries,
  WintryMix,
  Blizzard,
  BlowingSnow,
  FreezingDrizzle,
  FreezingRain,
  HeavySnow,
  Hurricane,
  TropicalStorm,
};

const WeatherForecastWidget = (props) => {
  let IconWeatherCurr = null;
  let IconWeatherFirst = null;
  let IconWeatherSecond = null;
  let IconWeatherThird = null;
  if (props.weatherForecast && !props.weatherForecast.error) {
    IconWeatherCurr =
      WEATHER_ICON[props.weatherForecast.currentWeather.conditionCode];
    IconWeatherFirst =
      WEATHER_ICON[props.weatherForecast.forecastDaily.days[1].conditionCode];
    IconWeatherSecond =
      WEATHER_ICON[props.weatherForecast.forecastDaily.days[2].conditionCode];
    IconWeatherThird =
      WEATHER_ICON[props.weatherForecast.forecastDaily.days[3].conditionCode];
  }
  return (
    <div className='weather-col' style={{ flex: 2 }}>
      <div className='weather-title'>
        {props.forecast ? 'Forecast' : 'Weather'}
      </div>
      {!props.weatherForecast ? (
        <div className='weather-block' style={{ position: 'relative' }}>
          <Spinner cover={'container'} />
        </div>
      ) : null}
      {props.weatherForecast && !props.weatherForecast.error ? (
        <div className='weather-block weather-block-forecast'>
          <div className='forecast-today'>
            <div className='forecast-today-label'>
              <span>TODAY</span>
            </div>
            <div className='forecast-today-icon'>
              {IconWeatherCurr ? (
                <IconWeatherCurr />
              ) : (
                <span>
                  {props?.weatherForecast?.currentWeather.conditionCode}
                </span>
              )}
            </div>
            <div className='forecast-today-info'>
              <div className='forecast-today-info-bold'>
                {getTemperatureString(
                  props.weatherForecast.currentWeather.temperature,
                  {
                    initialUnit: 'C',
                    decimals: 0,
                  },
                )}
              </div>
              <div className='forecast-today-info-span'>
                {/*props.weatherForecast.currentWeather.summary*/}
              </div>
            </div>
            <div className='forecast-today-tempday'>
              <span>
                {getTemperatureString(
                  props.weatherForecast.forecastDaily.days[0].temperatureMax,
                  {
                    initialUnit: 'C',
                    decimals: 0,
                  },
                )}
              </span>
              <span>
                {getTemperatureString(
                  props.weatherForecast.forecastDaily.days[0].temperatureMin,
                  {
                    initialUnit: 'C',
                    decimals: 0,
                  },
                )}
              </span>
            </div>
          </div>
          <div className='forecast-dow'>
            <div className='forecast-day'>
              <div className='forecast-day-icon'>
                {IconWeatherFirst ? (
                  <IconWeatherFirst />
                ) : (
                  <span>
                    {props.weatherForecast.forecastDaily.days[1].conditionCode}
                  </span>
                )}
              </div>
              <div className='forecast-day-temp'>
                <span>
                  {getTemperatureString(
                    props.weatherForecast.forecastDaily.days[1].temperatureMax,
                    {
                      initialUnit: 'C',
                      decimals: 0,
                    },
                  )}
                </span>
                <span>
                  {getTemperatureString(
                    props.weatherForecast.forecastDaily.days[1].temperatureMin,
                    {
                      initialUnit: 'C',
                      decimals: 0,
                    },
                  )}
                </span>
              </div>
              <div className='forecast-day-name'>
                {moment
                  .utc(
                    props.weatherForecast.forecastDaily.days[1].forecastStart,
                  )
                  .format('ddd')}
              </div>
            </div>
            <div className='forecast-day'>
              <div className='forecast-day-icon'>
                {IconWeatherSecond ? (
                  <IconWeatherSecond />
                ) : (
                  <span>
                    {props.weatherForecast.forecastDaily.days[2].conditionCode}
                  </span>
                )}
              </div>
              <div className='forecast-day-temp'>
                <span>
                  {getTemperatureString(
                    props.weatherForecast.forecastDaily.days[2].temperatureMax,
                    {
                      initialUnit: 'C',
                      decimals: 0,
                    },
                  )}
                </span>
                <span>
                  {getTemperatureString(
                    props.weatherForecast.forecastDaily.days[2].temperatureMin,
                    {
                      initialUnit: 'C',
                      decimals: 0,
                    },
                  )}
                </span>
              </div>
              <div className='forecast-day-name'>
                {moment
                  .utc(
                    props.weatherForecast.forecastDaily.days[2].forecastStart,
                  )
                  .format('ddd')}
              </div>
            </div>
            <div className='forecast-day'>
              <div className='forecast-day-icon'>
                {IconWeatherThird ? (
                  <IconWeatherThird />
                ) : (
                  <span>
                    {props.weatherForecast.forecastDaily.days[3].conditionCode}
                  </span>
                )}
              </div>
              <div className='forecast-day-temp'>
                <span>
                  {getTemperatureString(
                    props.weatherForecast.forecastDaily.days[3].temperatureMax,
                    {
                      initialUnit: 'C',
                      decimals: 0,
                    },
                  )}
                </span>
                <span>
                  {getTemperatureString(
                    props.weatherForecast.forecastDaily.days[3].temperatureMin,
                    {
                      initialUnit: 'C',
                      decimals: 0,
                    },
                  )}
                </span>
              </div>
              <div className='forecast-day-name'>
                {moment
                  .utc(
                    props.weatherForecast.forecastDaily.days[3].forecastStart,
                  )
                  .format('ddd')}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {props.weatherForecast && props.weatherForecast.error ? (
        <div className='weather-block' style={{ position: 'relative' }}>
          <PageError />
        </div>
      ) : null}
    </div>
  );
};

export default storeConnector(WeatherForecastWidget, {
  service: ['weatherForecast'],
});
