import { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import TopSwitchResistorSection from '@src/sld/scatterwash/components/TopSwitchResistorSection';
import MultiSwitchConnector from '@src/sld/components/MultiSwitchConnector/MultiSwitchConnector';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import useSubscription from '@src/hooks/useSubscription';
import { SiteMeta } from '@src/types/SiteMeta';
import { filterDataPointsWithValueByEngineeringId } from '@src/utils/dataPoints';
import { checkIfDataPointsDefined, getDataPointByPointName } from '../utils';
import BreakerStack from '@src/sld/scatterwash/components/BreakerStack';
import { DataPointWithValue } from '@src/types/DataPoint';

type ScatterwashSLDProps = {
  siteMeta: SiteMeta;
};

function Scatterwash_1_Diagram(props: ScatterwashSLDProps): ReactElement {
  const { siteMeta } = props;

  const { data: rtacWidgetConfig } =
    useGetInternalWidgetConfig<WidgetTypeDeviceList>(
      'scatterwash1SLD_rtac',
      {
        sourceDeviceId: siteMeta.siteId,
      },
      { enabled: true },
    );

  const { data: meterWidgetConfig } =
    useGetInternalWidgetConfig<WidgetTypeDeviceList>(
      'scatterwash1SLD_meter',
      {
        sourceDeviceId: siteMeta.siteId,
      },
      { enabled: true },
    );

  const rtacDataPointsWithValue = useSubscription(
    rtacWidgetConfig?.dataPoints || [],
  );
  const meterDataPointsWithValue = useSubscription(
    meterWidgetConfig?.dataPoints || [],
  );

  enum RTAC_DATA_POINTS {
    SWITCH = 'SUB_SEL_3555_52_X1_STATUS_52A',
    BREAKER_1 = 'BMS_MPSEL_3555_89_M1_STATUS_89A',
    BREAKER_2 = 'BMS_MPSEL_3555_52_11_BKR_STATUS_52A_CLOSED_INDICATION',
    BREAKER_3 = 'BMS_MPSEL_3555_52_12_BKR_STATUS_52A_CLOSED_INDICATION',
    BREAKER_4 = 'BMS_MPSEL_3555_52_13_BKR_STATUS_52A_CLOSED_INDICATION',
    BREAKER_5 = 'BMS_MPSEL_3555_52_14_BKR_STATUS_52A_CLOSED_INDICATION',
  }

  enum MeterEngineeringIds {
    METER_1 = 'SEL_411L_11_L1P',
    METER_2 = 'SEL_451_50_11',
    METER_3 = 'SEL_451_50_12',
    METER_4 = 'SEL_451_50_13',
    METER_5 = 'SEL_451_50_14',
  }

  const RTAC_DATA_POINTS_ARRAY = Object.values(RTAC_DATA_POINTS);
  const METER_DATA_POINTS_ARRAY = ['Hz', 'Voltage', 'Current', 'MW', 'MVAR'];

  let rtac_DataPoints: DataPointWithValue[] | undefined;
  let meter_1_DataPoints: DataPointWithValue[] | undefined;
  let meter_2_DataPoints: DataPointWithValue[] | undefined;
  let meter_3_DataPoints: DataPointWithValue[] | undefined;
  let meter_4_DataPoints: DataPointWithValue[] | undefined;
  let meter_5_DataPoints: DataPointWithValue[] | undefined;

  if (rtacDataPointsWithValue.length > 0) {
    rtac_DataPoints = filterDataPointsWithValueByEngineeringId(
      rtacDataPointsWithValue,
      `${rtacWidgetConfig?.devices[0].engineeringId}`,
      RTAC_DATA_POINTS_ARRAY,
    );
  }

  if (meterDataPointsWithValue.length > 0) {
    meter_1_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_1,
      METER_DATA_POINTS_ARRAY,
    );
    meter_2_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_2,
      METER_DATA_POINTS_ARRAY,
    );
    meter_3_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_3,
      METER_DATA_POINTS_ARRAY,
    );
    meter_4_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_4,
      METER_DATA_POINTS_ARRAY,
    );
    meter_5_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_5,
      METER_DATA_POINTS_ARRAY,
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      viewBox='400 -20 1200 700'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor', overflowX: 'auto' }}
    >
      <title>Scatterwash 1</title>

      {checkIfDataPointsDefined(rtac_DataPoints) && (
        <TopSwitchResistorSection
          translateXCoordinate={260}
          translateYCoordinate={19}
          meterDataPoints={meter_1_DataPoints}
          switchStatus={getDataPointByPointName(
            rtac_DataPoints,
            RTAC_DATA_POINTS.SWITCH,
          )}
          breakerStatus={getDataPointByPointName(
            rtac_DataPoints,
            RTAC_DATA_POINTS.BREAKER_1,
          )}
          meterLabel={MeterEngineeringIds.METER_1.toString()}
          breakerLabel='52 X1'
          switchLabel='89 L1'
          resistorLabel='T1'
        />
      )}

      {/* 52-11 thru 52-12 */}
      <MultiSwitchConnector
        translateXCoordinate={328}
        translateYCoordinate={-354}
        verticalLength={20}
        horizontalLength={150}
        startingXCoordinate={410}
      />

      {/* 52-12 thru 52-13 */}
      <MultiSwitchConnector
        translateXCoordinate={478}
        translateYCoordinate={-354}
        verticalLength={20}
        horizontalLength={150}
        startingXCoordinate={410}
      />

      {/* 52-13 thru 52-14 */}
      <MultiSwitchConnector
        translateXCoordinate={628}
        translateYCoordinate={-354}
        verticalLength={20}
        horizontalLength={150}
        startingXCoordinate={410}
      />

      {checkIfDataPointsDefined(rtac_DataPoints) && (
        <>
          <BreakerStack
            translateXCoordinate={850}
            translateYCoordinate={-480}
            meterDataPointsSectionXCoordinate={-300}
            meterDataPointsSectionYCoordinate={825}
            meterLabel={MeterEngineeringIds.METER_2.toString()}
            breakerLabel='52-11'
            feederALabel='11A'
            feederBLabel='11B'
            breakerStatus={getDataPointByPointName(
              rtac_DataPoints,
              RTAC_DATA_POINTS.BREAKER_2,
            )}
            meterDataPoints={meter_2_DataPoints}
          />

          <BreakerStack
            translateXCoordinate={1000}
            translateYCoordinate={-480}
            meterDataPointsSectionXCoordinate={-175}
            meterDataPointsSectionYCoordinate={920}
            meterLabel={MeterEngineeringIds.METER_3.toString()}
            breakerLabel='52-12'
            feederALabel='12A'
            feederBLabel='12B'
            breakerStatus={getDataPointByPointName(
              rtac_DataPoints,
              RTAC_DATA_POINTS.BREAKER_3,
            )}
            meterDataPoints={meter_3_DataPoints}
          />

          <BreakerStack
            translateXCoordinate={1150}
            translateYCoordinate={-480}
            meterDataPointsSectionXCoordinate={-140}
            meterDataPointsSectionYCoordinate={920}
            meterLabel={MeterEngineeringIds.METER_4.toString()}
            breakerLabel='52-13'
            feederALabel='13A'
            feederBLabel='13B'
            breakerStatus={getDataPointByPointName(
              rtac_DataPoints,
              RTAC_DATA_POINTS.BREAKER_4,
            )}
            meterDataPoints={meter_4_DataPoints}
          />

          <BreakerStack
            translateXCoordinate={1300}
            translateYCoordinate={-480}
            meterDataPointsSectionXCoordinate={0}
            meterDataPointsSectionYCoordinate={825}
            meterLabel={MeterEngineeringIds.METER_5.toString()}
            breakerLabel='52-14'
            feederALabel='14A'
            feederBLabel='14B'
            breakerStatus={getDataPointByPointName(
              rtac_DataPoints,
              RTAC_DATA_POINTS.BREAKER_5,
            )}
            meterDataPoints={meter_5_DataPoints}
          />
        </>
      )}
    </svg>
  );
}

export default storeConnector(Scatterwash_1_Diagram, {
  config: ['siteMeta'],
});
