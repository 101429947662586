import { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import TopSwitchResistorSection from '@src/sld/scatterwash/components/TopSwitchResistorSection';
import MultiSwitchConnector from '@src/sld/components/MultiSwitchConnector/MultiSwitchConnector';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import useSubscription from '@src/hooks/useSubscription';
import { SiteMeta } from '@src/types/SiteMeta';
import { filterDataPointsWithValueByEngineeringId } from '@src/utils/dataPoints';
import { checkIfDataPointsDefined, getDataPointByPointName } from '../utils';
import BreakerStack from '@src/sld/scatterwash/components/BreakerStack';
import { DataPointWithValue } from '@src/types/DataPoint';

type ScatterwashSLDProps = {
  siteMeta: SiteMeta;
};

function Scatterwash_2_Diagram(props: ScatterwashSLDProps): ReactElement {
  const { siteMeta } = props;

  const { data: rtacWidgetConfig } =
    useGetInternalWidgetConfig<WidgetTypeDeviceList>(
      'scatterwash_2_SLD_rtac',
      {
        sourceDeviceId: siteMeta.siteId,
      },
      { enabled: true },
    );

  const { data: meterWidgetConfig } =
    useGetInternalWidgetConfig<WidgetTypeDeviceList>(
      'scatterwash_2_SLD_meter',
      {
        sourceDeviceId: siteMeta.siteId,
      },
      { enabled: true },
    );

  const rtacDataPointsWithValue = useSubscription(
    rtacWidgetConfig?.dataPoints || [],
  );

  const meterDataPointsWithValue = useSubscription(
    meterWidgetConfig?.dataPoints || [],
  );

  enum MeterEngineeringIds {
    METER_1 = 'SEL_311L1_L2P',
    METER_2 = 'SEL_451_50_21',
    METER_3 = 'SEL_451_50_22',
  }

  enum RTAC_DATA_POINTS {
    SWITCH = 'SUB_SEL_3555_89_M2_STATUS_89A',
    BREAKER_1 = 'SUB_SEL_3555_52_X2_STATUS_52A',
    BREAKER_2 = 'SUB_SEL_3555_52_21_BKR_STATUS_52A_CLOSED',
    METER_4 = 'SUB_SEL_3555_52_22_BKR_STATUS_52A_CLOSED',
  }

  const RTAC_DATA_POINTS_ARRAY = Object.values(RTAC_DATA_POINTS);
  const METER_DATA_POINTS_ARRAY = ['HZ', 'IA_HV', 'VAB_HV', 'MW', 'MVAR'];

  let rtac_DataPoints: DataPointWithValue[] | undefined;
  let meter_1_DataPoints: DataPointWithValue[] | undefined;
  let meter_2_DataPoints: DataPointWithValue[] | undefined;
  let meter_3_DataPoints: DataPointWithValue[] | undefined;

  if (rtacDataPointsWithValue.length > 0) {
    rtac_DataPoints = filterDataPointsWithValueByEngineeringId(
      rtacDataPointsWithValue,
      `${rtacWidgetConfig?.devices[0].engineeringId}`,
      RTAC_DATA_POINTS_ARRAY,
    );
  }

  if (meterDataPointsWithValue.length > 0) {
    meter_1_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_1,
      METER_DATA_POINTS_ARRAY,
    );
    meter_2_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_2,
      METER_DATA_POINTS_ARRAY,
    );
    meter_3_DataPoints = filterDataPointsWithValueByEngineeringId(
      meterDataPointsWithValue,
      MeterEngineeringIds.METER_3,
      METER_DATA_POINTS_ARRAY,
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      viewBox='400 -20 1200 700'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor', overflowX: 'auto' }}
    >
      <title>Scatterwash 2</title>

      {checkIfDataPointsDefined(rtac_DataPoints) && (
        <TopSwitchResistorSection
          translateXCoordinate={260}
          translateYCoordinate={19}
          meterDataPoints={meter_1_DataPoints}
          breakerStatus={getDataPointByPointName(
            rtac_DataPoints,
            RTAC_DATA_POINTS.SWITCH,
          )}
          switchStatus={getDataPointByPointName(
            rtac_DataPoints,
            'SUB_SEL_3555_89_M2_STATUS_89A',
          )}
          breakerLabel='52 X2'
          switchLabel='89 L2'
          meterLabel={MeterEngineeringIds.METER_1.toString()}
          resistorLabel='T2'
        />
      )}

      {/* 21A thru 22B */}
      <MultiSwitchConnector
        translateXCoordinate={485}
        translateYCoordinate={-354}
        verticalLength={20}
        horizontalLength={150}
        startingXCoordinate={410}
      />

      {checkIfDataPointsDefined(rtac_DataPoints) && (
        <>
          <BreakerStack
            translateXCoordinate={1007}
            translateYCoordinate={-480}
            meterDataPointsSectionXCoordinate={-300}
            meterDataPointsSectionYCoordinate={750}
            meterLabel={MeterEngineeringIds.METER_2.toString()}
            feederALabel='Feeder 21A'
            feederBLabel='Feeder 21B'
            breakerStatus={getDataPointByPointName(
              rtac_DataPoints,
              RTAC_DATA_POINTS.BREAKER_1,
            )}
            meterDataPoints={meter_2_DataPoints}
          />

          <BreakerStack
            translateXCoordinate={1157}
            translateYCoordinate={-480}
            meterDataPointsSectionXCoordinate={-25}
            meterDataPointsSectionYCoordinate={750}
            meterLabel={MeterEngineeringIds.METER_3.toString()}
            feederALabel='Feeder 22A'
            feederBLabel='Feeder 22B'
            breakerStatus={getDataPointByPointName(
              rtac_DataPoints,
              RTAC_DATA_POINTS.BREAKER_2,
            )}
            meterDataPoints={meter_3_DataPoints}
          />
        </>
      )}
    </svg>
  );
}

export default storeConnector(Scatterwash_2_Diagram, {
  config: ['siteMeta'],
});
