import {
  Alert,
  AlertColumn,
  AlertEntity,
  AlertType,
  HistoricalAlert,
} from '@src/types/Alerts';
import React from 'react';
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { SiteMeta } from '@src/types/SiteMeta';
import { getTopicInfo } from '@store/reducers/alerts';
import { Box, MenuItem, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import prepColSx from '@components/Home/Alerts/utils/prepColSx';
import AlarmAcknowledgeCell from '../Table/AcknowledgeCell';
import AcknowledgedInfoCell from '../Table/AcknowledgedInfoCell';
import AlarmHelpCell from '../Table/HelpCell';
import ClearCell from '../Table/ClearCell';
import EventTypeCell from '../Table/EventTypeCell';
import SeverityCell from '../Table/SeverityCell';
import EventTimeCell from '../Table/EventTimeCell';

const eventSeverityBooleanFilterOperator: GridFilterOperator = {
  label: 'Equals',
  value: 'equals',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (filterItem.value == null) {
      return null;
    }

    return (value: string) => value === filterItem.value;
  },
  InputComponent: ({ item, applyValue }: GridFilterInputValueProps) => {
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <TextField
        select
        value={item.value ?? ''}
        onChange={handleFilterChange}
        variant='standard'
        label='Value'
      >
        <MenuItem value='Fire'>Fire Alarm</MenuItem>
        <MenuItem value='Alarm'>Alarm</MenuItem>
        <MenuItem value='Warning'>Warning</MenuItem>
      </TextField>
    );
  },
};

const eventTypeBooleanFilterOperator: GridFilterOperator = {
  label: 'Equals',
  value: 'equals',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (filterItem.value == null) {
      return null;
    }
    return (value: boolean) => value === (filterItem.value === 'On');
  },
  InputComponent: ({ item, applyValue }: GridFilterInputValueProps) => {
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <TextField
        select
        value={item.value ?? ''}
        onChange={handleFilterChange}
        variant='standard'
        label='Value'
      >
        <MenuItem value='On'>On</MenuItem>
        <MenuItem value='Off'>Off</MenuItem>
      </TextField>
    );
  },
};

const prepColumns = (
  columns: AlertColumn[],
  alertType: AlertType,
  siteMeta: SiteMeta,
): GridColDef[] => {
  const columnsMap: Record<AlertColumn, GridColDef> = {
    [AlertColumn.TIME_EVENT]: {
      headerName: 'Event time',
      field: 'ts',
      sortable: true,
      minWidth: 220,
      type: 'dateTime',
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value: string) => new Date(value),
      renderCell: ({ value }) => <EventTimeCell time={value} />,
    },
    [AlertColumn.SEVERITY]: {
      headerName: 'Severity',
      field: 'severity',
      sortable: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      filterOperators: [eventSeverityBooleanFilterOperator],
      valueGetter: (_value: string | undefined, row: Alert) =>
        ['Warning', 'Alarm', 'Fire'][
          (row as AlertEntity).config?.severity ??
            (row as HistoricalAlert).configSeverity
        ],
      renderCell: ({
        value = 'Warning',
        row,
      }: {
        value?: 'Warning' | 'Alarm' | 'Fire';
        row: Alert;
      }) => <SeverityCell severity={value} isAcked={row.isAcked} />,
    },
    [AlertColumn.EVENT_TYPE]: {
      headerName: 'Event type',
      field: 'isActive',
      sortable: true,
      flex: 0.2,
      filterOperators: [eventTypeBooleanFilterOperator],
      renderCell: ({ value }) => <EventTypeCell isActive={value} />,
    },
    [AlertColumn.UNIT]: {
      headerName: 'Unit',
      field: 'unitName',
      sortable: true,
      flex: 0.2,
      valueGetter: (_value: string | undefined, row: Alert) => {
        const { Use_New_Topic_Structure: useNewTopicStructure } = siteMeta.ui;
        const { dataSource } = getTopicInfo(useNewTopicStructure, row.topic);
        const index = siteMeta.Units.indexOf(dataSource);
        return index === -1 ? 'Site' : siteMeta.UnitNames[index] || 'N/A';
      },
    },
    [AlertColumn.ALERT_NAME]: {
      headerName: 'Alert name',
      field: 'alertName',
      sortable: true,
      flex: 0.5,
      minWidth: 160,
      valueGetter: (_value: string | undefined, row: Alert) =>
        (row as AlertEntity).config?.alertName ??
        (row as HistoricalAlert).configAlertName,
    },
    [AlertColumn.MESSAGE]: {
      headerName: 'Message',
      field: 'message',
      sortable: true,
      flex: 1,
    },
    [AlertColumn.TOPIC]: {
      headerName: 'Topic',
      field: 'topic',
      sortable: true,
      flex: 1,
    },
    [AlertColumn.HELP]: {
      headerName: 'Help',
      field: 'details',
      sortable: false,
      flex: 0.2,
      disableColumnMenu: true,
      renderCell: ({ row }: { row: Alert }) => (
        <AlarmHelpCell alarmEntity={row} />
      ),
    },
    [AlertColumn.IS_EQUIPMENT_FAULT]: {
      headerName: 'Is Equipment Fault',
      field: 'isEquipmentFault',
      sortable: true,
      flex: 0.2,
      minWidth: 150,
      disableColumnMenu: true,
      renderCell: ({ row }: { row: Alert }) =>
        (row as HistoricalAlert)?.isEquipmentFault === 'true' ||
        (row as AlertEntity)?.config?.isEquipmentFault ? (
          <Box sx={prepColSx.alertIsEquipmentFaultBox}>
            <FontAwesomeIcon icon={faCheck} size='lg' />
          </Box>
        ) : null,
    },
    [AlertColumn.ACKNOWLEDGE]: {
      headerName: 'Acknowledged',
      field: 'acknowledged',
      sortable: true,
      flex: 0.18,
      minWidth: 120,
      disableColumnMenu: true,
      // when the amount of differences between ACTIVE_ALERT and HISTORICAL_ALERT
      // will be higher pass columns from the top layers of component props
      renderCell: ({ row }: { row: Alert }) => {
        switch (alertType) {
          case AlertType.ACTIVE_ALERT:
            return <AlarmAcknowledgeCell alertEntity={row} />;
          case AlertType.HISTORICAL_ALERT:
            return <AcknowledgedInfoCell alertEntity={row} />;
          default:
            return <AlarmAcknowledgeCell alertEntity={row} />;
        }
      },
    },
    [AlertColumn.CLEAR]: {
      headerName: (() => {
        let header: string;
        switch (alertType) {
          case AlertType.ACTIVE_ALERT:
            header = 'Clear';
            break;
          case AlertType.HISTORICAL_ALERT:
            header = 'Manual Reset';
            break;
          default:
            header = 'Clear';
            break;
        }
        return header;
      })(),
      field: 'clear',
      sortable: false,
      flex: 0.25,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: ({ row }: { row: Alert }) => <ClearCell alertEntity={row} />,
    },
  };

  return columns.map((columnName: AlertColumn) => columnsMap[columnName]);
};

export default prepColumns;
