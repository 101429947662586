import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';

function CommandsNumberInput({
  label,
  unit,
  minimum,
  maximum,
  value,
  currentValue,
  setValue,
}: {
  label: string;
  unit: string;
  minimum: number;
  maximum: number;
  value: string;
  currentValue: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
}) {
  const [isValueValid, setIsValueValid] = useState(true);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValid =
      value.length > 0 &&
      parseFloat(value) <= maximum &&
      parseFloat(value) >= minimum;
    setIsValueValid(isValid);
    setValue(value);
  };

  return (
    <FormControl
      variant='outlined'
      sx={{ width: '100%', mt: 2.5 }}
      error={!isValueValid}
    >
      <TextField
        size='small'
        label={label}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
        }}
        variant='filled'
        type='number'
        value={value}
        onChange={handleChange}
        onFocus={(event) => event.target.select()}
        error={!isValueValid}
      />
      <FormHelperText>
        Range from {minimum} to {maximum}
        <br />
        Current value: {currentValue}
      </FormHelperText>
    </FormControl>
  );
}

export default CommandsNumberInput;
