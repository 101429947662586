import PopupButton from '@src/components/_elements/Popup/PopupButton';
import { ModeConfig, ModeSubmitForm } from '@src/types/control-modes/Modes';
import storeConnector from '@store/storeConnector';
import '../Modes.scoped.scss';
import { ControlMode } from '@src/types/control-modes/ControlModes';
import { buttonStyles } from '../modes-utils';
import ModeConfigForm from '../ModeConfigForm';

interface FullConfigPopupButtonProps {
  config: ModeConfig;
  modeDisplayName: string | undefined;
  modeName: ControlMode;
  submitForm: ModeSubmitForm;
}

function FullConfigPopupButton({
  config,
  modeDisplayName,
  modeName,
  submitForm,
}: FullConfigPopupButtonProps) {
  const popupContent = (close: () => Promise<void>) => (
    <ModeConfigForm
      config={config}
      close={close}
      formType='full'
      mode={modeName}
      submitForm={submitForm}
    />
  );

  return (
    <PopupButton
      buttonStyles={buttonStyles}
      title={`Edit ${modeDisplayName || modeName} Config`}
      link
      content={popupContent}
      bodyClass='popup-body-class'
    >
      Full Config
    </PopupButton>
  );
}

export default storeConnector(FullConfigPopupButton, {
  config: ['siteMeta'],
});
