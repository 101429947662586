import History from '@pages/History/index';
import { RouteObject } from 'react-router';
import AppLayout from '../_layout/AppLayout';

export default [
  {
    path: '/history',
    element: <AppLayout />,
    children: [{ index: true, element: <History /> }],
  },
] as RouteObject[];
