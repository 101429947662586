import { ControlMode } from '@src/types/control-modes/ControlModes';
import { useState } from 'react';
import storeConnector from '@store/storeConnector';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import { ActiveModesListProps } from '@src/types/control-modes/Modes';
import {
  convertControlModesToTopicFields,
  convertTopicFieldToControlMode,
} from './modes-utils';

const activeModesDict = {
  'AGC Response': 'P Follow',
} as Record<Partial<ControlMode>, string | undefined>;

function ActiveModesList({ siteMeta }: ActiveModesListProps) {
  const [enabledModes, setEnabledModes] = useState([] as ControlMode[]);

  useWsSubscribe({
    site: {
      fields: convertControlModesToTopicFields(siteMeta.ControlModes),
      cb: (data: Record<ControlMode, number>) => {
        const mode = Object.keys(data)[0] as ControlMode;
        const modeIndex = enabledModes.indexOf(mode);
        // note: mode is enabled and is not in array, needs to be added
        if (modeIndex === -1 && data[mode] === 0) {
          enabledModes.push(mode);
        }
        // note: mode is disabled and is in array, needs to be removed
        if (modeIndex !== -1 && data[mode] === 1) {
          enabledModes.splice(modeIndex, 1);
        }
        setEnabledModes(
          enabledModes.sort().map(convertTopicFieldToControlMode),
        );
      },
    },
  });

  return (
    <div>
      {enabledModes.map((mode) => (
        <p key={mode} style={{ marginBottom: '2px' }}>
          {activeModesDict[mode] ?? mode}
        </p>
      ))}
    </div>
  );
}

export default storeConnector(ActiveModesList, {
  config: ['siteMeta'],
});
