import { useState } from 'react';
import './SelectUnit.scoped.scss';
import Input from '@components/_elements/Input/Input';
import storeConnector from '@store/storeConnector';

const SelectUnit = ({ unit, hideAll, onChange, siteMeta, noPadding }) => {
  const [selectedUnit, setSelectedUnit] = useState(unit || -1);

  const changeUnit = (selectedUnit) => {
    if (hideAll === true) {
      setSelectedUnit(selectedUnit);
      onChange(selectedUnit);
    } else {
      setSelectedUnit(selectedUnit.unit);
      onChange(selectedUnit.unit, selectedUnit.unitsn);
    }
  };

  const units = [
    ...(hideAll ? [] : ['All']),
    ...siteMeta.UnitNames.map((unitName) => `UNIT ${unitName}`),
  ];
  return (
    <div className={'select-unit ' + (noPadding ? 'no-padding' : '')}>
      <Input
        type='dropdown'
        placeholder='Select unit'
        value={
          selectedUnit === -1
            ? hideAll
              ? false
              : { index: 0 }
            : {
                index: hideAll ? selectedUnit : selectedUnit + 1,
              }
        }
        onChange={(val) =>
          changeUnit(
            hideAll
              ? val
              : {
                  unit: val - 1,
                  ...(val ? { unitsn: siteMeta.Units[val - 1] } : {}),
                },
          )
        }
        options={units}
      />
    </div>
  );
};

export default storeConnector(SelectUnit, {
  config: ['siteMeta'],
});
