import { DataPointWithValue } from '@src/types/DataPoint';

const filterDataPointsWithValueByEngineeringId = (
  dataPoints: DataPointWithValue[],
  engineeringId: string,
  pointNames: string[],
): DataPointWithValue[] => {
  const filteredDataPointsWithPointNames = dataPoints.filter(
    (dataPoint) =>
      dataPoint.sourceDeviceId === engineeringId &&
      pointNames.includes(dataPoint.pointName),
  );

  return filteredDataPointsWithPointNames;
};

export { filterDataPointsWithValueByEngineeringId };
