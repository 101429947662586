import { ReactElement } from 'react';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';

type MultiSwitchConnectorProps = {
  translateXCoordinate: number;
  translateYCoordinate: number;
  horizontalLength: number;
  verticalLength: number;
  startingXCoordinate: number;
};

function MultiSwitchConnector(props: MultiSwitchConnectorProps): ReactElement {
  const {
    translateXCoordinate,
    translateYCoordinate,
    horizontalLength,
    verticalLength,
    startingXCoordinate,
  } = props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;
  const endXCoordinate = startingXCoordinate + horizontalLength;
  return (
    <g transform={translate}>
      <VerticalLine
        length={verticalLength}
        color='white'
        width={0.5}
        translateXCoordinate={startingXCoordinate}
        translateYCoordinate={545}
      />

      <HorizontalLine
        translateXCoordinate={startingXCoordinate}
        translateYCoordinate={545}
        color='white'
        width={0.5}
        length={horizontalLength}
      />

      <VerticalLine
        length={20}
        color='white'
        width={0.5}
        translateXCoordinate={endXCoordinate}
        translateYCoordinate={545}
      />
    </g>
  );
}

export default MultiSwitchConnector;
