import { Actions } from '@src/types/Actions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '@components/_elements/Popup/Popup';
import Button from '@components/_elements/Button/Button';
import { store } from '@store';
import storeConnector from '@store/storeConnector';

interface ToggleRDGPopupProps {
  actions: Actions;
  isOpen: boolean;
  close: () => void;
}

function ToggleRDGPopup({ actions, isOpen, close }: ToggleRDGPopupProps) {
  const { t } = useTranslation();
  const clientMQTT = useMemo(() => store.getState().mqtt.clientMQTT, []);
  const sendRdgValue = (v: string) => {
    if (!clientMQTT) {
      actions.notifyError(`No MQTT connection`);
      close();
      return;
    }
    clientMQTT.publish('ENABLE_RDG', v);
    if (v === '1') {
      actions.notify(`RDG turned ON`);
    } else {
      actions.notifyError(`RDG turned OFF`);
    }
    close();
  };

  return (
    <Popup
      isOpen={isOpen}
      title={t('CONFIRM_EXECUTION_QUESTION')}
      close={() => close()}
      bodyClass='content-popup'
    >
      <div style={{ margin: '20px' }}>Toggle Random Data Generator</div>
      <div className='btn-row'>
        <Button onClick={() => sendRdgValue('1')} size='m'>
          ON
        </Button>
        <Button onClick={() => sendRdgValue('0')} size='m'>
          OFF
        </Button>
      </div>
    </Popup>
  );
}

export default storeConnector(ToggleRDGPopup, {
  config: ['siteMeta'],
});
