import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import { Device } from '@src/types/Device';

export default () =>
  useApiQuery<Device[]>({
    queryKey: [QueryKeys.devices],
    apiPath: '/devices',
    method: 'GET',
    useV2API: true,
  });
