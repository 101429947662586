import RatioValueLoader from '@components/_shared/RatioValueLoader';
import { CSSProperties } from 'react';
import { DataPointWithValue } from '@src/types/DataPoint';
import ValueLoader from './ValueLoader';

const pointGroupItem: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  marginLeft: '16px',
  fontSize: '0.8rem',
  marginBottom: '3px',
  width: '100%',
};

const pointItemName: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  overflow: 'hidden',
  maxWidth: '80%',
};

const pointItemValue: CSSProperties = {
  whiteSpace: 'nowrap',
  display: 'inline-block',
  overflow: 'hidden',
  marginLeft: '16px',
  minWidth: '20%',
  textAlign: 'right',
};

const flexContainerParent: CSSProperties = {
  textOverflow: 'ellipsis',
};

const createFlexboxChildElement = (
  dataPoint: DataPointWithValue,
  index: number,
) => (
  <div
    style={pointGroupItem}
    key={`${dataPoint.pointName}-${index}-${Math.random()}`}
  >
    <span style={pointItemName}>
      {dataPoint.displayName || dataPoint.pointName}
    </span>
    <span className='fractal-datapoint-value-cell' style={pointItemValue}>
      {dataPoint.displayDenominator ? (
        <RatioValueLoader dataPoint={dataPoint} justifyContent='end' />
      ) : (
        <ValueLoader dataPoint={dataPoint} />
      )}
    </span>
  </div>
);

interface Props {
  data: DataPointWithValue[];
}

function SimplePointDataTable(props: Props) {
  return (
    <div id='flexbox-container-parent' style={flexContainerParent}>
      {props.data.map((dp) => createFlexboxChildElement(dp, 0))}
    </div>
  );
}

export default SimplePointDataTable;
