import { Box, Stack } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { memo } from 'react';
import { PointConfig } from '@src/types/PointConfig';
import PCSDetail from './PCSDetail';
import { pcsSx } from '@src/components/_shared/sxStyles/Battery/deivceTableList';
import ConverterContainer from './ConverterContainer';

interface PCSRow {
  id: GridRowParams['id'];
  name: string;
  [key: string]: PointConfig | string | GridRowParams['id'];
}

interface PCSProps {
  row: PCSRow;
  unitControllerId: string;
  unitEngineeringId: string;
}

const PCSMasterDetail = memo(
  ({ row, unitControllerId, unitEngineeringId }: PCSProps) => (
    <Box sx={pcsSx.detailWrapper}>
      <Stack spacing={2}>
        <PCSDetail
          pcsId={row.id}
          pcsName={row.name}
          unitControllerId={unitControllerId}
          unitEngineeringId={unitEngineeringId}
        />
        <ConverterContainer
          pcsId={row.id}
          unitControllerId={unitControllerId}
          unitEngineeringId={unitEngineeringId}
        />
      </Stack>
    </Box>
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.row.id === nextProps.row.id &&
      prevProps.unitControllerId === nextProps.unitControllerId &&
      prevProps.unitEngineeringId === nextProps.unitEngineeringId
    );
  },
);

export default PCSMasterDetail;
