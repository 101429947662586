import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import storeConnector from '@store/storeConnector';
import { Actions } from '@src/types/Actions';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import useUserDelete from '@src/hooks/api/mutations/useUserDelete';
import { LoadingButton } from '@mui/lab';
import { User } from '@src/types/User';

interface DeleteUserDialogProps {
  open: boolean;
  onClose: () => void;
  actions: Actions;
  user: User;
}

function DeleteUserDialog({
  open,
  onClose,
  actions,
  user,
}: DeleteUserDialogProps) {
  const queryClient = useQueryClient();
  const { mutate: deleteUser, isPending } = useUserDelete(user.id);

  const handleConfirm = () => {
    deleteUser(null, {
      onSuccess: () => {
        actions.notify('Successfully deleted user.');
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.user, user.id],
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.users] });
        onClose();
      },
      onError: (error: Error) => {
        const customError = error.cause as { error?: { message: string } };
        actions.notifyError(
          customError?.error?.message ?? 'Failed to deleted user.',
        );
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
      <DialogTitle>Are you sure to delete this user?</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600, p: 1 }}
        >
          {user.username}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          variant='contained'
          onClick={handleConfirm}
          loading={isPending}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default storeConnector(DeleteUserDialog, {});
