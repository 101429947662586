export enum CommandsCategory {
  TSC = 'TSC',
  MP = 'MP',
  POWINEMS = 'POWINEMS',
  POWINBESS = 'POWINBESS',
}

export type CommandsTSCOperation =
  | 'Enable'
  | 'Disable'
  | 'Power'
  | 'WriteToVariant';

export type CommandsPOWINOperation =
  | 'Power'
  | 'WriteToVariant'
  | 'PCSStart'
  | 'PCSStop'
  | 'CloseDCContactors'
  | 'OpenDCContactors'
  | 'UnitFaultReset';

export type CommandsMPOperation =
  | 'OpenBreaker'
  | 'CloseBreaker'
  | 'DisableMegapack'
  | 'ClearDisableMegapack';

export type CommandsOperation =
  | CommandsTSCOperation
  | CommandsMPOperation
  | CommandsPOWINOperation;

export type CommandsParams = Record<string, string>;

export enum POWIN_COMMANDS {
  PCS_START = 'PCSStart',
  PCS_STOP = 'PCSStop',
  CLOSE_DC_CONTACTORS = 'CloseDCContactors',
  OPEN_DC_CONTACTORS = 'OpenDCContactors',
  UNIT_FAULT_RESET = 'UnitFaultReset',
}
