import SiteMetaEditor from '@components/_shared/SiteMetaEditor';
import Popup from '@components/_elements/Popup/Popup';

interface SiteMetaEditorPopupProps {
  isOpen: boolean;
  close: () => void;
}

function SiteMetaEditorPopup({ isOpen, close }: SiteMetaEditorPopupProps) {
  return (
    <Popup
      isOpen={isOpen}
      title='Site Meta Editor'
      close={() => close()}
      bodyClass='content-popup site-meta-editor'
    >
      <SiteMetaEditor />
    </Popup>
  );
}

export default SiteMetaEditorPopup;
