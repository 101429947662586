import Spinner from '@components/_elements/Spinner/Spinner';
import PageError, { PageNotFound } from '@pages/PageError';
import { store } from '@store';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router';
import SetupWizard from '@components/Wizard';
import AdminRoutes from '@pages/Admin/routes';
import HomeRoutes from '@pages/Home/routes';
import BatteryRoutes from '@pages/Battery/routes';
import SunRoutes from '@pages/Sun/routes';
import WindRoutes from '@pages/Wind/routes';
import MktRoutes from '@pages/Mkt/routes';
import IncidentCommandRoutes from '@pages/IncidentCommand/routes';
import HistoryRoutes from '@pages/History/routes';
import HistorianTableViewRoutes from '@pages/HistorianTableView/routes';
import protectedLoader from '@pages/_loader';
import App from './App';

function AppWithStore() {
  return (
    <ErrorBoundary fallback={<PageError />}>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  );
}

function AppRouter() {
  const router = createBrowserRouter([
    {
      id: 'root',
      loader: protectedLoader,
      hydrateFallbackElement: <Spinner type='fullPage' />,
      element: <AppWithStore />,
      errorElement: <PageError />,
      children: [
        ...AdminRoutes,
        ...HistorianTableViewRoutes,
        ...HomeRoutes,
        ...BatteryRoutes,
        ...SunRoutes,
        ...MktRoutes,
        ...HistoryRoutes,
        ...WindRoutes,
        ...IncidentCommandRoutes,
        {
          path: '/wizard',
          element: <SetupWizard />,
        },
        {
          path: '*',
          element: <PageNotFound />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default AppRouter;
