import './GaugeChart.scoped.scss';
import ChartLoader from '../ChartLoader';

const colorsDict = {
  n: '#00FF00',
  w: '#FFFF00',
  e: '#FF0000',
};

const gradients = {
  humidityGradient: [
    { c: 'n', v: '0.45' },
    { c: 'w', v: '0.52' },
    { c: 'e', v: '1' },
  ],
  ambientGradient: [
    { c: 'e', v: '0.30' },
    { c: 'w', v: '0.47' },
    { c: 'n', v: '0.51' },
    { c: 'w', v: '0.54' },
    { c: 'e', v: '0.8' },
  ],
  cellGradient: [
    { c: 'e', v: '0.18' },
    { c: 'w', v: '0.28' },
    { c: 'w', v: '0.44' },
    { c: 'n', v: '0.51' },
    { c: 'w', v: '0.55' },
    { c: 'w', v: '0.72' },
    { c: 'e', v: '0.8' },
  ],
};

const GaugeChart = ({
  gradient,
  height,
  width,
  max,
  min,
  value,
  minValue,
  loading,
  maxValue,
  units = '°C',
  title,
  minRangeNorm,
  maxRangeNorm,
}) => {
  const axisToStr = (num) => {
    if (num % 1 === 0 || (num * 10) % 1 === 0) {
      return num;
    }
    return num && !isNaN(+num) ? (+num).toFixed(2) : 0;
  };
  const calcPath = ([cx, cy], [rx, ry], [t1, angl], fi) => {
    const cos = Math.cos;
    const sin = Math.sin;
    const pi = Math.PI;
    const f_matrix_times = ([[a, b], [c, d]], [x, y]) => [
      a * x + b * y,
      c * x + d * y,
    ];
    const f_rotate_matrix = (x) => [
      [cos(x), -sin(x)],
      [sin(x), cos(x)],
    ];
    const f_vec_add = ([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2];
    angl = angl % (2 * pi);
    const rotMatrix = f_rotate_matrix(fi);
    const [sX, sY] = f_vec_add(
      f_matrix_times(rotMatrix, [rx * cos(t1), ry * sin(t1)]),
      [cx, cy],
    );
    const [eX, eY] = f_vec_add(
      f_matrix_times(rotMatrix, [rx * cos(t1 + angl), ry * sin(t1 + angl)]),
      [cx, cy],
    );
    const fA = angl > pi ? 1 : 0;
    const fS = angl > 0 ? 1 : 0;
    return (
      'M ' +
      sX +
      ' ' +
      sY +
      ' A ' +
      [rx, ry, (fi / (2 * pi)) * 360, fA, fS, eX, eY].join(' ')
    );
  };
  const calcGradient = () => {
    const grdnt = gradients[gradient];
    if (![minRangeNorm, maxRangeNorm].includes(undefined)) {
      const blur = 0.04;
      const minR = +minRangeNorm;
      const maxR = +maxRangeNorm;
      const mx = +max;
      const mn = +min;
      const interval = mx - mn;
      const g = [
        {
          c: 'e',
          v: `${(minR - mn) / interval - blur}`,
        },
        {
          c: 'w',
          v: `${(minR - mn) / interval + blur}`,
        },
        {
          c: 'n',
          v: `${((minR + minR) / 2 - mn) / interval}`,
        },
        {
          c: 'w',
          v: `${(maxR - mn) / interval - blur}`,
        },
        {
          c: 'e',
          v: `${(maxR - mn) / interval + blur}`,
        },
      ];
      return g;
    }
    return grdnt;
  };

  const calcAngleByValue = (l, c) => {
    return (l * Math.PI) / c;
  };

  const isNum = (val) => {
    return !!val || val === 0;
  };

  const containerStyle = {
    ...(height ? { minHeight: height } : {}),
    width: width,
  };

  const fontStyle = {
    fill: '#87959f',
    fontWeight: '400',
    fontSize: '9px',
    cursor: 'default',
  };

  const interval = max - min;
  const angle = (((value ? value : minValue) - min) * 180) / interval;
  return (
    <div className='gauge-container' style={containerStyle}>
      {loading && <ChartLoader />}
      <svg viewBox='0 0 300 152'>
        <defs>
          <linearGradient
            id={'gradient-' + gradient}
            gradientUnits='userSpaceOnUse'
          >
            {calcGradient().map((g, i) => (
              <stop key={i} stopColor={colorsDict[g.c]} offset={g.v} />
            ))}
          </linearGradient>
        </defs>
        <g className='dxg-axis' transform='translate(40,35)'>
          <g className='dxg-elements'>
            <text
              x='-21'
              y='113.5'
              transform='translate(0,0)'
              style={fontStyle}
              textAnchor='middle'
            >
              {axisToStr(min)}
            </text>
            <text
              x='-9.766529860203981'
              y='48.67974433722377'
              transform='translate(0,0)'
              style={fontStyle}
              textAnchor='middle'
            >
              {axisToStr(min + interval / 6)}
            </text>
            <text
              x='37.103888520677174'
              y='-1.213421539788305'
              transform='translate(0,0)'
              style={fontStyle}
              textAnchor='middle'
            >
              {axisToStr(min + interval / 3)}
            </text>
            <text
              x='110'
              y='-18'
              transform='translate(0,0)'
              style={fontStyle}
              textAnchor='middle'
            >
              {axisToStr(min + interval / 2)}
            </text>
            <text
              x='182.00279661115206'
              y='-2.4040629014260553'
              transform='translate(0,0)'
              style={fontStyle}
              textAnchor='middle'
            >
              {axisToStr(min + (interval * 2) / 3)}
            </text>
            <text
              x='231.5884572681199'
              y='46.25'
              transform='translate(0,0)'
              style={fontStyle}
              textAnchor='middle'
            >
              {axisToStr(min + (interval * 5) / 6)}
            </text>
            <text
              x='249'
              y='113.5'
              transform='translate(0,0)'
              style={fontStyle}
              textAnchor='middle'
            >
              {axisToStr(max)}
            </text>
          </g>
        </g>

        <g className='dxg-scale' style={{ fill: `url(#gradient-${gradient})` }}>
          <rect x='30' y='144' height='2' width='10' />
          <rect x='260' y='144' height='2' width='10' />
          <path d='M 45 86 L 44 88 L 53 93 L 53 91 Z' strokeWidth='0' />
          <path d='M 87 43 L 85 43 L 90 52 L 91 51 Z' strokeWidth='0' />
          <path d='M 213 43 L 211 42 L 207 50 L 208 51 Z' strokeWidth='0' />
          <path d='M 254 86 L 253 84 L 245 89 L 246 91 Z' strokeWidth='0' />
          <rect x='149' y='24' height='10' width='2' />
          <path
            d='M 265.00000 145.00000 A 115.00000 115.00000 0 1 0 35.00000 145.00000 L 40.00000 145.00000 A 110.00000 110.00000 0 1 1 260.00000 145.00000 Z'
            transform='translate(0,0)'
            strokeLinejoin='round'
          />
        </g>
        {isNum(value) && value.toFixed && (
          <foreignObject id='G' height='160' width='302'>
            <svg
              style={{
                overflow: 'visible',
                transform: `rotate(${angle}deg)`,
                transformOrigin: 'right bottom',
                transition: 'transform 1s',
              }}
              height='146'
              width='50%'
            >
              <g>
                <rect
                  x='34'
                  y='145'
                  height='2'
                  width='calc(100% - 34px)'
                  fill='#87959f'
                />
                <circle
                  cx='37'
                  cy='146'
                  r='6'
                  stroke='#87959f'
                  strokeWidth='2'
                  fill='#202327'
                />
                <circle
                  cx='calc(100% - 1px)'
                  cy='146'
                  r='4'
                  stroke='#87959f'
                  strokeWidth='2'
                  fill='#202327'
                />
              </g>
            </svg>
          </foreignObject>
        )}
        {isNum(maxValue) &&
          isNum(minValue) &&
          maxValue.toFixed &&
          minValue.toFixed && (
            <foreignObject id='G' height='160' width='302'>
              <svg
                style={{
                  overflow: 'visible',
                  transform: `rotate(${angle}deg)`,
                  transformOrigin: 'center bottom',
                  transition: 'transform 1s',
                }}
                height='146'
                width='100%'
              >
                <g>
                  <path
                    stroke='#87959f'
                    strokeWidth='3px'
                    fillOpacity='0'
                    d={calcPath(
                      [151, 146],
                      [118, 118],
                      [0, calcAngleByValue(maxValue - minValue, interval)],
                      Math.PI,
                    )}
                  />
                  <path
                    stroke='#87959f'
                    strokeWidth='3px'
                    fillOpacity='0'
                    d={calcPath(
                      [151, 146],
                      [110, 110],
                      [0, calcAngleByValue(maxValue - minValue, interval)],
                      Math.PI,
                    )}
                  />
                  <rect
                    x='34'
                    y='145'
                    height='2'
                    width='calc(50% - 34px)'
                    fill='#87959f'
                  />
                  <line
                    x1='151'
                    y1='146'
                    x2={
                      151 +
                      118 *
                        Math.cos(
                          Math.PI +
                            calcAngleByValue(maxValue - minValue, interval),
                        )
                    }
                    y2={
                      146 +
                      118 *
                        Math.sin(
                          Math.PI +
                            calcAngleByValue(maxValue - minValue, interval),
                        )
                    }
                    stroke='#87959f'
                    strokeWidth='2px'
                  />
                  <circle
                    cx='151'
                    cy='146'
                    r='4'
                    stroke='#87959f'
                    strokeWidth='2'
                    fill='#202327'
                  />
                </g>
              </svg>
            </foreignObject>
          )}
      </svg>
      <div className='title-block'>
        <div>
          {value !== undefined
            ? axisToStr(value)
            : `${axisToStr(minValue)} - ${axisToStr(maxValue)}`}
          {units}
        </div>
        <div>{title}</div>
      </div>
    </div>
  );
};

export default GaugeChart;
