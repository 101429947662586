import './TSCConfig.scoped.scss';

const getModeStatusClass = (modeStatus: string | number | null | undefined) => {
  switch (modeStatus) {
    case 0:
      return 'red';
    case 1:
      return 'green';
    default:
      return 'grey';
  }
};

function TeslaStatusIcon({
  status,
}: {
  status: string | number | null | undefined;
}) {
  return (
    <i
      className='status status__icon'
      style={{
        background: getModeStatusClass(status),
      }}
    />
  );
}

export default TeslaStatusIcon;
