import { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import JSONEditor from '@components/_elements/JSONEditor/JSONEditor';
import Draggable from 'react-draggable';
import dialogActionsStyles from './auditLogSx';

interface AuditLogDialogProps {
  requestBodyDetails: Record<string, string>;
  handleClose: () => void;
}

function AuditLogDialog(props: AuditLogDialogProps): ReactElement {
  return (
    <Draggable bounds='body'>
      <Dialog open PaperProps={{ sx: dialogActionsStyles.dialogPaper }}>
        <DialogContent>
          <JSONEditor
            json={props.requestBodyDetails}
            options={{ mode: 'preview' }}
          />
        </DialogContent>

        <DialogActions sx={dialogActionsStyles.dialogActions}>
          <Button size='small' onClick={props.handleClose} variant='contained'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}
export default AuditLogDialog;
