import DraggableDialog from '@components/_shared/DraggableDialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Actions } from '@src/types/Actions';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';

const dialogActionsStyles = {
  display: 'flex',
  justifyContent: 'center',
  pb: 2,
};

interface BreakerCommandConfirmationDialogProps {
  setShowConfirm: (v: boolean) => void;
  setShowBreakerCmdDialog: (v: boolean) => void;
  breakerName: string;
  operation: string;
  siteMeta: SiteMeta;
  actions: Actions;
}

function BreakerCommandsConfirmationDialog({
  setShowConfirm,
  setShowBreakerCmdDialog,
  breakerName,
  operation,
  siteMeta,
  actions,
}: BreakerCommandConfirmationDialogProps) {
  const handleConfirmOnClick = (sendCmd: boolean): void => {
    if (sendCmd) {
      const variant = breakerName.split(' ')[1].toUpperCase();
      actions.sendCommand(
        [`WriteToVariant::${variant}_11_${operation.toUpperCase()}_CMD::1`],
        siteMeta.siteId,
      );
    }
    setShowConfirm(false);
    setShowBreakerCmdDialog(false);
  };

  return (
    <div className='cover-container cover-container-confirm'>
      <DraggableDialog
        maxWidth={false}
        fullWidth={false}
        onClose={() => setShowConfirm(false)}
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          {operation.toUpperCase()} Breaker {breakerName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions sx={dialogActionsStyles}>
          <Button
            size='small'
            onClick={() => handleConfirmOnClick(true)}
            variant='contained'
          >
            Yes
          </Button>
          <Button
            size='small'
            onClick={() => handleConfirmOnClick(false)}
            autoFocus
            variant='outlined'
          >
            No
          </Button>
        </DialogActions>
      </DraggableDialog>
    </div>
  );
}

export default storeConnector(BreakerCommandsConfirmationDialog, {
  config: ['siteMeta'],
});
