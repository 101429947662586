import storeConnector from '@store/storeConnector';
import { NavLink } from 'react-router';

function TopBar() {
  return (
    <div className='topbar'>
      <div className='frame'>
        <NavLink
          to='/home/collections/devices'
          className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
        >
          Devices
        </NavLink>
        <NavLink
          to='/home/collections/point-configs'
          className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
        >
          Point Configs
        </NavLink>
        <NavLink
          to='/home/collections/widget-configs'
          className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
        >
          Widget Configs
        </NavLink>
      </div>
      <div />
    </div>
  );
}

export default storeConnector(TopBar, {
  config: ['siteMeta'],
  user: ['role'],
});
