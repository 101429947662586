import { useCallback, useMemo } from 'react';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import BMSMasterDetail from '@src/components/Battery/BMSContainer/BMSMasterDetail';
import DeviceDataTable from '@src/components/_shared/DeviceDataTable';
import UIBox from '@components/_shared/UIBox';
import useGetInternalWidgetConfig from '@hooks/api/queries/useGetInternalWidgetConfig';
import useGetDevicesFiltered from '@src/hooks/api/queries/collections/useGetDevicesFiltered';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import { GridRowSpacingParams } from '@mui/x-data-grid-pro';
import SystemMeter from '@src/components/_shared/SystemMeter';
import UnitTable from '@src/components/_shared/UnitTable/UnitTable';
import './UnitLayout.scss';
import { bmsSx } from '@src/components/_shared/sxStyles/Battery/deivceTableList';

function BMS({
  currentUnitDeviceId,
  siteMeta,
}: {
  currentUnitDeviceId: string;
  siteMeta: SiteMeta;
}) {
  const { Units } = siteMeta;
  const {
    data: units,
    isLoading: unitsLoading,
    isError: unitsError,
  } = useGetDevicesFiltered({
    filter: {
      type: {
        $in: ['unitController'],
      },
    },
  });

  const unitControllerId: string = units?.find(
    (unit) => unit.engineeringId === currentUnitDeviceId,
  )?.deviceId as string;

  const {
    data: widgetConfig,
    isLoading,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypeDeviceList>(
    'bmsList',
    {
      unitControllerId: unitControllerId || 'unitController_1',
      unitControllerEngineeringId: currentUnitDeviceId || Units[0],
    },
    {
      enabled: !!unitControllerId,
      queryKeys: [currentUnitDeviceId],
    },
  );
  const memoizedUnitControllerId = useMemo(
    () => unitControllerId,
    [unitControllerId],
  );
  const memoizedCurrentUnitDeviceId = useMemo(
    () => currentUnitDeviceId,
    [currentUnitDeviceId],
  );

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row }) => (
      <BMSMasterDetail
        row={row}
        unitControllerId={memoizedUnitControllerId}
        unitEngineeringId={memoizedCurrentUnitDeviceId}
      />
    ),
    [memoizedUnitControllerId, memoizedCurrentUnitDeviceId],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  return (
    <div className='home-row bms-wrapper'>
      <div className='fcol unit-f1'>
        <SystemMeter />
        <UnitTable />
      </div>
      <div className='fcol unit-f2'>
        <UIBox
          header={`${siteMeta.UnitNames[siteMeta.Units.indexOf(currentUnitDeviceId)]} BMS List`}
          isError={isError || unitsError}
        >
          <DeviceDataTable
            id='bms'
            sx={bmsSx.wrapper}
            hideFooter
            disableColumnMenu
            devices={widgetConfig?.devices}
            dataPoints={widgetConfig?.dataPoints || []}
            pointConfigRefs={widgetConfig?.pointConfigRefs || []}
            disableRowSelectionOnClick
            getHeaderHeight={() => 0}
            getRowClassName={() => 'MuiDataGrid-clickable bms-row'}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            density={'standard'}
            loading={isLoading || unitsLoading}
            allowExpandedCount={2}
            additionalClassnames='bms-data-table'
            getRowSpacing={(_params: GridRowSpacingParams) => ({
              top: 9,
              bottom: 4,
            })}
          />
        </UIBox>
      </div>
    </div>
  );
}

export default storeConnector(BMS, {
  config: ['siteMeta'],
  service: ['currentUnitDeviceId'],
});
