import useSubscription from '@hooks/useSubscription';
import { isActionRole, isAdmin } from '@src/services/auth';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import { UserState } from '@store/reducers/userData';
import { memo, useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CommandsCategory } from '@src/types/Commands';
import useDataPointsSearch from '@src/hooks/api/queries/useDataPointsSearch';
import { generateDataPoint } from '@src/utils/generateTopic';
import { MqttClient } from 'mqtt';
import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import ControlSection from './ControlSection';
import PowerSection from './PowerSection';
import { CommandsModalContextProvider } from '../CommandsModalContext';

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  mqttClient: MqttClient;
  deviceId: string;
  deviceName: string;
  onClose: () => void;
  siteMeta: SiteMeta;
  role: UserState['role'];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, max-lines-per-function
function TSCCommandsModal({
  mqttClient,
  deviceId,
  deviceName,
  onClose,
  siteMeta,
  role,
}: Props) {
  const CHARGE_POINT_NAME = siteMeta.TeslaChargePowerPointName;
  const DISCHARGE_POINT_NAME = siteMeta.TeslaDischargePowerPointName;
  const QSET = 'Qset';
  const PSET = 'Pset';

  const onDialogClose = () => {
    onClose();
    mqttClient?.end();
  };

  const dataPoints = useDataPointsSearch([
    { deviceType: 'site', pointName: PSET },
    { deviceType: 'site', pointName: QSET },
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  const pointsToSubscribe = useMemo(() => {
    const minMaxDataPoints: DataPoint[] = [
      CHARGE_POINT_NAME,
      DISCHARGE_POINT_NAME,
    ].map((pointName: string) =>
      generateDataPoint(
        {
          deviceType: 'tsc',
          controllerId: siteMeta.siteId,
          sourceDeviceId: deviceId,
          pointName,
          pointAlias: pointName,
        },
        siteMeta.siteId,
      ),
    );
    return [...dataPoints, ...minMaxDataPoints];
  }, [deviceId]);

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    pointsToSubscribe,
    mqttClient,
  );

  const displayEnableDisableCommandsSection: boolean = useMemo((): boolean => {
    //  if siteMeta role check is false or doesn't exist then
    //  operator, engineer, admin, superadmin
    //  can view the enable / disable command section
    //  is role check is on then only admin / superadmin
    //  can view. The "viewer" role can never see, regardless of
    //  the siteMeta flag
    return (
      (siteMeta.ui.TSC_Only_Allow_Admin_Enable_Disable_Commands &&
        isAdmin(role)) ||
      (!siteMeta.ui.TSC_Only_Allow_Admin_Enable_Disable_Commands &&
        isActionRole(role))
    );
  }, [role, siteMeta.ui.TSC_Only_Allow_Admin_Enable_Disable_Commands]);

  const { pSetValue, qSetValue, minThreshold, maxThreshold } =
    dataPointsWithValue.reduce(
      (
        acc: {
          pSetValue: string;
          qSetValue: string;
          minThreshold: number;
          maxThreshold: number;
        },
        item,
      ) => {
        if (!item.value) {
          return acc;
        }
        if (item.pointName === PSET) {
          acc.pSetValue = item.value as string;
        } else if (item.pointName === QSET) {
          acc.qSetValue = item.value as string;
        } else if (item.pointName === CHARGE_POINT_NAME) {
          acc.minThreshold = Number(item.value);
        } else if (item.pointName === DISCHARGE_POINT_NAME) {
          acc.maxThreshold = Number(item.value);
        }
        return acc;
      },
      { pSetValue: '', qSetValue: '', minThreshold: 0, maxThreshold: 0 },
    );

  return (
    <CommandsModalContextProvider
      category={CommandsCategory.TSC}
      deviceId={siteMeta.siteId}
    >
      <Dialog open onClose={onDialogClose} maxWidth='sm' fullWidth>
        <StyledDialogTitle>
          {deviceName} Commands
          <IconButton onClick={onClose} sx={{ mr: -2, mt: -1 }}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers>
          {displayEnableDisableCommandsSection && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <ControlSection />
            </Box>
          )}
          <PowerSection
            currentPset={pSetValue}
            currentQset={qSetValue}
            minThreshold={minThreshold}
            maxThreshold={maxThreshold}
            deviceId={deviceId}
          />
        </DialogContent>
      </Dialog>
    </CommandsModalContextProvider>
  );
}

export default storeConnector(memo(TSCCommandsModal), {
  config: ['siteMeta'],
  user: ['role'],
});
