import {
  DataPoint,
  QueryDataPointsSearchBodyElement,
} from '@src/types/DataPoint';
import { SiteMeta } from '@src/types/SiteMeta';
import DataTypePointNameDictionary from '@utils/config/dataTypePointName.map';
import { capitalized, purgeEmptyStrings } from '@utils/index_ts';

export type GetFullTopicV2Args = {
  dataType: string;
} & QueryDataPointsSearchBodyElement;

export const SiteControllerSourceDeviceCategories = [
  'ads',
  'alerts',
  'comap',
  'commstatus',
  'facp',
  'imd',
  'local controller',
  'master_tracker',
  'meter',
  'mkt',
  'mp',
  'mpc',
  'plc',
  'powinems',
  'powinbess',
  'rtac',
  'site',
  'tsc',
  'ups',
  'weather',
];

export const getFullTopicV1 = (o: GetFullTopicV2Args, siteId: string) =>
  purgeEmptyStrings(() => {
    switch (capitalized(o.deviceType)) {
      case 'Site':
        return `Site/${o.controllerId}/${o.pointName}/${o.dataType}`;
      case 'MSG':
        return `MSG/${o.controllerId}`;
      default:
        return `${siteId}/${capitalized(o.deviceType)}/${o.controllerId}/${
          o.pointName
        }/${o.dataType}`;
    }
  });

enum TopicSegmentLength {
  SITE_ROOT = 6,
  SITE_SOURCE_DEVICE = 7,
  UNIT_ROOT = 8,
  UNIT_SOURCE_DEVICE = 9,
}

export const extractEngineeringIdFromTopic = (
  topic: string,
): string | undefined => {
  if (!topic) {
    return;
  }

  const topicSegments = topic.split('/');
  const segmentCount = topicSegments.length;

  const engineeringIdMap: Record<number, number> = {
    [TopicSegmentLength.SITE_ROOT]: 2,
    [TopicSegmentLength.SITE_SOURCE_DEVICE]: 4,
    [TopicSegmentLength.UNIT_ROOT]: 4,
    [TopicSegmentLength.UNIT_SOURCE_DEVICE]: 6,
  };

  const engineeringIdIndex = engineeringIdMap[segmentCount];

  if (!engineeringIdIndex) {
    return;
  }

  return topicSegments[engineeringIdIndex];
};

export const getFullTopicV2 = (o: GetFullTopicV2Args, siteId: string) => {
  const siteLevelDeviceCategory = SiteControllerSourceDeviceCategories.find(
    (d) => d === o.deviceType,
  );
  // Site root point - 7 segments
  if (o.deviceType === 'site' && o.sourceDeviceId === undefined) {
    return `ems/site/${siteId}/root/${o.pointName}/${o.dataType}`;
  }
  // Site sourceDevice point - 8 segments
  if (siteLevelDeviceCategory !== undefined && o.sourceDeviceId !== undefined) {
    return `ems/site/${siteId}/${siteLevelDeviceCategory}/${o.sourceDeviceId}/${o.pointName}/${o.dataType}`;
  }
  // Unit root point - 9 segments
  if (o.deviceType === 'unit' && o.sourceDeviceId === undefined) {
    return `ems/site/${siteId}/unit/${o.controllerId}/root/${o.pointName}/${o.dataType}`;
  }
  // Unit sourceDevice point - 10 segments
  if (siteLevelDeviceCategory === undefined && o.sourceDeviceId !== undefined) {
    return `ems/site/${siteId}/unit/${o.controllerId}/${o.deviceType}/${o.sourceDeviceId}/${o.pointName}/${o.dataType}`;
  }
  return null;
};

export const generateDataPoint = (
  pointObj: QueryDataPointsSearchBodyElement,
  siteId: string,
): DataPoint => {
  const dataType = DataTypePointNameDictionary[pointObj.pointName] || 'float';
  const fullTopic =
    getFullTopicV2({ ...pointObj, dataType }, siteId) ||
    'incorrect/parameters/to/generate/topic';
  return {
    id: '00000-00000-00000-00000-00000',
    createdAt: 'someDate',
    updatedAt: 'someDate',
    displayName: 'dummyDisplayName',
    pointAlias: pointObj.pointAlias || '',
    deviceType: pointObj.deviceType,
    controllerId: pointObj.controllerId || siteId,
    pointName: pointObj.pointName,
    fullTopic,
    siteId,
    dataType,
    sourceDeviceId: pointObj.sourceDeviceId || null, // Type.String()
    sourceDeviceType: pointObj.sourceDeviceId ? pointObj.deviceType : null,
  };
};

export const generateTopics = (
  data: QueryDataPointsSearchBodyElement[],
  siteId: SiteMeta['siteId'],
) => data.map((p) => generateDataPoint(p, siteId));
