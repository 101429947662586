import { LOOKBACK_MS, seriesColors } from '@components/Home/Alerts/config';
import {
  HistoricalAlert,
  SeverityTypes,
  TimelineChartSeries,
} from '@src/types/Alerts';
import { toMatrix } from '.';

const processAlertsForTimelineChart = (
  alerts: HistoricalAlert[],
  lookbackPeriod: string,
): TimelineChartSeries[] => {
  const formattedData: TimelineChartSeries[] = [];
  const grouped = alerts.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.topic]: [
        ...(acc[curr.topic] !== undefined ? acc[curr.topic] : []),
        curr,
      ],
    }),
    {} as { [key: HistoricalAlert['topic']]: HistoricalAlert[] },
  );
  (Object.keys(grouped) as HistoricalAlert['topic'][]).forEach(
    (alertSeriesId) => {
      const alertsGrouped = grouped[alertSeriesId].reverse();
      if (!alertsGrouped[0].isActive) {
        alertsGrouped.unshift({
          ...alertsGrouped[0],
          fake: true,
          isActive: true,
          ts: new Date(Date.now() - LOOKBACK_MS[lookbackPeriod]).toISOString(),
        } as HistoricalAlert);
      }
      if (alertsGrouped[alertsGrouped.length - 1].isActive) {
        alertsGrouped.push({
          ...alertsGrouped[alertsGrouped.length - 1],
          isActive: false,
          fake: true,
          ts: new Date().toISOString(),
        } as HistoricalAlert);
      }
      if (alertsGrouped.length > 0) {
        const seriesName = SeverityTypes[alertsGrouped[0].configSeverity];
        let seriesContainer: TimelineChartSeries;
        const existingSeries = formattedData.find((o) => o.name === seriesName);
        if (existingSeries) {
          seriesContainer = existingSeries;
        } else {
          formattedData.push({
            name: seriesName,
            data: [],
            color: seriesColors[seriesName],
          });
          seriesContainer = formattedData[formattedData.length - 1];
        }
        const alertsFormatted = toMatrix(alertsGrouped, 2) as unknown as [
          HistoricalAlert,
          HistoricalAlert,
        ][];
        alertsFormatted.forEach(([startingAlert, endingAlert]) => {
          try {
            seriesContainer.data.push({
              x: 'Alert active',
              y: [
                new Date(startingAlert.ts).getTime(),
                new Date(endingAlert.ts).getTime(),
              ],
              alertName: startingAlert.configAlertName,
            });
          } catch (e) {
            console.error(e);
            throw new Error('ERROR: Bad Influx data for timeline.');
          }
        });
      }
    },
  );
  return formattedData;
};

export default processAlertsForTimelineChart;
