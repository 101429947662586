import {
  DataPoint,
  QueryDataPointsSearchBodyElement,
} from '@src/types/DataPoint';
import { store } from '@store';
import { generateTopics } from '@utils/generateTopic';

const useDataPointsSearch = (
  body: QueryDataPointsSearchBodyElement[],
): DataPoint[] =>
  generateTopics(body, store.getState().config.siteMeta?.siteId);

export default useDataPointsSearch;
