import SystemMeter from '@components/_shared/SystemMeter';
import { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import UnitTable from '@components/_shared/UnitTable/UnitTable';
import WeatherWidget from '@components/Sun/WeatherWidget';
import SwitchPCSContainer from '@components/_shared/PCSContainers/SwitchPCSContainer';

interface METProps {
  siteMeta: SiteMeta;
}
function MET({ siteMeta }: METProps): ReactElement {
  return (
    <div className='home-row'>
      <div className='fcol'>
        <SystemMeter />
        <UnitTable />
      </div>
      <div className='fcol'>
        <SwitchPCSContainer pcsType={siteMeta.PCS_type} pcsIndex={0} />
        <WeatherWidget hideForecast={!siteMeta.ui.WeatherForecast_enable} />
      </div>
    </div>
  );
}

export default storeConnector(MET, {
  config: ['siteMeta'],
});
