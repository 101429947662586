/* eslint-disable max-lines */
import { ReactElement } from 'react';
import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import useSubscription from '@hooks/useSubscription';
import useDataPointsSearch from '@hooks/api/queries/useDataPointsSearch';
import {
  DatapointConnector,
  DynamicConnector,
  Resistor,
} from '@src/sld/components/Shared';
import {
  BearCanyonWestFordPointsWithValues,
  createBearCanyonWestFordFlatDiagramPropertiesFromDataPoints,
  getBearCanyonWestFordPointsForSearchBody,
  prepareSubscriptionDataPointArray,
} from '@src/sld/components/utils';
import Breaker from '@src/sld/components/Breaker/Breaker';

function BearCanyonDiagram(): ReactElement {
  const dataPoints = useDataPointsSearch(
    getBearCanyonWestFordPointsForSearchBody(),
  );

  const subscriptionDataPoints: DataPoint[] =
    prepareSubscriptionDataPointArray(dataPoints);

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    subscriptionDataPoints,
  );

  const diagramPointsWithValues: BearCanyonWestFordPointsWithValues =
    createBearCanyonWestFordFlatDiagramPropertiesFromDataPoints(
      dataPointsWithValue,
    );

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      width='17in'
      height='11in'
      viewBox='0 0 1224 792'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor' }}
    >
      <g>
        <title>Bear Canyon</title>

        <g transform='translate(556.48 -620.031)'>
          <title>Title</title>
          <polygon
            transform='translate(-13, 715) rotate(-90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <DynamicConnector
            label='PG&E 230KV'
            pathCoordinates='M-8.99 792v-21.84h-.02v-64.02" className="st2'
            className=''
            xCoordinate={0}
            yCoordinate={695}
          />
        </g>

        <g transform='rotate(90 579.59 743.22)'>
          <title>Resistor</title>
          {/* } x / y coordinates adjust the text height and alignment { */}
          <Resistor
            label='XFMR T1'
            path_1_coordinates='M33.36 758.64h-8.34a4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17h8.34'
            path_2_coordinates='M0 758.64h8.34a4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17A4.17 4.17 0 0 1 8.34 792H0'
            xCoordinate={15}
            yCoordinate={740}
          />
        </g>

        <g transform='translate(533.952 -504)'>
          <title>Breaker 52-M1</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_m1_dataPointValue}
            enableBreakerCommands={false}
            label='52-M1'
            xCoordinate={70}
            yCoordinate={785}
          />
        </g>

        <g transform='translate(849.691 -288)'>
          <title>Breaker 52-F3</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_f3_dataPointValue}
            enableBreakerCommands={false}
            label='52-F3'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17, 900) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          {/* arrow */}
          <text x={-700} y={950} fill='currentColor' stroke='none'>
            34.5KV BESS FEEDER #1
          </text>
        </g>

        <g transform='translate(533.952 -288)'>
          <title>Breaker 52-F2</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_f2_dataPointValue}
            enableBreakerCommands={false}
            label='52-F2'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17, 900) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          {/* arrow */}
          <text x={-65} y={950} fill='currentColor' stroke='none'>
            34.5KV BESS FEEDER #2
          </text>
        </g>

        <g transform='translate(218.214 -288)'>
          <title>Breaker 52-F1</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_f1_dataPointValue}
            enableBreakerCommands={false}
            label='52-F1'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17.5, 907) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          {/* arrow */}
          <text x={570} y={950} fill='currentColor' stroke='none'>
            34.5KV BESS FEEDER #3
          </text>
        </g>

        <g transform='translate(231.75 -315.073)'>
          <title>Data Points Line Connector</title>
          <DatapointConnector
            voltage={`Voltage: ${diagramPointsWithValues.voltage}`}
            current={`Current: ${diagramPointsWithValues.current}`}
            activePower={`Current: ${diagramPointsWithValues.activePower}`}
            reactivePower={`Current: ${diagramPointsWithValues.reactivePower}`}
            pathCoordinates='M0 792v-80.93h315.74v-108'
            className='st5'
            xCoordinate={475}
            yCoordinate={560}
          />
        </g>

        <g transform='translate(538.489 -531.073)'>
          <title>Dynamic connector.1323</title>
          <path d='M9 792v-72.28' className='st5' />
        </g>

        <g transform='translate(863.227 -306.073)'>
          <title>Dynamic connector.1325</title>
          <path d='M0 783v-80.93h-315.74V783' className='st5' />
        </g>

        <g transform='rotate(90 259.875 763.875)'>
          <title>Sheet.1329</title>
          <path d='M0 792h115.38' className='st6' />
        </g>

        <g transform='rotate(90 417.745 921.745)'>
          <title>Sheet.1356</title>
          <path d='M0 792h115.38' className='st6' />
        </g>

        <g transform='rotate(90 575.615 1079.615)'>
          <title>Sheet.1362</title>
          <path d='M0 792h115.38' className='st6' />
        </g>
      </g>
    </svg>
  );
}

export default BearCanyonDiagram;
