import { queryFn } from '@hooks/useApiQuery';
import { SiteMeta } from '@src/types/SiteMeta';
import { dispatcher, store } from '@store';
import queryClient from '@utils/queryClient';

export default async function loadSiteMeta() {
  let siteMeta: SiteMeta | undefined;
  try {
    siteMeta = await queryClient.fetchQuery<SiteMeta>({
      queryKey: ['siteMeta'],
      queryFn: () =>
        queryFn({
          queryKey: ['site-meta'],
          method: 'GET',
          apiPath: '/site-meta',
          useV2API: true,
        }),
    });
  } catch (error) {
    if (((error as Error).cause as Response)?.status === 404) {
      return null;
    }
    console.error(error);
    throw error;
  }

  store.dispatch(
    dispatcher('UPDATE_CONFIG_MODULE', {
      siteMeta,
    }),
  );
  return siteMeta;
}
