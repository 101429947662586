import Spinner from '../Spinner/Spinner';
import { useCallback, useEffect, useRef } from 'react';

const Popup = ({
  isOpen,
  title,
  close,
  children,
  loading = false,
  bodyClass,
  subTitle = null,
}) => {
  const modalRef = useRef();

  const handleClick = useCallback((e) => {
    if (modalRef.current?.contains(e.target) === false) {
      close();
    }
  }, []);
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return isOpen ? (
    <div
      className={'cover-container'}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          close();
        }
      }}
    >
      <div ref={modalRef} className='control-popup-container'>
        <div className='title-profile'>
          {title}
          {!!subTitle && (
            <>
              <br />
              <span style={{ fontWeight: 'normal' }}>{subTitle}</span>
            </>
          )}
        </div>
        <div className='popup-close' onClick={() => close()} />
        <div className={bodyClass || 'profile-content'}>{children}</div>
        {loading && <Spinner cover='container' />}
      </div>
    </div>
  ) : null;
};

export default Popup;
