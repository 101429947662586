import BarHighChart from '@components/_elements/BarHighChart/BarHighChart';

interface PVProductionProps {
  loading: boolean;
  data: { date: string; value: number }[];
}

function PVProduction({ loading, data }: PVProductionProps) {
  return (
    <BarHighChart
      variables={false}
      isAxesInvisible
      unit='MWh'
      name='PV'
      barsClasses={[0]}
      loading={loading}
      hourly
      margins={{ top: 10, right: 5, bottom: 10, left: 5 }}
      data={data}
    />
  );
}

export default PVProduction;
