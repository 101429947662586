import { ReactElement } from 'react';
import Circle from '@src/sld/components/Circle/Circle';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import Switch from '@src/sld/components/Switch/Switch';
import { Resistor } from '@src/sld/components/Resistor/Resistor';

type TopSwitchResistorProps = {
  translateXCoordinate: number;
  translateYCoordinate: number;
};

function TopSwitchResistorSection(props: TopSwitchResistorProps): ReactElement {
  const { translateXCoordinate, translateYCoordinate } = props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;
  return (
    <g transform={translate}>
      <Circle
        label='230 kV POI'
        translateXCoordinate={708}
        translateYCoordinate={57}
        diameter={3}
        stroke='white'
        strokeWidth={0.5}
        fillColor='black'
        overrideTextXCoordinate={-50}
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={65}
        length={8}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <Switch
        label='89L2'
        isClosed={false}
        translateXCoordinate={708}
        translateYCoordinate={74}
        stroke='green'
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={85}
        length={20}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <g transform='rotate(90 693 790)'>
        <Resistor label='T4' />
      </g>

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={122}
        length={28}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <Switch
        label='89T4'
        isClosed={false}
        translateXCoordinate={708}
        translateYCoordinate={152}
        stroke='green'
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={162}
        length={10}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <VerticalLine
        translateXCoordinate={235}
        translateYCoordinate={590}
        length={10}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <VerticalLine
        translateXCoordinate={235}
        translateYCoordinate={590}
        length={10}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />
    </g>
  );
}

export default TopSwitchResistorSection;
