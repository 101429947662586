import SiteMeta from '@pages/Home/pages/Collections/pages/SiteMeta';
import { Navigate, RouteObject } from 'react-router';
import AppLayout from '@pages/_layout/AppLayout';
import WidgetConfigCollection from '@src/components/Home/Collections/WidgetConfigs/WidgetConfigCollection';
import DeviceCollection from '@src/components/Home/Collections/Devices/DeviceCollection';
import PointConfigCollection from '@src/components/Home/Collections/PointConfigs/PointConfigCollection';
import TopBar from './TopBar';

export default [
  {
    path: 'collections',
    element: <AppLayout isNested topBar={<TopBar />} />,
    children: [
      { index: true, element: <Navigate to='devices' replace /> },
      { path: 'site-meta', element: <SiteMeta /> },
      { path: 'devices', element: <DeviceCollection /> },
      { path: 'point-configs', element: <PointConfigCollection /> },
      { path: 'widget-configs', element: <WidgetConfigCollection /> },
    ],
  },
] as RouteObject[];
