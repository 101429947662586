import { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import SystemMeter from '@components/_shared/SystemMeter';
import { SiteMeta } from '@src/types/SiteMeta';
import UnitTable from '@components/_shared/UnitTable/UnitTable';
import SunChart from '@components/Sun/SunChart/SunChart';
import WeatherWidget from '@components/Sun/WeatherWidget';

interface SYSProps {
  siteMeta: SiteMeta;
}
function SYS({ siteMeta }: SYSProps): ReactElement {
  return (
    <div className='home-row'>
      <div className='fcol'>
        <SystemMeter />
        <UnitTable />
      </div>
      <div className='fcol'>
        <SunChart />
        <WeatherWidget hideForecast={!siteMeta.ui.WeatherForecast_enable} />
      </div>
    </div>
  );
}

export default storeConnector(SYS, {
  config: ['siteMeta'],
});
