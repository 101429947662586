import { ValueGenerationFunction } from '@src/types/Widget';
import { UserState } from '@store/reducers/userData';
import { ReactElement, ReactNode, useMemo } from 'react';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { DataPointWithValue } from '@src/types/DataPoint';
import { BooleanInteger } from '@types';
import Indicator from '@components/_shared/Indicator';
import storeConnector from '@store/storeConnector';
import { isSuperAdmin } from '@src/services/auth';
import { formatFloat, isNumberTypeGuard } from '@utils/index_ts';

const DEFAULT_PRECISION = 2;

function parseValue(
  value: undefined | null | string | number | boolean,
  dataType: string,
  scalingFactor: number | null | undefined,
  decimalPrecision: number | null | undefined,
  fullTopic: string,
  invertDisplay: boolean | undefined,
  valueMappings: Record<string | number, string | number | boolean> | undefined,
): string | ReactNode {
  if (value === undefined) {
    return (
      <Box role='spinner'>
        <CircularProgress size={15} />
      </Box>
    );
  }

  if (value === null) {
    return 'ERR';
  }

  if (
    valueMappings &&
    (typeof value === 'string' || typeof value === 'number')
  ) {
    value =
      valueMappings[
        typeof value === 'number' ? JSON.stringify(value) : value
      ] ?? value;
    // force dataType to process the mapped value properly below
    if (typeof value === 'number') {
      dataType = 'integer';
    } else if (typeof value === 'boolean') {
      dataType = 'boolean_integer';
      value = value ? 1 : 0;
    } else {
      dataType = 'string';
    }
  }

  if (dataType === 'integer') {
    if (!isNumberTypeGuard(value)) {
      console.error(
        `ValueLoader: value ${value} is not a number for topic ${fullTopic}`,
      );
      return 'ERR';
    }
    return Math.floor(value).toString();
  }

  if (dataType === 'float') {
    if (!isNumberTypeGuard(value)) {
      console.error(
        `ValueLoader: value ${value} is not a number for topic ${fullTopic}`,
      );
      return 'ERR';
    }
    return formatFloat(
      value,
      scalingFactor,
      decimalPrecision,
      DEFAULT_PRECISION,
    );
  }

  if (dataType === 'boolean_integer') {
    if (!isNumberTypeGuard(value) || ![0, 1].includes(value)) {
      console.error(
        `ValueLoader: value ${value} is not a boolean integer for topic ${fullTopic}`,
      );
      return 'ERR';
    }

    return (
      <Indicator
        value={value as BooleanInteger}
        invertDisplay={invertDisplay}
      />
    );
  }

  return value.toString();
}

function ValueLoaderContent(p: {
  dataPoint: DataPointWithValue;
}): ReactElement {
  const {
    value,
    dataType,
    fullTopic,
    scalingFactor,
    decimalPrecision,
    unitOfMeasurement,
    invertDisplay,
    valueMappings,
  } = p.dataPoint;

  const parsedValue = parseValue(
    value,
    dataType,
    scalingFactor,
    decimalPrecision,
    fullTopic,
    invertDisplay,
    valueMappings,
  );

  return (
    <Box>
      {parsedValue}
      {unitOfMeasurement && (
        <span
          style={{
            fontSize: '0.75rem',
          }}
        >
          {unitOfMeasurement}
        </span>
      )}
    </Box>
  );
}

interface ValueLoaderProps {
  dataPoint: DataPointWithValue & {
    valueGenerationFunction: ValueGenerationFunction;
  };
  role: UserState['role'];
}

function ValueLoader(p: ValueLoaderProps): ReactElement {
  const title = useMemo(() => {
    if (!isSuperAdmin(p.role)) {
      return '';
    }
    return p.dataPoint.valueGenerationFunction
      ? `Generated value: ${p.dataPoint.valueGenerationFunction?.function} from ${p.dataPoint.valueGenerationFunction?.argumentArray.join(', ')}`
      : p.dataPoint.fullTopic;
  }, [p.role, p.dataPoint.fullTopic, p.dataPoint.valueGenerationFunction]);
  return (
    <Tooltip placement='right' role='tooltip' title={title}>
      <Box>
        <ValueLoaderContent dataPoint={p.dataPoint} />
      </Box>
    </Tooltip>
  );
}

export default storeConnector(ValueLoader, {
  user: ['role'],
});
