/* eslint-disable max-lines */
import {
  DeviceListDeviceRefsAll,
  WidgetConfigCreateOrEdit,
  WidgetTypes,
} from '@src/types/Widget';

const widgets: WidgetConfigCreateOrEdit[] = [
  {
    name: 'MP List',
    internalId: 'mpList',
    isSystemWidget: true,
    type: WidgetTypes.DEVICE_LIST,
    deviceRefs: DeviceListDeviceRefsAll.ALL,
    deviceType: 'mp',
    pointConfigRefs: [
      {
        pointAlias: 'megapack_disabled',
      },
      {
        pointAlias: 'ac_breaker_status',
      },
      {
        pointAlias: 'loss_of_megapack_communication',
      },
      {
        pointAlias: 'megapack_real_power',
      },
      {
        pointAlias: 'megapack_reactive_power',
      },
      {
        pointAlias: 'megapack_state_of_energy',
      },
    ],
  },
  {
    name: 'TSC List',
    internalId: 'tscList',
    isSystemWidget: true,
    type: WidgetTypes.DEVICE_LIST,
    deviceRefs: DeviceListDeviceRefsAll.ALL,
    deviceType: 'tsc',
    pointConfigRefs: [
      {
        pointAlias: 'meter_real_power',
      },
      {
        pointAlias: 'meter_reactive_power',
      },
      {
        pointAlias: 'remaining_battery_energy',
      },
      {
        pointAlias: 'number_of_available_megapacks',
      },
    ],
  },
  {
    name: 'Unit Controllers List',
    internalId: 'unitList',
    isSystemWidget: true,
    type: WidgetTypes.DEVICE_LIST,
    deviceRefs: DeviceListDeviceRefsAll.ALL,
    deviceType: 'unitController',
    pointConfigRefs: [
      {
        pointAlias: 'AC_Breaker',
      },
      {
        pointAlias: 'kW',
      },
      {
        pointAlias: 'kVAR',
      },
      {
        pointAlias: 'racks_online',
      },
      {
        pointAlias: 'max_amb_t',
      },
      {
        pointAlias: 'max_cell_t',
      },
      {
        pointAlias: 'ac_breaker',
      },
      {
        pointAlias: 'dc_disconnect',
      },
      {
        pointAlias: 'hvac_status_unit_state',
      },
      {
        pointAlias: 'fan_status',
      },
      {
        pointAlias: 'lo_co_alarm',
      },
      {
        pointAlias: 'lo_hydrogen_alarm',
      },
      {
        pointAlias: 'hi_hydrogen_alarm',
      },
      {
        pointAlias: 'vesda_fire_alarm',
      },
      {
        pointAlias: 'vesda_action',
      },
      {
        pointAlias: 'fa_discharge_alarm',
      },
    ],
  },
  {
    name: 'TSC Details',
    internalId: 'tscDetails',
    isSystemWidget: true,
    type: WidgetTypes.POINT_LIST,
    pointConfigRefs: [
      [
        {
          pointAlias: 'state_request',
        },
        {
          pointAlias: 'number_of_available_megapacks',
        },
        {
          pointAlias: 'remaining_battery_energy',
        },
        {
          pointAlias: 'dispatchable_charge_power',
        },
        {
          pointAlias: 'dispatchable_discharge_power',
        },
      ],
      [
        {
          pointAlias: 'meter_real_power',
        },
        {
          pointAlias: 'meter_reactive_power',
        },
        {
          pointAlias: 'battery_direct_real_power_command',
        },
      ],
      [
        {
          pointAlias: 'meter_voltage',
        },
        {
          pointAlias: 'meter_frequency',
        },
        {
          pointAlias: 'battery_direct_reactive_power_command',
        },
      ],
    ],
  },
  {
    name: 'MP Details Modal',
    internalId: 'mpDetailsModal',
    isSystemWidget: true,
    type: WidgetTypes.MULTI_SECTION_POINT_LIST,
    sections: [
      {
        title: 'Status',
        pointConfigRefs: [
          [
            {
              pointAlias: 'megapack_disabled',
            },
            {
              pointAlias: 'ac_breaker_status',
            },
            {
              pointAlias: 'loss_of_megapack_communication',
            },
          ],
          [
            {
              pointAlias: 'megapack_state_of_energy',
            },
            {
              pointAlias: 'megapack_real_power',
            },
            {
              pointAlias: 'megapack_reactive_power',
            },
            {
              pointAlias: 'megapack_real_power_target',
            },
            {
              pointAlias: 'megapack_reactive_power_target',
            },
            {
              pointAlias: 'megapack_available_charge_power',
            },
            {
              pointAlias: 'megapack_available_discharge_power',
            },
          ],
        ],
      },
      {
        title: 'Meter',
        pointConfigRefs: [
          [
            {
              pointAlias: 'megapack_frequency',
            },
            {
              pointAlias: 'megapack_ac_voltage',
            },
            {
              pointAlias: 'megapack_phase_a_voltage',
            },
            {
              pointAlias: 'megapack_phase_b_voltage',
            },
            {
              pointAlias: 'megapack_phase_c_voltage',
            },
          ],
          [
            {
              pointAlias: 'megapack_phase_a_current',
            },
            {
              pointAlias: 'megapack_phase_b_current',
            },
            {
              pointAlias: 'megapack_phase_c_current',
            },
          ],
        ],
      },
      {
        title: 'Temperature',
        pointConfigRefs: [
          [
            {
              pointAlias: 'maximum_battery_temperature',
            },
          ],
          [
            {
              pointAlias: 'ambient_temperature',
            },
          ],
        ],
      },
    ],
  },
  {
    name: 'Incident Command',
    internalId: 'incidentCommandList',
    isSystemWidget: true,
    type: WidgetTypes.DEVICE_LIST,
    header: 'Incident Command',
    deviceRefs: DeviceListDeviceRefsAll.ALL,
    deviceType: 'unitController',
    pointConfigRefs: [
      {
        pointAlias: 'ic_bms1_avg_cell_v',
        overrideSourceDeviceId: 'bms_1',
      },
      {
        pointAlias: 'ic_bms2_avg_cell_t',
        overrideSourceDeviceId: 'bms_2',
      },
      {
        pointAlias: 'min_amb_t',
      },
      {
        pointAlias: 'avg_amb_t',
      },
      {
        pointAlias: 'max_amb_t',
      },
      {
        pointAlias: 'max_cell_t',
      },
      {
        pointAlias: 'ac_breaker',
      },
      {
        pointAlias: 'dc_disconnect',
      },
      {
        pointAlias: 'hvac_status_unit_state',
      },
      {
        pointAlias: 'fan_status',
      },
      {
        pointAlias: 'lo_co_alarm',
      },
      {
        pointAlias: 'lo_hydrogen_alarm',
      },
      {
        pointAlias: 'hi_hydrogen_alarm',
      },
      {
        pointAlias: 'vesda_fire_alarm',
      },
      {
        pointAlias: 'vesda_action',
      },
      {
        pointAlias: 'fa_discharge_alarm',
      },
    ],
  },
];

export default widgets;
