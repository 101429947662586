import { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import SuperstitionDiagram from '@src/sld/superstition/SuperstitionDiagram';
import BearCanyonDiagram from '@src/sld/bear_canyon/BearCanyonDiagram';
import WestFordFlatDiagram from '@src/sld/west_ford_flat/WestFordFlatDiagram';
import SageBrushB59Diagram from '@src/sld/sagebrush/sagebrush_b59/SageBrushB59Diagram';
import SageBrushB40Diagram from '@src/sld/sagebrush/sagebrush_b40/SageBrushB40Diagram';
import BeaumontDiagram from '@src/sld/beaumont/BeaumontDiagram';
import CanyonCountryDiagram from '@src/sld/canyon_country/CanyonCountryDiagram';
import HatchRoadSolarDiagram from './hatchroadsolar/Diagram/Diagram';
import BrazosBendDiagram from './brazos_bend/BrazosBendDiagram';
import Scatterwash_1_Diagram from './scatterwash/Scatterwash_1_Diagram';
import Scatterwash_2_Diagram from './scatterwash/Scatterwash_2_Diagram';

const getSLD = (sitename: string) => {
  let diagram: ReactElement = <div />;

  switch (sitename.toLowerCase()) {
    case 'superstition':
      diagram = <SuperstitionDiagram />;
      break;
    case 'scatterwash 1':
      diagram = <Scatterwash_1_Diagram />;
      break;
    case 'scatterwash 2':
      diagram = <Scatterwash_2_Diagram />;
      break;
    case 'hatchroadsolar':
      diagram = <HatchRoadSolarDiagram />;
      break;
    case 'bear canyon':
      diagram = <BearCanyonDiagram />;
      break;
    case 'west ford flat':
      diagram = <WestFordFlatDiagram />;
      break;
    case 'sagebrush b59':
      diagram = <SageBrushB59Diagram />;
      break;
    case 'sagebrush b40':
      diagram = <SageBrushB40Diagram />;
      break;
    case 'brazos bend':
      diagram = <BrazosBendDiagram />;
      break;
    case 'beaumont':
      diagram = <BeaumontDiagram />;
      break;
    case 'canyon country':
      diagram = <CanyonCountryDiagram />;
      break;
    default:
      break;
  }

  return diagram;
};

function Diagram({ siteMeta }: { siteMeta: SiteMeta }): ReactElement {
  return getSLD(siteMeta.SiteName);
}

export default storeConnector(Diagram, {
  config: ['siteMeta'],
});
