import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import '../Data/index.scoped.scss';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePicker from 'rc-time-picker';
import Calendar from 'rc-calendar';
import Spinner from '@components/_elements/Spinner/Spinner';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';
import { tsDueTimeMode } from '@utils/index_ts';
import { QueryDropdownsOnly } from '@components/Home/Historian/QueryDropdowns';
import { getInitialState } from '@components/Home/Historian/FilterBlock';

const HistorianDump = ({ actions, timeMode, siteMeta }) => {
  const initialState = getInitialState(siteMeta, timeMode);
  const [activeSearch, setActiveSearch] = useState(initialState);

  const updateDataPoint = (_i, dp) =>
    setActiveSearch((prev) => ({
      ...prev,
      datapoints: [dp],
    }));

  const getTsDueTimeMode = (ts, momentObj, prevTimeMode) => {
    return tsDueTimeMode({
      timeMode: timeMode,
      lat: siteMeta.GPSLat,
      long: siteMeta.GPSLong,
      returnMoment: momentObj,
      prevTimeMode,
      ts,
    });
  };

  const [startDate, setStartDate] = useState(getTsDueTimeMode());
  const [stopDate, setStopDate] = useState(getTsDueTimeMode());
  const [startTime, setStartTime] = useState(
    getTsDueTimeMode(false, true)
      .subtract(1, 'hours')
      .format('YYYY-MM-DD HH:mm:ss'),
  );
  const [stopTime, setStopTime] = useState(getTsDueTimeMode());
  const [categories, setCategories] = useState([]);
  const status = useRef('');
  const [loading] = useState(false);

  // load data
  useEffect(() => {
    actions.getHistorianCategoriesAndUnits().then((res) => {
      setCategories(res.categories);
    });
  }, []);

  const runGetResult = async (downloadUrl) => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = downloadUrl;
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
    status.current = 'loaded';
  };

  const timeDateToISO = (timeStr, date) => {
    const time = moment(timeStr);
    const dateGen = moment(date);
    dateGen.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });
    return dateGen.format('YYYY-MM-DDTHH:mm:ss');
  };

  const runExport = async () => {
    const [dp] = activeSearch.dataPoints;
    status.current = 'loading';
    const req = {
      category: dp.category,
      deviceId: dp.sourceDeviceId,
      siteId: siteMeta.siteId,
      unitId: dp.unitId,
      dateFrom: timeDateToISO(startTime, startDate),
      dateTo: timeDateToISO(stopTime, stopDate),
    };
    const res = await actions.runHistorianDataDump(req);
    const urlRes = await actions.getHistorianDataDump(res);
    await runGetResult(urlRes);
  };

  const getCalendarContainer = () => {
    return document.getElementById('calendar-container');
  };

  const isBtnDisabled = () => {
    const [dp] = activeSearch.dataPoints;
    return !startDate || !stopDate || !startTime || !stopTime || !dp.category;
  };
  return (
    <div className='home-row historian-data'>
      {!loading ? (
        <div className='fcol full-width'>
          <div className='frow h30'>
            <div className='filter-block cell block-container centered-content'>
              <div className='filter-grid'>
                <div className='label' style={{ lineHeight: '1' }}>
                  <br />
                  <br />
                  <span>UTC</span>
                </div>
                <div className='inputs-row'>
                  <div
                    className='grid-timestamp'
                    style={{ gridTemplateColumns: 'repeat(3, auto)' }}
                  >
                    <div className='small-lable'>From</div>
                    <div className='small-lable' />
                    <div className='small-lable'>To</div>
                    <div>
                      <div className='input-date-container'>
                        <TimePicker
                          className='timepicker'
                          value={startTime ? moment(startTime) : startTime}
                          popupClassName={`popup-timepicker ${
                            startTime ? '' : 'novalue'
                          }`}
                          placeholder='Start Time'
                          defaultOpenValue={moment().utcOffset(0)}
                          clearIcon=''
                          showSecond={true}
                          onChange={(val) =>
                            setStartTime(
                              val ? val.format('YYYY-MM-DDTHH:mm:ss') : val,
                            )
                          }
                        />
                        <div
                          id='calendar-container'
                          key='start_calendar_container'
                        />
                        <DatePicker
                          value={startDate ? moment(startDate) : startDate}
                          onChange={(val) =>
                            setStartDate(
                              val ? val.format('YYYY-MM-DDTHH:mm:ss') : val,
                            )
                          }
                          getCalendarContainer={getCalendarContainer}
                          calendar={<Calendar format='YYYY-MM-DD' />}
                        >
                          {({ value }) => {
                            return (
                              <span>
                                <input
                                  style={{ width: '120px' }}
                                  readOnly
                                  placeholder='Start Date'
                                  value={
                                    (value &&
                                      moment(value).format('YYYY-MM-DD')) ||
                                    ''
                                  }
                                />
                              </span>
                            );
                          }}
                        </DatePicker>
                      </div>
                    </div>
                    <div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>

                    <div>
                      <div
                        className='input-date-container'
                        style={{ marginRight: '16px' }}
                      >
                        <TimePicker
                          className='timepicker'
                          value={stopTime ? moment(stopTime) : stopTime}
                          popupClassName={`popup-timepicker ${
                            stopTime ? '' : 'novalue'
                          }`}
                          placeholder='End Time'
                          clearIcon=''
                          defaultOpenValue={moment().utcOffset(0)}
                          showSecond={true}
                          onChange={(val) =>
                            setStopTime(
                              val ? val.format('YYYY-MM-DDTHH:mm:ss') : val,
                            )
                          }
                        />
                        <div id='calendar-container' />
                        <DatePicker
                          value={stopDate ? moment(stopDate) : stopDate}
                          onChange={(val) =>
                            setStopDate(
                              val ? val.format('YYYY-MM-DDTHH:mm:ss') : val,
                            )
                          }
                          getCalendarContainer={getCalendarContainer}
                          calendar={<Calendar format='YYYY-MM-DD' />}
                        >
                          {({ value }) => {
                            return (
                              <span>
                                <input
                                  style={{ width: '120px' }}
                                  readOnly
                                  placeholder='End Date'
                                  value={
                                    (value &&
                                      moment(value).format('YYYY-MM-DD')) ||
                                    ''
                                  }
                                />
                              </span>
                            );
                          }}
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <QueryDropdownsOnly
                  label='Data Point'
                  actions={actions}
                  siteMeta={siteMeta}
                  categories={categories}
                  dp={activeSearch.dataPoints[0]}
                  activeSearch={activeSearch}
                  i={0}
                  updateDataPoint={updateDataPoint}
                  hideDataPointDropdown={true}
                />
              </div>
              <div className='loading-row'>
                {status.current === 'loading' && (
                  <>
                    Loading data <Spinner />
                  </>
                )}
              </div>
              <div className='btns-row-for-search' style={{ marginTop: '5px' }}>
                <Button
                  classType='container-bg'
                  size='m'
                  disabled={isBtnDisabled()}
                  onClick={() => runExport()}
                >
                  EXPORT TO CSV
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner type='fullPage' />
      )}
    </div>
  );
};

export default storeConnector(HistorianDump, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
