import '../Modes.scoped.scss';
import PopupButton from '@src/components/_elements/Popup/PopupButton';
import Button from '@src/components/_elements/Button/Button';
import {
  ModeConfig,
  ModeMetadataContent,
  ConfigField,
  ConfigFormData,
  ModeSubmitForm,
} from '@src/types/control-modes/Modes';
import { ControlMode } from '@src/types/control-modes/ControlModes';
import {
  buttonStyles,
  getInputValue,
  getUpdatedConfigData,
} from '../modes-utils';

interface QuickSavePopupButtonProps {
  clearModeSwitchState: () => void;
  config: ModeConfig;
  disabled: boolean;
  formData: ConfigFormData;
  isModeEnabled: boolean;
  mode: ControlMode;
  modeMetadata: ModeMetadataContent;
  submitForm: ModeSubmitForm;
}

function QuickSavePopupButton({
  clearModeSwitchState,
  config,
  disabled,
  formData,
  isModeEnabled,
  mode,
  modeMetadata,
  submitForm,
}: QuickSavePopupButtonProps) {
  const popupContent = (close: () => void) => (
    <div>
      <p style={{ marginBottom: '0.25rem' }}>
        <b>Enabled:</b> {isModeEnabled.toString()}
      </p>
      {modeMetadata.configFields.length > 0
        ? modeMetadata.configFields.map(
            (quickConfig: ConfigField, idx: number) => (
              <div key={quickConfig.configParentProp}>
                <p style={{ marginTop: '1rem', marginBottom: '0.25rem' }}>
                  <b>
                    <u>{quickConfig.configParentProp}</u>
                  </b>
                </p>
                {quickConfig.configInput1?.label ? (
                  <p style={{ marginBottom: '0.25rem' }}>
                    <b>{quickConfig.configInput1.label}:</b>{' '}
                    {getInputValue(formData, idx, 1)}
                  </p>
                ) : (
                  ''
                )}
                {quickConfig.configInput2?.label ? (
                  <p style={{ marginBottom: '0.25rem' }}>
                    <b>{quickConfig.configInput2.label}:</b>{' '}
                    {getInputValue(formData, idx, 2)}
                  </p>
                ) : (
                  ''
                )}
              </div>
            ),
          )
        : ''}

      <hr />

      <Button
        label='Confirm'
        style={{ background: '#d7d7d7' }}
        onClick={async () => {
          const updatedConfigData = getUpdatedConfigData(
            config,
            formData,
            modeMetadata.configFields,
          );
          await submitForm(mode, updatedConfigData, false);
          clearModeSwitchState();
          close();
        }}
      />
    </div>
  );

  return (
    <PopupButton
      buttonStyles={buttonStyles}
      disabled={disabled}
      title={`Confirm ${
        modeMetadata.displayName ? modeMetadata.displayName : mode
      } Mode Config`}
      link
      content={popupContent}
    >
      Quick Save
    </PopupButton>
  );
}

export default QuickSavePopupButton;
