import { useTranslation } from 'react-i18next';
import Popup from '@components/_elements/Popup/Popup';

const SupportPopup = ({ isOpen, close }) => {
  const { t } = useTranslation();
  return (
    <Popup
      isOpen={isOpen}
      title={t('FRACTAL_OPERATIONS_CENTER')}
      close={() => close()}
    >
      <div>{t('PHONE')}</div>
      <div>+1 (512) 646-9689</div>
      <div>{t('EMAIL')}</div>
      <div>operations@fractalems.com</div>
      <div>{t('HELP_PORTAL')}</div>
      <div>
        <a
          className='active-link'
          target='_blank'
          rel='noopener noreferrer'
          href='http://fractal-ems-fractal.happyfox.com/'
        >
          HappyFox
        </a>
      </div>
    </Popup>
  );
};

export default SupportPopup;
