import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import tzlookup from 'tz-lookup';
import './SunPositionOverlay.scoped.scss';
import TextLoader from '@components/_elements/TextLoader';
import * as SunCalc from 'suncalc-ts';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';

const PATH_TIME_INTERVAL = 1000 * 60 * 20;

const SunPositionOverlay = ({ width, height, radius, sunRadius, siteMeta }) => {
  const [summerPath, setSummerPath] = useState([]);
  const [winterPath, setWinterPath] = useState([]);
  const [currentPath, setCurrentPath] = useState([]);
  const [sunlightPath, setSunlightPath] = useState([]);
  const [sunPosition, setSunPosition] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const [summerText, setSummerText] = useState({
    visible: false,
  });
  const [winterText, setWinterText] = useState({
    visible: false,
  });
  const [currentText, setCurrentText] = useState({
    visible: false,
  });
  const [siteTS, setSiteTS] = useState(null);

  useWsSubscribe({
    site: { fields: ['PVkW'], cb: (_data, _sn, ts) => setSiteTS(ts) },
  });

  const definiteWidth = width || 250;
  const definiteHeight = height || 250;
  const definiteRadius = radius || 90;
  const definiteSunRadius = sunRadius || 15;

  const centerX = definiteWidth / 2;
  const centerY = definiteHeight / 2;

  const lat = parseFloat(siteMeta.GPSLat);
  const long = parseFloat(siteMeta.GPSLong);
  const currentYearDate = new Date();
  const date = `${currentYearDate.getFullYear()}-01-01 ${siteMeta.PV_TZ}`;

  useEffect(() => {
    calculateSummerWinterPath();
    calculateCurrentPath();
  }, []);

  const getLongestDay = () => {
    const newDate = new Date(date);

    if (lat >= 0) {
      newDate.setMonth(5); // june 20th on northern hemisphere
      newDate.setDate(20);
    } else {
      newDate.setMonth(11); // december 21 on southern hemisphere
      newDate.setDate(21);
    }

    return newDate;
  };

  const getShortestDay = () => {
    const newDate = new Date(date);

    if (lat >= 0) {
      newDate.setMonth(11); // december 21 on northern hemisphere
      newDate.setDate(21);
    } else {
      newDate.setMonth(5); // june 20th on southern hemisphere
      newDate.setDate(20);
    }

    return newDate;
  };

  const getSunPosition = (date) => {
    const position = SunCalc.getPosition(date, lat, long);
    const angle = Math.PI / 2 + position.azimuth;

    return {
      x:
        centerX +
        definiteRadius * Math.cos(angle) * Math.cos(position.altitude),
      y:
        centerY +
        definiteRadius * Math.sin(angle) * Math.cos(position.altitude),
      altitude: position.altitude,
    };
  };

  const calculatePath = (dayInfo) => {
    const dates = [];

    const start = dayInfo.sunrise;
    const end = dayInfo.sunset;

    const date = new Date(start);
    while (date < end) {
      dates.push(new Date(date));
      date.setTime(date.valueOf() + PATH_TIME_INTERVAL);
    }
    dates.push(new Date(end));

    const path = [];
    let belowHorizon = true;

    dates.forEach((d, i) => {
      const sunPosition = getSunPosition(d);

      path.push(i === 0 ? 'M' : 'L');
      path.push(`${sunPosition.x},${sunPosition.y}`);

      belowHorizon = belowHorizon && sunPosition.altitude < 0;
    });

    return belowHorizon ? [] : path;
  };

  const calculateSunlightPath = (upperPath, lowerPath) => {
    if (upperPath.length === 0 || lowerPath.length === 0) {
      return [];
    }

    const path = lowerPath.concat(`A${definiteRadius},${definiteRadius},0,0,1`);

    for (let i = upperPath.length - 2; i >= 0; i -= 2) {
      if (i !== upperPath.length - 2) {
        path.push('L');
      }

      path.push(upperPath[i + 1]);
    }

    return path.concat(`A${definiteRadius},${definiteRadius},0,0,1,${path[1]}`);
  };

  const calculateSummerWinterPath = () => {
    const summerDay = getLongestDay();
    const summerDayInfo = SunCalc.getTimes(summerDay, lat, long);
    const summerPath = calculatePath(summerDayInfo);

    const winterDay = getShortestDay();
    const winterDayInfo = SunCalc.getTimes(winterDay, lat, long);
    const winterPath = calculatePath(winterDayInfo);
    const sunlightPath = calculateSunlightPath(summerPath, winterPath);

    setSummerPath(summerPath);
    setWinterPath(winterPath);
    setSunlightPath(sunlightPath);

    setSummerText({
      visible: summerPath.length > 0,
      x: summerPath[1] ? parseFloat(summerPath[1].split(',')[0]) + 2.5 : 0,
      y: summerPath[1] ? summerPath[1].split(',')[1] : 0,
    });
    setWinterText({
      visible: winterPath.length > 0,
      x: winterPath[1] ? parseFloat(winterPath[1].split(',')[0]) + 7.5 : 0,
      y: winterPath[1] ? winterPath[1].split(',')[1] : 0,
    });
  };

  const calculateCurrentPath = () => {
    const currentDay = new Date();

    const currentDayInfo = SunCalc.getTimes(new Date(), lat, long);
    const currentPath = calculatePath(currentDayInfo);

    const sunPosition = getSunPosition(currentDay);
    sunPosition.visible = sunPosition.altitude >= -0.018;

    setCurrentPath(currentPath);
    setSunPosition(sunPosition);

    setCurrentText({
      visible: currentPath.length > 0,
      x: currentPath[currentPath.length - 1]
        ? parseFloat(currentPath[currentPath.length - 1].split(',')[0]) - 4.5
        : 0,
      y: currentPath[currentPath.length - 1]
        ? currentPath[currentPath.length - 1].split(',')[1]
        : 0,
    });
  };

  let suntime;
  let localtime;
  let tz;
  if (siteTS !== null) {
    tz = tzlookup(lat, long);
    localtime = moment.utc(siteTS).tz(tz);
    suntime = SunCalc.getTimes(localtime, lat, long);
  }

  return (
    <div className='weather-col' style={{ flex: 4 }}>
      <div className='weather-title'>Sun</div>
      <div className='weather-block sun-position'>
        <div className='times'>
          <div className='suntime'>
            <div className='name'>SUNSET</div>
            <div className='time'>
              <TextLoader
                expectedSize={5}
                fontSise={12}
                loading={siteTS === null}
              >
                {suntime && moment(suntime.sunset).tz(tz).format('h:mm\u00A0A')}
              </TextLoader>
            </div>
          </div>
          <div className='suntime'>
            <div className='name'>SUNRISE</div>
            <div className='time'>
              <TextLoader
                expectedSize={5}
                fontSise={12}
                loading={siteTS === null}
              >
                {suntime &&
                  moment(suntime.sunrise).tz(tz).format('h:mm\u00A0A')}
              </TextLoader>
            </div>
          </div>
        </div>
        <svg width={definiteWidth} height={definiteHeight}>
          <circle cx={centerX} cy={centerY} r={definiteRadius} fill='#3cbaf9' />
          <path
            id='sunlightPath'
            stroke='none'
            fill='#fff7cc'
            fillOpacity='1'
            d={sunlightPath.join('')}
          />
          <path
            id='summerPath'
            stroke='rgb(255, 0, 0)'
            fill='none'
            d={summerPath.join('')}
          />
          <path
            id='winterPath'
            stroke='rgb(0, 19, 224)'
            fill='none'
            d={winterPath.join('')}
          />
          <path
            id='currentPath'
            stroke='#ffa500'
            fill='none'
            d={currentPath.join('')}
          />

          <g
            transform={`translate(${sunPosition.x - definiteSunRadius / 2}, ${
              sunPosition.y - definiteSunRadius / 2
            })`}
          >
            <svg
              x='0'
              y='0'
              viewBox='0 0 512 512'
              width={definiteSunRadius}
              height={definiteSunRadius}
              visibility={sunPosition.visible ? 'visible' : 'hidden'}
            >
              <g>
                <polygon
                  fill='#F2B851'
                  points='86.448,210.64 0,254.576 86.448,298.512 	'
                />
                <polygon
                  fill='#F2B851'
                  points='425.552,210.64 425.552,298.512 512,254.576 	'
                />
                <polygon
                  fill='#F2B851'
                  points='134.416,118.08 38.304,105.184 84.512,190.432 	'
                />
                <polygon
                  fill='#F2B851'
                  points='473.696,105.184 377.584,118.08 427.488,190.432 	'
                />
                <polygon
                  fill='#F2B851'
                  points='344.8,12.88 271.584,76.464 353.024,109.504 	'
                />
                <polygon
                  fill='#F2B851'
                  points='167.2,12.88 158.976,109.504 240.416,76.464 	'
                />
                <polygon
                  fill='#F2B851'
                  points='38.304,406.816 134.416,393.92 84.512,321.568 	'
                />
                <polygon
                  fill='#F2B851'
                  points='473.696,406.816 427.488,321.568 377.584,393.92 	'
                />
                <polygon
                  fill='#F2B851'
                  points='353.024,402.496 271.584,435.536 344.8,499.12 	'
                />
                <polygon
                  fill='#F2B851'
                  points='240.416,435.536 158.976,402.496 167.2,499.12 	'
                />
                <circle fill='#F2B851' cx='254.496' cy='256' r='146.496' />
              </g>
            </svg>
          </g>

          <text
            id='summerText'
            x={summerText.x}
            y={summerText.y}
            fill='rgb(255, 0, 0)'
            fontSize='0.5rem'
            textAnchor='start'
            visibility={summerText.visible ? 'visible' : 'hidden'}
          >
            Summer
          </text>
          <text
            id='winterText'
            x={winterText.x}
            y={winterText.y}
            fill='rgb(255, 255, 255)'
            fontSize='0.5rem'
            textAnchor='start'
            visibility={winterText.visible ? 'visible' : 'hidden'}
          >
            Winter
          </text>
          <text
            id='currentText'
            x={currentText.x}
            y={currentText.y}
            fill='#ffa500'
            fontSize='0.5rem'
            textAnchor='end'
            visibility={currentText.visible ? 'visible' : 'hidden'}
          >
            Current
          </text>
        </svg>
      </div>
    </div>
  );
};

export default storeConnector(SunPositionOverlay, {
  config: ['siteMeta'],
});
