import Spinner from '@components/_elements/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { isEmptyObject, windDir } from '@utils';
import storeConnector from '@store/storeConnector';

const WindWidget = ({ weatherForecast }) => {
  // Below renders the chart after component loads
  // to allow flexbox _layout to load,
  // and the chart to fill container width and height
  const [isLayoutLoaded, setLayoutLoaded] = useState(false);
  useEffect(() => {
    setLayoutLoaded(true);
  }, []);
  return isLayoutLoaded ? (
    <div className='weather-col'>
      <div className='weather-title'>Wind</div>
      <div className='weather-block weather-block-wind'>
        {!isEmptyObject(weatherForecast) && !weatherForecast.error ? (
          <div className='compass'>
            <div
              className='arrow'
              style={{
                transform:
                  'rotateZ(' +
                  weatherForecast.currentWeather?.windDirection +
                  'deg)',
              }}
            />
            <div className='direction'>
              <p>
                {windDir(weatherForecast.currentWeather?.windDirection)}
                <span>{weatherForecast.currentWeather?.windSpeed} mph</span>
              </p>
            </div>
          </div>
        ) : (
          <Spinner cover='container' />
        )}
      </div>
    </div>
  ) : null;
};

export default storeConnector(WindWidget, {
  service: ['weatherForecast'],
});
