import { SeverityTypes } from '@src/types/Alerts';
import WarningIcon from '@assets/warning.svg?react';
import CautionIcon from '@assets/caution.svg?react';
import FireIcon from '@assets/fire.svg?react';

function SeverityCell({
  severity,
  isAcked,
}: {
  severity: string;
  isAcked: boolean;
}) {
  const iconClass = `icon s${isAcked ? ' grayscale' : ''}`;

  if (severity === SeverityTypes[0]) {
    return <CautionIcon className={iconClass} />;
  }
  if (severity === SeverityTypes[1]) {
    return <WarningIcon className={iconClass} />;
  }
  return <FireIcon className={iconClass} />;
}

export default SeverityCell;
