import { MqttClient } from 'mqtt';
import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import NoDataIcon from '@assets/no-data.svg?react';
import { SiteMeta } from '@src/types/SiteMeta';
import useIsNoComms from '@src/hooks/useIsNoComms';

interface IconProps {
  lotoMode: boolean;
  isNoComms: boolean;
  circleClass: string;
}

function Icon(p: IconProps): ReactElement {
  if (p.lotoMode) {
    return (
      <div className='pointer'>
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faLock} />
      </div>
    );
  }
  if (p.isNoComms) {
    return (
      <div role='no-data-icon'>
        <NoDataIcon />
      </div>
    );
  }
  return <i role='status-circle' className={`status ${p.circleClass}`} />;
}

interface TooltipProps {
  invStatus: string;
  runMode: string;
  allowedMinP: number;
  allowedMaxP: number;
  allowedMinQ: number;
  allowedMaxQ: number;
  pcsP: number;
  pcsQ: number;
}

function Tooltip(p: TooltipProps): ReactElement {
  return (
    <div className='loto-mode-tooltip'>
      <div className='flex-row-s'>
        Mode: {p.invStatus} / {p.runMode}
      </div>
      <div className='flex-row-s'>
        P-limit:&nbsp;
        {p.allowedMinP} / {p.allowedMaxP}
      </div>
      <div className='flex-row-s'>
        Q-Limit:&nbsp;
        {p.allowedMinQ} / {p.allowedMaxQ}
      </div>
      <div className='flex-row-s'>
        kW Cmd:&nbsp;
        {p.pcsP}
      </div>
      <div className='flex-row-s'>
        kVAR Cmd:&nbsp;
        {p.pcsQ}
      </div>
    </div>
  );
}

export interface ModeDataCellProps {
  lotoMode: boolean;
  unitSN: string;
  circleClass: string;
  invStatus: string;
  runMode: string;
  allowedMinP: number;
  allowedMaxP: number;
  allowedMinQ: number;
  allowedMaxQ: number;
  pcsP: number;
  pcsQ: number;

  setActiveHintSN: (unitSN: string) => void;
  siteMeta: SiteMeta;

  useIsNoCommsMqttClient: MqttClient;
}
function ModeDataCell(p: ModeDataCellProps) {
  const isNoComms = useIsNoComms(
    p.siteMeta,
    p.useIsNoCommsMqttClient,
    p.unitSN,
  );

  return (
    <td
      className={`${p.lotoMode && 'red-text'} loto-mode-box`}
      onClick={() => p.setActiveHintSN(p.unitSN)}
    >
      <Icon
        lotoMode={p.lotoMode}
        isNoComms={isNoComms}
        circleClass={p.circleClass}
      />
      <Tooltip
        invStatus={p.invStatus}
        runMode={p.runMode}
        allowedMinP={p.allowedMinP}
        allowedMaxP={p.allowedMaxP}
        allowedMinQ={p.allowedMinQ}
        allowedMaxQ={p.allowedMaxQ}
        pcsP={p.pcsP}
        pcsQ={p.pcsQ}
      />
    </td>
  );
}

export default ModeDataCell;
