import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import useUsers from '@src/hooks/api/queries/useUsers';
import { User } from '@src/types/User';
import UsersListGridToolbar from './UsersListGridToolbar';
import EditUserButton from './EditUserButton';
import DeleteUserButton from './DeleteUserButton';

const columns: GridColDef<User>[] = [
  {
    headerName: 'Username',
    field: 'username',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Name',
    field: 'name',
    sortable: true,
    valueGetter: (_value, row: User) =>
      `${row.firstName ?? ''} ${row.lastName ?? ''}`,
    flex: 1,
  },
  {
    headerName: 'Email',
    field: 'email',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Groups',
    field: 'groups',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'MFA Enabled',
    field: 'totp',
    sortable: true,
    flex: 1,
    hideable: true,
  },
  {
    headerName: '',
    field: 'actions',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <EditUserButton userId={row.id} />
        <DeleteUserButton user={row} />
      </Box>
    ),
  },
];
function UsersList() {
  const { data: users, isLoading, isFetching } = useUsers();

  return (
    <DataGridPro
      loading={isLoading || isFetching}
      rows={users ?? []}
      columns={columns}
      slots={{ toolbar: UsersListGridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          printOptions: { disableToolbarButton: true },
        },
      }}
      density='comfortable'
      checkboxSelection
      disableRowSelectionOnClick
    />
  );
}

export default UsersList;
