import { ApiMutationOptions } from '@src/types/ApiQuery';
import { store } from '@store';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API_URL, API_V2_URL } from '@utils';

export const getAPIConfig = (path: string) => {
  const bearerToken = store.getState().user.keycloak?.token;
  return { url: `${API_URL}${path}`, bearerToken };
};
export const getV2APIConfig = (path: string) => {
  const bearerToken = store.getState().user.keycloak?.token;
  return { url: `${API_V2_URL}${path}`, bearerToken };
};

const setFileBody = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return formData;
};

const useApiMutation = <TRequest, TResponse>(
  apiOptions: ApiMutationOptions,
  mutationOptions: Omit<
    UseMutationOptions<TResponse, Error, TRequest>,
    'mutationFn'
  > = {},
) =>
  useMutation<TResponse, Error, TRequest>({
    ...mutationOptions,
    mutationFn: async (request: TRequest): Promise<TResponse> => {
      const apiConfig = apiOptions.useV2API
        ? getV2APIConfig(apiOptions.apiPath)
        : getAPIConfig(apiOptions.apiPath);
      const apiResponse = await fetch(apiConfig.url, {
        method: apiOptions.method,
        headers: {
          authorization: `Bearer ${apiConfig.bearerToken}`,
          ...(apiOptions.headers || {
            'Content-Type': 'application/json',
          }),
        },
        ...(request !== null
          ? {
              body: apiOptions.isFileUpload
                ? setFileBody(request as File)
                : JSON.stringify(request),
            }
          : {}),
      });

      if (!apiResponse.ok) {
        throw new Error(`Failed to fetch from ${apiResponse.url}`, {
          cause: await apiResponse.json(),
        });
      }

      return apiResponse.json();
    },
  });

export default useApiMutation;
