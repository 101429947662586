import storeConnector from '@store/storeConnector';
import { Alert, HistoricalAlert } from '@src/types/Alerts';
import { Box, Tooltip } from '@mui/material';
import alertsx from '@components/_shared/Alerts/AlertsSx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { tsDueTimeMode } from '@utils/index_ts';
import { ServiceState } from '@store/reducers/serviceData';
import { ConfigState } from '@store/reducers/configData';

function AcknowledgedInfoCell({
  alertEntity,
  timeMode,
  siteMeta,
}: {
  alertEntity: Alert;
  timeMode: ServiceState['timeMode'];
  siteMeta: ConfigState['siteMeta'];
}) {
  const { ackedBy, ackedNote, ackedTs, isAcked } =
    alertEntity as HistoricalAlert;

  if (!isAcked) {
    return null;
  }

  const title = (
    <div>
      {ackedBy && <div>{ackedBy}</div>}
      {ackedNote && <div>{ackedNote}</div>}
      {ackedTs && (
        <div>
          {tsDueTimeMode({
            timeMode,
            lat: siteMeta.GPSLat,
            long: siteMeta.GPSLong,
            ts: ackedTs,
          }).toString()}
        </div>
      )}
    </div>
  );

  return (
    <Tooltip title={title} placement='left'>
      <Box sx={alertsx.alertCenterAlignBox}>
        <FontAwesomeIcon icon={faCheck} size='lg' />
      </Box>
    </Tooltip>
  );
}

export default storeConnector(AcknowledgedInfoCell, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
