import { ReactElement } from 'react';
import Diagram from '../../../sld';

function SLD(): ReactElement {
  const styles = {
    diagram: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  };
  return (
    <div className='home-row'>
      <div className='fcol full-width h100'>
        <div className='cell block-container flex-1' style={styles.diagram}>
          <Diagram />
        </div>
      </div>
    </div>
  );
}

export default SLD;
