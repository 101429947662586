import useGetInternalWidgetConfig from '@hooks/api/queries/useGetInternalWidgetConfig';
import { generateMqttClient } from '@pages/_loader/initMqttClient.loader';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import { MqttClient } from 'mqtt';
import React, { useEffect } from 'react';
import DeviceDataTable from '@src/components/_shared/DeviceDataTable';
import { GridRowId } from '@mui/x-data-grid-pro';
import { bmsSx } from '@src/components/_shared/sxStyles/Battery/deivceTableList';

function RackContainer({
  bmsId,
  unitControllerId,
  unitEngineeringId,
}: {
  bmsId: GridRowId;
  unitControllerId: string;
  unitEngineeringId: string;
}) {
  const [mqttClient, setMqttClient] = React.useState<MqttClient>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const startClient = async () => {
      const client = await generateMqttClient();
      setMqttClient(client);
    };
    startClient();
    return () => {
      mqttClient?.end();
    };
  }, []);

  const { data: widgetConfig } =
    useGetInternalWidgetConfig<WidgetTypeDeviceList>(
      'rackList',
      {
        sourceDeviceId: bmsId as string,
        unitControllerId,
        unitControllerEngineeringId: unitEngineeringId,
      },
      {
        queryKeys: [bmsId as string],
      },
    );

  return (
    <div>
      <DeviceDataTable
        id='rack'
        hideFooter
        additionalClassnames='detail-data-table'
        devices={widgetConfig?.devices || []}
        dataPoints={widgetConfig?.dataPoints || []}
        pointConfigRefs={widgetConfig?.pointConfigRefs || []}
        mqttClient={mqttClient}
        sx={bmsSx.detailDataTable}
      />
    </div>
  );
}

export default RackContainer;
