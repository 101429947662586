import { useEffect, useState } from 'react';
import { Link } from 'react-router';
import Application from '@components/Home/Config/Application';
import Input from '@components/_elements/Input/Input';
import Spinner from '@components/_elements/Spinner/Spinner';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';

const ApplicationList = ({ actions, siteMeta }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletingElementId, setDeletingElementId] = useState(null);
  const [applications, setApplications] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [isModelAppsShown, setIsModelAppsShown] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const applications = await actions.loadApplications(siteMeta.siteId);
      setApplications(applications);
      setLoading(false);
    })();
  }, []);

  const sortObjKeys = (obj) => {
    return Object.keys(obj).sort((a, b) => {
      if (obj[a].name.toUpperCase() > obj[b].name.toUpperCase()) {
        return 1;
      }
      if (obj[a].name.toUpperCase() < obj[b].name.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  };
  const filterObj = (obj, str) => {
    const newObj = {};
    Object.keys(obj).forEach((el) => {
      if (obj[el].name.toUpperCase().includes(str.toUpperCase())) {
        newObj[el] = obj[el];
      }
    });
    return newObj;
  };
  const deleteApplicationClicked = (id) => {
    setShowConfirm(true);
    setDeletingElementId(id);
  };
  const deleteApplicationAccepted = async () => {
    setLoading(true);
    setShowConfirm(false);
    await actions.deleteApplication(siteMeta.siteId, deletingElementId);
    const applications = await actions.loadApplications(siteMeta.siteId);
    setApplications(applications);
    setLoading(false);
  };
  const filterObjNoModel = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((el) => {
      if (!obj[el].updatedBy.includes('Model-')) {
        newObj[el] = obj[el];
      }
    });
    return newObj;
  };

  const appsSearched = searchStr
    ? filterObj(applications, searchStr)
    : applications;
  const apps = isModelAppsShown ? appsSearched : filterObjNoModel(appsSearched);
  return (
    <div className='home-row'>
      {showConfirm && (
        <div className='cover-container'>
          <div className='control-popup-container small-popup'>
            <div className='popup-row centre'>
              <div className='bold '>
                {`Delete${' ' + applications[deletingElementId].name || ''}?`}
              </div>
            </div>
            <div className='popup-row commands-popup-control'>
              <Button
                size='m'
                onClick={() => {
                  deleteApplicationAccepted();
                }}
              >
                OK
              </Button>
              <Button
                size='m'
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
      )}
      {!loading ? (
        <div className='frow'>
          <div className='fcol'>
            <div className='all-container cell block-container'>
              <div className='all-list'>
                <div className='all-list-title'>APPLICATIONS</div>
                <div className='control-row'>
                  <div className='show-hide-btn'>
                    <Input
                      type='checkbox'
                      className='colored-active'
                      value={isModelAppsShown}
                      onChange={() =>
                        setIsModelAppsShown(
                          (isModelAppsShown) => !isModelAppsShown,
                        )
                      }
                    />
                    <span
                      onClick={() =>
                        setIsModelAppsShown(
                          (isModelAppsShown) => !isModelAppsShown,
                        )
                      }
                    >
                      Show Model apps
                    </span>
                  </div>
                  <Input
                    type='text'
                    className='dark-bg'
                    noAutocomplete
                    value={searchStr}
                    onChange={(val) => setSearchStr(val)}
                    placeholder={'Enter search request'}
                  />
                </div>
                {apps &&
                  sortObjKeys(apps).map((id) => (
                    <Application
                      searchStr={searchStr}
                      name={applications[id].name}
                      key={'app-' + id}
                      id={id}
                      description={applications[id].description}
                      onDeleteClick={(id) => deleteApplicationClicked(id)}
                    />
                  ))}
                {!sortObjKeys(apps).length && (
                  <div
                    style={{ paddingTop: '100px', paddingBottom: '100px' }}
                    className='warn-alarm-no-data'
                  >
                    {searchStr.length
                      ? 'No applications with such name'
                      : 'No created applications'}
                  </div>
                )}
                <Link
                  className='link-btn'
                  to='/home/config/applications/create'
                >
                  <Button className='stacks-button'>Create Application</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner type={'fullPage'} />
      )}
    </div>
  );
};

export default storeConnector(ApplicationList, {
  config: ['siteMeta'],
});
