import { ApiQueryOptions, isRequireBody } from '@src/types/ApiQuery';
import { store } from '@store';
import { saveAs } from 'file-saver';
import { useQuery } from '@tanstack/react-query';
import { API_URL, API_V2_URL } from '@utils';

export const getAPIConfig = (path: string) => {
  const bearerToken = store.getState().user.keycloak?.token;
  return { url: `${API_URL}${path}`, bearerToken };
};
export const getV2APIConfig = (path: string) => {
  const bearerToken = store.getState().user.keycloak?.token;
  return { url: `${API_V2_URL}${path}`, bearerToken };
};

export const queryFn = async (opts: ApiQueryOptions) => {
  const apiConfig = opts.useV2API
    ? getV2APIConfig(opts.apiPath)
    : getAPIConfig(opts.apiPath);
  const apiResponse = await fetch(apiConfig.url, {
    method: opts.method,
    headers: {
      authorization: `Bearer ${apiConfig.bearerToken}`,
      'Content-Type': 'application/json',
      ...opts.headers,
    },
    ...(isRequireBody(opts) ? { body: JSON.stringify(opts.body) } : {}),
  });

  if (!apiResponse.ok) {
    throw new Error(`Failed to fetch from ${apiResponse.url}`, {
      cause: apiResponse,
    });
  }

  if (opts.isFile) {
    const contentType = apiResponse.headers.get('Content-Type');
    const contentDisposition = apiResponse.headers.get('Content-Disposition');
    const blob = await apiResponse.blob();

    let fileName = 'download';
    if (contentDisposition) {
      const matches = contentDisposition.match(
        /filename[^=*=]*=((['"]).*?\2|([^;\n]*))/,
      );
      if (matches && matches[3]) {
        fileName = matches[3].replace(/['"]/g, '');
      }
    } else {
      fileName += `.${contentType?.includes('csv') ? 'csv' : 'json'}`;
    }

    saveAs(blob, fileName);
    return apiResponse;
  }

  return apiResponse.json();
};

const useApiQuery = <T>(opts: ApiQueryOptions) =>
  useQuery<T>({
    queryKey: opts.queryKey,
    queryFn: () => queryFn(opts),
    enabled: opts.enabled,
    refetchOnWindowFocus: false,
  });

export default useApiQuery;
