import storeConnector from '@store/storeConnector';
import '../Modes.scoped.scss';
import PopupButton from '@src/components/_elements/Popup/PopupButton';
import Button from '@src/components/_elements/Button/Button';
import { Actions } from '@src/types/Actions';
import { SiteMeta } from '@src/types/SiteMeta';
import { ModeStatus } from '@src/types/control-modes/Modes';
import { buttonStyles } from '../modes-utils';

interface FullConfigPopupButtonProps {
  actions: Actions;
  clearSCADASwitchState: () => void;
  SCADAModeToggle: ModeStatus;
  siteMeta: SiteMeta;
}

function ConfirmScadaPopupButton({
  actions,
  clearSCADASwitchState,
  SCADAModeToggle,
  siteMeta,
}: FullConfigPopupButtonProps) {
  const popupContent = (close: () => void) => {
    const isSCADAModeEnabled = SCADAModeToggle === ModeStatus.Enabled;
    return (
      <div>
        <p>
          Are you sure you want to{' '}
          <b>{isSCADAModeEnabled ? 'enable' : 'disable'}</b> SCADA Mode?
        </p>
        <hr />
        <Button
          label='Confirm'
          style={{ background: '#d7d7d7' }}
          onClick={() => {
            actions.sendCommand(
              [
                `WriteToVariant::ControlMode_Authority::${
                  isSCADAModeEnabled ? 1 : 0
                }`,
              ],
              siteMeta.siteId,
            );
            clearSCADASwitchState();
            close();
          }}
        />
      </div>
    );
  };

  return (
    <PopupButton
      buttonStyles={buttonStyles}
      title='Confirm SCADA Mode Config'
      link
      content={popupContent}
      bodyClass='popup-body-class'
    >
      Save
    </PopupButton>
  );
}

export default storeConnector(ConfirmScadaPopupButton, {
  config: ['siteMeta'],
});
