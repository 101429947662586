import storeConnector from '@store/storeConnector';
import { MqttClient } from 'mqtt';
import React, { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router';
import { SiteMeta } from '@src/types/SiteMeta';
import Logo from '@assets/logo.png';
import LoginIcon from '@assets/login.svg?react';
import HomeIcon from '@assets/home.svg';
import HomeInactiveIcon from '@assets/home_inactive.svg';
import BatteryIcon from '@assets/battery.svg';
import BatteryInactiveIcon from '@assets/battery_inactive.svg';
import SunIcon from '@assets/sun.svg';
import SunInactiveIcon from '@assets/sun_inactive.svg';
import MktIcon from '@assets/mkt.svg';
import MktInactiveIcon from '@assets/mkt_inactive.svg';
import HistoryIcon from '@assets/chart.svg';
import HistoryInactiveIcon from '@assets/chart_inactive.svg';
import WindIcon from '@assets/wind-turbine.svg';
import WindInactiveIcon from '@assets/wind-turbine_inactive.svg';
import Clock from '@components/_elements/clock/Clock';
import NoDataIcon from '@assets/no-data.svg?react';
import useIsNoComms from '@src/hooks/useIsNoComms';
import IncidentCommandIcon from '@assets/custom-pages.png';
import IncidentCommandInactiveIcon from '@assets/custom-pages_inactive.png';
import useLayoutPermission from '@src/hooks/useLayoutPermission';
import SiteSelect from '@src/components/_elements/SiteDropdown';
import AlarmIcons from './AlertIcons';

// helpers
function getIcon(pathname: string, iconName: string): string {
  switch (iconName) {
    case 'home':
      return pathname.startsWith('/home') ? HomeIcon : HomeInactiveIcon;
    case 'battery':
      return pathname.startsWith('/battery')
        ? BatteryIcon
        : BatteryInactiveIcon;
    case 'sun':
      return pathname.startsWith('/sun') ? SunIcon : SunInactiveIcon;
    case 'mkt':
      return pathname.startsWith('/mkt') ? MktIcon : MktInactiveIcon;
    case 'history':
      return pathname.startsWith('/history')
        ? HistoryIcon
        : HistoryInactiveIcon;
    case 'wind':
      return pathname.startsWith('/wind') ? WindIcon : WindInactiveIcon;
    case 'incident-command':
      return pathname.startsWith('/incident-command')
        ? IncidentCommandIcon
        : IncidentCommandInactiveIcon;
    default:
      throw new Error(`Unknown icon name: ${iconName}`);
  }
}

interface HeaderItemProps {
  name: string;
}

function HeaderItem({ name }: HeaderItemProps): ReactElement {
  const { pathname } = useLocation();
  return (
    <NavLink
      data-test={`nav-${name}`}
      to={`/${name}`}
      className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
    >
      <img
        src={getIcon(pathname, name)}
        className='icon'
        alt={name.toUpperCase()}
        title={name.toUpperCase()}
      />
    </NavLink>
  );
}

interface HeaderProps {
  siteMeta: SiteMeta;
  setShowUserPanel: React.Dispatch<React.SetStateAction<boolean>>;
  useIsNoCommsMqttClient: MqttClient;
}

function AppHeader({
  siteMeta,
  setShowUserPanel,
  useIsNoCommsMqttClient,
}: HeaderProps): ReactElement {
  const isNoComms = useIsNoComms(siteMeta, useIsNoCommsMqttClient);
  const sitesOptions = siteMeta.siteSelectorLinks;
  const { appFeatures } = useLayoutPermission();

  return (
    <div className='bar'>
      <img src={Logo} alt='Fractal EMS' />
      <div className='sndesc'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {sitesOptions && sitesOptions.length > 0 ? (
            <SiteSelect />
          ) : (
            <span style={{ fontSize: '1.5em', marginRight: '6px' }}>
              {siteMeta.SiteName}
            </span>
          )}
        </div>
        <div className='descr'>
          {+siteMeta.kWMaxP / 1000} MW / {+siteMeta.kWHRtg / 1000} MWh
        </div>
      </div>
      <div className='items'>
        <HeaderItem name='home' />
        {appFeatures.map((feature) => (
          <HeaderItem key={feature} name={feature} />
        ))}
      </div>
      {isNoComms && <NoDataIcon className='icon' />}
      {siteMeta.ui.Modes_Only_UI !== true && <AlarmIcons />}

      <Clock />
      <div
        style={{ cursor: 'pointer', marginLeft: '12px' }}
        onClick={(): void => {
          setShowUserPanel(true);
        }}
      >
        <LoginIcon className='icon login' />
      </div>
    </div>
  );
}

export default storeConnector(AppHeader, {
  config: ['siteMeta'],
});
