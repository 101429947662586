import {
  CommandsCategory,
  CommandsMPOperation,
  CommandsOperation,
  CommandsParams,
  CommandsPOWINOperation,
  CommandsTSCOperation,
} from '@src/types/Commands';

function useGetCommands(
  category: CommandsCategory,
  operation: CommandsOperation,
  params: CommandsParams = {},
): string | null {
  if (category === CommandsCategory.TSC) {
    switch (operation as CommandsTSCOperation) {
      case 'Enable':
        return 'Start Unit';
      case 'Disable':
        return 'Stop Unit';
      case 'Power':
        return `Power::${params.P}::${params.Q}`;
      case 'WriteToVariant':
        return `WriteToVariant::${params.P}`;
      default:
        return null;
    }
  }

  if (category === CommandsCategory.POWINEMS) {
    switch (operation as CommandsPOWINOperation) {
      case 'Power':
        return `Power::${params.P}::${params.Q}`;
      case 'WriteToVariant':
        return `WriteToVariant::${params.P}`;
      default:
        return null;
    }
  }

  if (category === CommandsCategory.POWINBESS) {
    switch (operation as CommandsPOWINOperation) {
      case 'PCSStart':
        return `PCS Start::${params.POWINBESS}`;
      case 'PCSStop':
        return `PCS Stop::${params.POWINBESS}`;
      case 'CloseDCContactors':
        return `Close DC Contactors::${params.POWINBESS}`;
      case 'OpenDCContactors':
        return `Open DC Contactors::${params.POWINBESS}`;
      case 'UnitFaultReset':
        return `Unit Fault Reset::${params.POWINBESS}`;
      default:
        return null;
    }
  }

  if (category === CommandsCategory.MP) {
    switch (operation as CommandsMPOperation) {
      case 'OpenBreaker':
        return `Open AC Breaker::${params.MP}`;
      case 'CloseBreaker':
        return `Close AC Breaker::${params.MP}`;
      case 'DisableMegapack':
        return `Open DC Contactor::${params.MP}`;
      case 'ClearDisableMegapack':
        return `Close DC Contactor::${params.MP}`;
      default:
        return null;
    }
  }

  return null;
}

export default useGetCommands;
