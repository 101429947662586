import { lazy } from 'react';
import Dashboard from '@pages/Home/pages/Dashboard';
import Overview from '@pages/Home/pages/Overview';
import SystemMessagesSubpage from '@pages/Home/pages/SystemMessages';
import { Navigate, RouteObject } from 'react-router';
import AppLayout from '@pages/_layout/AppLayout';
import CollectionsRoutes from './pages/Collections/routes';
import AlertsRoutes from './pages/Alerts/routes';
import HistorianRoutes from './pages/Historian/routes';
import ConfigRoutes from './pages/Config/routes';

const CalendarPage = lazy(() => import('@pages/Home/pages/Calendar'));

export default [
  {
    path: '/home',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Navigate to='overview' replace />,
      },
      { path: 'overview', element: <Overview /> },
      { path: 'dashboards', element: <Dashboard /> },
      ...CollectionsRoutes,
      ...AlertsRoutes,
      ...HistorianRoutes,
      { path: 'system_messages', element: <SystemMessagesSubpage /> },
      { path: 'calendar', element: <CalendarPage /> },
      ...ConfigRoutes,
    ],
  },
] as RouteObject[];
