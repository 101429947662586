export default [
  {
    field: 'pointAlias',
    headerName: 'Alias',
    description: 'Alias displayed in the UI',
    type: 'string',
    flex: 1,
  },
  {
    field: 'deviceType',
    headerName: 'Device Type',
    description: 'Device type of the point',
    type: 'string',
    flex: 1,
  },
  {
    field: 'displayName',
    headerName: 'Display Name',
    description: 'Device name of the point',
    type: 'string',
    flex: 1,
  },
  {
    field: 'pointName',
    headerName: 'Point Name',
    description: 'Name of the point',
    type: 'string',
    flex: 1,
  },
  {
    field: 'dataType',
    headerName: 'Data Type',
    description: 'Type of data the point holds',
    type: 'string',
    flex: 1,
  },
  {
    field: 'isSiteControllerSourceDevicePointConfig',
    headerName: 'Is Site Controller Source Device Point Config?',
    description: 'Type of data the point holds',
    type: 'boolean',
    flex: 1,
  },
  {
    field: 'decimalPrecision',
    headerName: 'Decimal Precision',
    description: 'Number of decimal places',
    type: 'number',
    flex: 1,
  },
  {
    field: 'scalingFactor',
    headerName: 'Scaling Factor',
    description: 'Factor to multiply the value by',
    type: 'number',
    flex: 1,
  },
  {
    field: 'invertDisplay',
    headerName: 'Invert Display',
    description: 'Inverting green/red for boolean_integer',
    type: 'number',
    flex: 1,
  },
  {
    field: 'unitOfMeasurement',
    headerName: 'Unit of Measurement',
    description: 'Not current used but will be',
    type: 'number',
    flex: 1,
  },
  {
    field: 'displayDenominator',
    headerName: 'Display Denominator',
    description: 'Used to attach behind the value, ie. RacksOnline/RacksTotal',
    type: 'number',
    flex: 1,
  },
  {
    field: 'valueMappings',
    headerName: 'Value Mappings',
    description: 'Custom Value Mappings',
    type: 'string',
    flex: 1,
  },
];
