import '@components/Battery/SiteUnitCommandsPopup/SiteUnitCommandsPopup.scoped.scss';
import storeConnector from '@store/storeConnector';
import Draggable from 'react-draggable';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

type persistCommandsPopupProps = {
  onClickHandler: (persistCommandSelection: boolean) => '';
};

function PersistCommandsPopup(props: persistCommandsPopupProps) {
  const dialogActionsStyles = {
    display: 'flex',
    justifyContent: 'center',
    pb: 2,
  };

  return (
    <div className='cover-container cover-container-confirm'>
      <Draggable bounds='body'>
        <Dialog open>
          <DialogContent>
            <DialogContentText>
              Persist existing power commands?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={dialogActionsStyles}>
            <Button
              size='small'
              onClick={() => props.onClickHandler(true)}
              variant='contained'
            >
              Yes
            </Button>
            <Button
              size='small'
              onClick={() => props.onClickHandler(false)}
              autoFocus
              variant='outlined'
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Draggable>
    </div>
  );
}

export default storeConnector(PersistCommandsPopup, {});
