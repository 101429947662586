import { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import type { SiteMeta } from '@src/types/SiteMeta';
import useSubscription from '@src/hooks/useSubscription';
import type { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import type { TransformedTeslaConfigItem } from '@src/types/TeslaConfig';
import TSCPointCell from './TSCPointCell';
import TSCStatus from './TSCStatus';
import tscSx from './TSCConfigSx';

interface RowProps {
  name: string;
  configItem: TransformedTeslaConfigItem[];
  siteMeta: SiteMeta;
}

function TSCRowTable({
  configItem,
  dataPoints,
}: {
  configItem: TransformedTeslaConfigItem[];
  dataPoints: DataPointWithValue[];
}) {
  const items = configItem.reduce<
    { pointLabel: string; cmdPayload: string; index: number }[]
  >((acc, item, idx) => {
    if (dataPoints[idx].dataType !== 'float-toggle') {
      acc.push({
        pointLabel: item.pointLabel,
        cmdPayload: item.cmdPayload,
        index: idx,
      });
    }
    return acc;
  }, []);

  return items.length > 0 ? (
    <Table sx={tscSx.configTable}>
      <TableHead>
        <TableRow>
          <TableCell>Parameter</TableCell>
          <TableCell>Current Value</TableCell>
          <TableCell colSpan={2} align='left'>
            Command
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(({ pointLabel, cmdPayload, index }) => (
          <TSCPointCell
            label={pointLabel}
            pointValue={dataPoints[index]?.value}
            payload={cmdPayload}
            key={pointLabel}
          />
        ))}
      </TableBody>
    </Table>
  ) : null;
}

const generateDataPoints = (
  siteId: string,
  configItem: TransformedTeslaConfigItem[],
): DataPoint[] =>
  configItem.map((i) => ({
    id: `00000-00000-00000-00000-0000${i}`,
    createdAt: 'someDate',
    updatedAt: 'someDate',
    deviceType: 'site',
    controllerId: siteId,
    pointName: i.pointLabel.replaceAll(' ', '_'),
    dataType: `${i.currentValueTopic.split('/').pop()}${
      i.inputType === 'Toggle button (enable/disable)' && '-toggle'
    }`,
    displayName: i.pointLabel,
    fullTopic: i.currentValueTopic.replace('0215D28F', siteId),
    sourceDeviceId: null,
    sourceDeviceType: null,
    siteId,
  }));

export default function TSCRow({ name, configItem, siteMeta }: RowProps) {
  const { siteId } = siteMeta;
  const dataPointsConfig = useMemo<DataPoint[]>(
    () => generateDataPoints(siteId, configItem),
    [configItem, siteId],
  );

  const dataPoints = useSubscription(dataPointsConfig);
  const statusFieldIndex = dataPoints.findIndex(
    (dp) => dp.dataType === 'float-toggle',
  );

  const statusField = {
    datapoint: dataPoints[statusFieldIndex],
    item: configItem[statusFieldIndex],
  };

  return (
    <TableRow>
      <TableCell>
        <b>{name}:</b>
      </TableCell>

      {statusField?.datapoint !== undefined &&
      statusField?.item !== undefined ? (
        <TSCStatus datapoint={statusField.datapoint} item={statusField.item} />
      ) : (
        <>
          <TableCell />
          <TableCell />
        </>
      )}
      <TableCell>
        <TSCRowTable configItem={configItem} dataPoints={dataPoints} />
      </TableCell>
    </TableRow>
  );
}
