import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import './ApplicationSettings.scoped.scss';
import DroopCurves from '../DroopCurves/DroopCurves';
import TableFields from '../TableFields';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';

import Button from '@components/_elements/Button/Button';
import { engineerOrAdmin } from '@src/services/auth';
const META_FIELDS = [
  'description',
  'updatedBy',
  'version',
  'name',
  'id',
  'siteId',
  'updatedAt',
  'deviceId',
];
const META_FIELDS_TO_SAVE_NEW = ['name', 'description', 'version'];
const META_FIELDS_TO_SAVE_EXISTING = [
  ...META_FIELDS_TO_SAVE_NEW,
  'id',
  'siteId',
  'updatedBy',
];

const ApplicationSettings = ({
  actions,
  siteMeta,
  paramsId,
  appInStackEdit,
  history,
  closeOnClick,
  showtitle,
  role,
}) => {
  const [currentConfigMode, setCurrentConfigMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [edited, setEdited] = useState(false);
  const [config, setConfig] = useState(null);
  const [newConfig, setNewConfig] = useState(null);
  const [maxLimiters, setMaxLimiters] = useState(null);
  const [minLimiters, setMinLimiters] = useState(null);
  const [boolFields, setBoolFields] = useState([]);
  const [listFields, setListFields] = useState({});
  const [arrayFields, setArrayFields] = useState([]);
  const [invalids, setInvalids] = useState({});
  const [name, setName] = useState(null);
  const [isTableValsValid, setIsTableValsValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const refs = useRef({});

  useEffect(() => {
    getConfig(siteMeta.ControlModes[0]);
  }, []);

  const isNum = (key, key2) => {
    return !!(maxLimiters[`${key}_${key2}`] || minLimiters[`${key}_${key2}`]);
  };
  const calcStep = (group, param) => {
    const key = `${group}_${param}`;
    const minLimiter = minLimiters[key] ? minLimiters[key] : 1;
    const maxLimiter = maxLimiters[key] ? maxLimiters[key] : 1;

    const digits = Math.max(
      Number.isInteger(minLimiter)
        ? 0
        : minLimiter.toString().split('.')[1].length,
      Number.isInteger(maxLimiter)
        ? 0
        : maxLimiter.toString().split('.')[1].length,
    );
    let step = 1;
    for (let i = -1; i++ < digits; ) {
      step /= 10;
    }
    return step;
  };
  const valueValid = (group, param) => {
    const key = `${group}_${param}`;
    if (newConfig[group][param] === '') {
      if (refs.current[`${group}_${param}`]) {
        return !['', ' '].includes(refs.current[`${group}_${param}`]);
      } else {
        return false;
      }
    }
    const value = newConfig[group][param];
    if (maxLimiters[key] !== undefined && +maxLimiters[key] < +value) {
      return false;
    }
    return !(minLimiters[key] !== undefined && +minLimiters[key] > +value);
  };
  const allValuesValid = () => {
    let valid = true;
    if (!newConfig) {
      return true;
    }
    Object.keys(newConfig).some((section) => {
      const sectionProps = newConfig[section];
      return Object.keys(sectionProps).some((prop) => {
        if (
          !valueValid(section, prop) ||
          invalids[`${section}_${prop}`] === false ||
          invalids[prop] === false
        ) {
          valid = false;
          return true;
        }
        return undefined;
      });
    });
    return valid;
  };

  const getConfig = async (mode) => {
    setCurrentConfigMode(mode);
    setLoading(true);
    setEdited(false);
    getFinalConfig(mode);
  };

  const getFinalConfig = async (mode) => {
    setLoading(true);

    const boolFields = [];
    const listFields = {};
    const arrayFields = [];
    const maxLimiters = {};
    const minLimiters = {};

    let r;
    if (paramsId) {
      r = await actions.getApplication(siteMeta.siteId, paramsId);
    } else {
      r = await actions.getSystemConfig(siteMeta.siteId, mode);
    }

    if (!(r && r[r.length - 1])) {
      setLoading(false);
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    let newConfigCopy = JSON.parse(JSON.stringify(r[r.length - 1]));
    const name = newConfigCopy.name || newConfigCopy.Description;
    if (newConfigCopy.body) {
      newConfigCopy = newConfigCopy.body;
    }

    Object.keys(newConfigCopy).forEach((sectionName) => {
      const sectionProps = newConfigCopy[sectionName];
      if (sectionProps !== null && typeof sectionProps === 'object') {
        if (Array.isArray(sectionProps)) {
          arrayFields.push(sectionName);
        } else {
          Object.keys(sectionProps).forEach((property) => {
            if (property.endsWith('__range')) {
              if (
                +sectionProps[property].split(',')[1] >
                +sectionProps[property].split(',')[0]
              ) {
                maxLimiters[
                  `${sectionName}_${property.replace('__range', '')}`
                ] = +sectionProps[property].split(',')[1];
                minLimiters[
                  `${sectionName}_${property.replace('__range', '')}`
                ] = +sectionProps[property].split(',')[0];
              } else {
                maxLimiters[
                  `${sectionName}_${property.replace('__range', '')}`
                ] = +sectionProps[property].split(',')[0];
                minLimiters[
                  `${sectionName}_${property.replace('__range', '')}`
                ] = +sectionProps[property].split(',')[1];
              }
            }
            if (property.endsWith('__min')) {
              minLimiters[`${sectionName}_${property.replace('__min', '')}`] =
                +sectionProps[property];
            }
            if (property.endsWith('__max')) {
              maxLimiters[`${sectionName}_${property.replace('__max', '')}`] =
                +sectionProps[property];
            }
            if (
              property.endsWith('__type') &&
              sectionProps[property] === 'bool'
            ) {
              boolFields.push(property.replace('__type', ''));
            }
            if (property.endsWith('__list')) {
              listFields[property.replace('__list', '')] = {
                defaultValue: sectionProps[property.replace('__list', '')],
                values: sectionProps[property].split(',').map((el) => {
                  return { value: el };
                }),
              };
            }
            if (Array.isArray(sectionProps[property])) {
              arrayFields.push(`${sectionName}_${property}`);
            }
          });
        }
      }
    });
    setConfig(r[r.length - 1]);
    setNewConfig(newConfigCopy);
    setMaxLimiters(maxLimiters);
    setMinLimiters(minLimiters);
    setBoolFields(boolFields);
    setListFields(listFields);
    setArrayFields(arrayFields);
    setName(name);
    setLoading(false);
  };

  const resetConfig = () => {
    getConfig(currentConfigMode);
  };

  const saveConfig = async () => {
    setEdited(false);
    if (!newConfig) {
      actions.notifyError('Can’t create config without template');
      return;
    }
    let newConfigWithStringValues = JSON.parse(JSON.stringify(newConfig));
    Object.keys(newConfig).forEach((section) => {
      if (
        newConfigWithStringValues[section] !== null &&
        typeof newConfigWithStringValues[section] === 'object'
      ) {
        Object.keys(newConfigWithStringValues[section]).forEach((property) => {
          if (
            typeof newConfigWithStringValues[section][property] === 'number' ||
            typeof newConfigWithStringValues[section][property] === 'boolean'
          ) {
            newConfigWithStringValues[section][property] =
              `${newConfigWithStringValues[section][property]}`;
          }
        });
      }
      if (typeof newConfigWithStringValues[section] === 'number') {
        newConfigWithStringValues[section] =
          `${newConfigWithStringValues[section]}`;
      }
    });
    newConfigWithStringValues.updatedAt = new Date().getTime();
    const applications = await actions.loadApplications(siteMeta.siteId);
    const names = Object.values(applications)
      .map((app) => app.name)
      .filter((name) => {
        if (config) {
          return name !== config.name;
        }
        return true;
      });
    if (names.includes(name)) {
      actions.notifyError(`Name "${name}" is already in use`);
      setEdited(true);
    } else {
      const appDataToSave = {
        ...newConfigWithStringValues,
        name,
      };
      const appToSave = Object.keys(appDataToSave).reduce((acc, cv) => {
        if (META_FIELDS.includes(cv)) {
          if (
            (paramsId
              ? META_FIELDS_TO_SAVE_EXISTING
              : META_FIELDS_TO_SAVE_NEW
            ).includes(cv)
          ) {
            return { ...acc, [cv]: appDataToSave[cv] };
          } else {
            return acc;
          }
        } else {
          return {
            ...acc,
            body: { ...(acc.body || {}), [cv]: appDataToSave[cv] },
          };
        }
      }, {});
      await actions.saveApplication(siteMeta.siteId, paramsId, appToSave);
      getConfig(currentConfigMode);
      !appInStackEdit
        ? history.push('/home/config/applications/')
        : closeOnClick();
    }
  };

  const updateConfig = (valueData, sectionName, groupParam) => {
    let value = valueData;
    const newConfigCopy = JSON.parse(JSON.stringify(newConfig));
    if (groupParam) {
      if (boolFields.includes(groupParam)) {
        value = newConfigCopy[sectionName][groupParam] === '0' ? '1' : '0';
        newConfigCopy[sectionName][groupParam] = +value;
      } else if (isNum(sectionName, groupParam)) {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
          newConfigCopy[sectionName][groupParam] = value;
        } else {
          if (+value === 0) {
            newConfigCopy[sectionName][groupParam] = value;
          } else {
            newConfigCopy[sectionName][groupParam] = +value;
          }
        }
      } else {
        newConfigCopy[sectionName][groupParam] = value;
      }
    } else {
      newConfigCopy[sectionName] = value;
    }
    setNewConfig(newConfigCopy);
    setEdited(true);
  };

  const updateName = (value) => {
    setEdited(true);
    setName(JSON.parse(JSON.stringify(value)));
  };

  const updateTableValues = (
    newTableValues,
    hasInvalids,
    section,
    property,
  ) => {
    hasInvalids ? setIsTableValsValid(true) : setIsTableValsValid(false);
    const invalidsCopy = { ...invalids };
    invalidsCopy[property ? `${section}_${property}` : section] = hasInvalids;
    setInvalids(invalidsCopy);
    updateConfig(newTableValues, section, property);
  };

  return (
    <>
      {loading && <Spinner cover='container' />}
      {!loading && (
        <>
          <div
            className={
              appInStackEdit
                ? 'app-settings-stack'
                : showError
                  ? 'system-config-wrap-error'
                  : 'system-config-wrap'
            }
          >
            <div className='system-config' style={{ padding: '0px' }}>
              <div className='app-name' style={{ justifyContent: 'normal' }}>
                {showtitle && (
                  <>
                    <div className='app-title'>
                      <span>Application Name: </span>
                    </div>
                    <div>
                      <input
                        className={
                          'app-input' + (!name ? ' invalid-input' : '')
                        }
                        type='text'
                        placeholder='Enter Application Name'
                        defaultValue={name}
                        onChange={(e) => updateName(e.target.value)}
                      />
                    </div>
                  </>
                )}
                <div className='button-row'>
                  {engineerOrAdmin(role) && (
                    <>
                      {!appInStackEdit && (
                        <Button
                          disabled={
                            !edited ||
                            !name ||
                            !allValuesValid() ||
                            isTableValsValid
                          }
                          onClick={() => saveConfig()}
                        >
                          {paramsId ? 'Save' : 'Create'}
                        </Button>
                      )}
                      {appInStackEdit && (
                        <Button
                          disabled={
                            !edited ||
                            !name ||
                            !allValuesValid() ||
                            isTableValsValid
                          }
                          onClick={() => {
                            saveConfig();
                            closeOnClick();
                          }}
                        >
                          {paramsId ? 'Save' : 'Create'}
                        </Button>
                      )}
                      <Button
                        disabled={!edited}
                        onClick={() => {
                          resetConfig();
                        }}
                      >
                        Reset
                      </Button>
                    </>
                  )}
                  {!appInStackEdit && (
                    <Link to={'/home/config/applications/'}>
                      <Button>Back</Button>
                    </Link>
                  )}
                  {appInStackEdit && (
                    <Button
                      onClick={() => {
                        closeOnClick();
                      }}
                    >
                      Close
                    </Button>
                  )}
                </div>
              </div>
              <div className='app-name'>
                <div className='system-info-app'>
                  <div className='app-title' style={{ width: '120px' }}>
                    <span>Description:</span>
                  </div>
                  {showtitle && !paramsId ? (
                    <select
                      onChange={(e) => {
                        getConfig(e.target.value);
                      }}
                      defaultValue={currentConfigMode}
                    >
                      {siteMeta &&
                        siteMeta.ControlModes.map((mode, i) => (
                          <option key={i} value={mode}>
                            {mode}
                          </option>
                        ))}
                    </select>
                  ) : (
                    !!newConfig && (
                      <>
                        <span>{newConfig.description || ''}</span>&nbsp;&nbsp;
                        <span style={{ fontSize: 'smaller' }}>version: </span>
                        &nbsp;
                        <span>{newConfig.version || ''}</span>
                        <>,</>&nbsp;&nbsp;
                        <span style={{ fontSize: 'smaller' }}>edited: </span>
                        &nbsp;
                        <span>
                          {newConfig.updatedAt
                            ? moment(newConfig.updatedAt)
                                .utc()
                                .format('YYYY-MM-DD hh:mm:ss')
                            : ''}
                        </span>
                        &nbsp;&nbsp;
                        <span style={{ fontSize: 'smaller' }}>by</span>&nbsp;
                        <span>{newConfig.updatedBy || ''}</span>
                      </>
                    )
                  )}
                </div>
              </div>
              {showError && (
                <div className='error-msg'>
                  Template not found
                  <br />
                  Please select other
                </div>
              )}
              {!showError && (
                <div className='home-row frow' style={{ minHeight: '450px' }}>
                  <div className='system-left'>
                    <div className='cell config-container'>
                      <div className='config-grid'>
                        {newConfig &&
                          Object.keys(newConfig).map((section, index) =>
                            typeof newConfig[section] === 'object' &&
                            newConfig[section] !== null &&
                            !META_FIELDS.includes(section)
                              ? (arrayFields.includes(section) && (
                                  <React.Fragment key={`field-${index}`}>
                                    <div className='spc' />
                                    <div className='spc' />
                                    <div className='name'>{section}</div>
                                    <div>
                                      <TableFields
                                        settings={newConfig[section]}
                                        section={section}
                                        onTableUpdate={(
                                          newVals,
                                          hasInvalids,
                                          section,
                                        ) =>
                                          updateTableValues(
                                            newVals,
                                            hasInvalids,
                                            section,
                                          )
                                        }
                                      />
                                    </div>
                                    <div className='spc' />
                                    <div className='spc' />
                                  </React.Fragment>
                                )) ||
                                (!arrayFields.includes(section) && (
                                  <React.Fragment key={`field-${index}`}>
                                    <div className='spc' />
                                    <div className='spc' />
                                    <div className='title'>{section}</div>
                                    <div />
                                    {Object.keys(newConfig[section]).map(
                                      (property, index2) =>
                                        !property.includes('__range') &&
                                        !property.includes('__min') &&
                                        !property.includes('__max') &&
                                        !property.includes('__type') &&
                                        !property.includes('__list') && (
                                          <React.Fragment
                                            key={`field-${index}-${index2}`}
                                          >
                                            <div className='name'>
                                              {property}
                                            </div>
                                            <div>
                                              <div
                                                className={`${
                                                  arrayFields.includes(
                                                    `${section}_${property}`,
                                                  )
                                                    ? 'table-scroll-input'
                                                    : 'input'
                                                }`}
                                              >
                                                {minLimiters[
                                                  `${section}_${property}`
                                                ] && (
                                                  <>
                                                    <span className='small-font'>
                                                      fr:
                                                    </span>
                                                    <span className='small-val'>
                                                      {
                                                        minLimiters[
                                                          `${section}_${property}`
                                                        ]
                                                      }
                                                    </span>
                                                    &nbsp;
                                                  </>
                                                )}
                                                {maxLimiters[
                                                  `${section}_${property}`
                                                ] && (
                                                  <>
                                                    <span className='small-font'>
                                                      {' '}
                                                      to:
                                                    </span>
                                                    <span className='small-val'>
                                                      {
                                                        maxLimiters[
                                                          `${section}_${property}`
                                                        ]
                                                      }
                                                    </span>
                                                  </>
                                                )}
                                                {listFields[property] ? (
                                                  <select
                                                    className='select-dropdown'
                                                    value={
                                                      listFields[property]
                                                        .defaultValue
                                                    }
                                                    onChange={(e) => {
                                                      if (
                                                        e.target !== null &&
                                                        e.target.value !== ''
                                                      ) {
                                                        const val =
                                                          e.target.value;
                                                        let listFieldsWithNewValue =
                                                          {
                                                            ...listFields,
                                                          };
                                                        listFieldsWithNewValue[
                                                          property
                                                        ].defaultValue = val;
                                                        setListFields(
                                                          listFieldsWithNewValue,
                                                        );
                                                        updateConfig(
                                                          val,
                                                          section,
                                                          property,
                                                        );
                                                      } else {
                                                        setListFields(
                                                          (listFields) =>
                                                            listFields,
                                                        );
                                                        updateConfig(
                                                          listFields[property]
                                                            .defaultValue,
                                                          section,
                                                          property,
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    {listFields[
                                                      property
                                                    ].values.map((v) => (
                                                      <option
                                                        key={v}
                                                        value={v.value}
                                                      >
                                                        {v.value}
                                                      </option>
                                                    ))}
                                                  </select>
                                                ) : arrayFields.includes(
                                                    `${section}_${property}`,
                                                  ) ? (
                                                  <TableFields
                                                    settings={
                                                      newConfig[section][
                                                        property
                                                      ]
                                                    }
                                                    section={section}
                                                    property={property}
                                                    onTableUpdate={(
                                                      newVals,
                                                      hasInvalids,
                                                      section,
                                                      property,
                                                    ) =>
                                                      updateTableValues(
                                                        newVals,
                                                        hasInvalids,
                                                        section,
                                                        property,
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <input
                                                    type={
                                                      boolFields.includes(
                                                        property,
                                                      )
                                                        ? 'checkbox'
                                                        : isNum(
                                                              section,
                                                              property,
                                                            )
                                                          ? 'number'
                                                          : 'text'
                                                    }
                                                    defaultChecked={
                                                      newConfig[section][
                                                        property
                                                      ] !== '0'
                                                    }
                                                    ref={(element) =>
                                                      (refs.current[
                                                        section + '_' + property
                                                      ] = element)
                                                    }
                                                    className={
                                                      'comand-popup-inp ' +
                                                      (!valueValid(
                                                        section,
                                                        property,
                                                      )
                                                        ? 'error'
                                                        : '')
                                                    }
                                                    onChange={(e) => {
                                                      updateConfig(
                                                        e.target.value,
                                                        section,
                                                        property,
                                                      );
                                                    }}
                                                    value={
                                                      newConfig[section][
                                                        property
                                                      ]
                                                        ? newConfig[section][
                                                            property
                                                          ]
                                                        : '' +
                                                          newConfig[section][
                                                            property
                                                          ]
                                                    }
                                                    step={calcStep(
                                                      section,
                                                      property,
                                                    )}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        ),
                                    )}
                                    <div className='spc' />
                                    <div className='spc' />
                                  </React.Fragment>
                                ))
                              : !META_FIELDS.includes(section) && (
                                  <React.Fragment key={'title-' + index}>
                                    <div className='spc' />
                                    <div className='spc' />
                                    <div className='name'>{section}</div>
                                    <div className='input input-large'>
                                      <input
                                        type='text'
                                        onChange={(e) =>
                                          updateConfig(e.target.value, section)
                                        }
                                        defaultValue={newConfig[section]}
                                      />
                                    </div>
                                    <div className='spc' />
                                    <div className='spc' />
                                  </React.Fragment>
                                ),
                          )}
                      </div>
                    </div>
                  </div>
                  {
                    <div className='system-right'>
                      <DroopCurves config={newConfig} />
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default storeConnector(ApplicationSettings, {
  service: ['history'],
  user: ['role'],
  config: ['siteMeta'],
});
