import { PointConfigEdit, PointConfigExtended } from '@src/types/PointConfig';
import { DeviceEdit, DeviceExtended, GenerateDevicePayload } from './Device';
import {
  WidgetConfigCreateOrEdit,
  WidgetConfigExtended,
  WidgetCreate,
} from './Widget';

export type CollectionPayload =
  | GenerateDevicePayload
  | DeviceExtended
  | PointConfigExtended
  | WidgetConfigExtended
  | DeviceEdit
  | PointConfigEdit
  | WidgetConfigCreateOrEdit
  | WidgetCreate;

export enum CollectionTypes {
  DEVICES,
  POINT_CONFIGS,
  WIDGETS,
}

export enum PCActionType {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  BULK_DELETE = 'bulk_delete',
}
