import './ProgressBar.scoped.scss';
import ChartLoader from '@components/_elements/ChartLoader';

const ProgressBar = ({ styleClass, loading, value, title }) => {
  const progressValue =
    value === undefined || value === null || typeof value !== 'number'
      ? 'ERR'
      : `${Number(value).toFixed(2)}%`;

  return (
    <div
      className={`pg-bar-new-style ${styleClass}`}
      style={{ position: 'relative' }}
    >
      {loading && <ChartLoader />}
      <div className='bcgrnd'>
        <div className='progress-line-bg' style={{ width: '240px' }}>
          <div className='progresline' style={{ width: progressValue }} />
        </div>
      </div>
      <div className='value'>{progressValue}</div>
      <div className='title'>{title}</div>
    </div>
  );
};

export default ProgressBar;
