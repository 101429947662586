import { useEffect, useRef, useState } from 'react';
import './SunChart.scoped.scss';
import * as d3 from 'd3';
import 'd3-transition';
import moment from 'moment';
import { calcTooltipPosition, formatTS } from '@utils';
import storeConnector from '@store/storeConnector';
import Spinner from '@components/_elements/Spinner/Spinner';

const SunChart = ({ actions, sitePV, siteMeta, timeMode, fixedYTicks }) => {
  const [activeScale, setActiveScale] = useState('1H');
  const [loading, setLoading] = useState(true);
  const tooltipDiv = useRef();
  const svg = useRef();
  const svgEl = useRef();

  useEffect(() => {
    const listener = () => {
      drawChart();
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  });

  useEffect(() => {
    tooltipDiv.current = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)
      .style('top', 0)
      .style('left', 0);

    load(activeScale);

    return () => {
      actions.clearSitePV();
      tooltipDiv.current.remove();
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      drawChart();
    }
  }, [loading]);

  const load = async (scale) => {
    setLoading(true);
    setActiveScale(scale);
    await actions.loadSitePV(siteMeta?.siteId, scale, true);
    setLoading(false);
  };

  const drawChart = () => {
    if (!svgEl.current) {
      return;
    }
    const period = {
      '15m': '15min',
      '1h': '1hour',
      '12h': '12hour',
      '1d': '1day',
      '3d': '3day',
    }[activeScale.toLocaleLowerCase()];

    const barWidth = {
      '15M': 22,
      '1H': 6,
      '12H': 6,
      '1D': 3,
      '3D': 1,
    }[activeScale];
    const width = svgEl.current.getBoundingClientRect().width - 5;
    const height = svgEl.current.getBoundingClientRect().height;
    svg.current = d3.select(svgEl.current);
    svg.current.selectAll('*').remove();
    const yTicks = fixedYTicks || height / 80;
    const margin = {
      top: 30,
      right: 50,
      bottom: 20,
      left: 50,
    };
    const x = d3.scaleTime().rangeRound([margin.left, width - margin.right]);
    const yLeft = d3.scaleLinear().range([height - margin.bottom, margin.top]);
    const yRight = d3.scaleLinear().range([height - margin.bottom, margin.top]);
    const PVkW = sitePV[period].map((data) => data.PVkW + 100);
    const PV_Irradiance_GHI = sitePV[period].map(
      (data) => data.PV_Irradiance_GHI + data.PV_Irradiance_GHI * 0.05,
    );

    const TS = sitePV[period].map((data) => data.TS);
    const padding = {
      '15M': {
        value: 10,
        unit: 'seconds',
      },
      '1H': {
        value: 1,
        unit: 'minutes',
      },
      '12H': {
        value: 12,
        unit: 'minutes',
      },
      '1D': {
        value: 0.25,
        unit: 'hours',
      },
      '3D': {
        value: 1,
        unit: 'hours',
      },
    }[activeScale];
    const minmax = d3.extent(TS, (d) => new Date(d));
    const mindate = moment.utc(minmax[0]).subtract(padding.value, padding.unit);
    const maxdate = moment.utc(minmax[1]).add(padding.value, padding.unit);
    x.domain(d3.extent([new Date(mindate), new Date(maxdate)]));
    yLeft.domain([0, d3.max(PVkW, (el) => Number(el))]);
    yRight.domain([0, d3.max(PV_Irradiance_GHI, (el) => Number(el))]);

    const xAxis = (g) =>
      g
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .attr('class', 'color-chart-gray')
        .call(
          d3
            .axisBottom(x)
            .ticks(d3.timeHour.every(24))
            .tickFormat(d3.timeFormat('%b %e')),
        );
    svg.current
      .append('g')
      .call(xAxis)
      .call((g) => {
        g.selectAll('.tick line').attr('opacity', '0.2');
      });

    const yLeftAxis = (g) =>
      g
        .attr('transform', `translate(${margin.left},0)`)
        .attr('class', 'color-chart-gray')
        .call(d3.axisLeft(yLeft).ticks(yTicks).tickSize(0))
        .call((g) => g.select('.domain').attr('style', 'opacity:0.2;'))
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) => g.selectAll('.tick text').attr('x', -10));

    svg.current.append('g').call(yLeftAxis);

    const yRightAxis = (g) =>
      g
        .attr('transform', `translate(${width - margin.right},0)`)
        .attr('class', 'color-chart-gray')
        .call(d3.axisRight(yRight).ticks(yTicks).tickSize(0))
        .call((g) => g.select('.domain').attr('style', 'opacity:0.2;'))
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', 10)
            .attr('style', 'color:#fa9632;'),
        );

    svg.current.append('g').call(yRightAxis);

    // <green-bar>
    svg.current
      .selectAll('bar')
      .data(sitePV[period])
      .enter()
      .append('rect')
      .attr('x', (d) => x(moment(d.TS)) - barWidth / 2)
      .attr('y', (d) => yLeft(d.PVkW))
      .attr('width', barWidth)
      .attr('height', (d) => height - yLeft(+d.PVkW) - margin.bottom)
      .attr('class', 'color-bar')
      .attr('style', 'pointer-events: visible;')
      .on('mouseover', (d) => {
        tooltipDiv.current.transition().duration(200).style('opacity', 0.9);
        tooltipDiv.current.html(`
          <div>${formatTS(d.TS, timeMode)}</div>
          <div class='color-bar'>
            Inverters output: ${d.PVkW.toFixed(2)} kW
          </div>
        `);
        const [x, y] = calcTooltipPosition(
          d3.event.pageX,
          d3.event.pageY,
          tooltipDiv.current,
        );
        tooltipDiv.current.style('left', x + 'px').style('top', y + 'px');
      })
      .on('mouseout', () => {
        tooltipDiv.current
          .transition()
          .duration(500)
          .style('opacity', 0)
          .on('end', () => {
            tooltipDiv.current.style('left', 0).style('top', 0);
          });
      });
    // </green-bar>

    // <orange-line>
    const lineWS = d3
      .line()
      .defined(() => true)
      .x((d) => x(new Date(d.TS)))
      .y((d) => yRight(d.PV_Irradiance_GHI));

    svg.current
      .append('path')
      .datum(sitePV[period])
      .attr('fill', 'none')
      .attr('class', 'color-chart-0')
      .attr('stroke-width', 2)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('d', lineWS);

    // <orange-line-dots>
    svg.current
      .selectAll('dot')
      .data(sitePV[period])
      .enter()
      .append('circle')
      .attr('r', 3)
      .attr('cx', (d) => x(new Date(d.TS)))
      .attr('cy', (d) => yRight(d.PV_Irradiance_GHI))
      .attr('class', 'color-dot-0')
      .on('mouseover', (d) => {
        tooltipDiv.current.transition().duration(200).style('opacity', 0.9);
        tooltipDiv.current.html(`
          <div>${formatTS(d.TS, timeMode)}</div>
          <div class='color-dot-0'>
            Weather Station: ${d.PV_Irradiance_GHI.toFixed(2)} W/m2
          </div>
        `);
        const [x, y] = calcTooltipPosition(
          d3.event.pageX,
          d3.event.pageY,
          tooltipDiv.current,
        );
        tooltipDiv.current.style('left', x + 'px').style('top', y + 'px');
      })
      .on('mouseout', () => {
        tooltipDiv.current
          .transition()
          .duration(500)
          .style('opacity', 0)
          .on('end', () => {
            tooltipDiv.current.style('left', 0).style('top', 0);
          });
      });
  };

  return (
    <div className='cell block-container'>
      <div className='chart-wrapper' style={{ width: '100%' }}>
        <div className='chart-top'>
          <div className='dur-switches'>
            {['15M', '1H', '12H', '1D', '3D'].map((ds, i) => (
              <div
                key={i}
                className={ds === activeScale ? 'active' : undefined}
                onClick={() => load(ds)}
              >
                {ds}
              </div>
            ))}
          </div>
        </div>
        {loading ? (
          <div className='chat-spinner-holder'>
            <Spinner cover={'container'} />
          </div>
        ) : (
          ''
        )}
        <div className='axis-holder'>
          <div className='axis-name-l'>Kilowatts</div>
        </div>
        <svg
          width='100%'
          height='90%'
          ref={svgEl}
          style={{ marginTop: '15px' }}
        />
        <div className='axis-holder'>
          <div className='axis-name-r'>
            Watts/Meter
            <sup>2</sup>
          </div>
        </div>
      </div>
      <div className='line-names'>
        <div className='chart-lines'>
          <div className='output-color' />
          <div className='output'>
            Inverter output <sub>kW</sub>
          </div>
        </div>
        <div className='chart-lines weather-info'>
          <div className='weather-color' />
          <div className='weather'>
            Weather station{' '}
            <sub>
              W/M<sup>2</sup>
            </sub>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(SunChart, {
  user: ['timeMode'],
  site: ['sitePV'],
  config: ['siteMeta'],
});
