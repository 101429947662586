import { Device } from '@src/types/Device';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const CurrentPowinEmsDeviceContext = createContext(
  null as unknown as {
    currentPowinEmsDevice: Device | undefined;
    setCurrentPowinEmsDevice: React.Dispatch<
      React.SetStateAction<Device | undefined>
    >;
  },
);

export function CurrentPowinEmsDeviceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentPowinEmsDevice, setCurrentPowinEmsDevice] = useState<Device>();

  const value = useMemo(
    () => ({ currentPowinEmsDevice, setCurrentPowinEmsDevice }),
    [currentPowinEmsDevice],
  );

  return (
    <CurrentPowinEmsDeviceContext.Provider value={value}>
      {children}
    </CurrentPowinEmsDeviceContext.Provider>
  );
}

export const useCurrentPowinEmsDeviceContext = () => {
  const { currentPowinEmsDevice, setCurrentPowinEmsDevice } = useContext(
    CurrentPowinEmsDeviceContext,
  );
  return { currentPowinEmsDevice, setCurrentPowinEmsDevice };
};
