import { Device } from '@src/types/Device';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const CurrentTscDeviceContext = createContext(
  null as unknown as {
    currentTscDevice: Device | undefined;
    setCurrentTscDevice: React.Dispatch<
      React.SetStateAction<Device | undefined>
    >;
  },
);

export function CurrentTscDeviceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentTscDevice, setCurrentTscDevice] = useState<Device>();

  const value = useMemo(
    () => ({ currentTscDevice, setCurrentTscDevice }),
    [currentTscDevice],
  );

  return (
    <CurrentTscDeviceContext.Provider value={value}>
      {children}
    </CurrentTscDeviceContext.Provider>
  );
}

export const useCurrentTscDeviceContext = () => {
  const { currentTscDevice, setCurrentTscDevice } = useContext(
    CurrentTscDeviceContext,
  );
  return { currentTscDevice, setCurrentTscDevice };
};
