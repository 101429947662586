import { SavedSearch, TimeMode } from '@types';
import { ReactElement } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import storeConnector from '@store/storeConnector';
import { Box } from '@mui/material';
import { isDayjsOrNull } from '@utils/index_ts';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DateTimePickerProps {
  activeSearch: SavedSearch;
  setSearchField: (field: string, value: Dayjs) => void;
  field: string;
  ampm?: boolean;
  timeMode: TimeMode;
}

function DateTimePicker(p: DateTimePickerProps): ReactElement {
  const ampm = p.ampm || false;
  const value = p.activeSearch[p.field];

  if (!isDayjsOrNull(value)) {
    throw new Error(`MuiDateTimePicker Expected Dayjs`);
  }

  const onChange = (val: Dayjs | null) => {
    if (isDayjsOrNull(val) && val !== null) {
      p.setSearchField(p.field, val);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box role={`date-time-picker-${p.field}`}>
        <MuiDateTimePicker
          onChange={onChange}
          value={value}
          ampm={ampm}
          maxDateTime={dayjs()}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default storeConnector(DateTimePicker, {
  service: ['timeMode', 'ampm'],
});
