import { Box } from '@mui/material';
import { useLocation } from 'react-router';
import { ReactNode } from 'react';

type CollectionWrapperProps = {
  children: ReactNode;
};

export default function CollectionWrapper({
  children,
}: CollectionWrapperProps) {
  const isWizard = useLocation().pathname.includes('wizard');
  return isWizard ? (
    <Box
      sx={{
        maxHeight: 595,
        '@media (min-height: 1080px)': {
          maxHeight: 705,
        },
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        '.block-container': {
          padding: 0,
        },
        '.MuiDataGrid-toolbarContainer': {
          padding: '10px',
        },
      }}
    >
      {children}
    </Box>
  ) : (
    <div className='home-row frow' id='device-config-wrapper'>
      <div className='fcol h100 full-width'>{children}</div>
    </div>
  );
}
