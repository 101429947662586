import { useEffect, useMemo, useState } from 'react';
import QuestionIcon from '@assets/circle-question.svg?react';
import '@src/pages/Home/pages/Alerts/pages/Active/index.scoped.scss';
import { store } from '@store';
import storeConnector from '@store/storeConnector';
import { getTopicInfo } from '@store/reducers/alerts';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const decoder = new TextDecoder('utf-8');

const LabelCell = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(22),
  padding: theme.spacing(1),
}));
const ValueCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
}));

const AlarmInfo = ({
  isOpen,
  historicalAlertObj,
  toggle,
  siteMeta,
  alertConfig,
}) => {
  const { ui } = siteMeta;

  const { dataSource, deviceName } = getTopicInfo(
    ui?.Use_New_Topic_Structure || false,
    historicalAlertObj?.topic,
  );
  const [value, setValue] = useState('');
  const clientMQTT = useMemo(() => store.getState().mqtt.clientMQTT, []);

  const onMessage = (topic, msg) => {
    if (topic === historicalAlertObj?.topic) {
      const decodedMsg = JSON.parse(decoder.decode(msg));
      setValue(decodedMsg.value);
    }
  };

  useEffect(() => {
    if (!clientMQTT) {
      return () => {};
    }
    if (historicalAlertObj.topic) {
      clientMQTT.on('message', onMessage);
      clientMQTT.subscribe(historicalAlertObj.topic);
    }

    return () => {
      clientMQTT.unsubscribe(historicalAlertObj.topic);
      clientMQTT.removeListener('message', onMessage);
    };
  }, []);

  return (
    <Dialog maxWidth='sm' open={isOpen} onClose={toggle} fullWidth>
      <DialogTitle>
        Alert Help: <strong>{alertConfig.alertName}</strong>{' '}
        <QuestionIcon className='icon lg' />
      </DialogTitle>
      <IconButton
        onClick={toggle}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        color='primary'
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Paper sx={{ p: 0.5, overflow: 'auto' }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <LabelCell>Severity:</LabelCell>
                  <ValueCell>{alertConfig.severity}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Device:</LabelCell>
                  <ValueCell>{String(deviceName)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Message:</LabelCell>
                  <ValueCell>{String(historicalAlertObj?.message)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Data Source:</LabelCell>
                  <ValueCell>{String(dataSource)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Hi Threshold:</LabelCell>
                  <ValueCell>{String(alertConfig.thresholdMax)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Low Threshold:</LabelCell>
                  <ValueCell>{String(alertConfig.thresholdMin)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Timeout to activate (ms):</LabelCell>
                  <ValueCell>{String(alertConfig.timeoutActivateMs)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Timeout to remove (ms):</LabelCell>
                  <ValueCell>{String(alertConfig.timeoutRemoveMs)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Config topic:</LabelCell>
                  <ValueCell>{String(alertConfig.topic)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Trigger topic:</LabelCell>
                  <ValueCell>{String(historicalAlertObj.topic)}</ValueCell>
                </TableRow>
                <TableRow>
                  <LabelCell>Value:</LabelCell>
                  <ValueCell>{String(value)}</ValueCell>
                </TableRow>
                {historicalAlertObj.isAcked ? (
                  <>
                    <TableRow>
                      <LabelCell>Acknowledged By:</LabelCell>
                      <ValueCell>
                        {String(historicalAlertObj.ackedBy)}
                      </ValueCell>
                    </TableRow>
                    <TableRow>
                      <LabelCell>Acknowledge Note:</LabelCell>
                      <ValueCell>
                        {String(historicalAlertObj.ackedNote)}
                      </ValueCell>
                    </TableRow>
                  </>
                ) : (
                  ''
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default storeConnector(AlarmInfo, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
