import Grid2 from '@mui/material/Grid2';
import MultiColPointDataTable from '@src/components/_shared/MultiColPointDataTable';
import { Typography } from '@mui/material';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import { WidgetTypePointList } from '@src/types/Widget';
import Spinner from '@components/_elements/Spinner/Spinner';
import { GridRowId } from '@mui/x-data-grid-pro';

export default function BMSDetail({
  bmsId,
  unitControllerId,
  unitEngineeringId,
}: {
  bmsId: GridRowId;
  bmsName: string;
  unitControllerId: string;
  unitEngineeringId: string;
}) {
  const {
    data: widgetConfig,
    isLoading,
    isError,
  } = useGetInternalWidgetConfig<WidgetTypePointList>(
    'bmsDetail',
    {
      sourceDeviceId: bmsId as string,
      unitControllerId: unitControllerId,
      unitControllerEngineeringId: unitEngineeringId,
    },
    {
      queryKeys: [bmsId as string],
    },
  );

  if (isLoading) {
    return <Spinner type='content-small' />;
  }
  if (isError) {
    return (
      <Typography textAlign='center' marginTop='50px'>
        Error fetching bms details.
      </Typography>
    );
  }

  return (
    <Grid2 container justifyContent='space-evenly'>
      <MultiColPointDataTable
        dataPointsMatrix={widgetConfig?.dataPoints || []}
      />
    </Grid2>
  );
}
