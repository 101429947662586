const QueryKeys = {
  user: 'user',
  users: 'users',
  'datapoints-search': 'datapoints-search',
  'widget-config': 'widget-config',
  'widget-config-internal': 'widget-config-internal',
  siteMeta: 'site-meta',
  groups: 'groups',
  'user-groups': 'user-groups',
  notes: 'notes',
  auditLogs: 'audit-logs',
  alerts: 'alerts',
  alertConfigs: 'alert-configs',
  pointConfigs: 'point-configs',
  widgets: 'widgets',
  devices: 'devices',
  devicesFiltered: 'devicesFiltered',
};

export default QueryKeys;
