import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import AddUserDialog from '../Dialogs/AddUserDialog';

function AddUserButton() {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>
        <AddIcon />
        Add User
      </Button>
      {dialogOpen && (
        <AddUserDialog open onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
}

export default AddUserButton;
