import { generateMqttClient } from '@pages/_loader/initMqttClient.loader';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import storeConnector from '@store/storeConnector';
import { MqttClient } from 'mqtt';
import { useEffect, useMemo, useState } from 'react';
import TSCCommandsModal from '@components/Battery/Tesla/Commands/TSC/TSCCommandsModal';
import DeviceDataTable from '@components/_shared/DeviceDataTable';
import { useCurrentTscDeviceContext } from '@hooks/useCurrentTscDevice';
import UIBox from '@components/_shared/UIBox';
import useGetInternalWidgetConfig from '@src/hooks/api/queries/useGetInternalWidgetConfig';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';

function TeslaControllerList({ role }: { role: UserRole }) {
  const { currentTscDevice, setCurrentTscDevice } =
    useCurrentTscDeviceContext();
  const { deviceId: currentTscId } = currentTscDevice || { deviceId: 'tsc_1' };
  const {
    data: widgetConfig,
    isLoading: isLoadingWidgetData,
    isError: isErrorOnWidgetData,
  } = useGetInternalWidgetConfig<WidgetTypeDeviceList>('tscList');

  const memoizedDevices = useMemo(
    () => widgetConfig?.devices || [],
    [widgetConfig?.devices],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (currentTscDevice === undefined) {
      setCurrentTscDevice(memoizedDevices[0]);
    }
  }, [memoizedDevices]);

  const memoizedDataPoints = useMemo(
    () => widgetConfig?.dataPoints || [],
    [widgetConfig?.dataPoints],
  );

  const memoizedPointConfigRefs = useMemo(
    () => widgetConfig?.pointConfigRefs || [],
    [widgetConfig?.pointConfigRefs],
  );

  const [cmdModal, setCmdModal] = useState(false);
  const [deviceIdForCmdModal, setDeviceIdForCmdModal] = useState<string>('');
  const tscNameForCmdModal = useMemo(
    () =>
      memoizedDevices.find((device) => device.deviceId === deviceIdForCmdModal)
        ?.name,
    [memoizedDevices, deviceIdForCmdModal],
  );

  const handleRowSelection = (newTscId: string) => {
    if (newTscId) {
      setCurrentTscDevice(
        memoizedDevices.find((device) => device.deviceId === newTscId),
      );
    }
  };

  const closeCmdModal = () => {
    setCmdModal(false);
  };

  const [mqttClient, setMqttClient] = useState<MqttClient | undefined>(
    undefined,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (!cmdModal) {
      mqttClient?.end();
      setMqttClient(undefined);
      return () => {};
    }
    const startClient = async () => {
      const client = await generateMqttClient();
      setMqttClient(client);
    };

    startClient();
    return () => {
      mqttClient?.end();
    };
  }, [cmdModal]);

  return (
    <UIBox
      header={widgetConfig?.header}
      isLoading={isLoadingWidgetData}
      isError={isErrorOnWidgetData}
    >
      <DeviceDataTable
        id={'tesla-controllers-list'}
        enableCmdBtnColumn={isCommandsEnabled() && isActionRole(role)}
        hideFooter
        devices={memoizedDevices}
        dataPoints={memoizedDataPoints}
        pointConfigRefs={memoizedPointConfigRefs}
        openCmdModal={(deviceId: string) => {
          setDeviceIdForCmdModal(deviceId);
          setCmdModal(true);
        }}
        rowSelectionModel={[currentTscId]}
        onRowSelectionModelChange={([newTscId]: string) =>
          handleRowSelection(newTscId)
        }
        getRowClassName={() => 'MuiDataGrid-clickable'}
      />
      {isCommandsEnabled() && isActionRole(role) && cmdModal && mqttClient ? (
        <TSCCommandsModal
          mqttClient={mqttClient}
          deviceName={tscNameForCmdModal}
          deviceId={deviceIdForCmdModal}
          onClose={() => closeCmdModal()}
        />
      ) : null}
    </UIBox>
  );
}

export default storeConnector(TeslaControllerList, {
  user: ['role'],
});
